import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hb-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {

  public isScrolled = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  public setScrolled(e: any) {
    this.isScrolled = e.target.scrollTop > 0;
  }

}
