import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CameraSearchComponent} from './camera-search.component';


@Injectable({
  providedIn: 'root'
})
export class CameraSearchDialogService extends BaseDialogService<CameraSearchComponent, void> {

  protected config: MatDialogConfig = {
    hasBackdrop: true,
    backdropClass: 'hb-camera-backdrop',
    panelClass: 'hb-dialog-container',
    disableClose: true,
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, CameraSearchComponent);
  }
}
