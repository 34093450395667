import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromAccount from './account.reducer';
import {NGXLogger} from 'ngx-logger';
import {Actions, ofType} from '@ngrx/effects';
import {filter, map, take} from 'rxjs/operators';
import {Update} from '@ngrx/entity';
import {AccountEntity, AccountViewModel} from './account.models';
import {AccountSelectors} from './account.selectors';
import {AccountActions} from './account.actions';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public readonly isLoading$ = this.store.pipe(select(AccountSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(AccountSelectors.getException));
  public readonly items$ = this.store.pipe(select(AccountSelectors.getItems));
  public readonly selected$ = this.store.pipe(select(AccountSelectors.getSelected));
  public readonly accountId$ = this.store.pipe(select(AccountSelectors.getAccountId));
  public readonly context$ = this.store.pipe(select(AccountSelectors.getContext));

  constructor(private store: Store<fromAccount.AccountPartialState>, private actions$: Actions, logger: NGXLogger) {
  }

  public load() {
    this.store.dispatch(AccountActions.loadAccountsRequest());
  }

  public get(accountId: number) {
    this.store.dispatch(AccountActions.getAccountRequest({request: {accountId}}));
    return this.next(AccountActions.getAccountSuccess).pipe(
      map(action => action.payload.item)
    );
  }

  public setAccountId(selectedId: number) {
    this.store.dispatch(AccountActions.setAccountId({accountId: selectedId}));
  }

  public removeAccountId() {
    this.store.dispatch(AccountActions.removeAccountId());
  }

  public persist(vm: AccountViewModel) {
    this.store.dispatch(AccountActions.persistAccountRequest({account: vm.updated}));
    return this.next(AccountActions.persistAccountSuccess).pipe(
      map(action => action.payload)
    );
  }

  public update(update: Update<AccountEntity>) {
    this.store.dispatch(AccountActions.updateAccount({update}));
  }

  private next(type: any, actionId?: string) {
    return this.actions$.pipe(
      ofType(type),
      filter(action => !actionId || (action.actionId === actionId)),
      take(1)
    );
  }
}
