<form #ngForm="ngForm">
  <h2 mat-dialog-title>{{'dialogs.create_repository.title' | translate}}</h2>
  <mat-dialog-content>
    <div fxLayout="column nowrap">
      <mat-form-field appearance="outline">
        <mat-label>
          <label for="title">{{'forms.fields.name.label' | translate}}</label>
        </mat-label>
        <input #title="ngModel" [(ngModel)]="viewModel.name"
               [placeholder]="'forms.fields.name.placeholder' | translate"
               id="title"
               matInput
               name="title" required
               touched>
        <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
        <mat-error *ngIf="title.dirty && title.touched">
          <ng-container *ngIf="title.hasError('required')">
            {{'forms.errors.is_required' | translate}}
          </ng-container>
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-divider></mat-divider>
  <mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">

    <button [disabled]="ngForm.invalid" [mat-dialog-close]="{next: true, entity: viewModel.updated}" color="accent"
            mat-raised-button type="submit">{{'keys.local.global.ok_and_next' | translate}}</button>
    <button [disabled]="ngForm.invalid" [mat-dialog-close]="{next: false, entity: viewModel.updated}"
            mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
    <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
  </mat-dialog-actions>
</form>

