import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {DocumentEntity} from './document.models';
import {Exception} from '../../../../../../core/models';
import {DocumentActions} from './document.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const DOCUMENT_FEATURE_KEY = 'document';

export interface DocumentState extends EntityState<DocumentEntity> {
  isLoading: boolean;
  exception: Exception;
  rows: number;
}

export interface DocumentPartialState {
  readonly [DOCUMENT_FEATURE_KEY]: DocumentState;
}

export const documentAdapter: EntityAdapter<DocumentEntity> = createEntityAdapter<DocumentEntity>();

export const initialState: DocumentState = documentAdapter.getInitialState({
  isLoading: false,
  exception: null,
  rows: 0,
});

const documentReducer = createReducer(
  initialState,
  on(DocumentActions.loadDocumentsRequest, (state) => ({
    ...state,
    isLoading: true,

  })),
  on(DocumentActions.loadDocumentsSuccess, (state, {payload}) =>
    documentAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(DocumentActions.loadDocumentsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(DocumentActions.getDocumentRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DocumentActions.getDocumentSuccess, (state, {payload}) =>
    documentAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })
  ),
  on(DocumentActions.getDocumentFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(DocumentActions.persistDocumentRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DocumentActions.persistDocumentSuccess, (state, {payload}) =>
    documentAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(DocumentActions.persistDocumentFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(DocumentActions.deleteDocumentRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DocumentActions.deleteDocumentSuccess, (state, {payload}) =>
    documentAdapter.removeOne(payload.documentId, {
      ...state,
      isLoading: false,
    })),
  on(DocumentActions.deleteDocumentFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(DocumentActions.updateDocument, (state, {update}) =>
    documentAdapter.updateOne(update, {
      ...state
    })),
  on(DocumentActions.resetDocument, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: DocumentState | undefined, action: Action) {
  return documentReducer(state, action);
}
