import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hb-card-column-view',
  templateUrl: './card-column-view.component.html',
  styleUrls: ['./card-column-view.component.scss']
})
export class CardColumnViewComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
