import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  CertificateEntity,
  CertificateItemPayload,
  CertificateItemResponse,
  CertificateListPayload,
  CertificateListRequest,
  CertificateListResponse
} from './certificate.models';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {environment} from '@hb/environments/environment';

@Injectable()
export class CertificateDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(request: CertificateListRequest): Observable<CertificateListPayload> {
    return this.http.get<CertificateListResponse>(
      `${environment.api}/repositories/${request.repositoryId}/users/${request.userId}/trainings`).pipe(
      map(res => ({
        items: res.datalist.map(item => ({
          ...item,
          userId: Number(request.userId),
          repositoryId: Number(request.repositoryId)
        })), rows: res.rows
      })),
    );
  }

  persist(certificate: CertificateEntity): Observable<CertificateItemPayload> {
    return this.http.put<CertificateItemResponse>(
      `${environment.api}/repositories/${certificate.repositoryId}/users/${certificate.userId}/trainings`,
      certificate).pipe(
      map(res => ({item: {...res.data, userId: certificate.userId, repositoryId: certificate.repositoryId}}))
    );
  }

  delete(certificate: CertificateEntity): Observable<CertificateItemPayload> {
    return this.http.delete<CertificateItemResponse>(
      `${environment.api}/repositories/${certificate.repositoryId}/users/${certificate.userId}/trainings/${certificate.id}`).pipe(
      map(res => ({item: certificate}))
    );
  }
}
