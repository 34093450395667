import {Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MatFormFieldControl} from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';

@Component({
  selector: 'hb-input-geolocation',
  templateUrl: './input-geolocation.component.html',
  styleUrls: ['./input-geolocation.component.scss'],
  providers: [{provide: MatFormFieldControl, useExisting: InputGeolocationComponent}],
  exportAs: 'geolocationInput'
})
export class InputGeolocationComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {

  static nextId = 0;

  @HostBinding() id = `geolocation-input-${InputGeolocationComponent.nextId++}`;
  focused = false;
  readonly autofilled: boolean;
  readonly controlType = 'geolocation-input';
  readonly errorState: boolean;
  readonly stateChanges = new Subject<void>();

  constructor(private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>,
              @Optional() @Self() public ngControl: NgControl, private logger: NGXLogger) {
    this.ngControl.valueAccessor = this;
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  private _value: string;

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
    this.stateChanges.next();
    this.onChange();
    this.onTouch();
  }

  private _placeholder: string;

  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get required() {
    return this._required;
  }

  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _readonly = false;

  @Input()
  get readonly(): boolean {
    return this._readonly;
  }

  set readonly(value: boolean) {
    this._readonly = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get empty() {
    return !this.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  setDescribedByIds(ids: string[]) {

  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  public getGeolocation() {
    if (!this.disabled) {
      if (this.empty) {
        navigator.geolocation.getCurrentPosition(position => {
          this.value = `${position.coords.latitude}+${position.coords.longitude}`;
        }, positionError => {
          this.logger.error(positionError);
        });
      } else {
        window.open('http://maps.google.com/maps?z=12&t=m&q=loc:' + this.value, '_blank');
      }
    }
  }

  clear() {
    if (!this.disabled) {
      this.value = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: string): void {
    this.value = obj;
  }

  private onChange = () => {
  };

  private onTouch = () => {
  };


}
