import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CreatePayload} from './models/create/create-payload.models';
import {UpdatePayload} from './models/update/update-payload.models';
import {DeletePayload} from './models/delete/delete-payload.models';
import {CreateRequest} from './models/create/create-request.models';
import {UpdateRequest} from './models/update/update-request.models';
import {DeleteRequest} from './models/delete/delete-request.models';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {HttpParamsBuilder} from '../../../../../../core';
import {CreateResponse, CreateResponseAdapter} from './models/create/create-response.models';
import {environment} from '@hb/environments/environment';
import {map} from 'rxjs/operators';
import {UpdateResponse, UpdateResponseAdapter} from './models/update/update-response.models';
import {DeleteResponse} from './models/delete/delete-response.models';
import {LoadRequest} from './models/load/load-request.models';
import {LoadPayload} from './models/load/load-payload.models';
import {LoadResponse, LoadResponseAdapter} from './models/load/load-response.models';
import {GetRequest} from './models/get/get-request.models';
import {GetPayload} from './models/get/get-payload.models';
import {GetResponse, GetResponseAdapter} from './models/get/get-response.models';
import {SearchEntityAdapter} from './models/entity/search.models';

@Injectable({
  providedIn: 'root'
})
export class SearchDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }


  public load(request: LoadRequest): Observable<LoadPayload> {
    const url = `${environment.api}/storage`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.type, request.queryParams.type)
      .build();
    return this.http.get<LoadResponse>(url, {params}).pipe(
      map(res => new LoadPayload(new LoadResponseAdapter(res)))
    );
  }

  public get(request: GetRequest): Observable<GetPayload> {
    const url = `${environment.api}/storage/${request.params.id}`;
    const params = HttpParamsBuilder.get().build();
    return this.http.get<GetResponse>(url, {params}).pipe(
      map(res => new GetPayload(new GetResponseAdapter(res)))
    );
  }

  public create(request: CreateRequest): Observable<CreatePayload> {
    const url = `${environment.api}/storage`;
    const params = HttpParamsBuilder.get().build();
    return this.http.put<CreateResponse>(url, new SearchEntityAdapter(request.body), {params}).pipe(
      map(res => new CreatePayload(new CreateResponseAdapter(res))),
    );
  }

  public update(request: UpdateRequest): Observable<UpdatePayload> {
    const url = `${environment.api}/storage/${request.params.id}`;
    const params = HttpParamsBuilder.get().build();
    return this.http.put<UpdateResponse>(url, new SearchEntityAdapter(request.body), {params}).pipe(
      map(res => new UpdatePayload(new UpdateResponseAdapter(res))),
    );
  }

  public remove(request: DeleteRequest): Observable<DeletePayload> {
    const url = `${environment.api}/storage/${request.params.id}`;
    const params = HttpParamsBuilder.get().build();
    return this.http.delete<DeleteResponse>(url, {params}).pipe(
      map(res => new DeletePayload({...res, data: {id: request.params.id}}))
    );
  }
}
