import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from './root.state';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class RootFacade {

  constructor(private store: Store<State>,
              private logger: NGXLogger) {
  }
}
