<mat-divider></mat-divider>
<div class="flex flex-col flex-grow">
  <canvas [chartType]="pieChartType"
          [colors]="pieChartColors"
          [data]="pieChartData"
          [labels]="pieChartLabels"
          [legend]="pieChartLegend"
          [options]="pieChartOptions"
          baseChart
          class="hb-diagram">
  </canvas>
</div>


