<h2 mat-dialog-title>{{'dialogs.edit_share.title' | translate}}</h2>
<mat-dialog-content>
  <mat-list class="list">
    <mat-list-item *ngFor="let share of viewModel.sharedTargets">
      <span mat-line>{{share.targetUsrEmail}}</span>
      <button (click)="deleteShare(share.targetUsrEmail)" mat-icon-button
              matTooltip="{{'keys.local.tooltips.remove_item' | translate}}">
        <mat-icon color="danger" fontIcon="fa-times-circle" fontSet="fas"></mat-icon>
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>
  </mat-list>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button mat-button mat-dialog-close>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
