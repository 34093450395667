import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DetailCategoryListPayload, DetailCategoryListResponse} from './detail-category.models';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../../../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class DetailCategoryDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(): Observable<DetailCategoryListPayload> {
    return this.http.get<DetailCategoryListResponse>(`${environment.api}/detailcategories`).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }
}
