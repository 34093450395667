<hb-sidenav-view>
  <div [class.hb-background]="background | async" class="flex flex-col flex-nowrap flex-grow h-screen">
    <div [class.mat-elevation-z8]="isScrolled$ | async" class="z-50">
      <header class="hb-header" id="hb-header">
        <hb-header></hb-header>
      </header>
      <nav class="hb-breadcrumb" id="hb-breadcrumb">
        <hb-breadcrumb [items]="this.path$ | async"></hb-breadcrumb>
      </nav>
    </div>
    <main (scroll)="onScroll($event)" class="flex-1 overflow-y-auto">
      <router-outlet #o="outlet">

      </router-outlet>
    </main>
  </div>
</hb-sidenav-view>
