import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UserDataService} from './user-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Update} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';
import {UserActions} from './user.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {AttachmentActions} from '../../../attachment-store/src/+state/attachment.actions';
import {MediaType} from '../../../attachment-store';
import {UserEntity} from './user.models';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


@Injectable()
export class UserEffects {

  loadUsersRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUsersRequest),
      switchMap(action => this.userDataService.load(action.request).pipe(
        map(payload => UserActions.loadUsersSuccess({payload})),
        catchError(error => of(UserActions.loadUsersFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadUsersSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.loadUsersSuccess),
    ), {dispatch: false}
  );

  loadUsersFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.loadUsersFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  persistUserRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.persistUserRequest),

      switchMap(action => this.userDataService.persist(action.request).pipe(
        map(payload => UserActions.persistUserSuccess({payload})),
        catchError(error => of(UserActions.persistUserFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  persistUserSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.persistUserSuccess),
      map(() => this.messageService.openV2(marker('keys.local.messages.successful_persisted'))),
    ), {dispatch: false}
  );

  persistUserFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.persistUserFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUserRequest),
      switchMap(action => this.userDataService.get(action.request).pipe(
        map(payload => UserActions.getUserSuccess({payload})),
        catchError(error => of(UserActions.getUserFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  getUserSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.getUserSuccess),
    ), {dispatch: false}
  );

  getUserFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.getUserFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getLoggedInUserRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.getLoggedInUserRequest),

      switchMap(action => this.userDataService.getLoggedInUser(action.request).pipe(
        map(payload => UserActions.getLoggedInUserSuccess({payload})),
        catchError(error => of(UserActions.getLoggedInUserFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  getLoggedInUserSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.getLoggedInUserSuccess),
    ), {dispatch: false}
  );

  getLoggedInUserFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.getLoggedInUserFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  createUserRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUserRequest),

      switchMap(action => this.userDataService.create(action.request).pipe(
        map(payload => UserActions.createUserSuccess({payload})),
        catchError(error => of(UserActions.createUserFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  createUserSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.createUserSuccess),
    ), {dispatch: false}
  );

  createUserFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.createUserFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  deleteUserRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.deleteUserRequest),

      switchMap(action => this.userDataService.delete(action.request).pipe(
        map(payload => UserActions.deleteUserSuccess({payload})),
        catchError(error => of(UserActions.deleteUserFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  deleteUserSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.deleteUserSuccess),
    ), {dispatch: false}
  );

  deleteUserFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UserActions.deleteUserFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getUserRowsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserRowsRequest),
      switchMap(action => this.userDataService.loadRows(action.request).pipe(
        map(payload => UserActions.loadUserRowsSuccess({payload})),
        catchError(error => of(UserActions.loadUserRowsFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  getUserRowsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserRowsSuccess),
    ), {dispatch: false}
  );

  getUserRowsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserRowsFailure),
    ), {dispatch: false}
  );

  loadAccountUserListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadAccountUserListRequest),
      switchMap(action => this.userDataService.loadAccountUserList(action.request).pipe(
        map(payload => UserActions.loadAccountUserListSuccess({payload})),
        catchError(error => of(UserActions.loadAccountUserListFailure({exception: new Exception(error, action)})))
      )),
    ), {dispatch: false}
  );

  loadAccountUserListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadAccountUserListSuccess),
    ), {dispatch: false}
  );

  loadAccountUserListFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadAccountUserListFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    ), {dispatch: false}
  );

  setUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setUserId),

      tap(action => this.userDataService.setUserId(action.userId))
    ), {dispatch: false}
  );

  removeUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.removeUserId, AuthenticationActions.clearSession),

      tap(() => this.userDataService.removeUserId())
    ), {dispatch: false}
  );

  updateUserImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentSuccess),
      map(action => action.payload.item),
      filter(attachment => attachment.mediaType === MediaType.USER_AVATAR),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: attachment.id}} as Update<UserEntity>)),
      map(update => UserActions.updateUser({update}))
    )
  );

  removeUserImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentSuccess),
      map(action => action.payload),
      filter(attachment => attachment.mediaType === MediaType.USER_AVATAR),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: null}} as Update<UserEntity>)),
      map(update => UserActions.updateUser({update}))
    )
  );

  constructor(private actions$: Actions,
              private userDataService: UserDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }
}
