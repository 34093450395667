import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PathDataService} from './path-data.service';
import {NGXLogger} from 'ngx-logger';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {Exception} from '../../../../../../core/models';
import {PathActions} from './path.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class PathEffects {

  loadPathRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(PathActions.loadPathRequest),
      switchMap(action =>
        this.pathDataService.load(action.request.repositoryId, action.request.materialId, action.request.userId, action.request.documentId, action.request.type).pipe(
          map(payload => PathActions.loadPathSuccess({payload})),
          catchError(error => of(PathActions.loadPathFailure({exception: new Exception(error, action)})))
        ))
    )
  );

  loadPathSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(PathActions.loadPathSuccess),
    ), {dispatch: false}
  );

  loadPathFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(PathActions.loadPathFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions,
              private pathDataService: PathDataService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {
  }

}
