import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {take} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {RouterService} from '../../../router-store';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private authenticationService: AuthenticationService, private routerService: RouterService, private logger: NGXLogger) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    return this.authenticationService.isLoggedIn().pipe(
      take(1),
    );
  }
}
