import {AuthenticationService, CountryService, LanguageService, RegisterRequest, RouterService} from '../../../modules/store';
import {FormBuilder} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MessageService} from '../../../dialogs/message';
import {Observable} from 'rxjs';
import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TermsOfUseDialogService} from '../../../dialogs/terms-of-use';
import {environment} from '../../../../environments';


@Component({
  selector: 'hb-registration-view',
  templateUrl: './registration-view.component.html',
  styleUrls: ['./registration-view.component.css']
})
export class RegistrationViewComponent implements OnInit, OnDestroy {

  public readonly password_pattern = environment.regex.password_pattern;

  public viewModel: RegisterRequest = {
    company: '',
    name: '',
    street: '',
    zip: '',
    city: '',
    countryId: '',
    phone: '',
    cell: '',
    fax: '',
    email: '',
    password: '',
    confirm: '',
    languageId: '',
  };

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private routerService: RouterService,
    private countryService: CountryService,
    private languageService: LanguageService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private termsOfUseDialogService: TermsOfUseDialogService,
    private logger: NGXLogger) {

  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'hb-background');
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.renderer.removeClass(document.body, 'hb-background');
  }

  get countries$() {
    return this.countryService.items$;
  }

  get languages$() {
    return this.languageService.items$;
  }

  isLoading$(): Observable<boolean> {
    return this.authenticationService.isLoading$;
  }

  submit() {
    this.authenticationService.register({...this.viewModel});
  }

  openTermsOfUse() {
    this.termsOfUseDialogService.open();
  }
}
