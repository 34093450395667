import {Action, createReducer, on} from '@ngrx/store';
import {UsageEntity} from './usage.models';
import {Exception} from '../../../../../../core/models';
import {UsageActions} from './usage.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const USAGE_FEATURE_KEY = 'usage';

export interface UsageState {
  isLoading: boolean;
  items: UsageEntity[];
  exception: Exception;
}

export interface UsagePartialState {
  readonly [USAGE_FEATURE_KEY]: UsageState;
}

export const initialState: UsageState = {
  isLoading: false,
  exception: null,
  items: []
};


export const usageReducer = createReducer(
  initialState,

  on(UsageActions.loadUsagesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(UsageActions.loadUsagesSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(UsageActions.loadUsagesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: UsageState | undefined, action: Action) {
  return usageReducer(state, action);
}
