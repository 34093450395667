import {SearchEntity, SearchModel, SearchModelAdapter} from '../entity/search.models';
import {ResponseItemModel} from '../../../../../../../../core';

export class CreateResponse extends ResponseItemModel<SearchModel> {
}

export class CreateResponseAdapter extends ResponseItemModel<SearchEntity> {
  constructor(response: CreateResponse) {
    super();
    this.code = response.code;
    this.extMsg = response.extMsg;
    this.field = response.field;
    this.htmlCode = response.htmlCode;
    this.msg = response.msg;
    this.msgKey = response.msgKey;
    this.rows = response.rows;
    this.value = response.value;
    this.data = new SearchModelAdapter(response.data);
  }
}
