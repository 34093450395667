import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {RouterService} from '../../../modules/store';

@Component({
  selector: 'hb-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit, OnDestroy {


  constructor(public routerFacade: RouterService,
              private logger: NGXLogger) {
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
  }

}
