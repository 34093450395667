import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AuthenticationService, RouterService} from '../../../modules/store';
import {take} from 'rxjs/operators';

@Component({
  selector: 'hb-user-activation-container',
  templateUrl: './activation-view.component.html',
  styleUrls: ['./activation-view.component.css']
})
export class ActivationViewComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  constructor(public routerService: RouterService,
              private authenticationFacade: AuthenticationService,
              private logger: NGXLogger) {

  }

  ngOnInit() {

    this.subscription.add(
      this.routerService.queryParams$.pipe(
        take(1)
      ).subscribe(params => {
        this.authenticationFacade.activate({key: params.key, email: params.email});
      })
    );
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.subscription.unsubscribe();
  }
}
