import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {SEARCH_FEATURE_KEY, searchAdapter, SearchPartialState, SearchState} from './search.reducer';
import {SearchEntity} from './models/entity/search.models';
import {Dictionary} from '@ngrx/entity';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getSearchState = createFeatureSelector<SearchPartialState,
  SearchState>(SEARCH_FEATURE_KEY);

const {selectAll, selectEntities} = searchAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getSearchState,
  (state: SearchState) => state.isLoading
);

const getException = createSelector(
  getSearchState,
  (state: SearchState) => state.exception
);

const getItems: MemoizedSelector<object, SearchEntity[]> = createSelector(
  getSearchState,
  (state: SearchState) => selectAll(state)
);

const getLength = createSelector(
  getSearchState,
  (state: SearchState) => state.rows
);

const getSearchEntities: MemoizedSelector<object, Dictionary<SearchEntity>> = createSelector(
  getSearchState,
  (state: SearchState) => selectEntities(state)
);

const getById = createSelector(
  getSearchEntities,
  (items, id) => items[id] ? items[id] : undefined
);

const isActive: MemoizedSelector<object, boolean> = createSelector(
  RouterSelectors.getSearchIdParam,
  (id) => !!id
);

export const SearchSelectors = {
  isLoading,
  getException,
  getItems,
  getLength,
  getSearchEntities,
  getById,
  isActive,
};
