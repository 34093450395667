import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {ADMINISTRATION_FEATURE_KEY, AdministrationPartialState, AdministrationState} from './administration.reducer';
import {Exception} from '../../../../../../core/models';


const getAdministrationState = createFeatureSelector<AdministrationPartialState,
  AdministrationState>(ADMINISTRATION_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getAdministrationState,
  (state: AdministrationState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getAdministrationState,
  (state: AdministrationState) => state.exception
);

export const AdministrationSelectors = {
  isLoading,
  getException
};
