import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AttachmentViewModel} from '../../../modules/store';

@Component({
  selector: 'hb-rename-attachment',
  templateUrl: './edit-attachment.component.html',
  styleUrls: ['./edit-attachment.component.scss']
})
export class EditAttachmentComponent implements OnInit {


  constructor(private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) private data: AttachmentViewModel,
              private logger: NGXLogger) {
  }

  private _form = this.formBuilder.group({
    filename: this.formBuilder.control(this.data.filename, {validators: [Validators.required]})
  });

  public get form(): FormGroup {
    return this._form;
  }

  ngOnInit(): void {
  }

}
