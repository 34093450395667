import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromApp from './app.reducer';
import {NGXLogger} from 'ngx-logger';
import {BreakpointService} from '../../../../../../core';
import {AppSelectors} from './app.selectors';
import {AppActions} from './app.actions';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public readonly showInactive$ = this.store.pipe(select(AppSelectors.getShowInactive));
  public readonly showSideNav$ = this.store.pipe(select(AppSelectors.getShowSideNav));
  public readonly isScrolled$ = this.store.pipe(select(AppSelectors.getIsScrolled));

  constructor(private store: Store<fromApp.AppPartialState>, private breakpointService: BreakpointService, logger: NGXLogger) {
    this.showInactive$.subscribe(showInactive => this._showInactive = showInactive);
    this.breakpointService.streamMatchHandset().subscribe(isMobile => this.toggleSideNav(!isMobile));
  }

  private _showInactive: boolean;

  public get showInactive() {
    return this._showInactive;
  }

  toggleShowInactive() {
    this.store.dispatch(AppActions.toggleShowInactive());
  }

  toggleSideNav(isOpen?: boolean) {
    this.store.dispatch(AppActions.toggleSideNav({isOpen}));
  }

  setScrolled(isScrolled: boolean) {
    this.store.dispatch(AppActions.setScrolled({isScrolled}));
  }

}
