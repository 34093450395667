<mat-list-item class="list-item" mat-ripple>
  <span mat-line>{{viewModel.name}}</span>
  <span mat-line>{{'id: ' + viewModel.id}}</span>
  <form fxLayout="row nowrap" fxLayoutAlign="space-between center" ngForm>


    <mat-form-field appearance="outline" style="padding-top: 1.34375em">
      <mat-label>
        <label for="valid_until">{{'forms.fields.valid_until.label' | translate}}</label>
      </mat-label>
      <input #validUntil="ngModel"
             [(ngModel)]="viewModel.validUntil"
             [matDatepicker]="picker"
             [placeholder]="'forms.fields.valid_until.placeholder' | translate"
             [disabled]="disabled"
             (click)="picker.open()"
             id="valid_until"
             matInput
             name="validUntil">
      <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="viewModel.value" [disabled]="disabled" color="primary" name="valid"
                  style="padding-left: 10px"></mat-checkbox>

  </form>
  <mat-divider></mat-divider>
</mat-list-item>
