import {EditableViewModel, PayloadItemModel, PayloadListModel, ResponseItemModel, ResponseListModel} from '../../../../../../core';


export interface AddressItemRequest {
  addressId: number;
}

export interface AddressItemResponse extends ResponseItemModel<AddressEntity> {
}

export interface AddressItemPayload extends PayloadItemModel<AddressEntity> {
}

export interface AddressListResponse extends ResponseListModel<AddressEntity> {
}

export interface AddressListPayload extends PayloadListModel<AddressEntity> {
}

export interface AddressEntity {
  id: number;
  companyName: string;
  contactPerson: string;
  street: string;
  zipCode: string;
  city: string;
  phone: string;
  email: string;
  fax: string;
  countryId: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
}

export class AddressViewModel extends EditableViewModel<AddressEntity> {

  constructor(model: AddressEntity) {
    super(model);
  }

  get id(): number {
    return this.model.id;
  }

  get city(): string {
    return this.model.city;
  }

  set city(value: string) {
    this.changes.city = value;
  }

  get companyName(): string {
    return this.model.companyName;
  }

  set companyName(value: string) {
    this.changes.companyName = value;
  }

  get contactPerson(): string {
    return this.model.contactPerson;
  }

  set contactPerson(value: string) {
    this.changes.contactPerson = value;
  }

  get email(): string {
    return this.model.email;
  }

  set email(value: string) {
    this.changes.email = value;
  }

  get fax(): string {
    return this.model.fax;
  }

  set fax(value: string) {
    this.changes.fax = value;
  }

  get phone(): string {
    return this.model.phone;
  }

  set phone(value: string) {
    this.changes.phone = value;
  }

  get street(): string {
    return this.model.street;
  }

  set street(value: string) {
    this.changes.street = value;
  }

  get zipCode(): string {
    return this.model.zipCode;
  }

  set zipCode(value: string) {
    this.changes.zipCode = value;
  }

  get countryId(): number {
    return this.model.countryId;
  }

  set countryId(value: number) {
    this.changes.countryId = value;
  }
}

export function addressViewModelFactory(model: AddressEntity): AddressViewModel {
  return new AddressViewModel(model);
}

export function addressEntityFactory(): AddressEntity {
  return {
    id: 0,
    companyName: null,
    contactPerson: null,
    street: null,
    zipCode: null,
    city: null,
    email: null,
    fax: null,
    countryId: null,
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null,
  } as AddressEntity;
}

