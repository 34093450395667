import {createAction, props} from '@ngrx/store';
import {Exception, Identity} from '../../../../../../core';
import {CreateRequest} from './models/create/create-request.models';
import {CreatePayload} from './models/create/create-payload.models';
import {UpdateRequest} from './models/update/update-request.models';
import {UpdatePayload} from './models/update/update-payload.models';
import {DeleteRequest} from './models/delete/delete-request.models';
import {DeletePayload} from './models/delete/delete-payload.models';
import {LoadRequest} from './models/load/load-request.models';
import {LoadPayload} from './models/load/load-payload.models';
import {GetRequest} from './models/get/get-request.models';
import {GetPayload} from './models/get/get-payload.models';

enum SearchActionTypes {

  GET_SEARCH_REQUEST = '[Search] Get Search Request',
  GET_SEARCH_SUCCESS = '[Search] Get Search Success',
  GET_SEARCH_FAILURE = '[Search] Get Search Failure',

  LOAD_SEARCH_REQUEST = '[Search] Load Search Request',
  LOAD_SEARCH_SUCCESS = '[Search] Load Search Success',
  LOAD_SEARCH_FAILURE = '[Search] Load Search Failure',

  CREATE_SEARCH_REQUEST = '[Search] Create Search Request',
  CREATE_SEARCH_SUCCESS = '[Search] Create Search Success',
  CREATE_SEARCH_FAILURE = '[Search] Create Search Failure',

  UPDATE_SEARCH_REQUEST = '[Search] Update Search Request',
  UPDATE_SEARCH_SUCCESS = '[Search] Update Search Success',
  UPDATE_SEARCH_FAILURE = '[Search] Update Search Failure',

  REMOVE_SEARCH_REQUEST = '[Search] Remove Search Request',
  REMOVE_SEARCH_SUCCESS = '[Search] Remove Search Success',
  REMOVE_SEARCH_FAILURE = '[Search] Remove Search Failure',
}

const getSearchRequest = createAction(
  SearchActionTypes.GET_SEARCH_REQUEST,
  props<{ request: GetRequest, identity: Identity }>()
);

const getSearchSuccess = createAction(
  SearchActionTypes.GET_SEARCH_SUCCESS,
  props<{ payload: GetPayload, identity: Identity }>()
);

const getSearchFailure = createAction(
  SearchActionTypes.GET_SEARCH_FAILURE,
  props<{ exception: Exception, identity: Identity }>()
);

const loadSearchRequest = createAction(
  SearchActionTypes.LOAD_SEARCH_REQUEST,
  props<{ request: LoadRequest, identity: Identity }>()
);

const loadSearchSuccess = createAction(
  SearchActionTypes.LOAD_SEARCH_SUCCESS,
  props<{ payload: LoadPayload, identity: Identity }>()
);

const loadSearchFailure = createAction(
  SearchActionTypes.LOAD_SEARCH_FAILURE,
  props<{ exception: Exception, identity: Identity }>()
);

const createSearchRequest = createAction(
  SearchActionTypes.CREATE_SEARCH_REQUEST,
  props<{ request: CreateRequest, identity: Identity }>()
);

const createSearchSuccess = createAction(
  SearchActionTypes.CREATE_SEARCH_SUCCESS,
  props<{ payload: CreatePayload, identity: Identity }>()
);

const createSearchFailure = createAction(
  SearchActionTypes.CREATE_SEARCH_FAILURE,
  props<{ exception: Exception, identity: Identity }>()
);

const updateSearchRequest = createAction(
  SearchActionTypes.UPDATE_SEARCH_REQUEST,
  props<{ request: UpdateRequest, identity: Identity }>()
);

const updateSearchSuccess = createAction(
  SearchActionTypes.UPDATE_SEARCH_SUCCESS,
  props<{ payload: UpdatePayload, identity: Identity }>()
);

const updateSearchFailure = createAction(
  SearchActionTypes.UPDATE_SEARCH_FAILURE,
  props<{ exception: Exception, identity: Identity }>()
);

const removeSearchRequest = createAction(
  SearchActionTypes.REMOVE_SEARCH_REQUEST,
  props<{ request: DeleteRequest, identity: Identity }>()
);

const removeSearchSuccess = createAction(
  SearchActionTypes.REMOVE_SEARCH_SUCCESS,
  props<{ payload: DeletePayload, identity: Identity }>()
);

const removeSearchFailure = createAction(
  SearchActionTypes.REMOVE_SEARCH_FAILURE,
  props<{ exception: Exception, identity: Identity }>()
);

export const SearchActions = {
  getSearchRequest,
  getSearchSuccess,
  getSearchFailure,
  loadSearchRequest,
  loadSearchSuccess,
  loadSearchFailure,
  createSearchRequest,
  createSearchSuccess,
  createSearchFailure,
  updateSearchRequest,
  updateSearchSuccess,
  updateSearchFailure,
  removeSearchRequest,
  removeSearchSuccess,
  removeSearchFailure
};
