import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {RepositoryViewModel} from '../../../modules/store';

@Component({
  selector: 'hb-edit-share',
  templateUrl: './edit-share.component.html',
  styleUrls: ['./edit-share.component.scss']
})
export class EditShareComponent implements OnInit {

  public readonly viewModel: RepositoryViewModel;

  constructor(@Inject(MAT_DIALOG_DATA) public data: RepositoryViewModel) {
    this.viewModel = data;
  }

  ngOnInit(): void {

  }

  deleteShare(email: string) {
    // this.data.action(email);
    // this.data.repository = {
    //   ...this.data.repository,
    //   sharedFolderTargets: this.data.repository.sharedFolderTargets.filter(item => item.targetUsrEmail !== email)
    // };
  }

}
