import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {MATERIAL_FEATURE_KEY, materialAdapter, MaterialPartialState, MaterialState} from './material.reducer';
import {MaterialEntity, materialFilterProperties, MaterialViewModel, materialViewModelFactory} from './material.models';
import {isNotNullOrUndefined, isNullOrUndefined, objectFilter} from '../../../../../../core';
import {Dictionary} from '@ngrx/entity';
import {getShowInactive} from '../../../app-store/src/+state/app.selectors';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getMaterialState = createFeatureSelector<MaterialPartialState,
  MaterialState>(MATERIAL_FEATURE_KEY);

const {selectAll, selectEntities} = materialAdapter.getSelectors();

const getIsLoading: MemoizedSelector<object, boolean> = createSelector(
  getMaterialState,
  (state: MaterialState) => state.isLoading
);

const getIsSearching: MemoizedSelector<object, boolean> = createSelector(
  getMaterialState,
  (state: MaterialState) => state.isSearching
);

const getSearch: MemoizedSelector<object, string> = createSelector(
  getMaterialState,
  (state: MaterialState) => state.search
);

const getException = createSelector(
  getMaterialState,
  (state: MaterialState) => state.exception
);

const getAllItems: MemoizedSelector<object, MaterialViewModel[]> = createSelector(
  getMaterialState,
  (state: MaterialState) => selectAll(state).map(item => materialViewModelFactory(item))
);

const getItems: MemoizedSelector<object, MaterialViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getRepositoryIdParam,
  RouterSelectors.getFilterQueryParam,
  getShowInactive,
  (items, repositoryId, filter, showInactive) => items
    .filter(item => item.repositoryId === repositoryId || item.virtualParentId === repositoryId)
    .filter(item => !item.isSetItem)
    .filter(item => !filter || (filter && (objectFilter(item, materialFilterProperties, filter))))
    .filter(item => showInactive || item.isActive)
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getItemsWithProps = createSelector(
  getAllItems,
  (items, props: { search?: string, repositoryId?: number }) => items
    .filter(item => isNullOrUndefined(props.search) || (props.search && (objectFilter(item, materialFilterProperties, props.search))))
    .filter(item => isNullOrUndefined(props.repositoryId) || (props.repositoryId && item.parentId === props.repositoryId))
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getRows = createSelector(
  getMaterialState,
  (state: MaterialState) => state.rows
);

const getMaterialEntities: MemoizedSelector<object, Dictionary<MaterialEntity>> = createSelector(
  getMaterialState,
  (state: MaterialState) => selectEntities(state)
);

const getSelected: MemoizedSelector<object, MaterialViewModel> = createSelector(
  getMaterialEntities,
  RouterSelectors.getMaterialIdParam,
  (entities, selected) => {
    if (isNotNullOrUndefined(selected) && entities[selected]) {
      return materialViewModelFactory(entities[selected]);
    }
  }
);

const getSelectedParent = createSelector(
  getMaterialEntities,
  getSelected,
  (entities, selected) => selected && entities[selected.parentId]
);

const getSelectedChildren: MemoizedSelector<object, MaterialViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getMaterialIdParam,
  (items, selected) => items.filter(item => item.parentId === selected)
);

const selectWithFilter = createSelector(
  getItems,
  (items, search) => items.filter(item => !search || (search && (objectFilter(item, materialFilterProperties, search))))
);

const getAuditable: MemoizedSelector<object, MaterialViewModel[]> = createSelector(
  getAllItems,
  (items) => items.filter(item => item.needCheck)
);


export const MaterialSelectors = {
  getIsLoading,
  getIsSearching,
  getSearch,
  getException,
  getAllItems,
  getItems,
  getItemsWithProps,
  getRows,
  getMaterialEntities,
  getSelected,
  getSelectedParent,
  getSelectedChildren,
  selectWithFilter,
  getAuditable
};

