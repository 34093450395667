<h2 mat-dialog-title>{{'dialogs.scan.title' | translate}}</h2>
<mat-dialog-content>
  <form #ngForm=ngForm fxLayout="column nowrap" ngForm style="padding: 1rem;">

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="usage">{{'forms.fields.usage.label' | translate}}</label>
      </mat-label>
      <mat-select #usage="ngModel"
                  [(ngModel)]="viewModel.usageId"
                  [placeholder]="'forms.fields.usage.placeholder' | translate"
                  id="usage"
                  name="usage" required touched>
        <mat-option *ngFor="let usage of usages$ | async"
                    [value]="usage.id">{{usage.key | translate}}</mat-option>
      </mat-select>
      <mat-hint>{{'forms.fields.usage.hint' | translate}}</mat-hint>
      <mat-error *ngIf="usage.dirty && usage.touched">
        <ng-container *ngIf="usage.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label
          for="barcode">{{'forms.fields.barcode.label' | translate}}</label>
      </mat-label>
      <input #barcode="ngModel"
             (keydown.enter)="handleEnter()"
             [placeholder]="'forms.fields.barcode.placeholder' | translate"
             [(ngModel)]="viewModel.barcode"
             matInput
             cdkfocusinitial
             id="barcode"
             name="barcode">
      <mat-hint>{{'forms.fields.barcode.hint' | translate}}</mat-hint>
      <mat-error *ngIf="barcode.dirty && barcode.touched">

      </mat-error>
    </mat-form-field>

    <mat-form-field #firstUsageDateFormat="hbYearMonthDateFormat" appearance="outline"
                    hbYearMonthDateFormat>
      <mat-label>
        <label for="firstUsage">{{'forms.fields.first_usage.label' | translate}}</label>
      </mat-label>
      <input #firstUsage="ngModel" [(ngModel)]="viewModel.firstUsage"
             [matDatepicker]="firstUsageDatePicker"
             [max]="currentDate | date"
             [min]="viewModel.firstUsage | date"
             (click)="firstUsageDatePicker.open()"
             [placeholder]="'forms.fields.first_usage.placeholder' | translate" id="firstUsage"
             matInput
             name="firstUsage" readonly>
      <mat-datepicker-toggle [for]="firstUsageDatePicker"
                             matSuffix></mat-datepicker-toggle>
      <mat-datepicker #firstUsageDatePicker
                      (monthSelected)="firstUsageDateFormat.chosenMonthHandler($event, firstUsageDatePicker)"
                      [touchUi]="!matchWeb"
                      startView="year"></mat-datepicker>
      <mat-hint>{{'forms.fields.first_usage.hint' | translate}}</mat-hint>
      <mat-error *ngIf="firstUsage.dirty && firstUsage.touched">

      </mat-error>
    </mat-form-field>


    <mat-form-field *ngIf="usage.value === 1" appearance="outline">
      <mat-label>
        <label for="soldOn">{{'forms.fields.sold_on.label' | translate}}</label>
      </mat-label>
      <input #soldOn="ngModel"
             [(ngModel)]="viewModel.soldOn"
             [matDatepicker]="soldOnPicker"
             [placeholder]="'forms.fields.sold_on.placeholder' | translate"
             (click)="soldOnPicker.open()"
             id="soldOn"
             matInput
             name="soldOn">
      <mat-datepicker-toggle [for]="soldOnPicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #soldOnPicker [touchUi]="!matchWeb"></mat-datepicker>
      <mat-hint>{{'forms.fields.sold_on.hint' | translate}}</mat-hint>
      <mat-error *ngIf="soldOn.dirty && soldOn.touched">

      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label
          for="customerSerialNumber">{{'forms.fields.customer_serialnumber.label' | translate}}</label>
      </mat-label>
      <input #customerSerialNumber="ngModel"
             [(ngModel)]="viewModel.rfid"
             [placeholder]="'forms.fields.customer_serialnumber.placeholder' | translate"
             id="customerSerialNumber"
             matInput
             name="customerSerialNumber">
      <mat-hint>{{'forms.fields.customer_serialnumber.hint' | translate}}</mat-hint>
      <mat-error *ngIf="customerSerialNumber.dirty && customerSerialNumber.touched">

      </mat-error>
    </mat-form-field>

  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.close' | translate}}</button>
</mat-dialog-actions>





