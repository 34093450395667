import {Component, OnDestroy, OnInit} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {filter, flatMap, map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {BreakpointService, ImageService} from '../../../modules/core';
import {TranslateService} from '@ngx-translate/core';
import {Breakpoints} from '@angular/cdk/layout';
import {SelectProductDialogService} from '../../../dialogs/select-product';
import {CreateProductDialogService} from '../../../dialogs/create-product';
import {MessageService} from '../../../dialogs/message';
import {HintDialogService} from '../../../dialogs/hint';
import {ScanDialogService} from '../../../dialogs/scan';
import {CameraScanDialogService} from '../../../dialogs/camera-scan';
import {Platform} from '@angular/cdk/platform';
import {MaterialViewDataSource} from './material-view.data-source';
import {AddAttachmentDialogService} from '../../../dialogs/add-attachment/src/add-attachment-dialog.service';
import {CreateMaterialDialogService} from '../../../dialogs/create-material/src/create-material-dialog.service';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {
  AuthenticationService,
  DocumentService,
  DocumentViewModel,
  FormService,
  MaterialService,
  MaterialViewModel,
  MediaType,
  PathService,
  PropertyEntity,
  RouterService,
  UsageService
} from '../../../modules/store';
import {environment} from '../../../../environments';
import {formatNumber} from '@angular/common';

@Component({
  selector: 'hb-material-view-container',
  templateUrl: './material-view.component.html',
  styleUrls: ['./material-view.component.css'],
})
export class MaterialViewComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  constructor(
    private materialService: MaterialService,
    private documentService: DocumentService,
    private formService: FormService,
    private pathService: PathService,
    private usageService: UsageService,
    private routerService: RouterService,
    private imageService: ImageService,
    private breakpointService: BreakpointService,
    private translateService: TranslateService,
    private selectProductDialogService: SelectProductDialogService,
    private createProductDialogService: CreateProductDialogService,
    private createMaterialDialogService: CreateMaterialDialogService,
    private scanDialogService: ScanDialogService,
    private cameraDialogService: CameraScanDialogService,
    private messageService: MessageService,
    private hintDialogService: HintDialogService,
    private authenticationFacade: AuthenticationService,
    private addAttachmentDialogService: AddAttachmentDialogService,
    private platform: Platform,
    private dataSource: MaterialViewDataSource,
    private logger: NGXLogger) {

  }

  public get viewModel(): MaterialViewModel {
    return this.dataSource.viewModel;
  }

  public get path$() {
    return this.dataSource.path$;
  }

  public get documents$() {
    return this.dataSource.documents$;
  }

  public get children$() {
    return this.dataSource.children$;
  }

  public get usages$() {
    return this.dataSource.usages$;
  }

  public get attachments$() {
    return this.dataSource.attachments$;
  }

  public get forms$() {
    return this.dataSource.forms$;
  }

  public get isLoading$() {
    return this.dataSource.isLoading$;
  }

  public get locale$() {
    return this.dataSource.locale$;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get currentDate() {
    return this.dataSource.currentDate;
  }

  public get matchWeb() {
    return this.breakpointService.matchWeb();
  }

  public get matchTablet() {
    return this.breakpointService.matchTablet();
  }

  public get matchHandset() {
    return this.breakpointService.matchHandset();
  }

  public get isMobile() {
    return this.platform.ANDROID || this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  }

  public readonly mediaType: typeof MediaType = MediaType;

  public locale: string;

  public get hasOpenDocument$() {
    return this.dataSource.hasOpenDocument$;
  }

  ngOnInit() {
    this.subscription = this.routerService.route$.pipe(
      filter(route => route.routeConfig.path === ':materialId'),
    ).subscribe((route) => {
      this.dataSource.load({
        repositoryId: Number(route.params.repositoryId),
        materialId: Number(route.params.materialId),
        queryParams: route.queryParams
      });
    });

    this.locale$.subscribe(locale => this.locale = locale);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save() {
    this.dataSource.save();
  }

  add() {
    this.selectProductDialogService.open().afterClosed().pipe(
      flatMap(id => {
        if (id === '0') {
          return this.createProductDialogService.open().afterClosed();
        } else {
          return of(id);
        }
      }),
      filter(result => !!result),
      map(productId => Number(productId)),
      flatMap(productId => this.materialService.create({
        productId,
        parentMaterialId: this.viewModel.id,
        usageId: this.viewModel.usageId,
        repositoryId: this.routerService.repositoryId,
      })),
      flatMap(material => this.createMaterialDialogService.open(material).afterClosed().pipe(
        filter(result => !!result),
        flatMap(result => this.materialService.persistV2(result.viewModel).pipe(
          filter(viewModel => !!viewModel),
          map(viewModel => ({viewModel, next: result.next}))
        )),
      )),
      filter(result => result.next),
      map(result => result.viewModel)
    ).subscribe(viewModel => this.routerService.navigateToMaterialView(viewModel.repositoryId, viewModel.id));
  }

  scan() {
    if ((environment.production && !(this.platform.ANDROID || this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) ||
      (!environment.production && this.breakpointService.matchWeb())) {
      this.scanDialogService.open({
        parentMaterialId: this.viewModel.id,
        repositoryId: Number(this.routerService.repositoryId),
        id: 0,
        usageId: this.viewModel.usageId
      });
    } else {
      this.cameraDialogService.open({
        parentMaterialId: this.viewModel.id,
        repositoryId: Number(this.routerService.repositoryId),
        id: 0,
        usageId: this.viewModel.usageId
      });
    }
  }

  camera() {
    this.cameraDialogService.open({parentMaterialId: this.viewModel.id, repositoryId: this.viewModel.repositoryId, id: 0});
  }

  showExtendedStatus(): boolean {
    return this.breakpointService.matches([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape]);
  }

  addAttachment() {
    this.addAttachmentDialogService.open({mediaType: MediaType.MATERIAL_ATTACHMENT, targetId: this.viewModel.id})
      .afterClosed()
      .pipe(
        filter(result => !!result)
      ).subscribe(() => this.messageService.openV2(marker('keys.local.messages.success')));
  }

  placeholder() {
    return this.imageService.getProductImage(
      this.viewModel.manufacturerImageBaseUrl, this.viewModel.product.articleNumber, '300', '300');
  }

  childrenDeleteEventHandler(event) {
    if (event.name === 'delete') {
      this.materialService.change({
        id: this.viewModel.id,
        changes: {
          materials: this.viewModel.model.materials.filter(c => c.id !== event.data.id)
        }
      });
    }
  }

  createDocument(formId: string) {
    this.documentService.create(this.viewModel.repositoryId, this.viewModel.id, {[environment.queryParamsNames.formId]: formId})
      .subscribe(payload => this.routerService.navigateToDocumentView(this.viewModel.repositoryId, this.viewModel.id, payload.id));
  }

  openSubMaterial(viewModel: MaterialViewModel) {
    this.routerService.navigateToMaterialViewV2(viewModel);
  }

  openDocument(viewModel: DocumentViewModel) {
    this.routerService.navigateToDocumentView(viewModel.repositoryId, viewModel.materialId, viewModel.id);
  }

  getPropertyValue(p: PropertyEntity) {
    if (!!p) {
      if (!!p.unit && !!parseFloat(p.value)) {
        // tslint:disable-next-line:max-line-length
        return `${(formatNumber(parseFloat(p.value), this.locale, '1.0-2'))} ${this.translateService.instant('properties.units.' + p.unit)}`;
      } else if (!!p.unit) {
        return `${p.value} ${this.translateService.instant('properties.units.' + p.unit)}`;
      } else if (!!parseFloat(p.value)) {
        return `${p.value}`;
      } else if (p.name === 'Material') {
        return this.translateService.instant('properties.values.' + p.value);
      } else {
        return `${p.value}`;
      }
    }
  }
}
