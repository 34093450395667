import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {Injector, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS} from '@angular/material/snack-bar';
import {RouterModule} from '@angular/router';
import {routes} from './app.routes';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {ChartsModule} from 'ng2-charts';
import {LoginViewComponent} from './views/login-view';
import {MaterialViewComponent} from './views/material-view';
import {PasswordForgottenViewComponent} from './views/password-forgotten-view';
import {RegistrationViewComponent} from './views/register-view';
import {UserViewComponent} from './views/user-view';
import {RepositoryViewComponent} from './views/repository-view';
import {UsersViewComponent} from './views/users-view';
import {DashboardViewComponent} from './views/dashboard-view';
import {BidiModule} from '@angular/cdk/bidi';
import {ImprintComponent} from './dialogs/imprint';
import {PrivacyComponent} from './dialogs/privacy';
import {TermsOfUseComponent} from './dialogs/terms-of-use';
import {FabComponent} from './components/fab';
import {DocumentViewComponent} from './views/document-view';
import {SealDocumentComponent} from './dialogs/seal-document';
import {SelectRepositoryComponent} from './dialogs/select-repository';
import {AutocompleteChipListComponent} from './components/autocomplete-chip-list';
import {SidenavViewComponent} from './views/sidenav-view';
import {StatusComponent} from './components/status';
import {HeaderViewComponent} from './views/header-view';
import {VirtualScrollerModule} from 'ngx-virtual-scroller';
import {LayoutModule} from '@angular/cdk/layout';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PasswordResetViewComponent} from './views/password-reset-view';
import {HintComponent} from './dialogs/hint';
import {ActivationViewComponent} from './views/activation-view';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {ListComponent} from './components/list/src/list.component';
import {ListItemComponent} from './components/list-item/src/list-item.component';
import {ContentComponent} from './components/content/src/content.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {SelectProductComponent} from './dialogs/select-product';
import {ChangeEmailViewComponent} from './views/change-email-view';
import {CreateProductComponent} from './dialogs/create-product';
import {ScanComponent} from './dialogs/scan';
import {A11yModule} from '@angular/cdk/a11y';
import {PlacesViewComponent} from './views/places-view';
import {EditShareComponent} from './dialogs/edit-share';
import {MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {ImageInputComponent} from './components/image';
import {AdministrationViewComponent} from './views/administation-view';
import {CameraScanComponent} from './dialogs/camera-scan';
import {AvatarComponent} from './components/avatar';
import {ThemeDirective} from './directives/theme';
import {CardComponent} from './components/card';
import {CardAccordionComponent} from './components/card-accordion';
import {CardColumnViewComponent} from './components/card-column-view';
import {CardImageComponent} from './components/card-image/src/card-image.component';
import {CardContentComponent} from './components/card-content';
import {RadioInputComponent} from './components/radio-input';
import {OverlayContainer} from '@angular/cdk/overlay';
import {FlexLayoutModule} from '@angular/flex-layout';
import {SelectImageComponent} from './dialogs/select-image';
import {NgxImageCompressService} from 'ngx-image-compress';
import {InputSignatureComponent} from './components/input-signature/src/input-signature.component';
import {InputGeolocationComponent} from './components/input-geolocation';
import {EditEmailComponent} from './dialogs/edit-email';
import {CommercialMarkerComponent} from './components/commercial-marker';
import {BreadcrumbComponent} from './components/breadcrumb';
import {SidenavContainerComponent} from './containers/sidenav-container-component/src/sidenav-container.component';
import {SingleContainerComponent} from './containers/single-container-component/src/single-container.component';
import {PasswordToggleVisibilityComponent} from './components/password-toggle-visibility/src/password-toggle-visibility.component';
import {FilterBarComponent} from './components/filter-bar';
import {RepositoryItemViewComponent} from './views/repository-item-view/src/repository-item-view.component';
import {MaterialItemViewComponent} from './views/material-item-view/src/material-item-view.component';
import {UserItemViewComponent} from './views/user-item-view/src/user-item-view.component';
import {ShareComponent} from './dialogs/share/src/share.component';
import {DocumentItemViewComponent} from './views/document-item-view/src/document-item-view.component';
import {FabGroupComponent, FabGroupItemDirective} from './components/fab-group/src/fab-group.component';
import {RadioInputMaterialLabelComponent} from './components/radio-input-material-label/src/radio-input-material-label.component';
import {CertificateItemViewComponent} from './views/certificate-item-view/src/certificate-item-view.component';
import {ApprovalItemViewComponent} from './views/approval-item-view/src/approval-item-view.component';
import {LoggedInUserItemViewComponent} from './views/logged-in-user-item-view/src/logged-in-user-item-view.component';
import {AccountViewComponent} from './views/account-view';
import {EditPasswordComponent} from './dialogs/edit-password/src/edit-password.component';
import {SearchComponent} from './dialogs/search/src/search.component';
import {AddCertificateComponent} from './dialogs/add-certificate/src/add-certificate.component';
import {SearchDialogEntryComponent} from './components/search-dialog-entry/src/search-dialog-entry.component';
import {AutoInputDirective} from './directives/auto-input/src/auto-input.directive';
import {AbilityModule} from '@casl/angular';
import {Ability, PureAbility} from '@casl/ability';
import {MarkAsTouchedDirective} from './directives/mark-as-touched/src/mark-as-touched.directive';
import {YearMonthDateFormatDirective} from './directives/year-month-date-format/src/year-month-date-format.directive';
import {AddAttachmentComponent} from './dialogs/add-attachment/src/add-attachment.component';
import {AttachmentItemViewComponent} from './views/attachment-item-view/src/attachment-item-view.component';
import {ImageViewerComponent} from './dialogs/image-viewer/src/image-viewer.component';
import {EditAttachmentComponent} from './dialogs/edit-attachment/src/edit-attachment.component';
import {CreateMaterialComponent} from './dialogs/create-material/src/create-material.component';
import {CreateRepositoryComponent} from './dialogs/create-repository/src/create-repository.component';
import {CreateUserComponent} from './dialogs/create-user/src/create-user.component';
import {ValidateEqualModule} from 'ng-validate-equal';
import {registerLocaleData} from '@angular/common';
import DE from '@angular/common/locales/de';
import EN from '@angular/common/locales/en';
import FR from '@angular/common/locales/fr';
import IT from '@angular/common/locales/it';
import ES from '@angular/common/locales/es';
import SV from '@angular/common/locales/sv';
import {ServiceWorkerModule} from '@angular/service-worker';
import {PromptComponent} from './modules/core/component/prompt/src/prompt.component';
import {CoreModule} from './modules/core/core.module';
import {EmptyComponent} from './components/empty/empty.component';
import {AddressViewComponent} from './views/address-view/src/address-view.component';
import {OrderModule} from 'ngx-order-pipe';
import {RootStoreModule} from './modules/store';
import {environment, VERSION} from '../environments';
import {DisabledDirective} from './directives/disable/src/disabled.directive';
import {InspectionsViewComponent} from './views/inspections-view/src/inspections-view.component';
import {SignatureComponent} from './dialogs/signature';
import {ScrolledContentDirective} from './directives/scrolled-content/src/scrolled-content.directive';
import {HeaderPortalComponent} from './components/header-portal/src/header-portal.component';
import {BreadcrumbPortalComponent} from './components/breadcrumb-portal/src/breadcrumb-portal.component';
import {CameraSearchComponent} from './dialogs/camera-search/src/camera-search.component';
import {handlers} from './modules/core/command.handlers';
import {CommandBusModule} from './modules/command-bus/command-bus.module';

registerLocaleData(DE);
registerLocaleData(EN);
registerLocaleData(FR);
registerLocaleData(IT);
registerLocaleData(ES);
registerLocaleData(SV);

// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = {
//     swipe: {direction: Hammer.DIRECTION_ALL},
//   };
//   options = {
//     domEvents: true
//   };
//
//   buildHammer(element: HTMLElement) {
//     return propagating(super.buildHammer(element));
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    LoginViewComponent,
    MaterialViewComponent,
    PasswordForgottenViewComponent,
    PasswordResetViewComponent,
    ActivationViewComponent,
    ChangeEmailViewComponent,
    RegistrationViewComponent,
    UserViewComponent,
    RepositoryViewComponent,
    UsersViewComponent,
    DashboardViewComponent,
    ImprintComponent,
    PrivacyComponent,
    TermsOfUseComponent,
    FabComponent,
    DocumentViewComponent,
    SealDocumentComponent,
    SelectRepositoryComponent,
    AutocompleteChipListComponent,
    SidenavViewComponent,
    SidenavViewComponent,
    StatusComponent,
    HeaderViewComponent,
    ListComponent,
    ListItemComponent,
    ContentComponent,
    HintComponent,
    SelectProductComponent,
    CreateProductComponent,
    ScanComponent,
    PlacesViewComponent,
    EditShareComponent,
    ImageInputComponent,
    AdministrationViewComponent,
    AdministrationViewComponent,
    CameraScanComponent,
    AvatarComponent,
    ThemeDirective,
    CardComponent,
    CardAccordionComponent,
    CardColumnViewComponent,
    CardImageComponent,
    CardContentComponent,
    RadioInputComponent,
    SelectImageComponent,
    InputSignatureComponent,
    InputGeolocationComponent,
    EditEmailComponent,
    CommercialMarkerComponent,
    BreadcrumbComponent,
    SidenavContainerComponent,
    SingleContainerComponent,
    PasswordToggleVisibilityComponent,
    FilterBarComponent,
    RepositoryItemViewComponent,
    MaterialItemViewComponent,
    UserItemViewComponent,
    ShareComponent,
    DocumentItemViewComponent,
    FabGroupComponent,
    FabGroupItemDirective,
    RadioInputMaterialLabelComponent,
    CertificateItemViewComponent,
    ApprovalItemViewComponent,
    LoggedInUserItemViewComponent,
    AccountViewComponent,
    EditPasswordComponent,
    SearchComponent,
    AddCertificateComponent,
    SearchDialogEntryComponent,
    AutoInputDirective,
    MarkAsTouchedDirective,
    YearMonthDateFormatDirective,
    AddAttachmentComponent,
    AttachmentItemViewComponent,
    ImageViewerComponent,
    EditAttachmentComponent,
    CreateMaterialComponent,
    CreateRepositoryComponent,
    CreateUserComponent,
    PromptComponent,
    EmptyComponent,
    AddressViewComponent,
    DisabledDirective,
    InspectionsViewComponent,
    SignatureComponent,
    ScrolledContentDirective,
    HeaderPortalComponent,
    BreadcrumbPortalComponent,
    CameraSearchComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    RootStoreModule,
    RouterModule.forRoot(routes, {enableTracing: false, onSameUrlNavigation: 'ignore'}),
    LoggerModule.forRoot({
      serverLoggingUrl: `${environment.api}/logs`,
      level: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
      colorScheme: ['purple', 'teal', 'blue', 'blue', 'yellow', 'red', 'red'],
      enableSourceMaps: true
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    RouterModule,
    ScrollingModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormsModule,
    ChartsModule,
    BidiModule,
    VirtualScrollerModule,
    LayoutModule,
    ZXingScannerModule,
    ImageCropperModule,
    A11yModule,
    FlexLayoutModule,
    AbilityModule,
    ValidateEqualModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production || environment.staging}),
    OrderModule,
    CommandBusModule.forRoot(handlers)
  ],
  entryComponents: [
    SealDocumentComponent,
    SelectRepositoryComponent,
    CameraScanComponent],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {verticalPosition: 'bottom', duration: 10000}
    },
    {
      provide: MESSAGE_FORMAT_CONFIG, useValue: {locales: ['de', 'en', 'fr', 'it', 'es', 'sv']}
    },
    NgxImageCompressService,
    {
      provide: Ability, useValue: new Ability()
    },
    {
      provide: PureAbility, useExisting: Ability
    },
    // {
    //   provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(lib: FaIconLibrary,
              injector: Injector,
              overlayContainer: OverlayContainer,
              private logger: NGXLogger) {

    this.logger.trace('*---------------------------------------------------------------------*');
    this.logger.trace('*', this.constructor.name, 'Loaded');
    this.logger.trace('*---------------------------------------------------------------------*');
    lib.addIconPacks(fas, far);
    overlayContainer.getContainerElement().classList.add('theme-' + environment.style.theme);
    this.logger.info('*---------------------------------------------------------------------*');
    this.logger.info('* Version: ', VERSION.semverString);
    this.logger.info('* Production: ', environment.production);
    this.logger.info('* Theme: ', environment.style.theme);
    this.logger.info('* Configuration: ', environment.configuration);
    this.logger.info('*---------------------------------------------------------------------*');
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.i18n}/`);
}
