import {Injectable} from '@angular/core';
import {AdministrationPartialState} from './administration.reducer';
import {select, Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {AdministrationSelectors} from './administration.selectors';
import {AdministrationActions} from './administration.actions';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  isLoading$ = this.store.pipe(select(AdministrationSelectors.isLoading));
  exception$ = this.store.pipe(select(AdministrationSelectors.getException));

  constructor(
    private store: Store<AdministrationPartialState>,
    private logger: NGXLogger) {
  }

  clearCache() {
    this.store.dispatch(AdministrationActions.clearCacheRequest());
  }
}
