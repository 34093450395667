import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {select, Store} from '@ngrx/store';
import * as fromProduct from './product.reducer';
import {QueryParams} from '@ngrx/data';
import {Actions, ofType} from '@ngrx/effects';
import {map, take} from 'rxjs/operators';
import {ProductEntity} from './product.models';
import {ProductSelectors} from './product.selectors';
import {ProductActions} from './product.actions';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  items$ = this.store.pipe(select(ProductSelectors.getAllProducts));
  selectedId$ = this.store.pipe(select(ProductSelectors.getSelectedId));
  isLoading$ = this.store.pipe(select(ProductSelectors.isLoading));
  exception$ = this.store.pipe(select(ProductSelectors.getException));

  constructor(private store: Store<fromProduct.ProductPartialState>, private actions$: Actions, private logger: NGXLogger) {
  }

  load(queryParams?: QueryParams) {
    this.store.dispatch(ProductActions.loadProductsRequest({queryParams}));
  }

  fetch(queryParams?: QueryParams) {
    this.store.dispatch(ProductActions.fetchProductsRequest({queryParams}));
  }

  get(productId: string | number, queryParams?: QueryParams) {
    this.store.dispatch(ProductActions.getProductRequest({productId, queryParams}));
  }

  persist(product: ProductEntity, queryParams?: QueryParams) {
    this.store.dispatch(ProductActions.persistProductRequest({product, queryParams}));
    return this.next(ProductActions.persistProductSuccess).pipe(
      map(action => action.payload.item)
    );
  }

  private next(type: any) {
    return this.actions$.pipe(
      ofType(type),
      take(1)
    );
  }

}
