import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromLanguage from './+state/language.reducer';
import {LANGUAGE_ID} from './+state/language.reducer';
import {EffectsModule} from '@ngrx/effects';
import {LanguageEffects} from './+state/language.effects';
import {LanguageInterceptor} from './+state/language.interceptor';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {NGXLogger} from 'ngx-logger';
import {LanguageService} from './+state/language.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromLanguage.LANGUAGE_FEATURE_KEY,
      fromLanguage.reducer
    ),
    EffectsModule.forFeature([LanguageEffects]),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
  ]
})
export class LanguageStoreModule {

  constructor(translateService: TranslateService, dateAdapter: DateAdapter<any>, private languageService: LanguageService, private logger: NGXLogger) {

    const possibleLang = ['de', 'en', 'fr', 'it', 'es', 'sv'];
    const browserLang = possibleLang.includes(translateService.getBrowserLang()) ? translateService.getBrowserLang() : 'en';
    const hasStorageLang = !!localStorage.getItem(LANGUAGE_ID);
    const storageLang = localStorage.getItem(LANGUAGE_ID);
    let lang = 'en';

    if (hasStorageLang && !possibleLang.includes(storageLang)) {
      localStorage.setItem(LANGUAGE_ID, browserLang);
      lang = browserLang;
    } else if (!hasStorageLang) {
      localStorage.setItem(LANGUAGE_ID, browserLang);
      lang = browserLang;
    } else {
      lang = storageLang;
    }

    translateService.setDefaultLang(String(lang));
    translateService.use(String(lang));
    dateAdapter.setLocale(String(lang));
    languageService.set({id: lang});


    this.logger.trace('*---------------------------------------------------------------------*');
    this.logger.trace('*', this.constructor.name, 'loaded');
    this.logger.trace('* Current Language: ', translateService.currentLang);
    this.logger.trace('* Default Language: ', translateService.defaultLang);
    this.logger.trace('*---------------------------------------------------------------------*');
  }
}
