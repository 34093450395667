import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {PrivacyComponent} from './privacy.component';

@Injectable({
  providedIn: 'root'
})
export class PrivacyDialogService extends BaseDialogService<PrivacyComponent, any> {

  protected config: MatDialogConfig = {
    width: '80vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, PrivacyComponent);
  }
}
