import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {CertificateEntity} from './certificate.models';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {CertificateActions} from './certificate.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const CERTIFICATE_FEATURE_KEY = 'certificate';

export interface CertificateState extends EntityState<CertificateEntity> {
  isLoading: boolean;
  exception: Exception;
  rows: number;
}

export interface CertificatePartialState {
  readonly [CERTIFICATE_FEATURE_KEY]: CertificateState;
}

export const certificateAdapter: EntityAdapter<CertificateEntity> = createEntityAdapter<CertificateEntity>();

export const initialState: CertificateState = certificateAdapter.getInitialState({
  isLoading: false,
  exception: null,
  rows: 0,
});

const certificateReducer = createReducer(
  initialState,
  on(CertificateActions.loadCertificatesRequest, state => ({
    ...state,
    isLoading: true,

  })),
  on(CertificateActions.loadCertificatesSuccess, (state, {payload}) =>
    certificateAdapter.addMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(CertificateActions.loadCertificatesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(CertificateActions.persistCertificateRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(CertificateActions.persistCertificateSuccess, (state, {payload}) =>
    certificateAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(CertificateActions.persistCertificateFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(CertificateActions.deleteCertificateRequest, (state) => ({
    ...state,
    isLoading: true,
    exception: null
  })),
  on(CertificateActions.deleteCertificateSuccess, (state, {payload}) =>
    certificateAdapter.removeOne(payload.item.id, {
      ...state,
      isLoading: false,
      exception: null,
    })),
  on(CertificateActions.deleteCertificateFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: CertificateState | undefined, action: Action) {
  return certificateReducer(state, action);
}
