import {createAction, props} from '@ngrx/store';
import {CountryListPayload} from './country.models';
import {Exception} from '../../../../../../core/models';

enum CountryActionTypes {
  LOAD_COUNTRIES_REQUEST = '[Country] Load Countries Request',
  LOAD_COUNTRIES_SUCCESS = '[Country] Load Countries Success',
  LOAD_COUNTRIES_FAILURE = '[Country] Load Countries Failure',
}

const loadCountriesRequest = createAction(
  CountryActionTypes.LOAD_COUNTRIES_REQUEST
);

const loadCountriesSuccess = createAction(
  CountryActionTypes.LOAD_COUNTRIES_SUCCESS,
  props<{ payload: CountryListPayload }>()
);

const loadCountriesFailure = createAction(
  CountryActionTypes.LOAD_COUNTRIES_FAILURE,
  props<{ exception: Exception }>()
);

export const CountryActions = {
  loadCountriesRequest,
  loadCountriesSuccess,
  loadCountriesFailure,
};
