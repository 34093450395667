import {createFeatureSelector, createSelector} from '@ngrx/store';
import {AUTHENTICATION_FEATURE_KEY, AuthenticationState} from './authentication.reducer';

const getAuthenticationState = createFeatureSelector<AuthenticationState>(AUTHENTICATION_FEATURE_KEY);

const isLoading = createSelector(
  getAuthenticationState,
  (state) => state.isLoading
);

const getAccess = createSelector(
  getAuthenticationState,
  (state) => state.access
);

const getRefresh = createSelector(
  getAuthenticationState,
  (state) => state.refresh
);

const getException = createSelector(
  getAuthenticationState,
  (state) => state.exception
);

export const AuthenticationSelectors = {
  isLoading,
  getAccess,
  getRefresh,
  getException,
};
