import * as fromRouter from '@ngrx/router-store';
import {createFeatureSelector, createSelector} from '@ngrx/store';

export const ROUTER_FEATURE_KEY = 'router';

export interface RouterPartialState {
  readonly [ROUTER_FEATURE_KEY]: fromRouter.RouterReducerState<any>;
}

const selectRouter = createFeatureSelector<RouterPartialState,
  fromRouter.RouterReducerState<any>>(ROUTER_FEATURE_KEY);


const {
  selectCurrentRoute,   // select the current route
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);


const getCurrentRoute = selectCurrentRoute;
const getCurrentUrl = selectUrl;
const getRouteParams = selectRouteParams;
const getQueryParams = selectQueryParams;
const getRouteData = selectRouteData;


const getRepositoryIdParam = createSelector(
  selectRouteParam('repositoryId'),
  id => Number(id)
);

const getMaterialIdParam = createSelector(
  selectRouteParam('materialId'),
  id => Number(id)
);

const getUserIdParam = createSelector(
  selectRouteParam('userId'),
  id => Number(id)
);

const getDocumentIdParam = createSelector(
  selectRouteParam('documentId'),
  id => Number(id)
);

const getTrainingIdParam = createSelector(
  selectRouteParam('trainingId'),
  id => Number(id)
);

const getAccountIdParam = createSelector(
  selectRouteParam('accountId'),
  (id) => Number(id)
);

const getSearchIdParam = createSelector(
  selectRouteParam('searchId'),
  (id) => id
);

const getFilterQueryParam = selectQueryParam('filter');
const getSearchQueryParam = selectQueryParam('search');

export const RouterSelectors = {
  getCurrentRoute,
  getCurrentUrl,
  getRouteParams,
  getQueryParams,
  getRouteData,
  getRepositoryIdParam,
  getMaterialIdParam,
  getUserIdParam,
  getDocumentIdParam,
  getTrainingIdParam,
  getAccountIdParam,
  getSearchIdParam,
  getFilterQueryParam,
  getSearchQueryParam,
};


// export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(routerStateConfig.stateKey);
// export const getMergedRoute = createSelector(
//   getRouterReducerState, (routerReducerState) => routerReducerState ? routerReducerState.state : undefined);
//
// export const getQueryParams = createSelector(
//   getMergedRoute, (state) => state && state.queryParams);
// export const getParams = createSelector(
//   getMergedRoute, (state) => state && state.params);
//
// export const getUrl = createSelector(getMergedRoute, (state) => state.url);
// export const getMergedData = createSelector(getMergedRoute, (state) => state.data ? state.data : {});
//
// export const getParamRepositoryId = createSelector(getParams, (params) => Number(params.repository));
// export const getParamMaterialId = createSelector(getParams, (params) => Number(params.material));
// export const getParamUserId = createSelector(getParams, (params) => Number(params.user));
// export const getParamDocumentId = createSelector(getParams, (params) => params.document);
// export const getParamTraining = createSelector(getParams, (params) => params.training);
// export const getParamAccount = createSelector(getParams, (params) => Number(params.accountId));
// export const getParamSearchId = createSelector(getParams, (params) => params.searchId);
//
// export const getFilter = createSelector(getQueryParams, (params) => params.filter);
// export const getQueryParamSearch = createSelector(getQueryParams, (params) => params.search);


