import {Observable, Subscription} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {QueryParams} from '@ngrx/data';
import {NGXLogger} from 'ngx-logger';
import {filter, map} from 'rxjs/operators';
import {Update} from '@ngrx/entity';
import {
  AttachmentService,
  DocumentService,
  FormService,
  LanguageService,
  MaterialEntity,
  MaterialService,
  MaterialViewModel,
  PathService,
  PermissionService,
  RouterService,
  UsageService
} from '../../../modules/store';

@Injectable({
  providedIn: 'root'
})
export class MaterialViewDataSource implements OnDestroy {

  private subscription: Subscription = new Subscription();
  private readonly _currentDate: Date;

  constructor(private materialService: MaterialService,
              private attachmentService: AttachmentService,
              private usageService: UsageService,
              private formService: FormService,
              private pathService: PathService,
              private documentService: DocumentService,
              private permissionService: PermissionService,
              private languageService: LanguageService,
              private routerService: RouterService,
              private logger: NGXLogger) {
    this.subscription.add(this.materialService.selected$.subscribe(vm => this._viewModel = vm));
    this._currentDate = new Date(Date.now());
  }

  private _viewModel: MaterialViewModel;

  get viewModel() {
    return this._viewModel;
  }

  get isLoading$(): Observable<boolean> {
    return this.materialService.isLoading$;
  }

  get path$() {
    return this.pathService.items$;
  }

  get documents$() {
    return this.documentService.items$;
  }

  get attachments$() {
    return this.attachmentService.materialItems$;
  }

  get usages$() {
    return this.usageService.items$;
  }

  get forms$() {
    return this.formService.items$;
  }

  get children$() {
    return this.materialService.children$;
  }

  get currentDate() {
    return this._currentDate;
  }

  get locale$() {
    return this.languageService.selected$;
  }

  get hasOpenDocument$() {
    return this.documentService.items$.pipe(
      map(items => items.every(item => item.readOnly)),
      map(allReadOnly => !allReadOnly)
    );
  }

  load(request: { repositoryId: number, materialId: number, queryParams: QueryParams }) {

    if (request.materialId !== 0) {
      this.pathService.load({...request, type: 'MATERIAL'});
      this.materialService.children(request.repositoryId, request.materialId);
      this.documentService.load(request.repositoryId, request.materialId);
      this.formService.load(request.repositoryId, request.materialId);
      this.attachmentService.load(request);
    }

    this.materialService.get(request.repositoryId, request.materialId, request.queryParams);
    this.usageService.load();
  }

  save() {
    this.materialService.persistV2(this.viewModel).pipe(
      filter(result => !!result)
    ).subscribe(() => {
      if (this.routerService.materialId !== 0) {
        this.routerService.back();
      }
    });
  }

  update(update: Update<MaterialEntity>) {
    this.materialService.change(update);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
