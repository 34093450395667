import {Injectable} from '@angular/core';
import {environment} from '@hb/environments/environment';
import {ImprintDialogService} from '../../dialogs/imprint';
import {CommandHandler} from '../../modules/command-bus/command.handler';
import {DomainCommand} from '../../modules/command-bus/command';

@Injectable()
export class OpenImprintCommandHandler implements CommandHandler {

  constructor(private imprintDialogService: ImprintDialogService) {
  }

  handle(command: DomainCommand): void {
    if (environment.imprintLink) {
      window.open(environment.imprintLink, '_blank');
    } else {
      this.imprintDialogService.open();
    }
  }
}
