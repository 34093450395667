import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AttachmentDataService} from './attachment-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {AttachmentActions} from './attachment.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class AttachmentEffects {

  loadAttachmentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.loadAttachmentsRequest),

      switchMap(action => this.attachmentDataService.load(action.request).pipe(
        map(payload => AttachmentActions.loadAttachmentsSuccess({payload})),
        catchError(error => of(AttachmentActions.loadAttachmentsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadAttachmentsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.loadAttachmentsSuccess),
    ), {dispatch: false}
  );
  loadAttachmentsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AttachmentActions.loadAttachmentsFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getAttachmentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.getAttachmentRequest),

      switchMap(action => this.attachmentDataService.get(action.request).pipe(
        map(payload => AttachmentActions.getAttachmentSuccess({payload})),
        catchError(error => of(AttachmentActions.getAttachmentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getAttachmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.getAttachmentSuccess),
    ), {dispatch: false}
  );
  getAttachmentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AttachmentActions.getAttachmentFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  createAttachmentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentRequest),

      switchMap(action => this.attachmentDataService.create(action.request).pipe(
        map(payload => AttachmentActions.createAttachmentSuccess({payload})),
        catchError(error => of(AttachmentActions.createAttachmentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  createAttachmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentSuccess),
    ), {dispatch: false}
  );
  createAttachmentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  updateAttachmentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.updateAttachmentRequest),

      switchMap(action => this.attachmentDataService.update(action.entity).pipe(
        map(payload => AttachmentActions.updateAttachmentSuccess({payload})),
        catchError(error => of(AttachmentActions.updateAttachmentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  updateAttachmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.updateAttachmentSuccess),
    ), {dispatch: false}
  );
  updateAttachmentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AttachmentActions.updateAttachmentFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  removeAttachmentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentRequest),

      switchMap(action => this.attachmentDataService.remove(action.request).pipe(
        map(payload => AttachmentActions.removeAttachmentSuccess({payload})),
        catchError(error => of(AttachmentActions.removeAttachmentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  removeAttachmentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentSuccess),
    ), {dispatch: false}
  );
  removeAttachmentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private attachmentDataService: AttachmentDataService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }
}
