import {createAction, props} from '@ngrx/store';
import {AddressEntity, AddressItemPayload, AddressItemRequest, AddressListPayload} from './address.models';
import {Exception} from '../../../../../../core/models';

enum AddressActionTypes {
  LOAD_ADDRESSES_REQUEST = '[Address] Load Addresses Request',
  LOAD_ADDRESSES_SUCCESS = '[Address] Load Addresses Success',
  LOAD_ADDRESSES_FAILURE = '[Address] Load Addresses Failure',
  GET_ADDRESS_REQUEST = '[Address] Get Address Request',
  GET_ADDRESS_SUCCESS = '[Address] Get Address Success',
  GET_ADDRESS_FAILURE = '[Address] Get Address Failure',
  PERSIST_ADDRESS_REQUEST = '[Address] Persist Address Request',
  PERSIST_ADDRESS_SUCCESS = '[Address] Persist Address Success',
  PERSIST_ADDRESS_FAILURE = '[Address] Persist Address Failure',
}

const loadAddressesRequest = createAction(
  AddressActionTypes.LOAD_ADDRESSES_REQUEST,
);

const loadAddressesSuccess = createAction(
  AddressActionTypes.LOAD_ADDRESSES_SUCCESS,
  props<{ payload: AddressListPayload }>()
);

const loadAddressesFailure = createAction(
  AddressActionTypes.LOAD_ADDRESSES_FAILURE,
  props<{ exception: Exception }>()
);

const getAddressRequest = createAction(
  AddressActionTypes.GET_ADDRESS_REQUEST,
  props<{ request: AddressItemRequest }>()
);

const getAddressSuccess = createAction(
  AddressActionTypes.GET_ADDRESS_SUCCESS,
  props<{ payload: AddressItemPayload }>()
);

const getAddressFailure = createAction(
  AddressActionTypes.GET_ADDRESS_FAILURE,
  props<{ exception: Exception }>()
);

const persistAddressRequest = createAction(
  AddressActionTypes.PERSIST_ADDRESS_REQUEST,
  props<{ address: AddressEntity }>()
);

const persistAddressSuccess = createAction(
  AddressActionTypes.PERSIST_ADDRESS_SUCCESS,
  props<{ payload: AddressItemPayload }>()
);

const persistAddressFailure = createAction(
  AddressActionTypes.PERSIST_ADDRESS_FAILURE,
  props<{ exception: Exception }>()
);

export const AddressActions = {
  loadAddressesRequest,
  loadAddressesSuccess,
  loadAddressesFailure,
  getAddressRequest,
  getAddressSuccess,
  getAddressFailure,
  persistAddressRequest,
  persistAddressSuccess,
  persistAddressFailure,
};
