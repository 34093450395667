<div class="h-56p w-full mx-6">
  <ol class="list-none p-0 inline-flex w-full">
    <li *ngFor="let item of getItems(); index as i" [routerLink]="navigate(item)"
        class="flex items-center xl:max-w-1/6 md:max-w-1/5 max-w-1/4 cursor-pointer">
      <img *ngIf="i === 0" [alt]="item.name" class="hb-logo logo" src="">
      <mat-icon *ngIf="i !== 0" [appTheme]="getColor(i === getLength() - 1)" [fontIcon]="item.icon" class="mr-4"
                fontSet="fas"></mat-icon>
      <span *ngIf="i !== 0" class="truncate">{{item.name}}</span>
      <mat-icon *ngIf="i !== getLength() - 1" class="flex items-end mx-3" color="mute" fontIcon="fa-chevron-right"
                fontSet="fas"></mat-icon>
    </li>
  </ol>
</div>

