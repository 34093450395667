import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartOptions, ChartType} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import {NGXLogger} from 'ngx-logger';
import {DashboardService} from '../../../modules/core/services/dashboard';
import {Subject} from 'rxjs';
import {BreakpointService} from '../../../modules/core/services/breakpoint';
import {Breakpoints} from '@angular/cdk/layout';
import {TranslateService} from '@ngx-translate/core';
import {MaterialService, PathService, RepositoryService, RouterService} from '../../../modules/store';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'hb-dashboard-view',
  templateUrl: './dashboard-view.component.html',
  styleUrls: ['./dashboard-view.component.css'],
})
export class DashboardViewComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @ViewChild(BaseChartDirective)
  public chartContainer: BaseChartDirective;

  constructor(public repositoryService: RepositoryService,
              private materialService: MaterialService,
              private routerService: RouterService,
              public dashboardService: DashboardService,
              public breakpointService: BreakpointService,
              public translateService: TranslateService,
              private pathService: PathService,
              public routerFacade: RouterService,
              private logger: NGXLogger) {

  }

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = [
    this.translateService.instant('views.dashboard.labels.documents'),
    this.translateService.instant('views.dashboard.labels.materials'),
    this.translateService.instant('views.dashboard.labels.repositories')
  ];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

  get path$() {
    return this.pathService.items$;
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public columns() {
    if (this.breakpointService.matches([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])) {
      return 1;
    } else if (this.breakpointService.matches([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape])) {
      return 2;
    }
  }

  ngOnInit() {

    this.dashboardService.load().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(data => this.pieChartData = [data.docCount, data.materialCount, data.repositoryCount]);

    this.repositoryService.repositoryId$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(repositoryId => this.pathService.load({type: 'REPOSITORY', repositoryId}));

    this.translateService.onLangChange.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(event => {
      const temp = [
        this.translateService.instant('views.dashboard.labels.documents'),
        this.translateService.instant('views.dashboard.labels.materials'),
        this.translateService.instant('views.dashboard.labels.repositories')
      ];
      this.chartContainer.labels.length = 0;
      this.chartContainer.labels.push(...temp);
      this.chartContainer.update();
    });
  }
}
