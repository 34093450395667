import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {map} from 'rxjs/operators';
import {
  ActivateRequest,
  ChangeEmailRequest,
  LoginPayload,
  LoginRequest,
  LoginResponse,
  PasswordForgottenPayload,
  PasswordForgottenRequest,
  PasswordForgottenResponse,
  RefreshPayload,
  RefreshRequest,
  RefreshResponse,
  RegisterPayload,
  RegisterRequest,
  RegisterResponse,
  ResetPasswordPayload,
  ResetPasswordRequest,
  ResetPasswordResponse
} from './authentication.models';
import {ACCESS_TOKEN, REFRESH_TOKEN} from './authentication.reducer';
import {environment} from '../../../../../../../../environments';
import {UserItemResponse} from '../../../user-store';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationDataService {
  private subject: BehaviorSubject<boolean>;
  public isLoggedIn$: Observable<boolean>;

  getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  register(request: RegisterRequest): Observable<RegisterPayload> {
    return this.http.put<RegisterResponse>(`${environment.api}/register`, request);
  }

  login(request: LoginRequest): Observable<LoginPayload> {
    return this.http.post<LoginResponse>(`${environment.api}/login`, request).pipe(
      map(res => ({
        access: res.accessToken,
        refresh: res.refreshToken,
      }))
    );
  }

  logout(): Observable<any> {
    return this.http.post(`${environment.api}/logout`, '', {responseType: 'text'});
  }

  passwordForgotten(request: PasswordForgottenRequest): Observable<PasswordForgottenPayload> {
    return this.http.post<PasswordForgottenResponse>(`${environment.api}/reset-password`, request);
  }

  resetPassword(request: ResetPasswordRequest): Observable<ResetPasswordPayload> {
    return this.http.post<ResetPasswordResponse>(`${environment.api}/reset-password`, request);
  }

  activate(request: ActivateRequest): Observable<any> {
    return this.http.post(`${environment.api}/user-activation`, request);
  }

  changeEmail(request: ChangeEmailRequest): Observable<any> {
    return this.http.post(`${environment.api}/user-newemail-activation`, request);
  }

  refreshToken(request: RefreshRequest): Observable<RefreshPayload> {
    return this.http.post<RefreshResponse>(`${environment.api}/login-refresh`, request.refresh).pipe(
      map(res => ({
        access: res.accessToken,
        refresh: res.refreshToken
      }))
    );
  }

  constructor(private http: HttpClient, private logger: NGXLogger) {
    this.subject = new BehaviorSubject(this.hasToken());
    this.isLoggedIn$ = this.subject.asObservable();
  }

  hasToken() {
    return !!this.getAccessToken();
  }

  getLoggedInData() {
    return this.http.get<UserItemResponse>(`${environment.api}/loggedinuser`).pipe(
      map(res => (
        {
          repositoryId: res.data.repositoryId,
          userId: res.data.id,
          accountId: res.data.accountId,
        }
      ))
    );
  }

  public setTokens(tokens: LoginPayload | RefreshPayload) {
    window.localStorage.setItem(ACCESS_TOKEN, tokens.access);
    window.localStorage.setItem(REFRESH_TOKEN, tokens.refresh);
  }

  public removeTokens() {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(REFRESH_TOKEN);
  }

  isLoggedIn() {
    return this.http.get(`${environment.api}/login-check`);
  }

}
