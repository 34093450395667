import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {LICENSE_FEATURE_KEY, LicensePartialState, LicenseState} from './license.reducer';
import {LicenseEntity} from './license.models';
import {Exception} from '../../../../../../core/models';

const getLicenseState = createFeatureSelector<LicensePartialState, LicenseState>(LICENSE_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getLicenseState,
  (state: LicenseState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getLicenseState,
  (state: LicenseState) => state.exception
);

const getItems: MemoizedSelector<object, LicenseEntity[]> = createSelector(
  getLicenseState,
  (state: LicenseState) => state.items
);

export const LicenseSelectors = {
  isLoading,
  getException,
  getItems,
};
