import {Component, OnDestroy, OnInit} from '@angular/core';
import {MaterialService, MaterialViewModel, PathService, RepositoryService, RouterService} from '../../../modules/store';
import {NGXLogger} from 'ngx-logger';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'hb-inspections-view',
  templateUrl: './inspections-view.component.html',
  styleUrls: ['./inspections-view.component.css']
})
export class InspectionsViewComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(private materialService: MaterialService,
              private repositoryService: RepositoryService,
              private pathService: PathService,
              private routerService: RouterService,
              private logger: NGXLogger) {

  }

  get isLoading$() {
    return this.materialService.isLoading$;
  }

  public get items$() {
    return this.materialService.auditable$;
  }

  public get rows$() {
    return this.materialService.rows$;
  }

  get path$() {
    return this.pathService.items$;
  }

  public get innerHeight() {
    return `${window.innerHeight - 117}px`;
  }

  ngOnInit(): void {

    this.repositoryService.repositoryId$.pipe(
      takeUntil(this.unsubscribe$),
      filter(repositoryId => !!repositoryId)
    ).subscribe(repositoryId => this.pathService.load({type: 'REPOSITORY', repositoryId}));

    this.materialService.auditable({page: 0});
  }

  fetch($event) {
    this.materialService.auditable({page: $event.page});
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  open(viewModel: MaterialViewModel) {
    this.routerService.navigateToMaterialViewV2(viewModel);
  }

}
