import {Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {AbstractControlValueAccessor} from './utils/AbstractControlValueAccessor';

export enum DISPLAY_TYPE {
  SIMPLE,
  FORM_CONTROL,
  AUTOCOMPLETE,
  FORM_CONTROL_AND_AUTOCOMPLETE
}

@Component({
  selector: 'hb-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('true', style({ width: '*' })),
      state('false', style({ width: '0' })),
      transition('true => false', animate('300ms ease-in')),
      transition('false => true', animate('300ms ease-out'))
    ])
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterBarComponent),
      multi: true
    }
  ]
})
export class FilterBarComponent extends AbstractControlValueAccessor<string> implements OnInit {
  DISPLAY_TYPE = DISPLAY_TYPE;

  @ViewChild('input') inputElement: ElementRef;

  @Input() formControl: FormControl;
  @Input() matAutocomplete: MatAutocomplete;
  @Input() placeholder = '';
  @Input() searchVisible = false;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onBlur = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClose = new EventEmitter<void>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onEnter = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFocus = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onOpen = new EventEmitter<void>();

  mode: DISPLAY_TYPE;


  ngOnInit() {
    if (!this.formControl && !this.matAutocomplete) {
      this.mode = DISPLAY_TYPE.SIMPLE;
    } else if (this.formControl && this.matAutocomplete) {
      this.mode = DISPLAY_TYPE.FORM_CONTROL_AND_AUTOCOMPLETE;
    } else if (this.formControl) {
      this.mode = DISPLAY_TYPE.FORM_CONTROL;
    } else if (this.matAutocomplete) {
      this.mode = DISPLAY_TYPE.AUTOCOMPLETE;
    }
  }

  public close(): void {
    this.searchVisible = false;
    this.value = '';
    this.updateChanges();
    this.onClose.emit();
  }

  public open(): void {
    this.searchVisible = true;
    this.inputElement.nativeElement.focus();
    this.onOpen.emit();
  }

  onBlurring(searchValue: string) {
    if (!searchValue) {
      this.searchVisible = false;
    }
    this.onBlur.emit(searchValue);
  }

  onEnterring(searchValue: string) {
    this.onEnter.emit(searchValue);
  }

  onFocussing(searchValue: string) {
    this.onFocus.emit(searchValue);
  }

  handle() {
    if (this.searchVisible) {
      this.close();
    } else {
      this.open();
    }
  }
}
