<mat-progress-bar *ngIf="isLoading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>
<div class="flex flex-col flex-grow">
  <div *ngIf="!!viewModel && !(isLoading$ | async) && ('read' | able: 'document')">
    <hb-card-column-view>
      <hb-card [status]="viewModel.status"
               [title]="viewModel.title">
        <hb-card-image>
          <hb-image-input disabled="true">
          </hb-image-input>
        </hb-card-image>
        <hb-card-content>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="hb-left-icon" color="primary" fontIcon="fa-clipboard-list" fontSet="fas"></mat-icon>
                {{'groups.meta_data.title' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <small>{{'keys.local.global.id' | translate}}: {{viewModel.objectId}}<br></small>
            <small *ngIf="viewModel.createdAt">{{'keys.local.global.created' | translate}}
              : {{viewModel.createdAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.createdBy">, {{viewModel.createdBy}}<br></small>
            <small *ngIf="viewModel.updatedAt">{{'keys.local.global.updated' | translate}}
              : {{viewModel.updatedAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.updatedBy">, {{viewModel.updatedBy}}<br></small>
          </mat-expansion-panel>
          <mat-expansion-panel *ngIf="!!viewModel.hasPdf || !!viewModel.hasLabelPdf">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary" fontIcon="fa-download" fontSet="fas"
                          style="margin-right: 10px"></mat-icon>
                {{'groups.downloads.title' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hb-download-content" fxFlex fxLayout="column nowrap" fxLayoutGap="10px">
              <button (click)="download('pdf')" *ngIf="!!viewModel.hasPdf" [disabled]="!viewModel.hasPdf"
                      class="hb-truncate" mat-button>
                <mat-icon color="primary" fontIcon="fa-file-pdf" fontSet="fas"></mat-icon>
                {{viewModel.pdfFilename}}
              </button>
              <button (click)="download('zebra')" *ngIf="!!viewModel.hasLabelPdf" [disabled]="!viewModel.hasLabelPdf"
                      class="hb-truncate" mat-button>
                <mat-icon color="primary" fontIcon="fa-tag" fontSet="fas"></mat-icon>
                {{viewModel.labelPdfFilename}}
              </button>
            </div>

          </mat-expansion-panel>
        </hb-card-content>
      </hb-card>
      <hb-card-accordion>
        <mat-accordion>
          <form #ngForm="ngForm" ngForm>
            <mat-expansion-panel *ngFor="let group of groups"
                                 [disabled]="group === 'Signaturen' && viewModel.id === 0"
                                 [expanded]="group === 'Monteur/Prüfer'">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon [fontIcon]="icons.get(group)" color="primary" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{viewModel.getGroupCaption(group)}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                  <mat-icon *ngIf="groupControl.invalid" class="hb-right-icon" color="warn"
                            fontIcon="fa-exclamation-triangle"
                            fontSet="fas"></mat-icon>
                  <hb-commercial-marker *ngIf="group === 'Signaturen'"></hb-commercial-marker>
                </mat-panel-description>

              </mat-expansion-panel-header>
              <fieldset #groupControl="ngModelGroup" [disabled]="!('update' | able: 'document')"
                        [ngModelGroup]="group" fxLayout="column nowrap">

                <ng-container *ngFor="let field of viewModel.groups[group]" [ngSwitch]="field.editor">

                  <ng-container *ngSwitchCase="'string'">
                    <mat-form-field appearance="outline">
                      <mat-label>
                        <label for="string_{{field.id}}">{{field.caption}}</label>
                      </mat-label>
                      <input #stringControl="ngModel" [(ngModel)]="field.value"
                             [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                             [placeholder]="field.caption"
                             [required]="field.valueRequired"
                             id="string_{{field.id}}"
                             matInput
                             name="string_{{field.id}}" touched>
                      <mat-error *ngIf="stringControl.dirty && stringControl.touched">
                        <ng-container *ngIf="stringControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'date'">
                    <mat-form-field appearance="outline">
                      <mat-label>
                        <label for="date_{{field.id}}">{{field.caption}}</label>
                      </mat-label>
                      <input #dateControl="ngModel" [(ngModel)]="field.value"
                             [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                             [placeholder]="field.caption"
                             [required]="field.valueRequired"
                             id="date_{{field.id}}"
                             matInput
                             name="date_{{field.id}}" touched>
                      <mat-error *ngIf="dateControl.dirty && dateControl.touched">
                        <ng-container *ngIf="dateControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'text'">
                    <mat-form-field appearance="outline">
                      <mat-label>
                        <label for="text_{{field.id}}">{{field.caption}}</label>
                      </mat-label>
                      <textarea #textControl="ngModel" [(ngModel)]="field.value"
                                [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                [placeholder]="field.caption"
                                [required]="field.valueRequired"
                                id="text_{{field.id}}"
                                matInput
                                name="text_{{field.id}}" touched></textarea>
                      <mat-error *ngIf="textControl.dirty && textControl.touched">
                        <ng-container *ngIf="textControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'yesnonull'">
                    <mat-form-field appearance="outline">
                      <hb-radio-input #yesNoNullControl="ngModel" [(ngModel)]="field.value"
                                      [buttons]="[
                                          {value: 'yes', caption: 'keys.local.global.yes' | translate},
                                          {value: 'no', caption: 'keys.local.global.no' | translate}
                                          ]"
                                      [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                      [required]="field.valueRequired"
                                      [validValue]="field.validValue" id="yes_no_null_{{field.id}}"
                                      name="yes_no_null_{{field.id}}" touched>
                        <label>{{field.caption}}</label>
                      </hb-radio-input>
                      <mat-error *ngIf="yesNoNullControl.dirty && yesNoNullControl.touched">
                        <ng-container *ngIf="yesNoNullControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'yesnonullrepaired'">
                    <mat-form-field appearance="outline">
                      <hb-radio-input #yesNoNullRepairedControl="ngModel" [(ngModel)]="field.value"
                                      [buttons]="[
                                          {value: 'yes', caption: 'keys.local.global.yes' | translate},
                                          {value: 'no', caption: 'keys.local.global.no' | translate},
                                          {value: 'repaired', caption: 'keys.local.global.repaired' | translate}
                                          ]"
                                      [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                      [required]="field.valueRequired"
                                      [validValue]="field.validValue" id="yes_no_null_repaired_{{field.id}}"
                                      name="yes_no_null_repaired_{{field.id}}" touched>
                        <label>{{field.caption}}</label>
                      </hb-radio-input>
                      <mat-error *ngIf="yesNoNullRepairedControl.dirty && yesNoNullRepairedControl.touched">
                        <ng-container *ngIf="yesNoNullRepairedControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'yesnonullunavailable'">
                    <mat-form-field appearance="outline">
                      <hb-radio-input #yesNoNullUnavailableControl="ngModel"
                                      [(ngModel)]="field.value"
                                      [buttons]="[
                                          {value: 'yes', caption: 'keys.local.global.yes' | translate},
                                          {value: 'no', caption: 'keys.local.global.no' | translate},
                                          {value: 'unavailable', caption: 'keys.local.global.unavailable' | translate}
                                          ]"
                                      [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                      [required]="field.valueRequired"
                                      [validValue]="field.validValue"
                                      id="yes_no_null_unavailable_{{field.id}}"
                                      name="yes_no_null_unavailable_{{field.id}}" touched>
                        <label>{{field.caption}}</label>
                      </hb-radio-input>
                      <mat-error *ngIf="yesNoNullUnavailableControl.dirty && yesNoNullUnavailableControl.touched">
                        <ng-container *ngIf="yesNoNullUnavailableControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'addedremovedcheckednull'">
                    <mat-form-field appearance="outline">
                      <hb-radio-input #addedRemovedCheckedNullControl="ngModel"
                                      [(ngModel)]="field.value"
                                      [buttons]="[
                                          {value: 'added', caption: 'keys.local.global.added' | translate},
                                          {value: 'removed', caption: 'keys.local.global.removed' | translate},
                                          {value: 'checked', caption: 'keys.local.global.checked' | translate}
                                          ]"
                                      [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                      [placeholder]="field.caption"
                                      [required]="field.valueRequired"
                                      [validValue]="field.validValue"
                                      id="added_removed_checked_null_{{field.id}}"
                                      name="added_removed_checked_null_{{field.id}}" touched>
                        <hb-radio-input-material-label [material]="field.material">
                        </hb-radio-input-material-label>
                      </hb-radio-input>
                      <mat-error
                        *ngIf="addedRemovedCheckedNullControl.dirty && addedRemovedCheckedNullControl.touched">
                        <ng-container *ngIf="addedRemovedCheckedNullControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>
                  </ng-container>

                  <ng-container *ngSwitchCase="'addedremovedcheckeddamagednull'">
                    <mat-form-field appearance="outline">
                      <hb-radio-input #addedRemovedCheckedDamagedNullControl="ngModel"
                                      [(ngModel)]="field.value"
                                      [buttons]="[
                                          {value: 'added', caption: 'keys.local.global.added' | translate},
                                          {value: 'removed', caption: 'keys.local.global.removed' | translate},
                                          {value: 'checked', caption: 'keys.local.global.checked' | translate},
                                          {value: 'damaged', caption: 'keys.local.global.damaged' | translate}
                                          ]"
                                      [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                      [placeholder]="field.caption"
                                      [required]="field.valueRequired"
                                      [validValue]="field.validValue"
                                      id="added_removed_checked_damaged_null_{{field.id}}"
                                      name="added_removed_checked_damaged_null_{{field.id}}" touched>
                        <hb-radio-input-material-label [material]="field.material"></hb-radio-input-material-label>
                      </hb-radio-input>
                      <mat-error
                        *ngIf="addedRemovedCheckedDamagedNullControl.dirty && addedRemovedCheckedDamagedNullControl.touched">
                        <ng-container *ngIf="addedRemovedCheckedDamagedNullControl.hasError('required')">
                          {{'forms.errors.is_required' | translate}}
                        </ng-container>
                      </mat-error>
                    </mat-form-field>

                  </ng-container>

                  <ng-container *ngSwitchCase="'address'">

                    <ng-container *ngIf="!disableAddress.value">

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_organisation_{{field.id}}">
                            {{'forms.fields.organisation.label' | translate}}
                          </label>
                        </mat-label>
                        <input #addressOrganisationControl="ngModel" [(ngModel)]="field.address.companyName"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.organisation.placeholder' | translate"
                               [required]="field.valueRequired"
                               id="address_organisation_{{field.id}}"
                               matInput
                               name="address_organisation_{{field.id}}" touched>
                        <mat-hint>{{'forms.fields.organisation.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressOrganisationControl.dirty && addressOrganisationControl.touched">
                          <ng-container *ngIf="addressOrganisationControl.hasError('required')">
                            {{'forms.errors.is_required' | translate}}
                          </ng-container>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_contact_person_{{field.id}}">
                            {{'forms.fields.contact_person.label' | translate}}
                          </label>
                        </mat-label>
                        <input #addressContactPersonControl="ngModel" [(ngModel)]="field.address.contactPerson"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.contact_person.placeholder' | translate"
                               [required]="field.valueRequired"
                               id="address_contact_person_{{field.id}}"
                               matInput
                               name="address_contact_person_{{field.id}}" touched>
                        <mat-hint>{{'forms.fields.contact_person.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressContactPersonControl.dirty && addressContactPersonControl.touched">
                          <ng-container *ngIf="addressContactPersonControl.hasError('required')">
                            {{'forms.errors.is_required' | translate}}
                          </ng-container>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_line_1_{{field.id}}">
                            {{'forms.fields.address_line_1.label' | translate}}
                          </label>
                        </mat-label>
                        <input #addressLine1Control="ngModel" [(ngModel)]="field.address.street"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.address_line_1.placeholder' | translate"
                               [required]="field.valueRequired"
                               id="address_line_1_{{field.id}}"
                               matInput
                               name="address_line_1_{{field.id}}" touched>
                        <mat-hint>{{'forms.fields.address_line_1.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressLine1Control.dirty && addressLine1Control.touched">
                          <ng-container *ngIf="addressLine1Control.hasError('required')">
                            {{'forms.errors.is_required' | translate}}
                          </ng-container>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_zip_{{field.id}}">
                            {{'forms.fields.zip.label' | translate}}
                          </label>
                        </mat-label>
                        <input #addressZipControl="ngModel" [(ngModel)]="field.address.zipCode"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.zip.placeholder' | translate"
                               [required]="field.valueRequired"
                               id="address_zip_{{field.id}}"
                               matInput
                               name="address_zip_{{field.id}}" touched>
                        <mat-hint>{{'forms.fields.zip.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressZipControl.dirty && addressZipControl.touched">
                          <ng-container *ngIf="addressZipControl.hasError('required')">
                            {{'forms.errors.is_required' | translate}}
                          </ng-container>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_city_{{field.id}}">
                            {{'forms.fields.city.label' | translate}}
                          </label>
                        </mat-label>
                        <input #addressCityControl="ngModel" [(ngModel)]="field.address.city"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.city.placeholder' | translate"
                               [required]="field.valueRequired"
                               id="address_city_{{field.id}}"
                               matInput
                               name="address_city_{{field.id}}" touched>
                        <mat-hint>{{'forms.fields.city.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressCityControl.dirty && addressCityControl.touched">
                          <ng-container *ngIf="addressCityControl.hasError('required')">
                            {{'forms.errors.is_required' | translate}}
                          </ng-container>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label>
                            {{'forms.fields.country.label' | translate}}
                          </label>
                        </mat-label>
                        <mat-select #addressCountry="ngModel" [(ngModel)]="field.address.countryId"
                                    [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                    [placeholder]="'forms.fields.country.placeholder' | translate"
                                    [required]="field.valueRequired"
                                    [typeaheadDebounceInterval]="400"
                                    id="address_country_{{field.id}}"
                                    name="address_country_{{field.id}}" touched>
                          <mat-option [value]="null"></mat-option>
                          <mat-option *ngFor="let country of countries$ | async"
                                      [value]="country.id">{{country.name}}</mat-option>
                        </mat-select>
                        <mat-hint>{{'forms.fields.country.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressCountry.dirty && addressCountry.touched">
                          <ng-container *ngIf="addressCountry.hasError('required')">
                            {{'forms.errors.is_required' | translate}}
                          </ng-container>
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_email_{{field.id}}">
                            {{'forms.fields.email.label' | translate}}
                          </label>
                        </mat-label>
                        <input #addressEmailControl="ngModel"
                               [(ngModel)]="field.address.email"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.email.placeholder' | translate"
                               id="address_email_{{field.id}}" matInput
                               name="address_email_{{field.id}}"
                               touched
                               type="email">
                        <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
                        <mat-error *ngIf="addressEmailControl.dirty && addressEmailControl.touched">

                        </mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>
                          <label for="address_phone_{{field.id}}">{{'forms.fields.phone.label' | translate}}</label>
                        </mat-label>
                        <input #addressPhoneControl="ngModel"
                               [(ngModel)]="field.address.phone"
                               [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                               [placeholder]="'forms.fields.phone.placeholder' | translate"
                               id="address_phone_{{field.id}}" matInput
                               name="address_phone_{{field.id}}"
                               touched
                               type="tel">
                        <mat-error *ngIf="addressPhoneControl.dirty && addressPhoneControl.touched">

                        </mat-error>
                      </mat-form-field>

                    </ng-container>


                    <mat-slide-toggle #disableAddress="ngModel"
                                      [(ngModel)]="field.disabled"
                                      [disabled]="field.valueRequired"
                                      color="primary"
                                      id="active"
                                      labelPosition="before"
                                      name="active">
                      {{'forms.fields.disable.label' | translate}}
                    </mat-slide-toggle>

                  </ng-container>

                  <ng-container *ngSwitchCase="'signature'">
                    <hb-input-signature (update)="signatureUpdateEventHandler($event, field)"
                                        [disabled]="field.readOnly || viewModel.readOnly || !('update' | able: 'document')"
                                        [mediaType]="field.field === 'prüfer' ? mediaType.SIGNATURE_INSPECTOR : mediaType.SIGNATURE_PURCHASER"
                                        [signatureTitle]="field.caption"
                                        [targetId]="field.id"
                                        [value]="field.mediaPoolFile">
                    </hb-input-signature>
                  </ng-container>

                </ng-container>
              </fieldset>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="viewModel.id !== 0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-paperclip" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{'groups.attachments.title' | translate}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                  <hb-commercial-marker></hb-commercial-marker>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div fxLayout="column nowrap" fxLayoutGap="1rem">
                <button (click)="addAttachment()"
                        [disabled]="!('create' | able: 'document.media') || viewModel.readOnly" color="primary"
                        mat-raised-button>
                  <mat-label>
                    <mat-icon [inline]="true" fontIcon="fa-plus" fontSet="fas" matPrefix></mat-icon>
                    {{'keys.local.global.add_attachment' | translate}}</mat-label>
                </button>
                <mat-list>
                  <hb-attachment-item-view *ngFor="let attachment of attachments$ | async"
                                           [disabled]="viewModel.readOnly || !('update' | able: 'document.media')"
                                           [viewModel]="attachment"
                                           parentType="document">

                  </hb-attachment-item-view>
                </mat-list>
              </div>
            </mat-expansion-panel>

          </form>
        </mat-accordion>

      </hb-card-accordion>
    </hb-card-column-view>

    <hb-fab-group [label]="'keys.local.fab.menu' | translate">
      <ng-container *hbFabItem>
        <button (click)="save()"
                [disabled]="!('update' | able: 'document')"
                [matTooltip]="'keys.local.fab.save' | translate"
                color="accent" mat-mini-fab
                matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
          <mat-icon fontIcon="fa-save" fontSet="fas" inline="true"></mat-icon>
        </button>
      </ng-container>
      <ng-container *hbFabItem>
        <button (click)="seal()"
                [disabled]="ngForm.invalid || !('update' | able: 'document')"
                [matTooltip]="'keys.local.fab.seal' | translate"
                color="accent" mat-mini-fab matTooltipHideDelay="200" matTooltipPosition="left"
                matTooltipTouchGestures="on">
          <mat-icon fontIcon="fa-lock" fontSet="fas" inline="true"></mat-icon>
        </button>
      </ng-container>
    </hb-fab-group>
  </div>
</div>
