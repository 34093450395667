import {createAction, props} from '@ngrx/store';
import {FormListPayload, FormListRequest} from './form.models';
import {Exception} from '../../../../../../core/models';

enum FormActionTypes {

  LOAD_FORMS_REQUEST = '[Form] Load Forms Request',
  LOAD_FORMS_SUCCESS = '[Form] Load Forms Success',
  LOAD_FORMS_FAILURE = '[Form] Load Forms Failure',

  RESET_FORM_STORE = '[Form] Reset Form Store',
}

const loadFormsRequest = createAction(
  FormActionTypes.LOAD_FORMS_REQUEST,
  props<{ request: FormListRequest }>()
);

const loadFormsSuccess = createAction(
  FormActionTypes.LOAD_FORMS_SUCCESS,
  props<{ payload: FormListPayload }>()
);

const loadFormsFailure = createAction(
  FormActionTypes.LOAD_FORMS_FAILURE,
  props<{ exception: Exception }>()
);

const resetFormStore = createAction(
  FormActionTypes.RESET_FORM_STORE
);

export const FormActions = {
  loadFormsRequest,
  loadFormsSuccess,
  loadFormsFailure,
  resetFormStore,
};
