import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {TileActions} from './tile.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';
import {TileEntity} from './tile.models';


export const TILE_FEATURE_KEY = 'tile';

export interface TileState extends EntityState<TileEntity> {
  isLoading: boolean;
  autocomplete: TileEntity[];
  exception: Exception;
  length: number;
}

export interface TilePartialState {
  readonly [TILE_FEATURE_KEY]: TileState;
}

export const tileAdapter: EntityAdapter<TileEntity> = createEntityAdapter<TileEntity>();

export const initialState: TileState = tileAdapter.getInitialState({
  isLoading: false,
  autocomplete: [],
  exception: null,
  length: 0
});

const tileReducer = createReducer(
  initialState,
  on(TileActions.autocompleteInitial, (state) => ({
    ...state,
    autocomplete: []
  })),
  on(TileActions.autocompleteRequest, (state) => ({
    ...state,
    autocomplete: [],
  })),
  on(TileActions.autocompleteSuccess, (state, {payload}) =>
    ({
      ...state,
      autocomplete: payload.items,
    })
  ),
  on(TileActions.autocompleteFailure, (state, {exception}) => ({
    ...state,
    autocomplete: [],
    exception
  })),
  on(TileActions.loadTilesRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TileActions.loadTilesSuccess, (state, {payload}) =>
    tileAdapter.setAll(payload.items, {
      ...state,
      isLoading: false,
      length: payload.rows
    })
  ),
  on(TileActions.loadTilesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(TileActions.getTileRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(TileActions.getTileSuccess, (state, {payload}) =>
    tileAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })
  ),
  on(TileActions.getTileFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(TileActions.fetchTilesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(TileActions.fetchTilesSuccess, (state, {payload}) =>
    tileAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })
  ),
  on(TileActions.fetchTilesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(TileActions.resetTiles, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: TileState | undefined, action: Action) {
  return tileReducer(state, action);
}
