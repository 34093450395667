import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ImprintComponent} from './imprint.component';

@Injectable({
  providedIn: 'root'
})
export class ImprintDialogService extends BaseDialogService<ImprintComponent, any> {

  protected config: MatDialogConfig = {
    width: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, ImprintComponent);
  }
}
