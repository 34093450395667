<mat-progress-bar *ngIf="isLoading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>
<div class="flex flex-col flex-grow">
  <div *ngIf="!!viewModel && !(isLoading$ | async)">
    <hb-card-column-view>
      <hb-card [subTitle]="'keys.local.global.customerId' | translate:{value: viewModel.customerId.toString()}"
               [title]="viewModel.name">
        <hb-card-image>
          <hb-commercial-marker style="position: absolute; z-index: 500; padding: 5px;"></hb-commercial-marker>
          <hb-image-input
            [disabled]="!('update' | able: 'account.media')"
            [mediaId]="viewModel.mediaId"
            [mediaType]="mediaType.ACCOUNT_IMAGE"
            [targetId]="viewModel.id">
          </hb-image-input>
        </hb-card-image>
        <hb-card-content>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="hb-left-icon" color="primary" fontIcon="fa-clipboard-list" fontSet="fas"></mat-icon>
                {{'groups.meta_data.title' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <small>{{'keys.local.global.id' | translate}}: {{viewModel.objectId}}<br></small>
            <small *ngIf="viewModel.createdAt">{{'keys.local.global.created' | translate}}
              : {{viewModel.createdAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.createdBy">, {{viewModel.createdBy}}<br></small>
            <small *ngIf="viewModel.updatedAt">{{'keys.local.global.updated' | translate}}
              : {{viewModel.updatedAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.updatedBy">, {{viewModel.updatedBy}}<br></small>
          </mat-expansion-panel>
        </hb-card-content>
      </hb-card>
      <hb-card-accordion>
        <mat-accordion>
          <form #ngForm=ngForm>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-sitemap" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{'groups.account.title' | translate}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                  <mat-icon *ngIf="fgMain.invalid" class="hb-cropper-icon" color="warn"
                            fontIcon="fa-exclamation-triangle"
                            fontSet="fas"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <fieldset #fgMain="ngModelGroup" fxLayout="column nowrap" ngModelGroup="main">

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="name">{{'forms.fields.name.label' | translate}}</label>
                  </mat-label>
                  <input #title="ngModel"
                         [(ngModel)]="viewModel.name"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.name.placeholder' | translate"
                         id="name"
                         matInput
                         name="name"
                         required touched>
                  <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="title.dirty && title.touched">
                    <ng-container *ngIf="title.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="license">{{'forms.fields.license.label' | translate}}</label>
                  </mat-label>
                  <mat-select #license="ngModel"
                              [disabled]="!('update' | able: context)"
                              [ngModel]="viewModel.licenseId"
                              [placeholder]="'forms.fields.license.placeholder' | translate"
                              id="license"
                              name="license">
                    <mat-option *ngFor="let license of licenses$ | async"
                                [value]="license.id">{{license.name}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.license.hint' | translate}}</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>{{'forms.fields.owner.label' | translate}}</mat-label>
                  <mat-select #owner="ngModel"
                              [(ngModel)]="viewModel.ownerId"
                              [disabled]="!('update' | able: context)"
                              [placeholder]="'forms.fields.owner.placeholder' | translate"
                              id="owner"
                              name="owner">
                    <mat-option *ngFor="let user of users$ | async" [value]="user.id">{{user.name}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.owner.hint' | translate}}</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="check_interval">{{'forms.fields.check_interval.label' | translate}}</label>
                  </mat-label>
                  <input #check_interval="ngModel"
                         [(ngModel)]="viewModel.checkInterval"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.check_interval.placeholder' | translate"
                         id="check_interval"
                         matInput
                         min="1"
                         name="check_interval"
                         pattern="^\d*$"
                         required
                         type="number">
                  <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()" color="info" fontIcon="fa-info-circle"
                            fontSet="fas" matSuffix
                            matTooltip="{{'keys.local.tooltips.form_interval' | translate}}"></mat-icon>
                  <mat-hint>{{'forms.fields.check_interval.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="check_interval.dirty && check_interval.touched">
                    <ng-container *ngIf="check_interval.hasError('pattern')">
                      {{'forms.errors.only_digits' | translate}}
                    </ng-container>
                    <ng-container *ngIf="check_interval.hasError('required')">
                      {{'forms.errors.required' | translate}}
                    </ng-container>
                    <ng-container *ngIf="check_interval.hasError('min')">
                      {{'forms.errors.min' | translate:{'value': 1}  }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

              </fieldset>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-map-marker" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{'groups.address.title' | translate}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                  <mat-icon *ngIf="fgAddress.invalid" class="hb-cropper-icon" color="warn"
                            fontIcon="fa-exclamation-triangle"
                            fontSet="fas"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <fieldset #fgAddress="ngModelGroup"
                        fxLayout="column nowrap"
                        ngModelGroup="address">

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="organization">{{'forms.fields.organisation.label' | translate}}</label>
                  </mat-label>
                  <input #organisation="ngModel" [(ngModel)]="address.companyName"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.organisation.placeholder' | translate"
                         id="organization"
                         matInput
                         name="organisation"
                         required touched>
                  <mat-hint>{{'forms.fields.organisation.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="organisation.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="contact_person">{{'forms.fields.contact_person.label' | translate}}</label>
                  </mat-label>
                  <input #contact_person="ngModel"
                         [(ngModel)]="address.contactPerson"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.contact_person.placeholder' | translate"
                         id="contact_person"
                         matInput
                         name="contact_person"
                         required touched>
                  <mat-hint>{{'forms.fields.contact_person.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="contact_person.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="email">{{'forms.fields.email.label' | translate}}</label>
                  </mat-label>
                  <input #email="ngModel" [(ngModel)]="address.email"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.email.placeholder' | translate"
                         email
                         id="email"
                         matInput
                         name="email" required touched>
                  <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="email.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                    <ng-container *ngIf="email.hasError('email')">
                      {{'forms.errors.invalid_email' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="address_line_1">{{'forms.fields.address_line_1.label' | translate}}</label>
                  </mat-label>
                  <input #address_line_1="ngModel" [(ngModel)]="address.street"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.address_line_1.placeholder' | translate"
                         id="address_line_1"
                         matInput
                         name="address_line_1"
                         required touched>
                  <mat-hint>{{'forms.fields.address_line_1.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="address_line_1.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="zip">{{'forms.fields.zip.label' | translate}}</label>
                  </mat-label>
                  <input #zip="ngModel" [(ngModel)]="address.zipCode"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.zip.placeholder' | translate"
                         id="zip"
                         matInput
                         name="zip"
                         required touched>
                  <mat-hint>{{'forms.fields.zip.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="zip.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="city">{{'forms.fields.city.label' | translate}}</label>
                  </mat-label>
                  <input #city="ngModel" [(ngModel)]="address.city"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.city.placeholder' | translate"
                         id="city"
                         matInput
                         name="city"
                         required touched>
                  <mat-hint>{{'forms.fields.city.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="city.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="country">{{'forms.fields.country.label' | translate}}</label>
                  </mat-label>
                  <mat-select #country="ngModel" [(ngModel)]="address.countryId"
                              [disabled]="!('update' | able: context)"
                              [placeholder]="'forms.fields.country.placeholder' | translate"
                              [typeaheadDebounceInterval]="400"
                              id="country"
                              name="country"
                              required touched>
                    <mat-option *ngFor="let country of countries$ | async"
                                [value]="country.id">{{country.name}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.country.hint' | translate}}</mat-hint>
                  <mat-error>
                    <ng-container *ngIf="country.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="phone">{{'forms.fields.phone.label' | translate}}</label>
                  </mat-label>
                  <input #phone="ngModel" [(ngModel)]="address.phone"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.phone.placeholder' | translate" id="phone"
                         matInput
                         name="phone"
                         touched
                         type="tel">
                  <mat-hint>{{'forms.fields.phone.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="phone.dirty && phone.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="fax">{{'forms.fields.fax.label' | translate}}</label>
                  </mat-label>
                  <input #fax="ngModel" [(ngModel)]="address.fax"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.fax.placeholder' | translate" id="fax"
                         matInput
                         name="fax"
                         touched
                         type="tel">
                  <mat-hint>{{'forms.fields.fax.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="fax.dirty && fax.touched">

                  </mat-error>
                </mat-form-field>
              </fieldset>
            </mat-expansion-panel>
          </form>

        </mat-accordion>
      </hb-card-accordion>
    </hb-card-column-view>
    <hb-fab (fabClick)="save()"
            [disabled]="ngForm.invalid || !('update' | able: context)"
            [label]="'keys.local.fab.save' | translate"
            fontIcon="fa-save" fontSet="fas"></hb-fab>
  </div>

</div>

