import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FormListPayload, FormListResponse} from './form.models';
import {environment} from '../../../../../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {}

  load(repositoryId: number, materialId: number): Observable<FormListPayload> {
    const url = `${environment.api}/repositories/${repositoryId}/materials/${materialId}/forms`;

    return this.http.get<FormListResponse>(url).pipe(
      map(res => ({items: res.datalist.map(item => ({
          ...item,
          repositoryId: Number(repositoryId),
          materialId: Number(materialId)
      })), rows: res.rows}))
    );
  }
}
