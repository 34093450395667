import {Component, Inject, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserEntity, UserViewModel, userViewModelFactory} from '../../../modules/store';


@Component({
  selector: 'hb-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {

  private _viewModel: UserViewModel = userViewModelFactory(this.data);

  public get viewModel() {
    return this._viewModel;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: UserEntity, private logger: NGXLogger) {
  }



  ngOnInit(): void {
  }

}
