import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {RouterService} from '../../../modules/store';

@Component({
  selector: 'hb-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit, OnDestroy {

  constructor(public routerFacade: RouterService,
              private logger: NGXLogger) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
