<h2 mat-dialog-title>{{'dialogs.create_material.title' | translate}}</h2>
<mat-dialog-content>
  <form #ngForm="ngForm">
    <div *ngIf="!!viewModel" fxLayout="column nowrap">
      <mat-form-field appearance="outline">
        <mat-label>
          <label for="usage">{{'forms.fields.usage.label' | translate}}</label>
        </mat-label>
        <mat-select #usage="ngModel" [(ngModel)]="viewModel.usageId"
                    [placeholder]="'forms.fields.usage.placeholder' | translate"
                    id="usage"
                    name="usage" required>
          <mat-option *ngFor="let usage of usages$ | async"
                      [value]="usage.id">{{usage.key | translate}}</mat-option>
        </mat-select>
        <mat-hint>{{'forms.fields.usage.hint' | translate}}</mat-hint>
        <mat-error *ngIf="usage.dirty && usage.touched">
          <ng-container *ngIf="usage.hasError('required')">
            {{'forms.errors.is_required' | translate}}
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field #manufacturerDateFormat="hbYearMonthDateFormat" appearance="outline" hbYearMonthDateFormat>
        <mat-label>
          <label for="manufacturingDate">{{'forms.fields.manufacturingDate.label' | translate}}</label>
        </mat-label>
        <input #manufacturingDate="ngModel" [(ngModel)]="viewModel.manufacturingDate"
               [matDatepicker]="manufacturingDatePicker"
               [max]="currentDate"
               [placeholder]="'forms.fields.manufacturingDate.placeholder' | translate"
               (click)="manufacturingDatePicker.open()"
               id="manufacturingDate" matInput
               name="manufacturingDate" readonly required>
        <mat-hint>{{'forms.fields.manufacturingDate.hint' | translate}}</mat-hint>
        <mat-datepicker-toggle [for]="manufacturingDatePicker" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #manufacturingDatePicker
                        (monthSelected)="manufacturerDateFormat.chosenMonthHandler($event, manufacturingDatePicker)"
                        [touchUi]="!matchWeb"
                        startView="year"></mat-datepicker>
        <mat-error *ngIf="manufacturingDate.dirty && manufacturingDate.touched">

        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="!generateSerialNumber.checked" appearance="outline">
        <mat-label>
          <label for="serialNumber">{{'forms.fields.serialnumber.label' | translate}}</label>
        </mat-label>
        <input #serialNumber="ngModel" [(ngModel)]="viewModel.serialNumber"
               [placeholder]="'forms.fields.serialnumber.placeholder' | translate"
               [required]="!viewModel.isSealPac"
               id="serialNumber"
               matInput name="serialNumber">
        <mat-hint>{{'forms.fields.serialnumber.hint' | translate}}</mat-hint>
        <mat-error *ngIf="serialNumber.dirty && serialNumber.touched">
          <ng-container *ngIf="serialNumber.hasError('serialNumber')">
            {{'forms.errors.is_required' | translate}}
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="viewModel.isSealPac" appearance="outline">
        <mat-label>
          <label for="sealPacNumber">{{'forms.fields.sealPacNumber.label' | translate}}</label>
        </mat-label>
        <input #sealPacNumber="ngModel"
               [(ngModel)]="viewModel.sealPacNumber"
               [disabled]="!!viewModel.sealPacNumber || !('update' | able: 'material')"
               [placeholder]="'forms.fields.sealPacNumber.placeholder' | translate"
               id="sealPacNumber"
               matInput
               name="sealPacNumber"
               required>
        <mat-hint>{{'forms.fields.sealPacNumber.hint' | translate}}</mat-hint>
        <mat-error *ngIf="sealPacNumber.dirty && sealPacNumber.touched">
          <ng-container *ngIf="sealPacNumber.hasError('required')">
            {{'forms.errors.is_required' | translate}}
          </ng-container>
        </mat-error>
      </mat-form-field>

      <mat-slide-toggle #generateSerialNumber
                        [checked]="viewModel.isSealPac"
                        [disabled]="mandatorySerialnumber"
                        color="primary"
                        labelPosition="before"
                        name="generateSerialNumber">
        <mat-label (mouseover)="tooltip.show(1000)">
          {{'forms.fields.generate_serialnumber.label' | translate}}
          <mat-icon #tooltip="matTooltip" [matTooltip]="'forms.fields.generate_serialnumber.hint' | translate"
                    color="info"
                    fontIcon="fa-info-circle"
                    fontSet="fas" inline matTooltipTouchGestures="on"></mat-icon>
        </mat-label>
      </mat-slide-toggle>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="{viewModel: viewModel, next: true}" color="accent"
          mat-raised-button>{{'keys.local.global.ok_and_next' | translate}}</button>
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="{viewModel: viewModel, next: false}" color="accent"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>


