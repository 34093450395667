import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LoadPermissionsRequest, PermissionListPayload, PermissionListResponse} from './permission.models';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {environment} from '@hb/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PermissionDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(request: LoadPermissionsRequest): Observable<PermissionListPayload> {
    return this.http.get<PermissionListResponse>(`${environment.api}/repositories/${request.repositoryId}/users/${request.userId}/permissions`).pipe(
      map((res) =>
        ({items: res.datalist.map(p => p.conditions ? ({...p, conditions: JSON.parse(p.conditions)}) : p)}))
    );
  }
}
