import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import * as fromTile from './+state/tile.reducer';
import {EffectsModule} from '@ngrx/effects';
import {TileEffects} from './+state/tile.effects';
import {StoreModule} from '@ngrx/store';
import {TileDataService} from './+state/tile-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromTile.TILE_FEATURE_KEY,
      fromTile.reducer
    ),
    EffectsModule.forFeature([TileEffects])
  ],
  providers: [
    TileDataService,
  ]
})
export class TileStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
