import {Injectable} from '@angular/core';
import {filter, flatMap, map, take} from 'rxjs/operators';
import {SealDocumentDialogService} from '../../dialogs/seal-document';
import {DocumentEntity, DocumentService, RouterService, UserService} from '../../modules/store';
import {CommandHandler} from '../../modules/command-bus/command.handler';
import {DomainCommand} from '../../modules/command-bus/command';

@Injectable()
export class SealDocumentCommandHandler implements CommandHandler {

  constructor(private sealDocumentDialogService: SealDocumentDialogService,
              private userService: UserService,
              private documentService: DocumentService,
              private routerService: RouterService) {
  }

  handle(command: DomainCommand): void {

    const viewModel: DocumentEntity = command.payload.viewModel.updated;
    const addressField = viewModel.fields.filter(field => field.field === 'address_Auftraggeber/Besitzer').last();
    const _email = addressField?.disabled ? null : addressField?.address?.email;
    const sendViaEmail = addressField?.disabled ? false : !!addressField?.address?.email;

    this.userService.loggedInUser$.pipe(
      filter(user => !!user),
      map(user => _email || user.email),
      take(1),
      flatMap(email => this.sealDocumentDialogService.open({email, sendViaEmail}).afterClosed()),
      filter(result => !!result),
      flatMap(queryParams => this.documentService.persist(viewModel, queryParams))
    ).subscribe(() => this.routerService.navigateToMaterialView(viewModel.repositoryId, viewModel.materialId));
  }
}
