import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {filter, flatMap, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {HintDialogService} from '../../../dialogs/hint';
import {SelectImageDialogService} from '../../../dialogs/select-image';
import {AttachmentService, AttachmentUpdateEvent, CreateAttachmentRequest, MediaType} from '../../../modules/store';


export interface ImageInputChangedEvent {
  mediaId: number;
}

@Component({
  selector: 'hb-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
})
export class ImageInputComponent {

  @Input()
  height = 300;
  @Input()
  width = 300;
  @Input()
  placeholder: string;
  @Input()
  maintainAspectRatio: boolean;
  @Input()
  aspectRatio: number;
  @Input()
  format: 'png' | 'jpeg' | 'bmp' | 'webp' | 'ico';
  @Input()
  resizeToWidth: number;
  @Input()
  resizeToHeight: number;
  @Input()
  onlyScaleDown: boolean;
  @Input()
  src: any;
  @Input()
  disabled = false;
  @Input()
  mediaType: MediaType;
  @Input()
  targetId: number;
  @Input()
  mediaId: number;

  @Output()
  update = new EventEmitter<AttachmentUpdateEvent>();

  constructor(private hintDialogService: HintDialogService,
              private selectImageDialogService: SelectImageDialogService,
              private attachmentService: AttachmentService,
              private translateService: TranslateService,
              private logger: NGXLogger) {
  }

  fileChangeEvent(event: any): void {
    if (event.target.files[0]) {
      this.selectImageDialogService.open({
        file: event.target.files[0],
      }).afterClosed().pipe(
        filter(request => !!request),
        map((request: CreateAttachmentRequest) => ({...request, mediaType: this.mediaType, targetId: this.targetId})),
        flatMap((request: CreateAttachmentRequest) => this.attachmentService.create(request)),
        map(payload => payload.item)
      ).subscribe((data) => {
        this.update.emit({type: 'added', data});
      });
    }
  }

  getImage(): string {
    if (!!this.attachmentService.url(this.mediaId, `${this.width}x${this.height}`)) {
      return this.attachmentService.url(this.mediaId, `${this.width}x${this.height}`);
    } else if (!!this.placeholder) {
      return this.placeholder;
    } else {
      return null;
    }
  }

  hasImage(): boolean {
    return !!this.attachmentService.url(this.mediaId, `${this.width}x${this.height}`) || !!this.placeholder;
  }

  removeImage() {
    this.hintDialogService.openWarning(this.translateService.instant('keys.local.messages.really_delete'))
      .afterClosed().pipe(
      filter(result => !!result),
      flatMap(() => this.attachmentService.remove({id: this.mediaId, mediaType: this.mediaType, targetId: this.targetId}))
    ).subscribe();
  }
}
