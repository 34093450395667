import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {filter, takeUntil} from 'rxjs/operators';
import {HintDialogService} from '../../../dialogs/hint';
import {Subject} from 'rxjs';
import {CertificateService, CertificateViewModel, LanguageService, RouterService} from '../../../modules/store';

interface ButtonConfiguration {
  status?: boolean;
  context?: boolean;
}

@Component({
  selector: 'hb-certificate-item-view',
  templateUrl: './certificate-item-view.component.html',
  styleUrls: ['./certificate-item-view.component.css']
})
export class CertificateItemViewComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  constructor(private certificateService: CertificateService,
              private languageService: LanguageService,
              private hintDialogService: HintDialogService,
              private routerService: RouterService, private logger: NGXLogger) {

  }

  @Input()
  public viewModel: CertificateViewModel;

  private _buttons: ButtonConfiguration = {status: false, context: false};

  public get buttons(): ButtonConfiguration {
    return this._buttons;
  }

  @Input()
  public set buttons(buttons: ButtonConfiguration) {
    this._buttons = {...this._buttons, ...buttons};
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get locale$() {
    return this.languageService.selected$;
  }

  @Input()
  public disabled = false;

  ngOnInit(): void {

  }

  delete($event) {
    $event.stopImmediatePropagation();
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      takeUntil(this.unsubscribe$),
      filter(result => !!result),
    ).subscribe(() => {
      this.certificateService.delete(this.viewModel);
    });
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
