import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {FormBuilder} from '@angular/forms';
import {debounceTime, filter, flatMap, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {LoadRepositoriesRequest, RepositoryService} from '../../../modules/store';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'hb-select-repository',
  templateUrl: './select-repository.component.html',
  styleUrls: ['./select-repository.component.scss']
})
export class SelectRepositoryComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();
  public readonly searchInput = this.formBuilder.control('');
  private readonly _items$: BehaviorSubject<LoadRepositoriesRequest>;
  private readonly selectedId$: BehaviorSubject<number>;
  private readonly filteredIds: number[];

  constructor(private repositoryService: RepositoryService,
              private formBuilder: FormBuilder,
              private logger: NGXLogger,
              @Inject(MAT_DIALOG_DATA) public data: SelectRepositoryDialogParameter) {
    this._items$ = new BehaviorSubject({repositoryId: this.data.repositoryId, page: 0});
    this.selectedId$ = new BehaviorSubject<number>(this.data.repositoryId);
    this.filteredIds = this.data.filteredIds;
  }

  public get isLoading$() {
    return this.repositoryService.isLoading$;
  }

  public get items$() {
    return this._items$.asObservable().pipe(
      filter(request => !!request),
      flatMap(request => this.repositoryService.itemsWithProps$(request)),
      map(items => items.filter(item => !item.isInPath(this.filteredIds)))
    );
  }

  public get selected$() {
    return this.selectedId$.pipe(
      filter(id => !!id),
      flatMap(id => this.repositoryService.getById(id))
    );
  }

  public get canNavigateUp$() {
    return this.selectedId$.pipe(
      withLatestFrom(this.repositoryService.repositoryId$),
      map(([selectedId, repositoryId]) => selectedId !== repositoryId)
    );
  }

  public get selectedId() {
    return this.selectedId$.getValue();
  }

  public get result(): SelectRepositoryDialogResult {
    return {repositoryId: this.selectedId};
  }

  ngOnInit() {

    this._items$.asObservable().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(request => this.repositoryService.load(request));

    this.searchInput.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(1000),
    ).subscribe(search => {
      if (!!search && search.length > 2) {
        this._items$.next({...this._items$.getValue(), page: 0, search, repositoryId: undefined});
      } else {
        this._items$.next({...this._items$.getValue(), page: 0, repositoryId: this.selectedId, search: undefined});
      }
    });
  }

  ngOnDestroy(): void {

  }

  public select(id: number) {
    this.selectedId$.next(id);
    this._items$.next({...this._items$.getValue(), repositoryId: id, search: null});
    this.repositoryService.get(id);
    this.searchInput.patchValue(null);
  }

  fetch(search, $event) {
    this._items$.next({...this._items$.getValue(), page: $event.page});
  }
}

export interface SelectRepositoryDialogParameter {
  repositoryId: number;
  filteredIds: number[];
}

export interface SelectRepositoryDialogResult {
  repositoryId: number;
}
