import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Exception} from '../../../../../../core';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {SearchActions} from './search.actions';
import {SearchDataService} from './search-data.service';

@Injectable()
export class SearchEffects {


  getSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.getSearchRequest),
      switchMap(action => this.searchDataService.get(action.request).pipe(
        map(payload => SearchActions.getSearchSuccess({payload, identity: action.identity})),
        catchError(error => of(SearchActions.getSearchFailure({exception: new Exception(error, action), identity: action.identity})))
      )),
    )
  );

  getSearchSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.getSearchSuccess),
    ), {dispatch: false}
  );

  getSearchFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.getSearchFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  loadSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.loadSearchRequest),
      switchMap(action => this.searchDataService.load(action.request).pipe(
        map(payload => SearchActions.loadSearchSuccess({payload, identity: action.identity})),
        catchError(error => of(SearchActions.loadSearchFailure({exception: new Exception(error, action), identity: action.identity})))
      )),
    )
  );

  loadSearchSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.loadSearchSuccess),
    ), {dispatch: false}
  );

  loadSearchFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.loadSearchFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  createSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.createSearchRequest),
      switchMap(action => this.searchDataService.create(action.request).pipe(
        map(payload => SearchActions.createSearchSuccess({payload, identity: action.identity})),
        catchError(error => of(SearchActions.createSearchFailure({exception: new Exception(error, action), identity: action.identity})))
      )),
    )
  );

  createSearchSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.createSearchSuccess),
    ), {dispatch: false}
  );

  createSearchFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.createSearchFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  updateSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.updateSearchRequest),
      switchMap(action => this.searchDataService.update(action.request).pipe(
        map(payload => SearchActions.updateSearchSuccess({payload, identity: action.identity})),
        catchError(error => of(SearchActions.updateSearchFailure({exception: new Exception(error, action), identity: action.identity})))
      )),
    )
  );

  updateSearchSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.updateSearchSuccess),
    ), {dispatch: false}
  );

  updateSearchFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.updateSearchFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  deleteSearchRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchActions.removeSearchRequest),
      switchMap(action => this.searchDataService.remove(action.request).pipe(
        map(payload => SearchActions.removeSearchSuccess({payload, identity: action.identity})),
        catchError(error => of(SearchActions.removeSearchFailure({exception: new Exception(error, action), identity: action.identity})))
      )),
    )
  );

  deleteSearchSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.removeSearchSuccess),
    ), {dispatch: false}
  );

  deleteSearchFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(SearchActions.removeSearchFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions,
              private searchDataService: SearchDataService,
              private logger: NGXLogger) {
  }
}
