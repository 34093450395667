import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {MatDialog} from '@angular/material/dialog';
import {filter, flatMap, map, take} from 'rxjs/operators';
import {HintDialogService} from '../../../dialogs/hint';
import {AttachmentEntity, AttachmentService, AttachmentUpdateEvent, CreateAttachmentRequest, MediaType} from '../../../modules/store';
import {SignatureDialogService} from '../../../dialogs/signature';

@Component({
  selector: 'hb-input-signature',
  templateUrl: './input-signature.component.html',
  styleUrls: ['./input-signature.component.scss']
})
export class InputSignatureComponent implements OnInit {

  @Input()
  public value: AttachmentEntity = null;

  @Input()
  public signatureTitle = '';

  @Input()
  public mediaType: MediaType;

  @Input()
  public targetId: number;

  @Input()
  public disabled = false;

  @Output()
  public update: EventEmitter<AttachmentUpdateEvent> = new EventEmitter<AttachmentUpdateEvent>();

  @ViewChild('signaturePadTemplate', {static: false})
  private signaturePadTemplate: TemplateRef<any>;

  constructor(private attachmentService: AttachmentService,
              private translateService: TranslateService,
              private matDialog: MatDialog,
              private hintDialogService: HintDialogService,
              private signatureDialogService: SignatureDialogService,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
  }

  isEmpty() {
    return !this.value;
  }

  isDisabled() {
    return this.disabled;
  }

  onClick(event) {
    if (this.isEmpty() && !this.isDisabled()) {
      this.add();
    }
  }

  onAdd(event) {
    this.add();
    event.stopPropagation();
  }

  onDelete(event) {
    this.delete();
    event.stopPropagation();
  }

  getImage() {
    return this.attachmentService.url(this.value.id);
  }

  private add() {
    this.signatureDialogService.open()
      .afterClosed()
      .pipe(
        filter(result => !!result),
        map((request: CreateAttachmentRequest) => ({...request, mediaType: this.mediaType, targetId: this.targetId})),
        flatMap(media => this.attachmentService.create(media)),
        map(payload => payload.item),
        take(1),
      )
      .subscribe(result => this.update.emit({type: 'added', data: result}));
  }

  private delete() {
    this.hintDialogService.openWarning(this.translateService.instant('keys.local.messages.really_delete')).afterClosed().pipe(
      filter(result => result),
      flatMap(() => this.attachmentService.remove({id: this.value.id, mediaType: this.mediaType, targetId: this.targetId}))
    ).subscribe(() => this.update.emit({type: 'removed', data: null}));
  }

}
