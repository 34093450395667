import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromMaterial from './+state/material.reducer';
import {EffectsModule} from '@ngrx/effects';
import {MaterialEffects} from './+state/material.effects';
import {MaterialDataService} from './+state/material-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromMaterial.MATERIAL_FEATURE_KEY,
      fromMaterial.reducer
    ),
    EffectsModule.forFeature([MaterialEffects]),
  ],
  providers: [MaterialDataService],
  declarations: [],
  exports: []
})
export class MaterialStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
