<mat-progress-bar *ngIf="administrationService.isLoading$ | async" color="primary"
                  mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>
<div class="flex flex-col flex-grow">
  <mat-tab-group mat-align-tabs="start">
    <mat-tab [label]="'views.administration.tabs.cache' | translate">
      <div class="hb-tab-content-1">
        <button (click)="administrationService.clearCache()" class="d-flex" color="primary"
                mat-raised-button>{{'keys.local.global.clear_cache' | translate}}</button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>



