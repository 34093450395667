import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {TagEntity} from '../../../modules/core/models';
import {remoteTranslationMap} from '../../../modules/core/maps/src/translation.maps';

@Component({
  selector: 'hb-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  private static id = 0;

  private componentId = StatusComponent.id++;

  @Input() extended;
  @Input()
  public status: TagEntity;
  public icons = {
    success: 'fa-check-circle',
    warn: 'fa-exclamation-circle',
    danger: 'fa-times-circle',
  };

  public get prefix() {
    return this.status.isLocal ? '' : 'keys.remote.';
  }

  constructor(private translateService: TranslateService, private logger: NGXLogger) {
  }

  ngOnInit() {
    this.logger.trace('INIT: ', this);
  }

  getStatusText() {
    return this.translateService.instant(remoteTranslationMap[this.status.key] || this.status.key, {value: this.status.value});
  }
}
