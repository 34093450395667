import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateMaterialComponent} from './create-material.component';
import {MaterialViewModel} from '../../../modules/store';


@Injectable({
  providedIn: 'root'
})
export class CreateMaterialDialogService
  extends BaseDialogService<CreateMaterialComponent, MaterialViewModel, CreateMaterialDialogResult | undefined> {

  protected config: MatDialogConfig = {
    width: '80vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, CreateMaterialComponent);
  }
}

interface CreateMaterialDialogResult {
  viewModel: MaterialViewModel;
  next: boolean;
}
