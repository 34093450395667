import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '@hb/environments/environment';
import {TileItemPayload, TileListPayload, TileListResponse} from './tile.models';

@Injectable({
  providedIn: 'root'
})
export class TileDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  public load(queryParams?: Params): Observable<TileListPayload> {
    return this.http.get<TileListResponse>(`${environment.api}/productchooser`, {params: queryParams}).pipe(
      map((res) => ({
        items: res.datalist,
        rows: res.rows
      })),
      map(payload => ({...payload, items: payload.items.map((item) => ({...item, id: `${item.type}${item.id}`}))}))
    );
  }

  public get(queryParams?: Params): Observable<TileItemPayload> {
    return null;
  }
}
