import {Component, Input, OnInit} from '@angular/core';
import {TagEntity} from '../../../modules/core/models';

@Component({
  selector: 'hb-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() title: string;
  @Input() subTitle: string;

  @Input() status: TagEntity;

  constructor() {
  }

  ngOnInit(): void {
  }

}
