import {ExceptionModel, PayloadItemModel, PayloadListModel, ResponseItemModel, ResponseListModel} from '../../../../../../core/models';
import {EditableViewModel} from '../../../../../../core/models/src/base-view-model';


export interface CreateAttachmentRequest {
  data?: string;
  filename?: string;
  id: number;
  mimetype?: string;
  mediaType?: MediaType;
  targetId?: number;
  size?: number;
}

export interface GetAttachmentRequest {
  id: number;
}

export interface LoadAttachmentsRequest {
  repositoryId: number;
  materialId: number;
  documentId?: number;
}

export interface RemoveRequest {
  id: number;
  mediaType: MediaType;
  targetId: number;
}

export interface RemovePayload {
  id: number;
  mediaType: MediaType;
  targetId: number;
}

export interface AttachmentItemResponse extends ResponseItemModel<AttachmentEntity> {
}

export interface AttachmentListResponse extends ResponseListModel<AttachmentEntity> {
}

export interface AttachmentItemPayload extends PayloadItemModel<AttachmentEntity> {
}

export interface AttachmentListPayload extends PayloadListModel<AttachmentEntity> {
}

export interface AttachmentException extends ExceptionModel {
}



export class AttachmentViewModel extends EditableViewModel<AttachmentEntity> implements AttachmentEntity {

  constructor(model: AttachmentEntity) {
    super(model);
  }

  get data(): string {
    return this.model.data;
  }

  get filename(): string {
    return this.model.filename;
  }

  set filename(value: string) {
    this.changes.filename = value;
  }

  get id(): number {
    return this.model.id;
  }

  get mediaType(): MediaType {
    return this.model.mediaType;
  }

  get mimetype(): string {
    return this.model.mimetype;
  }

  get targetId(): number {
    return this.model.targetId;
  }

  get size(): number {
    return this.model.size;
  }
}

export interface AttachmentEntity {
  data?: string;
  filename?: string;
  id: number;
  mimetype?: string;
  mediaType?: MediaType;
  targetId?: number;
  size?: number;
}

export enum MediaType {
  USER_AVATAR = 'USER_AVATAR',
  MATERIAL_ATTACHMENT = 'MATERIAL_ATTACHMENT',
  MATERIAL_REAL_IMAGE = 'MATERIAL_REAL_IMAGE',
  DOCUMENT_ATTACHMENT = 'DOCUMENT_ATTACHMENT',
  SIGNATURE_PURCHASER = 'SIGNATURE_PURCHASER',
  SIGNATURE_INSPECTOR = 'SIGNATURE_INSPECTOR',
  REPOSITORY_IMAGE = 'REPOSITORY_IMAGE',
  ACCOUNT_IMAGE = 'ACCOUNT_IMAGE',
}

export function attachmentViewModelFactory(model: AttachmentEntity): AttachmentViewModel {
  return new AttachmentViewModel(model);
}

export interface AttachmentUpdateEvent {
  type: 'added' | 'changed' | 'removed';
  data: AttachmentEntity;
}
