import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AddressEntity,
  addressEntityFactory,
  AddressItemPayload,
  AddressItemResponse,
  AddressListPayload,
  AddressListResponse
} from './address.models';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {ADDRESS_ID} from './address.reducer';
import {environment} from '@hb/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(): Observable<AddressListPayload> {
    return this.http.get<AddressListResponse>(`${environment.api}/addresses`).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }

  get(addressId: number): Observable<AddressItemPayload> {

    return this.http.get<AddressItemResponse>(`${environment.api}/addresses/${addressId}`).pipe(
      map((res) => {
        if (addressId === 0) {
          return ({item: addressEntityFactory()});
        } else {
          return ({item: res.data});
        }
      })
    );
  }

  persist(address: AddressEntity) {
    return this.http.put<AddressItemResponse>(`${environment.api}/addresses/${address.id}`, address).pipe(
      map(res => ({item: res.data}))
    );
  }

  setAddressId(addressId: number) {
    window.localStorage.setItem(ADDRESS_ID, addressId.toString());
  }

  removeAddressId() {
    window.localStorage.removeItem(ADDRESS_ID);
  }
}
