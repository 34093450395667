import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CertificateEntity, certificateFactory, TrainingService, TrainingViewModel} from '../../../modules/store';
import {BreakpointService} from '../../../modules/core/services/breakpoint';

@Component({
  selector: 'hb-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent implements OnInit, OnDestroy {

  private _viewModel: CertificateEntity = certificateFactory(this.data);

  constructor(private trainingService: TrainingService,
              private breakpointService: BreakpointService,
              private formBuilder: FormBuilder,
              private logger: NGXLogger,
              @Inject(MAT_DIALOG_DATA) private data: TrainingViewModel) {

  }

  public get viewModel(): CertificateEntity {
    return this._viewModel;
  }

  public get trainings$() {
    return this.trainingService.items$;
  }

  public get matchWeb() {
    return this.breakpointService.matchWeb();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
