import {SearchEntity, SearchModel, SearchModelAdapter} from '../entity/search.models';
import {ResponseListModel} from '../../../../../../../../core';

export class LoadResponse extends ResponseListModel<SearchModel> {
}

export class LoadResponseAdapter extends ResponseListModel<SearchEntity> {
  constructor(response: LoadResponse) {
    super();
    this.code = response.code;
    this.extMsg = response.extMsg;
    this.field = response.field;
    this.htmlCode = response.htmlCode;
    this.msg = response.msg;
    this.msgKey = response.msgKey;
    this.rows = response.rows;
    this.value = response.value;
    this.datalist = response.datalist.map(item => new SearchModelAdapter(item));
  }
}
