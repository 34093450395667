import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService} from './authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {RouterService} from '../../../router-store';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private static addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  constructor(private authenticationService: AuthenticationService,
              private routerService: RouterService,
              private translateService: TranslateService,
              private logger: NGXLogger) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authenticationService.getAccess()) {
      request = AuthenticationInterceptor.addToken(request, this.authenticationService.getAccess());
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          return this.handle403Error(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 401) {
          this.authenticationService.clear();
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refresh().pipe(
        tap(() => this.isRefreshing = false),
        switchMap(result => {
          const token = isNotNullOrUndefined(result) ? result.access : null;
          this.refreshTokenSubject.next(token);
          return next.handle(AuthenticationInterceptor.addToken(request, token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(AuthenticationInterceptor.addToken(request, token)))
      );
    }
  }
}
