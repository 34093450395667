import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {TRAINING_FEATURE_KEY, trainingAdapter, TrainingPartialState, TrainingState} from './training.reducer';
import {TrainingEntity, TrainingViewModel, trainingViewModelFactory} from './training.models';
import {Dictionary} from '@ngrx/entity';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getTrainingState = createFeatureSelector<TrainingPartialState,
  TrainingState>(TRAINING_FEATURE_KEY);

const {selectAll, selectEntities} = trainingAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getTrainingState,
  (state: TrainingState) => state.isLoading
);

const getException = createSelector(
  getTrainingState,
  (state: TrainingState) => state.exception
);

const getItems: MemoizedSelector<object, TrainingViewModel[]> = createSelector(
  getTrainingState,
  (state: TrainingState) => selectAll(state).map(item => trainingViewModelFactory(item))
);

const getLength = createSelector(
  getTrainingState,
  (state: TrainingState) => state.rows
);

const getTrainingEntities: MemoizedSelector<object, Dictionary<TrainingEntity>> = createSelector(
  getTrainingState,
  (state: TrainingState) => selectEntities(state)
);

const getSelected = createSelector(
  getTrainingEntities,
  RouterSelectors.getTrainingIdParam,
  (entities, selected) => selected && entities[selected]
);

export const TrainingSelectors = {
  isLoading,
  getException,
  getItems,
  getLength,
  getTrainingEntities,
  getSelected,
};
