<h2 mat-dialog-title>{{'dialogs.edit_attachment.title' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="form" fxLayout="column nowrap">
    <mat-form-field appearance="outline">
      <mat-label>
        <label for="filename">{{'forms.fields.filename.label' | translate}}</label>
      </mat-label>
      <input [placeholder]="'forms.fields.filename.placeholder' | translate"
             formControlName="filename"
             id="filename"
             matInput
             type="text">
      <mat-hint>{{'forms.fields.filename.hint' | translate}}</mat-hint>
      <mat-error *ngIf="form.getError('required', 'email')">{{'forms.errors.is_required' | translate}}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="form.invalid" [mat-dialog-close]="form.get('filename').value" color="accent"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
