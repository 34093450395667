import {animate, query, stagger, style, transition, trigger} from '@angular/animations';


export const items =
  trigger('items', [
    transition(':enter', [
      style({transform: 'scale(0.5)', opacity: 0}),  // initial
      animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({transform: 'scale(1)', opacity: 1}))  // final
    ]),
    transition(':leave', [
      style({transform: 'scale(1)', opacity: 1, height: '*'}),
      animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          transform: 'scale(0.5)', opacity: 0,
          height: '0px', margin: '0px'
        }))
    ])
  ]);

export const list =
  trigger('list', [
    transition(':enter', [
      query('@items', stagger(300, items))
    ]),
  ]);


export const listAnimation =
  trigger('listAnimation', [
    transition('* => *', [
      query(
        ':enter',
        [
          style({opacity: 0, transform: 'translateY(-15px)'}),
          stagger(
            '50ms',
            animate(
              '550ms ease-out',
              style({opacity: 1, transform: 'translateY(0px)'})
            )
          )
        ],
        {optional: true}
      ),
      query(':leave', animate('50ms', style({opacity: 0})), {
        optional: true
      })
    ])
  ]);
