import {Action, createReducer, on} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {RepositoryEntity} from './repository.models';
import {Exception} from '../../../../../../core/models';
import {RepositoryActions} from './repository.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const REPOSITORY_FEATURE_KEY = 'repository';

export const REPOSITORY_ID = 'REPOSITORY_ID';

export interface RepositoryState extends EntityState<RepositoryEntity> {
  isLoading: boolean;
  isSearching: boolean;
  exception: Exception;
  rows: number;
  repositoryId: number;
  search: string;
  filteredId: number | null;
}

export interface RepositoryPartialState {
  readonly [REPOSITORY_FEATURE_KEY]: RepositoryState;
}

export const repositoryAdapter: EntityAdapter<RepositoryEntity> = createEntityAdapter<RepositoryEntity>();

export const initialState: RepositoryState = repositoryAdapter.getInitialState({
  isLoading: false,
  isSearching: false,
  exception: null,
  rows: 0,
  repositoryId: Number(localStorage.getItem(REPOSITORY_ID)) || null,
  search: null,
  filteredId: null
});

const repositoryReducer = createReducer(
  initialState,
  on(RepositoryActions.loadRepositoriesRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RepositoryActions.loadRepositoriesSuccess, (state, {payload}) =>
    repositoryAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })
  ),
  on(RepositoryActions.loadRepositoriesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(RepositoryActions.getRepositoryRowsRequest, (state) => ({
    ...state,
    isLoading: true,

  })),
  on(RepositoryActions.getRepositoryRowsSuccess, (state, {payload}) => ({
      ...state,
      isLoading: false,
      rows: payload.rows,
    })
  ),
  on(RepositoryActions.getRepositoryRowsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(RepositoryActions.getRepositoryRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RepositoryActions.getRepositorySuccess, (state, {payload}) =>
    repositoryAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })
  ),
  on(RepositoryActions.getRepositoryFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),

  on(RepositoryActions.setNotificationRequest, (state) => ({
    ...state,
  })),
  on(RepositoryActions.setNotificationSuccess, (state, {update}) =>
    repositoryAdapter.updateOne(update, {
      ...state,
      isLoading: false,

    })
  ),
  on(RepositoryActions.setNotificationFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),

  on(RepositoryActions.shareRepositoryRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RepositoryActions.shareRepositorySuccess, (state, {update}) =>
    repositoryAdapter.updateOne(update, {
      ...state,
      isLoading: false,

    })
  ),
  on(RepositoryActions.shareRepositoryFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),

  on(RepositoryActions.deleteShareRepositoryRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(RepositoryActions.deleteShareRepositorySuccess, (state, {update}) =>
    repositoryAdapter.updateOne(update, {
      ...state,
      isLoading: false,

    })
  ),
  on(RepositoryActions.deleteShareRepositoryFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(RepositoryActions.updateRepository, (state, {update}) =>
    repositoryAdapter.updateOne(update, {
      ...state,
    })
  ),
  on(RepositoryActions.persistRepositoryRequest, (state) => ({
    ...state,
    exception: null,
  })),
  on(RepositoryActions.persistRepositorySuccess, (state, {payload}) =>
    repositoryAdapter.upsertOne(payload.item, {
      ...state,
      exception: null
    })
  ),
  on(RepositoryActions.persistRepositoryFailure, (state, {exception}) => ({
    ...state,
    exception
  })),
  on(RepositoryActions.createRepositoryRequest),
  on(RepositoryActions.createRepositorySuccess, (state, {payload}) =>
    repositoryAdapter.upsertOne(payload.item, {
      ...state,
      exception: null
    })
  ),
  on(RepositoryActions.setRepositoryId, (state, {repositoryId}) => ({
    ...state,
    repositoryId
  })),
  on(RepositoryActions.removeRepositoryId, (state) => ({
    ...state,
    repositoryId: null
  })),
  on(RepositoryActions.moveRepositoryRequest),
  on(RepositoryActions.moveRepositorySuccess, (state, {payload}) =>
    repositoryAdapter.upsertOne(payload.item, {
      ...state,
      exception: null
    })
  ),
  on(RepositoryActions.moveRepositoryFailure),
  on(RepositoryActions.deleteRepositoryRequest),
  on(RepositoryActions.deleteRepositorySuccess, (state, {payload}) =>
    repositoryAdapter.removeOne(payload.item.id, {
      ...state,
      exception: null
    })
  ),
  on(RepositoryActions.deleteRepositoryFailure),
  on(RepositoryActions.setRepositorySearch, (state, {search}) => ({
    ...state,
    search
  })),
  on(RepositoryActions.removeRepositorySearch, (state) => ({
    ...state,
    search: null
  })),
  on(RepositoryActions.setRepositorySearchId, (state, {id}) => ({
    ...state,
    filterId: id
  })),
  on(RepositoryActions.removeRepositorySearchId, (state) => ({
    ...state,
    filterId: null
  })),
  on(RepositoryActions.resetRepositories, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: RepositoryState | undefined, action: Action) {
  return repositoryReducer(state, action);
}
