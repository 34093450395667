import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {AdministrationActions} from './administration.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const ADMINISTRATION_FEATURE_KEY = 'administration';

export interface AdministrationState {
  isLoading: boolean;
  exception: Exception;
}

export interface AdministrationPartialState {
  readonly [ADMINISTRATION_FEATURE_KEY]: AdministrationState;
}

export const initialState: AdministrationState = {
  isLoading: false,
  exception: null,
};

const administrationReducer = createReducer(
  initialState,
  on(AdministrationActions.clearCacheRequest, (state) => ({
    ...state,
    isLoading: true
  })),
  on(AdministrationActions.clearCacheSuccess, (state) => ({
    ...state,
    isLoading: false
  })),
  on(AdministrationActions.clearCacheFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: AdministrationState | undefined, action: Action) {
  return administrationReducer(state, action);
}
