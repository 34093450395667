<mat-divider></mat-divider>
<mat-progress-bar *ngIf="(isLoading$ | async)" color="primary" mode="indeterminate"></mat-progress-bar>
<hb-list #list
         (vsPageChange)="fetch($event)"
         [items]="items$ | async"
         [rows]="rows$ | async"
         [virtualHeight]="availableHeight"
         itemSize="88">
  <ng-template #item let-viewModel="$implicit">
    <hb-repository-item-view (click)="openRepository(viewModel)"
                             (selection)="select(viewModel)"
                             *ngIf="viewModel.type === 'RepositoryViewModel'"
                             [buttons]="{share: true, notify: true, context: true}"
                             [isSelected]="isSelected(viewModel)"
                             [viewModel]="viewModel">
    </hb-repository-item-view>
    <hb-material-item-view (click)="openMaterial(viewModel)"
                           (selection)="select(viewModel)"
                           *ngIf="viewModel.type === 'MaterialViewModel'"
                           [buttons]="{status: true, case: true, notify: true, context: true}"
                           [isSelected]="isSelected(viewModel)"
                           [viewModel]="viewModel">
    </hb-material-item-view>
  </ng-template>
</hb-list>

<hb-fab-group #defaultFabGroup *ngIf="!hasSelected()" [disabled]="sharedPath"
              [label]="'keys.local.fab.menu' | translate">
  <ng-container *hbFabItem>
    <button (click)="createNewRepository(); defaultFabGroup.close()" [disabled]="!('create' | able: 'folder')"
            [matTooltip]="'keys.local.fab.add_repository' | translate" color="accent"
            mat-mini-fab matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-folder" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
  <ng-container *hbFabItem>
    <button (click)="createNewMaterial(); defaultFabGroup.close()" [disabled]="!('create' | able: 'material')"
            [matTooltip]="'keys.local.fab.add_material' | translate" color="accent"
            mat-mini-fab matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-dice-d6" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
  <ng-container *hbFabItem>
    <button (click)="scanNewMaterial(); defaultFabGroup.close()" [disabled]="!('create' | able: 'material')"
            [matTooltip]="'keys.local.fab.scan' | translate"
            color="accent" mat-mini-fab matTooltipHideDelay="200"
            matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-camera" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
  <ng-container *hbFabItem>
    <button (click)="selectAll(); defaultFabGroup.close()"
            [disabled]="!('update' | able: 'folder') && !('selection' | able: 'material')"
            [matTooltip]="'keys.local.fab.select_all' | translate"
            color="accent" mat-mini-fab
            matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-check-square" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
</hb-fab-group>

<hb-fab-group #selectedFabGroup *ngIf="hasSelected()" [disabled]="sharedPath"
              [label]="'keys.local.fab.menu' | translate">
  <ng-container *hbFabItem>
    <button (click)="moveSelection(); selectedFabGroup.close()"
            [disabled]="!('update' | able: 'folder') && !('update' | able: 'material')"
            [matTooltip]="'keys.local.fab.move_selection' | translate" color="accent"
            mat-mini-fab matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-share" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
  <ng-container *hbFabItem>
    <button (click)="deleteSelection(); selectedFabGroup.close()"
            [disabled]="!('delete' | able: 'folder') && !('delete' | able: 'material')"
            [matTooltip]="'keys.local.fab.delete_selection' | translate" color="accent"
            mat-mini-fab matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-trash" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
  <ng-container *hbFabItem>
    <button (click)="selectAll(); selectedFabGroup.close()"
            [disabled]="!('update' | able: 'folder') && !('selection' | able: 'material')"
            [matTooltip]="'keys.local.fab.select_all' | translate"
            color="accent" mat-mini-fab
            matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-check-square" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
  <ng-container *hbFabItem>
    <button (click)="deSelectAll(); selectedFabGroup.close()"
            [disabled]="!('update' | able: 'folder') && !('selection' | able: 'material')"
            [matTooltip]="'keys.local.fab.deselect_all' | translate"
            color="accent" mat-mini-fab
            matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
      <mat-icon fontIcon="fa-minus-square" fontSet="fas" inline="true"></mat-icon>
    </button>
  </ng-container>
</hb-fab-group>



