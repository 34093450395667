import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'hb-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss'],
})
export class FabComponent {

  @Input()
  public label: string = null;

  @Input()
  public fontSet: string;

  @Input()
  public fontIcon: string;

  @Input()
  public disabled = false;

  @Output()
  public fabClick = new EventEmitter();

  constructor() {
  }

}
