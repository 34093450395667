import {Component, OnInit, ViewChild} from '@angular/core';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {BarcodeFormat} from '@zxing/library';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'hb-camera-search',
  templateUrl: './camera-search.component.html',
  styleUrls: ['./camera-search.component.css']
})
export class CameraSearchComponent implements OnInit {

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  formats: BarcodeFormat[] = [BarcodeFormat.DATA_MATRIX, BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128];
  hasCameras = false;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;

  constructor(private dialogRef: MatDialogRef<CameraSearchComponent>) {
  }

  private _autoFocus = false;

  public get autoFocus() {
    return this._autoFocus;
  }

  ngOnInit(): void {
  }

  scanSuccess(e: any) {
    this.dialogRef.close(e);
  }

}
