import {Injectable} from '@angular/core';
import {Howl} from 'howler';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SoundService {

  private soundFiles = {
    scan: './assets/sounds/scan.mp3',
  };

  constructor(private logger: NGXLogger) {

  }

  public playScan() {
    this.playSound(this.soundFiles.scan);
  }

  private playSound(soundFile) {
    this.logger.trace(soundFile);
    if (soundFile !== undefined) {
      const sound = new Howl({
        src: [soundFile]
      });
      sound.play();
    }
  }

}
