import {Component, Input, OnInit} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {NGXLogger} from 'ngx-logger';
import {AttachmentService} from '../../../modules/store';

@Component({
  selector: 'hb-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input()
  public mediaId: number = null;

  @Input()
  public placeholder: string;

  @Input()
  public fallback: IconProp;

  @Input()
  public height: number;

  @Input()
  public width: number;

  constructor(private attachmentService: AttachmentService, private logger: NGXLogger) {
  }

  ngOnInit(): void {
  }

  public hasImage(): boolean {
    return !!this.mediaId || !!this.placeholder;
  }

  public getImage() {
    if (!!this.mediaId) {
      return this.attachmentService.url(this.mediaId, `${this.width * 2}x${this.height * 2}`);
    } else if (!!this.placeholder) {
      return this.placeholder;
    } else {
      return null;
    }
  }

  public getIcon() {
    return this.fallback;
  }


}
