import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddCertificateComponent} from './add-certificate.component';
import {CertificateEntity} from '../../../modules/store/src/modules/certificate-store';
import {TrainingViewModel} from '../../../modules/store/src/modules/trainings-store';

@Injectable({
  providedIn: 'root'
})
export class AddCertificateDialogService extends BaseDialogService<AddCertificateComponent, TrainingViewModel, CertificateEntity> {

  protected config: MatDialogConfig = {
    width: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, AddCertificateComponent);
  }
}
