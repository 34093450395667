<mat-card>
  <mat-card-header class="hb-mat-card-header">
    <mat-card-title>{{title}}</mat-card-title>
    <mat-card-subtitle>{{subTitle || ''}}</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <div class="hb-mat-card-image" mat-card-image>
    <ng-content select="hb-card-image">

    </ng-content>
  </div>
  <mat-divider></mat-divider>
  <mat-card-content *ngIf="!!status">
    <div style="display: flex; flex-direction: row; justify-content: center; padding-top: 16px;">
      <hb-status [status]="status" extended="true">

      </hb-status>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-footer class="hb-mat-card-footer">
    <mat-accordion>
      <ng-content select="hb-card-content">

      </ng-content>
    </mat-accordion>
  </mat-card-footer>
</mat-card>
