import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromAttachment from './attachment.reducer';
import {NGXLogger} from 'ngx-logger';
import {
  AttachmentItemPayload,
  AttachmentViewModel,
  CreateAttachmentRequest,
  GetAttachmentRequest,
  LoadAttachmentsRequest,
  MediaType,
  RemoveRequest
} from './attachment.models';
import {Actions, ofType} from '@ngrx/effects';
import {map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {from, Observable} from 'rxjs';
import {environment} from '@hb/environments/environment';
import {AttachmentActions} from './attachment.actions';
import {AttachmentSelectors} from './attachment.selectors';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  public readonly isLoading$ = this.store.pipe(select(AttachmentSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(AttachmentSelectors.getException));
  public readonly items$ = this.store.pipe(select(AttachmentSelectors.getItems));
  public readonly materialItems$ = this.store.pipe(select(AttachmentSelectors.getMaterialItems));
  public readonly documentItems$ = this.store.pipe(select(AttachmentSelectors.getDocumentItems));

  constructor(private store: Store<fromAttachment.AttachmentPartialState>,
              private actions$: Actions, private http: HttpClient, private logger: NGXLogger) {
  }

  public load(request: LoadAttachmentsRequest) {
    this.store.dispatch(AttachmentActions.loadAttachmentsRequest({request}));
  }

  public get(request: GetAttachmentRequest) {
    this.store.dispatch(AttachmentActions.getAttachmentRequest({request}));
  }

  public getItems(targetId: number, mediaType: MediaType) {
    return this.store.pipe(
      select(AttachmentSelectors.getItems, {targetId, mediaType}),
    );
  }

  public getItem(id: number) {
    return this.store.pipe(
      select(AttachmentSelectors.getItem, {id}),
    );
  }

  public convertFileToRequest(file: File, mediaType: MediaType, targetId: number): Observable<CreateAttachmentRequest> {
    return from(this.getBase64(file)).pipe(
      map(data => ({data, mediaType, targetId, filename: file.name, mimetype: file.type} as CreateAttachmentRequest)),
    );
  }

  public create(request: CreateAttachmentRequest): Observable<AttachmentItemPayload> {
    this.store.dispatch(AttachmentActions.createAttachmentRequest({request}));
    return this.next(AttachmentActions.createAttachmentSuccess).pipe(
      map(action => action.payload)
    );
  }

  public update(vm: AttachmentViewModel) {
    this.store.dispatch(AttachmentActions.updateAttachmentRequest({entity: {...vm.model, ...vm.changes}}));
  }

  public remove(request: RemoveRequest) {
    this.store.dispatch(AttachmentActions.removeAttachmentRequest({request}));
    return this.next(AttachmentActions.removeAttachmentSuccess);
  }

  public download(vm: AttachmentViewModel) {
    this.http.get(`${environment.api}/media/${vm.id}`, {responseType: 'blob'})
      .subscribe(blob => saveAs(blob, vm.filename));
  }

  public open(vm: AttachmentViewModel) {
    const w = window.open(`${environment.api}/media/${vm.id}`, '_blank');
    w.name = vm.filename;
  }

  public url(id: number, size?: string): string {
    if (!!size && size.match('^[0-9]*x[0-9]*$')) {
      return !!id ? `${environment.api}/media/${id}?size=${size}` : null;
    } else {
      return !!id ? `${environment.api}/media/${id}` : null;
    }
  }

  private next(type: any) {
    return this.actions$.pipe(
      ofType(type),
      take(1)
    );
  }

  private getBase64(file): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.includes('image')) {
          resolve(this.compress(String(reader.result), environment.image_uploads.compressFactor));
        } else {
          resolve(reader.result);
        }
      };
    }).then((resolve: string) => resolve.split(',')[1]);
  }

  private compress(src: string, compressFactor: number): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src.toString();
      img.onload = () => {
        const elem = document.createElement('canvas');
        const newWidth = img.width;
        const newHeight = img.height;
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL('image/jpeg', compressFactor);
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }

}
