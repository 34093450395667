<mat-progress-bar *ngIf="isLoading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>

<hb-list #list (vsPageChange)="fetch($event)"
         [items]="items$ | async | orderBy: 'nextCheck'"
         [virtualHeight]="innerHeight"
         itemSize="99">
  <ng-template #item let-viewModel="$implicit">
    <hb-material-item-view (click)="open(viewModel)"
                           [buttons]="{status: true, case: false, notify: false, context: false}"
                           [viewModel]="viewModel"
                           showPath="true">
    </hb-material-item-view>
  </ng-template>
</hb-list>

