import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromForm from './form.reducer';
import {Actions} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {FormSelectors} from './form.selectors';
import {FormActions} from './form.actions';


@Injectable({
  providedIn: 'root'
})
export class FormService {

  items$ = this.store.pipe(select(FormSelectors.getItems));
  exception$ = this.store.pipe(select(FormSelectors.getException));

  constructor(private store: Store<fromForm.FormPartialState>,
              private actions$: Actions,
              private logger: NGXLogger) {
  }

  public load(repositoryId: number, materialId: number) {
    this.store.dispatch(FormActions.loadFormsRequest({request: {repositoryId, materialId}}));
  }

}
