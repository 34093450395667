<mat-progress-bar *ngIf="isLoading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>
<hb-list #list
         (vsPageChange)="fetch($event)"
         [items]="items$ | async"
         [rows]="rows$ | async"
         [virtualHeight]="innerHeight"
         itemSize="75">
  <ng-template #item let-viewModel="$implicit">
    <hb-user-item-view (click)="open(viewModel)"
                       [buttons]="{status: true, context: true}"
                       [disabled]="!('update' | able: 'user')" [viewModel]="viewModel">

    </hb-user-item-view>
  </ng-template>
</hb-list>
<hb-fab (fabClick)="add()" [disabled]="!('create' | able: 'user') || sharedPath" [label]="'keys.local.fab.add_user' | translate"
        fontIcon="fa-plus" fontSet="fas"></hb-fab>

































