import {Action, createReducer, on} from '@ngrx/store';
import {LicenseEntity} from './license.models';
import {Exception} from '../../../../../../core';
import {LicenseActions} from './license.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const LICENSE_FEATURE_KEY = 'license';

export interface LicenseState {
  isLoading: boolean;
  items: LicenseEntity[];
  exception: Exception;
}

export interface LicensePartialState {
  readonly [LICENSE_FEATURE_KEY]: LicenseState;
}

export const initialState: LicenseState = {
  isLoading: false,
  exception: null,
  items: []
};


export const licenseReducer = createReducer(
  initialState,

  on(LicenseActions.loadLicensesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(LicenseActions.loadLicensesSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(LicenseActions.loadLicensesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: LicenseState | undefined, action: Action) {
  return licenseReducer(state, action);
}
