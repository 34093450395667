import {DataSource} from '../../../../../../core/utils/src/datasource';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {CreateMaterialRequest, LoadMaterialsRequest, MaterialViewModel} from './material.models';
import {MaterialService} from './material.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialDataSource implements DataSource<MaterialViewModel> {

  readonly isLoading$: Observable<boolean> = this.materialService.isLoading$;
  readonly items$: Observable<MaterialViewModel[]> = this.materialService.items$;
  readonly rows$: Observable<number> = this.materialService.rows$;

  constructor(private materialService: MaterialService) {
  }

  load(request: LoadMaterialsRequest) {
    return this.materialService.load(request);
  }

  create(request: CreateMaterialRequest): Observable<MaterialViewModel> {
    return this.materialService.create(request);
  }

  move(vm: MaterialViewModel, destination: number) {
    return this.materialService.move(vm, destination);
  }

  delete(vm: MaterialViewModel) {
    return this.materialService.delete(vm);
  }

  persist(material: MaterialViewModel) {
    return this.materialService.persistV2(material);
  }
}
