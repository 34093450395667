import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {EditPasswordComponent} from './edit-password.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class EditPasswordDialogService extends BaseDialogService<EditPasswordComponent, any> {

  protected config: MatDialogConfig = {
    width: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, EditPasswordComponent);
  }
}
