<div class="hb-cropper">
  <div *ngIf="!mediaId && !disabled" class="hb-cropper-drop">
    <mat-icon fontIcon="fa-plus" fontSet="fas"></mat-icon>
    <h4>{{'keys.local.global.drop_here' | translate}}</h4>
  </div>
  <input (change)="fileChangeEvent($event)" *ngIf="!mediaId && !disabled" accept="image/*" class="hb-cropper-input"
         type="file"/>
  <div [ngStyle]="hasImage() ? {'background': 'url('+ getImage() +') no-repeat lightgray'} : null"
       class="hb-cropper-placeholder hb-default-image"></div>
</div>
<div *ngIf="!!mediaId && !disabled" class="hb-cropper-buttons">
  <button (click)="removeImage()" class="hb-cropper-button"
          mat-button>{{'keys.local.global.remove_image' | translate}}</button>
</div>
