import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {AuthenticationActions} from './authentication.actions';

export const AUTHENTICATION_FEATURE_KEY = 'authentication';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';




export interface AuthenticationState {
  isLoading: boolean;
  access: string;
  refresh: string;
  exception: Exception;
}

export interface AuthenticationPartialState {
  readonly [AUTHENTICATION_FEATURE_KEY]: AuthenticationState;
}


export const initialState: AuthenticationState = {
  isLoading: false,
  access: localStorage.getItem(ACCESS_TOKEN) || null,
  refresh: localStorage.getItem(REFRESH_TOKEN) || null,
  exception: null
};

const authenticationReducer = createReducer(
  initialState,
  on(AuthenticationActions.loginRequest, (state, {request}) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthenticationActions.loginSuccess, (state, {payload}) => ({
    ...state,
    ...payload,
    isLoading: false,
  })),
  on(AuthenticationActions.loginFailure, (state, {exception}) => ({
    ...state,
    exception,
    isLoading: false,
  })),
  on(AuthenticationActions.logoutRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthenticationActions.logoutSuccess, (state) => ({
    ...state,
    access: null,
    refresh: null,
    isLoading: false,
  })),
  on(AuthenticationActions.logoutFailure, (state) => ({
    ...state,
    access: null,
    refresh: null,
    isLoading: false,
  })),
  on(AuthenticationActions.registerRequest, (state, {request}) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthenticationActions.registerSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
  })),
  on(AuthenticationActions.registerFailure, (state, {exception}) => ({
    ...state,
    exception,
    isLoading: false,
  })),
  on(AuthenticationActions.passwordForgottenRequest, (state, {request}) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthenticationActions.passwordForgottenSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
  })),
  on(AuthenticationActions.passwordForgottenFailure, (state, {exception}) => ({
    ...state,
    exception,
    isLoading: false,
  })),

  on(AuthenticationActions.refreshRequest, (state, {request}) => ({
    ...state,
    isLoading: true,
    access: null,
  })),
  on(AuthenticationActions.refreshSuccess, (state, {payload}) => ({
    ...state,
    ...payload,
    isLoading: false,
  })),
  on(AuthenticationActions.refreshFailure, (state, {exception}) => ({
    ...state,
    exception,
    isLoading: false,
  })),
  on(AuthenticationActions.getLoggedInUserIdRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(AuthenticationActions.getLoggedInUserIdSuccess, (state, {payload}) => ({
    ...state,
    ...payload,
    isLoading: false,
  })),
  on(AuthenticationActions.getLoggedInUserIdFailure, (state, {exception}) => ({
    ...state,
    exception,
    isLoading: false,
  })),
  on(AuthenticationActions.clearSession, (state) => ({
    ...initialState,
    access: null,
    refresh: null,
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: AuthenticationState | undefined, action: Action) {
  return authenticationReducer(state, action);
}
