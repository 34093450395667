<mat-progress-bar *ngIf="isLoading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>
<div class="flex flex-col flex-grow">
  <div *ngIf="!!viewModel && !(isLoading$ | async) && ('read' | able: 'material')">
    <hb-card-column-view>
      <hb-card [status]="viewModel.status"
               [subTitle]="viewModel.articleNumber"
               [title]="viewModel.name">
        <hb-card-image>
          <hb-commercial-marker style="position: absolute; z-index: 500; padding: 5px;"></hb-commercial-marker>
          <hb-image-input
            [disabled]="!('update' | able: 'material.media') || viewModel.sharedPath"
            [mediaId]="viewModel.mediaId"
            [mediaType]="mediaType.MATERIAL_REAL_IMAGE"
            [placeholder]="placeholder()"
            [targetId]="viewModel.id">
          </hb-image-input>
        </hb-card-image>
        <hb-card-content>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="hb-left-icon" color="primary" fontIcon="fa-clipboard-list" fontSet="fas"></mat-icon>
                {{'groups.meta_data.title' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <small>{{'keys.local.global.id' | translate}}: {{viewModel.objectId}}<br></small>
            <small *ngIf="viewModel.createdAt">{{'keys.local.global.created' | translate}}
              : {{viewModel.createdAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.createdBy">, {{viewModel.createdBy}}<br></small>
            <small *ngIf="viewModel.updatedAt">{{'keys.local.global.updated' | translate}}
              : {{viewModel.updatedAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.updatedBy">, {{viewModel.updatedBy}}<br></small>
          </mat-expansion-panel>
        </hb-card-content>
      </hb-card>
      <hb-card-accordion>
        <mat-accordion>
          <form #ngForm=ngForm>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="hb-left-icon" color="primary" fontIcon="fa-dice-d6" fontSet="fas"></mat-icon>
                  {{'groups.material.title' | translate}}
                </mat-panel-title>
                <mat-panel-description class="hb-panel-description">
                  <mat-icon *ngIf="fgMain.invalid" class="hb-right-icon" color="warn"
                            fontIcon="fa-exclamation-triangle"
                            fontSet="fas"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <fieldset #fgMain="ngModelGroup" fxLayout="column nowrap"
                        ngModelGroup="main">

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="title">{{'forms.fields.name.label' | translate}}</label>
                  </mat-label>
                  <input #title="ngModel"
                         [(ngModel)]="viewModel.name"
                         [disabled]="!!viewModel.name || !('update' | able: 'material')"
                         [placeholder]="'forms.fields.name.placeholder' | translate"
                         id="title"
                         matInput
                         name="title"
                         required touched>
                  <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="title.dirty && title.touched">
                    <ng-container *ngIf="title.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="articleNumber">{{'forms.fields.articleNumber.label' | translate}}</label>
                  </mat-label>
                  <input #articleNumber="ngModel"
                         [(ngModel)]="viewModel.articleNumber"
                         [disabled]="!!viewModel.articleNumber || !('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.articleNumber.placeholder' | translate"
                         id="articleNumber"
                         matInput
                         name="articleNumber"
                         required
                         touched>
                  <mat-hint>{{'forms.fields.articleNumber.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="articleNumber.dirty && articleNumber.touched">
                    <ng-container *ngIf="articleNumber.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="ean">{{'forms.fields.ean.label' | translate}}</label>
                  </mat-label>
                  <input #ean="ngModel"
                         [(ngModel)]="viewModel.ean"
                         [disabled]="!!viewModel.ean || !('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.ean.placeholder' | translate"
                         id="ean"
                         matInput
                         name="ean" touched>
                  <mat-hint>{{'forms.fields.ean.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="ean.dirty && ean.touched">
                    <ng-container *ngIf="ean.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="manufacturer">{{'forms.fields.manufacturer.label' | translate}}</label>
                  </mat-label>
                  <input #manufacturer="ngModel"
                         [(ngModel)]="viewModel.manufacturer"
                         [disabled]="!!viewModel.manufacturer || !('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.manufacturer.placeholder' | translate"
                         id="manufacturer"
                         matInput
                         name="manufacturer"
                         required touched>
                  <mat-hint>{{'forms.fields.manufacturer.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="manufacturer.dirty && manufacturer.touched">
                    <ng-container *ngIf="manufacturer.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="category">{{'forms.fields.category.label' | translate}}</label>
                  </mat-label>
                  <input #category="ngModel"
                         [disabled]="!!viewModel.category || !('update' | able: 'material') || viewModel.sharedPath"
                         [ngModel]="viewModel.category"
                         [placeholder]="'forms.fields.category.placeholder' | translate"
                         id="category"
                         matInput
                         name="category" required touched>
                  <mat-hint>{{'forms.fields.category.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="category.dirty && category.touched">
                    <ng-container *ngIf="category.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="detailCategory">{{'forms.fields.detail_category.label' | translate}}</label>
                  </mat-label>
                  <input #detailCategory="ngModel"
                         [disabled]="!!viewModel.detailCategory || !('update' | able: 'material') || viewModel.sharedPath"
                         [ngModel]="viewModel.detailCategory"
                         [placeholder]="'forms.fields.detail_category.placeholder' | translate"
                         id="detailCategory"
                         matInput
                         name="detailCategory" touched>
                  <mat-hint>{{'forms.fields.detail_category.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="detailCategory.dirty && detailCategory.touched">

                  </mat-error>
                </mat-form-field>



                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="serialNumber">{{'forms.fields.serialnumber.label' | translate}}</label>
                  </mat-label>
                  <input #serialNumber="ngModel"
                         [(ngModel)]="viewModel.serialNumber"
                         [disabled]="!!viewModel.serialNumber || !('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.serialnumber.placeholder' | translate"
                         [required]="!viewModel.isSealPac"
                         id="serialNumber"
                         matInput name="serialNumber"
                         touched>
                  <mat-hint>{{'forms.fields.serialnumber.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="serialNumber.dirty && serialNumber.touched">
                    <ng-container *ngIf="serialNumber.hasError('serialNumber')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="viewModel.isSealPac" appearance="outline">
                  <mat-label>
                    <label for="sealPacNumber">{{'forms.fields.sealPacNumber.label' | translate}}</label>
                  </mat-label>
                  <input #sealPacNumber="ngModel"
                         [(ngModel)]="viewModel.sealPacNumber"
                         [disabled]="!!viewModel.sealPacNumber || !('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.sealPacNumber.placeholder' | translate"
                         id="sealPacNumber"
                         matInput
                         name="sealPacNumber"
                         required touched>
                  <mat-hint>{{'forms.fields.sealPacNumber.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="sealPacNumber.dirty && sealPacNumber.touched">
                    <ng-container *ngIf="sealPacNumber.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field #manufacturerDateFormat="hbYearMonthDateFormat" appearance="outline"
                                hbYearMonthDateFormat>
                  <mat-label>
                    <label for="manufacturingDate">{{'forms.fields.manufacturingDate.label' | translate}}</label>
                  </mat-label>
                  <input #manufacturingDate="ngModel"
                         (click)="manufacturingDatePicker.open()"
                         [(ngModel)]="viewModel.manufacturingDate"
                         [disabled]="!!viewModel.manufacturingDate || !('update' | able: 'material') || viewModel.sharedPath"
                         [matDatepicker]="manufacturingDatePicker"
                         [max]="currentDate"
                         [placeholder]="'forms.fields.manufacturingDate.placeholder' | translate"
                         id="manufacturingDate" matInput
                         name="manufacturingDate" readonly required>
                  <mat-datepicker-toggle [disabled]="!!viewModel.manufacturingDate || viewModel.sharedPath"
                                         [for]="manufacturingDatePicker"
                                         matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #manufacturingDatePicker
                                  (monthSelected)="manufacturerDateFormat.chosenMonthHandler($event, manufacturingDatePicker)"
                                  [disabled]="!!viewModel.manufacturingDate || !('update' | able: 'material') || viewModel.sharedPath"
                                  [touchUi]="!matchWeb"
                                  startView="year"></mat-datepicker>
                  <mat-hint>{{'forms.fields.manufacturingDate.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="manufacturingDate.dirty && manufacturingDate.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field #firstUsageDateFormat="hbYearMonthDateFormat" *ngIf="!viewModel.isSealPac"
                                appearance="outline"
                                hbYearMonthDateFormat>
                  <mat-label>
                    <label for="firstUsage">{{'forms.fields.first_usage.label' | translate}}</label>
                  </mat-label>
                  <input #firstUsage="ngModel" (click)="firstUsageDatePicker.open()"
                         [(ngModel)]="viewModel.firstUsage"
                         [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                         [matDatepicker]="firstUsageDatePicker"
                         [max]="currentDate | date"
                         [min]="viewModel.firstUsage | date"
                         [placeholder]="'forms.fields.first_usage.placeholder' | translate" id="firstUsage"
                         matInput
                         name="firstUsage" readonly>
                  <mat-datepicker-toggle [for]="firstUsageDatePicker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #firstUsageDatePicker
                                  (monthSelected)="firstUsageDateFormat.chosenMonthHandler($event, firstUsageDatePicker)"
                                  [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                                  [touchUi]="!matchWeb"
                                  startView="year"></mat-datepicker>
                  <mat-hint>{{'forms.fields.first_usage.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="firstUsage.dirty && firstUsage.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field #lastUsageDateFormat="hbYearMonthDateFormat" appearance="outline"
                                hbYearMonthDateFormat>
                  <mat-label>
                    <label for="lastUsage">{{'forms.fields.last_usage.label' | translate}}</label>
                  </mat-label>
                  <input #lastUsage="ngModel"
                         (click)="lastUsageDatePicker.open()"
                         [(ngModel)]="viewModel.lastUsage"
                         [disabled]="!!viewModel.lastUsage || !('update' | able: 'material') || viewModel.sharedPath"
                         [matDatepicker]="lastUsageDatePicker"
                         [max]="currentDate | date"
                         [min]="viewModel.firstUsage | date"
                         [placeholder]="'forms.fields.last_usage.placeholder' | translate" id="lastUsage"
                         matInput
                         name="lastUsage" readonly>
                  <mat-datepicker-toggle [disabled]="!!viewModel.lastUsage || viewModel.sharedPath"
                                         [for]="lastUsageDatePicker"
                                         matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #lastUsageDatePicker
                                  (monthSelected)="lastUsageDateFormat.chosenMonthHandler($event, lastUsageDatePicker)"
                                  [disabled]="!!viewModel.lastUsage || !('update' | able: 'material') || viewModel.sharedPath"
                                  [touchUi]="!matchWeb" startView="year"></mat-datepicker>
                  <mat-hint>{{'forms.fields.last_usage.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="lastUsage.dirty && lastUsage.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="usage">{{'forms.fields.usage.label' | translate}}</label>
                  </mat-label>
                  <mat-select #usage="ngModel"
                              [(ngModel)]="viewModel.usageId"
                              [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                              [placeholder]="'forms.fields.usage.placeholder' | translate"
                              id="usage"
                              name="usage" required touched>
                    <mat-option *ngFor="let usage of usages$ | async"
                                [value]="usage.id">{{usage.key | translate}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.usage.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="usage.dirty && usage.touched">
                    <ng-container *ngIf="usage.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="usage.value === 1" appearance="outline">
                  <mat-label>
                    <label for="soldOn">{{'forms.fields.sold_on.label' | translate}}</label>
                  </mat-label>
                  <input #soldOn="ngModel"
                         (click)="soldOnPicker.open()"
                         [(ngModel)]="viewModel.soldOn"
                         [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                         [matDatepicker]="soldOnPicker"
                         [placeholder]="'forms.fields.sold_on.placeholder' | translate"
                         id="soldOn"
                         matInput
                         name="soldOn">
                  <mat-datepicker-toggle [for]="soldOnPicker" matSuffix></mat-datepicker-toggle>
                  <mat-datepicker #soldOnPicker [touchUi]="!matchWeb"></mat-datepicker>
                  <mat-hint>{{'forms.fields.sold_on.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="soldOn.dirty && soldOn.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="checkInterval">{{'forms.fields.check_interval.label' | translate}}</label>
                  </mat-label>
                  <input #check_interval="ngModel"
                         [(ngModel)]="viewModel.checkInterval"
                         [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.check_interval.placeholder' | translate"
                         id="checkInterval"
                         matInput
                         name="check_interval"
                         pattern="^\d*$" touched>
                  <mat-hint>{{'forms.fields.check_interval.hint' | translate}}</mat-hint>
                  <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()" color="info" fontIcon="fa-info-circle"
                            fontSet="fas" matSuffix
                            matTooltip="{{'keys.local.tooltips.form_interval' | translate}}"></mat-icon>
                  <mat-error *ngIf="check_interval.dirty && check_interval.touched">
                    <ng-container *ngIf="check_interval.hasError('pattern')">
                      {{'forms.errors.only_digits' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label
                      for="customerSerialNumber">{{'forms.fields.customer_serialnumber.label' | translate}}</label>
                  </mat-label>
                  <input #customerSerialNumber="ngModel"
                         [(ngModel)]="viewModel.rfid"
                         [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.customer_serialnumber.placeholder' | translate"
                         id="customerSerialNumber"
                         matInput
                         name="customerSerialNumber">
                  <mat-hint>{{'forms.fields.customer_serialnumber.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="customerSerialNumber.dirty && customerSerialNumber.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="hb-pointer">
                  <mat-label>
                    <label for="geolocation">{{'forms.fields.geolocation.label' | translate}}</label>
                  </mat-label>
                  <hb-input-geolocation #geolocation="geolocationInput" (click)="geolocation.getGeolocation()"
                                        [(ngModel)]="viewModel.geolocation"
                                        [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                                        [placeholder]="'forms.fields.geolocation.placeholder' | translate"
                                        [readonly]="true"
                                        id="geolocation"
                                        name="geolocation">
                  </hb-input-geolocation>
                  <mat-hint>{{'forms.fields.geolocation.hint' | translate}}</mat-hint>
                  <mat-icon (click)="geolocation.clear()" *ngIf="!geolocation.empty && !geolocation.disabled"
                            color="mute" fontIcon="fa-times"
                            fontSet="fas" inline
                            matSuffix
                            style="display: flex; align-items: center; justify-content: center; align-self: center"></mat-icon>
                  <mat-icon (click)="geolocation.getGeolocation()" color="primary" fontIcon="fa-map-marker-alt"
                            fontSet="fas"
                            inline
                            matPrefix
                            style="padding-right: 0.5rem; display: flex; align-items: center; justify-content: center; align-self: center"></mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="comment">{{'forms.fields.comment.label' | translate}}</label>
                  </mat-label>
                  <input #comment="ngModel"
                         [(ngModel)]="viewModel.comment"
                         [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                         [placeholder]="'forms.fields.comment.placeholder' | translate"
                         id="comment"
                         matInput
                         name="comment">
                  <mat-hint>{{'forms.fields.comment.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="comment.dirty && comment.touched">

                  </mat-error>
                </mat-form-field>
              </fieldset>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="viewModel.product && viewModel.product.properties.length > 0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="hb-left-icon" color="primary" fontIcon="fa-box" fontSet="fas"></mat-icon>
                  {{'groups.properties.title' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="column nowrap">
                <mat-form-field *ngFor="let field of viewModel.product.properties" appearance="outline">
                  <mat-label>
                    <label [for]="field.id.toString()" for="field_{{field.id}}">{{'properties.titles.' + field.name | translate}}</label>
                  </mat-label>
                  <input [ngModel]="getPropertyValue(field)" [placeholder]="'properties.titles.' + field.name | translate" disabled
                         id="field_{{field.id}}"
                         matInput name="field_{{field.id}}">
                </mat-form-field>
              </div>

            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="viewModel.id !== 0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-file" fontSet="fas" style="margin-right: 10px"></mat-icon>
                  {{'groups.documents.title' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-form-field *ngIf="!(hasOpenDocument$ | async) && !viewModel.lastUsage" appearance="outline"
                              class="w-100">
                <mat-label>
                  <mat-icon color="mute" fontIcon="fa-plus" fontSet="fas" inline matPrefix></mat-icon>
                  {{'forms.fields.add_document.label' | translate}}</mat-label>
                <mat-select
                  [disabled]="!('create' | able: 'document') || viewModel.sharedPath"
                  [placeholder]="'forms.fields.add_document.placeholder' | translate"
                  class="hb-mat-primary">
                  <mat-option
                    (click)="createDocument(form.id.toString())"
                    *ngFor="let form of forms$ | async">{{form.name}}</mat-option>
                </mat-select>
                <mat-hint>{{'forms.fields.add_document.hint' | translate}}</mat-hint>
              </mat-form-field>
              <mat-list>
                <hb-document-item-view *ngFor="let document of documents$ | async | orderBy: 'date': true"
                                       [buttons]="{ status: true, context: true }"
                                       [disabled]="!('update' | able: 'document') || viewModel.sharedPath"
                                       [viewModel]="document">

                </hb-document-item-view>
              </mat-list>
            </mat-expansion-panel>
            <mat-expansion-panel
              *ngIf="viewModel.product.strategy === 'SealPacStrategyService' || viewModel.product.strategy === 'InstallationStrategyService'"
              [disabled]="viewModel.id === 0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon class="hb-left-icon" color="primary" fontIcon="fa-toolbox" fontSet="fas"></mat-icon>
                  {{'groups.set_items.title' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <mat-form-field appearance="outline" class="w-100">
                <mat-label>
                  <mat-icon [inline]="true" color="mute" fontIcon="fa-plus" fontSet="fas" matPrefix></mat-icon>
                  {{'forms.fields.add_material.label' | translate}}</mat-label>
                <mat-select [disabled]="!('create' | able: 'material') || viewModel.sharedPath"
                            [placeholder]="'forms.fields.add_material.placeholder' | translate">
                  <mat-option (click)="add()">
                      <span>
                        <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
                        {{'forms.fields.add_material.options.selection' | translate}}
                      </span>
                  </mat-option>
                  <mat-option (click)="scan()">
                        <span><mat-icon fontIcon="fa-barcode" fontSet="fas"></mat-icon>
                          {{'forms.fields.add_material.options.scan' | translate}}</span>
                  </mat-option>
                </mat-select>
                <mat-hint>{{'forms.fields.add_material.hint' | translate}}</mat-hint>
              </mat-form-field>

              <mat-list>

                <hb-material-item-view (click)="openSubMaterial(child)"
                                       *ngFor="let child of children$ | async"
                                       [buttons]="{status: true, context: true}"
                                       [disabled]="!('update' | able: 'material') || viewModel.sharedPath"
                                       [viewModel]="child"
                                       sub="true">
                </hb-material-item-view>
              </mat-list>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="viewModel.id !== 0">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-paperclip" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{'groups.attachments.title' | translate}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                  <hb-commercial-marker></hb-commercial-marker>
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div fxLayout="column nowrap" fxLayoutGap="1rem" style="margin-bottom: 1.34375em;">
                <button (click)="addAttachment()"
                        [disabled]="!('create' | able: 'material.media') || viewModel.sharedPath" color="primary"
                        mat-raised-button>
                  <mat-label>
                    <mat-icon [inline]="true" fontIcon="fa-plus" fontSet="fas" matPrefix></mat-icon>
                    {{'keys.local.global.add_attachment' | translate}}</mat-label>
                </button>
                <mat-list>
                  <hb-attachment-item-view *ngFor="let attachment of attachments$ | async"
                                           [disabled]="!('update' | able: 'material.media') || viewModel.sharedPath"
                                           [viewModel]="attachment"
                                           parentType="material">

                  </hb-attachment-item-view>
                </mat-list>
              </div>
            </mat-expansion-panel>
          </form>
        </mat-accordion>
      </hb-card-accordion>
    </hb-card-column-view>
    <hb-fab (fabClick)="save()" [disabled]="ngForm.invalid || !('update' | able: 'material') || viewModel.sharedPath"
            [label]="'keys.local.fab.save' | translate"
            fontIcon="fa-save" fontSet="fas">

    </hb-fab>
  </div>

</div>

