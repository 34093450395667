import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hb-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss']
})
export class CardContentComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
