import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {QueryParams} from '@ngrx/data';
import {Params} from '@angular/router';
import {appendIfExists, HttpParamsFactory} from '../../../../../../core/utils';
import {
  DocumentEntity,
  DocumentItemPayload,
  DocumentItemResponse,
  DocumentListPayload,
  DocumentListResponse,
  DocumentRemovePayload
} from './document.models';
import {environment} from '@hb/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  create(repositoryId: number, materialId: number, queryParams?: QueryParams): Observable<DocumentItemPayload> {
    const url = `${environment.api}/repositories/${repositoryId}/materials/${materialId}/documents`;
    return this.http.put<DocumentItemResponse>(url, '', {params: queryParams}).pipe(
      map(res => res.data)
    );
  }

  load(repositoryId: number, materialId: number): Observable<DocumentListPayload> {
    const url = `${environment.api}/repositories/${repositoryId}/materials/${materialId}/documents`;

    return this.http.get<DocumentListResponse>(url).pipe(
      map(res => ({items: res.datalist, rows: res.rows}))
    );
  }

  get(repositoryId: number, materialId: number, documentId: number): Observable<DocumentItemPayload> {
    return this.http.get<DocumentItemResponse>(
      `${environment.api}/repositories/${repositoryId}/materials/${materialId}/documents/${documentId}`).pipe(
      map(res => ({item: res.data}))
    );
  }

  fetch(repositoryId: number, materialId: number, documentId: number, queryParams?: QueryParams): Observable<DocumentItemPayload> {
    const url = `${environment.api}/repositories/${repositoryId}/materials/${materialId}/documents/${documentId}`;

    return this.http.get<DocumentItemResponse>(url, {params: queryParams}).pipe(
      map(res => ({item: {...res.data, icon: 'fa-file'}}))
    );
  }

  persist(document: DocumentEntity, queryParams?: QueryParams): Observable<DocumentItemPayload> {
    const url = `${environment.api}/repositories/${document.repositoryId}/materials/${document.materialId}/documents/${document.id}`;
    let params = HttpParamsFactory();
    params = appendIfExists(params, environment.queryParamsNames.email, queryParams?.email);
    params = appendIfExists(params, environment.queryParamsNames.createPDF, queryParams?.createPDF);
    params = appendIfExists(params, environment.queryParamsNames.pdfIncludeAttachments, queryParams?.pdfIncludeAttachments);

    return this.http.put<DocumentItemResponse>(url, document, {params}).pipe(
      map(res => ({item: res.data}))
    );
  }

  delete(params: Params, queryParams?: QueryParams): Observable<DocumentRemovePayload> {
    const url =
      `${environment.api}/repositories/${params.repository}/materials/${params.material}/documents/${params.document}`;

    return this.http.delete(url).pipe(
      map(res => ({documentId: params.document, repositoryId: params.repository, materialId: params.material}))
    );
  }

  seal(params: Params, queryParams?: QueryParams, data?: any): Observable<DocumentItemPayload> {
    const url =
      `${environment.api}/repositories/${params.repository}/materials/${params.material}/documents/${params.document}/createPDF`;

    return this.http.post<DocumentItemResponse>(url, data).pipe(
      map(res => ({}))
    );
  }
}
