import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromUser from './+state/user.reducer';
import {UserEffects} from './+state/user.effects';
import {UserDataService} from './+state/user-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromUser.USER_FEATURE_KEY,
      fromUser.reducer
    ),
    EffectsModule.forFeature([UserEffects])
  ],
  providers: [UserDataService],
  declarations: [],
  exports: []
})
export class UserStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
