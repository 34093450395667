<div class="h-full flex flex-col justify-center items-center flex-grow">
  <form #ngForm="ngForm" (ngSubmit)="submit()"
        class="flex flex-col flex-nowrap max-w-screen-sm w-full max-h-9/10 md:max-w-3/4 lg:max-w-1/2 xl:max-w-1/3 mb-16"
        ngForm>
    <mat-card class="m-2 mat-elevation-z4">
      <div class="hb-card-image"></div>
      <mat-card-content>
        <div class="flex flex-col">
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="organization">{{'forms.fields.organisation.label' | translate}}</label>
            </mat-label>
            <input #organisation="ngModel" [(ngModel)]="viewModel.company"
                   [placeholder]="'forms.fields.organisation.placeholder' | translate" id="organization"
                   matInput
                   name="organisation"
                   required>
            <mat-hint>{{'forms.fields.organisation.hint' | translate}}</mat-hint>
            <mat-error>
              <ng-container *ngIf="organisation.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="name">{{'forms.fields.name.label' | translate}}</label>
            </mat-label>
            <input #name="ngModel" [(ngModel)]="viewModel.name"
                   [placeholder]="'forms.fields.name.placeholder' | translate" id="name" matInput
                   name="name"
                   required
                   type="text">
            <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
            <mat-error *ngIf="name.dirty && name.touched">
              <ng-container *ngIf="name.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="email">{{'forms.fields.email.label' | translate}}</label>
            </mat-label>
            <input #email="ngModel" [(ngModel)]="viewModel.email"
                   [placeholder]="'forms.fields.email.placeholder' | translate" email
                   id="email"
                   matInput
                   name="email" required>
            <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
            <mat-error *ngIf="email.dirty && email.touched">
              <ng-container *ngIf="email.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
              <ng-container *ngIf="email.hasError('email')">
                {{'forms.errors.invalid_email' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="password">{{'forms.fields.password.label' | translate}}</label>
            </mat-label>
            <hb-password-toggle-visibility #toggle color="primary" matSuffix></hb-password-toggle-visibility>
            <input #password="ngModel" [(ngModel)]="viewModel.password"
                   [pattern]="password_pattern" [placeholder]="'forms.fields.password.placeholder' | translate"
                   [type]="toggle.type"
                   id="password"
                   matInput
                   name="password"
                   required>
            <mat-hint>{{'forms.fields.password.hint' | translate}}</mat-hint>

            <mat-error *ngIf="password.dirty && password.touched">
              <ng-container *ngIf="password.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
              <ng-container *ngIf="password.hasError('pattern')">
                {{'forms.errors.password_pattern' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="confirm">{{'forms.fields.password_confirm.label' | translate}}</label>
            </mat-label>
            <input #confirm="ngModel"
                   [placeholder]="'forms.fields.password_confirm.placeholder' | translate"
                   id="confirm"
                   matInput
                   name="confirm"
                   ngModel
                   ngValidateEqual="password"
                   required
                   type="password">
            <mat-error *ngIf="confirm.dirty && confirm.touched">
              <ng-container *ngIf="confirm.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
              <ng-container *ngIf="confirm.hasError('notEqual')">
                {{'forms.errors.password_is_not_equal' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>


          <mat-form-field appearance="outline">
            <mat-label>
              <label for="address_line_1">
                {{'forms.fields.address_line_1.label' | translate}}
              </label>
            </mat-label>
            <input #address_line_1="ngModel" [(ngModel)]="viewModel.street"
                   [placeholder]="'forms.fields.address_line_1.placeholder' | translate" id="address_line_1"
                   matInput
                   name="address_line_1"
                   required
                   type="text">
            <mat-hint>{{'forms.fields.address_line_1.hint' | translate}}</mat-hint>
            <mat-error *ngIf="address_line_1.dirty && address_line_1.touched">
              <ng-container *ngIf="address_line_1.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="zip">{{'forms.fields.zip.label' | translate}}</label>
            </mat-label>
            <input #zip="ngModel" [(ngModel)]="viewModel.zip"
                   [placeholder]="'forms.fields.zip.placeholder' | translate" id="zip" matInput
                   name="zip"
                   required
                   type="text">
            <mat-hint>{{'forms.fields.zip.hint' | translate}}</mat-hint>
            <mat-error *ngIf="zip.dirty && zip.touched">
              <ng-container *ngIf="zip.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="city">{{'forms.fields.city.label' | translate}}</label>
            </mat-label>
            <input #city="ngModel" [(ngModel)]="viewModel.city"
                   [placeholder]="'forms.fields.city.placeholder' | translate" id="city" matInput
                   name="city"
                   required
                   type="text">
            <mat-hint>{{'forms.fields.city.hint' | translate}}</mat-hint>
            <mat-error *ngIf="city.dirty && city.touched">
              <ng-container *ngIf="city.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="country">{{'forms.fields.country.label' | translate}}</label>
            </mat-label>
            <mat-select #country="ngModel" [(ngModel)]="viewModel.countryId"
                        [placeholder]="'forms.fields.country.placeholder' | translate"
                        id="country"
                        name="country"
                        required>
              <mat-option *ngFor="let country of countries$ | async"
                          [value]="country.id">{{country.name}}</mat-option>
            </mat-select>
            <mat-hint>{{'forms.fields.country.hint' | translate}}</mat-hint>
            <mat-error *ngIf="country.dirty && country.touched">
              <ng-container *ngIf="country.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="language">{{'forms.fields.language.label' | translate}}</label>
            </mat-label>
            <mat-select #language="ngModel" [(ngModel)]="viewModel.languageId"
                        [placeholder]="'forms.fields.language.placeholder' | translate"
                        id="language"
                        name="language"
                        required>
              <mat-option *ngFor="let language of languages$ | async"
                          [value]="language.id">{{language.name}}</mat-option>
            </mat-select>
            <mat-hint>{{'forms.fields.language.hint' | translate}}</mat-hint>
            <mat-error *ngIf="language.dirty && language.touched">
              <ng-container *ngIf="language.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="phone">{{'forms.fields.phone.label' | translate}}</label>
            </mat-label>
            <input #phone="ngModel" [(ngModel)]="viewModel.phone"
                   [placeholder]="'forms.fields.phone.placeholder' | translate" id="phone" matInput
                   name="phone"
                   type="tel">
            <mat-hint>{{'forms.fields.phone.hint' | translate}}</mat-hint>
            <mat-error *ngIf="phone.dirty && phone.touched">

            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="mobile">{{'forms.fields.mobile.label' | translate}}</label>
            </mat-label>
            <input #mobile="ngModel" [(ngModel)]="viewModel.cell"
                   [placeholder]="'forms.fields.mobile.placeholder' | translate" id="mobile"
                   matInput
                   name="mobile">
            <mat-hint>{{'forms.fields.mobile.hint' | translate}}</mat-hint>
            <mat-error *ngIf="mobile.dirty && mobile.touched">

            </mat-error>
          </mat-form-field>


          <section>
            <mat-checkbox color="primary" id="tou" name="tou" ngModel required>
              <span>{{'keys.local.global.i_accept_the' | translate}}</span>
              <span>{{' '}}</span>
              <span (click)="openTermsOfUse(); $event.stopImmediatePropagation()"
                    class="text-primary">{{'keys.local.global.terms_of_use' | translate}}</span>
            </mat-checkbox>
          </section>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="flex flex-col flex-nowrap">
          <button [disabled]="(isLoading$() | async) || ngForm.invalid" color="primary" mat-raised-button
                  type="submit">
            {{'keys.local.global.register' | translate}}
          </button>
          <div class="flex flex-row flex-wrap justify-center align-items py-2">
            <a routerLink="/login">{{'keys.local.global.back_to_login' | translate}}</a><br/>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
    <div class="min-h-40">
    </div>
  </form>
</div>

