import {createAction, props} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';

enum AdministrationActionTypes {
  CLEAR_CACHE_REQUEST = '[Administration] Clear Cache Request',
  CLEAR_CACHE_SUCCESS = '[Administration] Clear Cache Success',
  CLEAR_CACHE_FAILURE = '[Administration] Clear Cache Failure',
  CLEAR_CACHE_COMPLETE = '[Administration] Clear Cache Complete',
}

const clearCacheRequest = createAction(
  AdministrationActionTypes.CLEAR_CACHE_REQUEST,
);

const clearCacheSuccess = createAction(
  AdministrationActionTypes.CLEAR_CACHE_SUCCESS,
);

const clearCacheFailure = createAction(
  AdministrationActionTypes.CLEAR_CACHE_FAILURE,
  props<{ exception: Exception }>()
);

const clearCacheComplete = createAction(
  AdministrationActionTypes.CLEAR_CACHE_COMPLETE,
  props<{ success: boolean, data?: any }>()
);

export const AdministrationActions = {
  clearCacheRequest,
  clearCacheSuccess,
  clearCacheFailure,
  clearCacheComplete
};
