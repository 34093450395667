import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as fromTraining from './training.reducer';
import {select, Store} from '@ngrx/store';
import {TrainingActions} from './training.actions';
import {TrainingSelectors} from './training.selectors';


@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  items$ = this.store.pipe(select(TrainingSelectors.getItems));
  isLoading$ = this.store.pipe(select(TrainingSelectors.isLoading));
  exception$ = this.store.pipe(select(TrainingSelectors.getException));
  length$ = this.store.pipe(select(TrainingSelectors.getLength));

  constructor(private store: Store<fromTraining.TrainingPartialState>, private logger: NGXLogger) {
  }

  load() {
    this.store.dispatch(TrainingActions.loadTrainingsRequest());
  }
}
