import {AfterViewInit, Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {bellAnimation} from '../../../route-animation';
import {filter, flatMap, map, take, takeUntil} from 'rxjs/operators';
import {PlacesViewDataSource} from './places-view.data-source';
import {SelectProductDialogService} from '../../../dialogs/select-product';
import {CreateProductDialogService} from '../../../dialogs/create-product';
import {CreateMaterialDialogService} from '../../../dialogs/create-material/src/create-material-dialog.service';
import {SelectRepositoryDialogService} from '../../../dialogs/select-repository';
import {HintDialogService} from '../../../dialogs/hint';
import {CreateRepositoryDialogService} from '../../../dialogs/create-repository/src/create-repository-dialog.service';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {BreakpointService} from '../../../modules/core';
import {ScanDialogService} from '../../../dialogs/scan';
import {CameraScanDialogService} from '../../../dialogs/camera-scan';
import {AppService, MaterialService, MaterialViewModel, RepositoryViewModel, RouterService} from '../../../modules/store';
import {Platform} from '@angular/cdk/platform';
import {environment} from '@hb/environments/environment';

@Component({
  selector: 'hb-places-view',
  templateUrl: './places-view.component.html',
  styleUrls: ['./places-view.component.css'],
  animations: [bellAnimation],
})
export class PlacesViewComponent implements OnInit, OnDestroy, AfterViewInit {
  private selection: Array<RepositoryViewModel | MaterialViewModel> = new Array<RepositoryViewModel | MaterialViewModel>();

  private unsubscribe$ = new Subject<void>();
  public archive = new BehaviorSubject<boolean>(true);

  constructor(private datasource: PlacesViewDataSource,
              private routerService: RouterService,
              private appService: AppService,
              private selectProductDialogService: SelectProductDialogService,
              private createProductDialogService: CreateProductDialogService,
              private createMaterialDialogService: CreateMaterialDialogService,
              private selectRepositoryDialogService: SelectRepositoryDialogService,
              private createRepositoryDialogService: CreateRepositoryDialogService,
              private cameraScanDialogService: CameraScanDialogService,
              private scanDialogService: ScanDialogService,
              private breakpointService: BreakpointService,
              private hintDialogService: HintDialogService,
              private viewContainerRef: ViewContainerRef,
              private materialService: MaterialService,
              private platform: Platform,
              private logger: NGXLogger) {

  }

  public get path$() {
    return this.datasource.path$;
  }

  public get items$() {
    return this.datasource.items$;
  }

  public get rows$() {
    return this.datasource.rows$;
  }

  public get isLoading$() {
    return this.datasource.isLoading$;
  }

  public get availableHeight() {
    return `${window.innerHeight - 117}px`;
  }

  public get sharedPath() {
    return this.datasource.sharedPath;
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.routerService.route$.pipe(
      takeUntil(this.unsubscribe$),
      filter(route => !!route && route.routeConfig.path === 'places/:repositoryId'),
    ).subscribe((route) => {
      this.datasource.load(route.params.repositoryId, 0, this.appService.showInactive, route.queryParams.filter);
      this.selection = new Array<RepositoryViewModel | MaterialViewModel>();
    });
  }

  fetch($event) {
    this.datasource.load(this.routerService.repositoryId, $event.page, this.appService.showInactive);
  }

  createNewMaterial() {

    this.selectProductDialogService.open().afterClosed().pipe(
      takeUntil(this.unsubscribe$),
      flatMap(id => {
        if (id === '0') {
          return this.createProductDialogService.open().afterClosed().pipe(
            map(result => result.id)
          );
        } else {
          return of(id);
        }
      }),
      filter(result => !!result),
      map(productId => Number(productId)),
      flatMap(productId => this.datasource.createNewMaterial({productId, repositoryId: this.routerService.repositoryId})),
      flatMap(material => this.createMaterialDialogService.open(material).afterClosed().pipe(
        filter(result => !!result),
        flatMap(result => this.datasource.persistMaterial(result.viewModel).pipe(
          filter(viewModel => !!viewModel),
          map(viewModel => ({viewModel, next: result.next}))
        )),
      )),
      filter(result => result.next),
      map(result => result.viewModel)
    ).subscribe(viewModel => this.routerService.navigateToMaterialView(viewModel.repositoryId, viewModel.id));
  }

  createNewRepository() {

    this.createRepositoryDialogService.open({id: 0, parentId: this.routerService.repositoryId})
      .afterClosed().pipe(
      takeUntil(this.unsubscribe$),
      filter(result => !!result),
      flatMap(result => this.datasource.createNewRepository(result.entity).pipe(
        filter(entity => !!entity),
        map(entity => ({...result, entity}))
      )),
      filter(result => result.next)
    ).subscribe(result => this.routerService.navigateToPlaces(result.entity.id));
  }

  scanNewMaterial() {
    if ((environment.production && !(this.platform.ANDROID || this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) ||
      (!environment.production && this.breakpointService.matchWeb())) {
      this.scanDialogService.open({repositoryId: Number(this.routerService.repositoryId), id: 0});
    } else {
      this.cameraScanDialogService.open({repositoryId: Number(this.routerService.repositoryId), id: 0});
    }
  }

  isSelected(vm) {
    return this.selection.includes(vm);
  }

  hasSelected() {
    return this.selection.length > 0;
  }

  addToSelection(vm) {
    this.selection.push(vm);
  }

  removeFromSelection(vm) {
    this.selection = this.selection.filter(item => !(item.id === vm.id && item.type === vm.type));
  }

  select(vm) {
    !this.isSelected(vm) ? this.addToSelection(vm) : this.removeFromSelection(vm);
  }

  openRepository(vm: RepositoryViewModel) {
    this.routerService.navigateToPlaces(vm.id);
  }

  openMaterial(vm: MaterialViewModel) {
    this.routerService.navigateToMaterialViewV2(vm);
  }

  handlePressEvent(vm, element, $event) {
    this.select(vm);
  }

  deSelectAll() {
    this.selection = new Array<RepositoryViewModel | MaterialViewModel>();
  }

  selectAll() {
    this.items$.pipe(
      take(1)
    ).subscribe(items => this.selection = items);
  }

  moveSelection() {
    this.selectRepositoryDialogService.open({
      repositoryId: this.routerService.repositoryId,
      filteredIds: this.selection.filter(selected => selected.type === 'RepositoryViewModel').map(selected => selected.id)
    }).afterClosed().pipe(
      takeUntil(this.unsubscribe$),
      filter(result => !!result),
      flatMap(result => this.datasource.move(this.selection, result.repositoryId)),
      filter(result => !!result),
    ).subscribe(repositoryId => this.routerService.navigateToPlaces(repositoryId));
  }

  deleteSelection() {
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      takeUntil(this.unsubscribe$),
      filter(result => !!result),
    ).subscribe(() => this.datasource.delete(this.selection));
  }

  ngAfterViewInit(): void {

  }
}
