import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromCategory from './+state/category.reducer';
import {EffectsModule} from '@ngrx/effects';
import {CategoryEffects} from './+state/category.effects';
import {NGXLogger} from 'ngx-logger';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromCategory.DETAIL_CATEGORY_FEATURE_KEY,
      fromCategory.reducer
    ),
    EffectsModule.forFeature([CategoryEffects]),
  ]
})
export class CategoryStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
