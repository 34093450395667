<div class="flex flex-col justify-center items-center lg:flex-row lg:justify-between lg:items-start p-12 h-full">

  <div class="h-full w-p300 lg:mr-8 mb-8 border border-solid border-gray-100">
    <ng-content select="hb-card">

    </ng-content>
  </div>

  <div class="h-full w-p300 w-full border border-solid border-gray-100">
    <ng-content select="hb-card-accordion">

    </ng-content>
  </div>


  <div style="height: 56px"></div>
</div>
