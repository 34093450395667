import {Action, createReducer, on} from '@ngrx/store';
import {CategoryEntity} from './category.models';
import {Exception} from '../../../../../../core/models';
import {CategoryActions} from './category.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const DETAIL_CATEGORY_FEATURE_KEY = 'detail_category';

export interface CategoryState {
  isLoading: boolean;
  items: CategoryEntity[];
  exception: Exception;
}

export interface CategoryPartialState {
  readonly [DETAIL_CATEGORY_FEATURE_KEY]: CategoryState;
}

export const initialState: CategoryState = {
  isLoading: false,
  exception: null,
  items: []
};


export const categoryReducer = createReducer(
  initialState,

  on(CategoryActions.loadCategoriesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(CategoryActions.loadCategoriesSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(CategoryActions.loadCategoriesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: CategoryState | undefined, action: Action) {
  return categoryReducer(state, action);
}
