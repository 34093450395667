import {AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

const DISABLED = 'disabled';
const HB_DISABLED = 'hb-disabled';
const TAB_INDEX = 'tabindex';
const TAG_ANCHOR = 'a';

@Directive({
  selector: '[hbDisabled]',
  exportAs: 'hbDisabled'
})
export class DisabledDirective implements OnChanges, AfterViewInit {

  @Input() hbDisabled = true;

  constructor(private ref: ElementRef, private renderer: Renderer2, private logger: NGXLogger) {
  }

  ngOnChanges() {
    this.disableElement(this.ref.nativeElement);
  }

  ngAfterViewInit() {
    this.disableElement(this.ref.nativeElement);
  }

  private disableElement(element: any) {
    if (this.hbDisabled) {
      if (!element.hasAttribute(DISABLED)) {
        this.renderer.setAttribute(element, HB_DISABLED, '');
        this.renderer.setAttribute(element, DISABLED, 'true');

        // disabling anchor tab keyboard event
        if (element.tagName.toLowerCase() === TAG_ANCHOR) {
          this.renderer.setAttribute(element, TAB_INDEX, '-1');
        }
      }
    } else {
      if (element.hasAttribute(HB_DISABLED)) {
        if (element.getAttribute('disabled') !== '') {
          element.removeAttribute(DISABLED);
        }
        element.removeAttribute(HB_DISABLED);
        if (element.tagName.toLowerCase() === TAG_ANCHOR) {
          element.removeAttribute(TAB_INDEX);
        }
      }
    }
    if (element.children) {
      for (const ele of element.children) {
        this.disableElement(ele);
      }
    }
  }

}
