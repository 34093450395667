import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export const remoteTranslationMap = {
  ex1000_title: marker('keys.local.errors.ex1000.title'),
  ex1000_message: marker('keys.local.errors.ex1000.message'),
  ex2000_title: marker('keys.local.errors.ex2000.title'),
  ex2000_message: marker('keys.local.errors.ex2000.message'),
  ex2010_title: marker('keys.local.errors.ex2010.title'),
  ex2010_message: marker('keys.local.errors.ex2010.message'),
  ex2020_title: marker('keys.local.errors.ex2020.title'),
  ex2020_message: marker('keys.local.errors.ex2020.message'),
  ex2030_title: marker('keys.local.errors.ex2030.title'),
  ex2030_message: marker('keys.local.errors.ex2030.message'),
  ex2040_title: marker('keys.local.errors.ex2040.title'),
  ex2040_message: marker('keys.local.errors.ex2040.message'),
  ex2050_title: marker('keys.local.errors.ex2050.title'),
  ex2050_message: marker('keys.local.errors.ex2050.message'),
  ex2060_title: marker('keys.local.errors.ex2060.title'),
  ex2060_message: marker('keys.local.errors.ex2060.message'),
  ex2070_title: marker('keys.local.errors.ex2070.title'),
  ex2070_message: marker('keys.local.errors.ex2070.message'),
  ex2080_title: marker('keys.local.errors.ex2080.title'),
  ex2080_message: marker('keys.local.errors.ex2080.message'),
  ex2090_title: marker('keys.local.errors.ex2090.title'),
  ex2090_message: marker('keys.local.errors.ex2090.message'),
  ex2100_title: marker('keys.local.errors.ex2100.title'),
  ex2100_message: marker('keys.local.errors.ex2100.message'),
  ex2110_title: marker('keys.local.errors.ex2110.title'),
  ex2110_message: marker('keys.local.errors.ex2110.message'),
  ex2120_title: marker('keys.local.errors.ex2120.title'),
  ex2120_message: marker('keys.local.errors.ex2120.message'),
  ex2130_title: marker('keys.local.errors.ex2130.title'),
  ex2130_message: marker('keys.local.errors.ex2130.message'),
  ex2140_title: marker('keys.local.errors.ex2140.title'),
  ex2140_message: marker('keys.local.errors.ex2140.message'),
  ex2150_title: marker('keys.local.errors.ex2150.title'),
  ex2150_message: marker('keys.local.errors.ex2150.message'),
  ex2160_title: marker('keys.local.errors.ex2160.title'),
  ex2160_message: marker('keys.local.errors.ex2160.message'),
  ex2170_title: marker('keys.local.errors.ex2170.title'),
  ex2170_message: marker('keys.local.errors.ex2170.message'),
  ex2180_title: marker('keys.local.errors.ex2180.title'),
  ex2180_message: marker('keys.local.errors.ex2180.message'),
  ex2190_title: marker('keys.local.errors.ex2190.title'),
  ex2190_message: marker('keys.local.errors.ex2190.message'),
  ex2200_title: marker('keys.local.errors.ex2200.title'),
  ex2200_message: marker('keys.local.errors.ex2200.message'),
  ex2210_title: marker('keys.local.errors.ex2210.title'),
  ex2210_message: marker('keys.local.errors.ex2210.message'),
  ex2220_title: marker('keys.local.errors.ex2220.title'),
  ex2220_message: marker('keys.local.errors.ex2220.message'),
  ex2230_title: marker('keys.local.errors.ex2230.title'),
  ex2230_message: marker('keys.local.errors.ex2230.message'),
  ex2240_title: marker('keys.local.errors.ex2240.title'),
  ex2240_message: marker('keys.local.errors.ex2240.message'),
  ex2250_title: marker('keys.local.errors.ex2250.title'),
  ex2250_message: marker('keys.local.errors.ex2250.message'),
  ex2260_title: marker('keys.local.errors.ex2260.title'),
  ex2260_message: marker('keys.local.errors.ex2260.message'),
  ex2270_title: marker('keys.local.errors.ex2270.title'),
  ex2270_message: marker('keys.local.errors.ex2270.message'),
  ex2280_title: marker('keys.local.errors.ex2280.title'),
  ex2280_message: marker('keys.local.errors.ex2280.message'),
  ex2290_title: marker('keys.local.errors.ex2290.title'),
  ex2290_message: marker('keys.local.errors.ex2290.message'),
  ex2300_title: marker('keys.local.errors.ex2300.title'),
  ex2300_message: marker('keys.local.errors.ex2300.message'),
  ex2310_title: marker('keys.local.errors.ex2310.title'),
  ex2310_message: marker('keys.local.errors.ex2310.message'),
  ex2320_title: marker('keys.local.errors.ex2320.title'),
  ex2320_message: marker('keys.local.errors.ex2320.message'),
  ex2330_title: marker('keys.local.errors.ex2330.title'),
  ex2330_message: marker('keys.local.errors.ex2330.message'),

  dealer: marker('keys.remote.usage.dealer'),
  owner: marker('keys.remote.usage.owner'),
  service: marker('keys.remote.usage.service'),
  undefined: marker('keys.remote.usage.undefined'),

  'status.mat_disposed': marker('keys.remote.status.mat_disposed'),
  'status.mat_expired': marker('keys.remote.status.mat_expired'),
  'status.mat_blocked': marker('keys.remote.status.mat_blocked'),
  'status.mat_overdue': marker('keys.remote.status.mat_overdue'),
  'status.mat_due': marker('keys.remote.status.mat_due'),
  'status.mat_open': marker('keys.remote.status.mat_open'),
  'status.mat_checked': marker('keys.remote.status.mat_checked'),
  'status.mat_new': marker('keys.remote.status.mat_new'),
  'status.doc_open': marker('keys.remote.status.doc_open'),
  'status.doc_checked': marker('keys.remote.status.doc_checked'),
  'status.doc_failed': marker('keys.remote.status.doc_failed'),
  'status.training_valid': marker('keys.remote.status.training_valid'),
  'status.training_invalid': marker('keys.remote.status.training_invalid'),

  'document.group_title_information': marker('keys.remote.document.group_title_information'),
  'document.group_title_exam_data': marker('keys.remote.document.group_title_exam_data'),
  'document.group_title_comment': marker('keys.remote.document.group_title_comment'),

  'status.active': marker('keys.local.status.active'),
  'status.inactive': marker('keys.local.status.inactive'),

  'keys.remote.message.generated_serialnumber': marker('keys.remote.messages.generated_serialnumber'),

  'Read-Only': marker('keys.remote.roles.read_only'),
  'PSA-Anwender': marker('keys.remote.roles.user'),
  Administrator: marker('keys.remote.roles.admin'),
  'Super-Administrator': marker('keys.remote.roles.super_user'),
  Servicestelle: marker('keys.remote.roles.service')
};


