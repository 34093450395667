import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {USER_FEATURE_KEY, userAdapter, UserPartialState, UserState} from './user.reducer';
import {UserEntity, userFilterProperties, UserViewModel, userViewModelFactory} from './user.models';
import {isNullOrUndefined, objectFilter} from '../../../../../../core/utils';
import {Dictionary} from '@ngrx/entity';
import {getShowInactive} from '../../../app-store/src/+state/app.selectors';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';
import {subject} from '@casl/ability';

const getUserState = createFeatureSelector<UserPartialState, UserState>(USER_FEATURE_KEY);

const {selectAll, selectEntities} = userAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getUserState,
  (state: UserState) => state.isLoading
);

const isSearching: MemoizedSelector<object, boolean> = createSelector(
  getUserState,
  (state: UserState) => state.isSearching
);

const getSearch: MemoizedSelector<object, string> = createSelector(
  getUserState,
  (state: UserState) => state.search
);

const getException = createSelector(
  getUserState,
  (state: UserState) => state.exception
);

const getUserId: MemoizedSelector<object, number> = createSelector(
  getUserState,
  (state: UserState) => state.userId
);

const getAllItems: MemoizedSelector<object, UserViewModel[]> = createSelector(
  getUserState,
  (state: UserState) => selectAll(state).map(model => userViewModelFactory(model))
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getItems: MemoizedSelector<object, UserViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getRepositoryIdParam,
  RouterSelectors.getFilterQueryParam,
  getShowInactive,
  (items, repositoryId, filter, showInactive) => items
    .filter(item => item.repositoryId === repositoryId)
    .filter(item => !filter || (filter && (objectFilter(item, userFilterProperties, filter))))
    .filter(item => showInactive || item.isActive)
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getRows = createSelector(
  getUserState,
  (state: UserState) => state.rows
);


const getUserEntities: MemoizedSelector<object, Dictionary<UserEntity>> = createSelector(
  getUserState,
  (state: UserState) => selectEntities(state)
);

const getSelected: MemoizedSelector<object, UserViewModel> = createSelector(
  getUserEntities,
  RouterSelectors.getUserIdParam,
  (entities, selected) => selected && entities[selected] && userViewModelFactory(entities[selected])
);

const selectWithFilter = createSelector(
  getItems,
  (items, search) => items.filter(item => !search || (search && (objectFilter(item, userFilterProperties, search))))
);

const getLoggedIn = createSelector(
  getUserEntities,
  getUserId,
  (entities, id) => id && entities[id] && userViewModelFactory(entities[id])
);


const getPossibleOwners: MemoizedSelector<object, UserViewModel[]> = createSelector(
  getAllItems,
  (items) => items
    .filter(item => item.possibleAccountOwner && item.enabled)
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getContext = createSelector(
  getSelected,
  getLoggedIn,
  (selected, loggedIn) => !!selected && subject('user', {isOwner: selected.id === loggedIn.id})
);

const getItemsWithProps = createSelector(
  getAllItems,
  (items, props: { search?: string, repositoryId?: number }) => items
    .filter(item => isNullOrUndefined(props.search) || (props.search && (objectFilter(item, userFilterProperties, props.search))))
    .filter(item => isNullOrUndefined(props.repositoryId) || (props.repositoryId && item.parentId === props.repositoryId))
    .sort((a, b) => a.name.localeCompare(b.name))
);

export const UserSelectors = {
  isLoading,
  isSearching,
  getSearch,
  getException,
  getUserId,
  getAllItems,
  getItems,
  getRows,
  getUserEntities,
  getSelected,
  selectWithFilter,
  getLoggedIn,
  getPossibleOwners,
  getContext,
  getItemsWithProps
};
