import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import * as fromProduct from './+state/product.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ProductEffects} from './+state/product.effects';
import {StoreModule} from '@ngrx/store';
import {ProductDataService} from './+state/product-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromProduct.PRODUCT_FEATURE_KEY,
      fromProduct.reducer
    ),
    EffectsModule.forFeature([ProductEffects])
  ],
  providers: [
    ProductDataService,
  ]
})
export class ProductStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
