import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {RepositoryDataService} from './repository-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Update} from '@ngrx/entity';
import {RepositoryEntity} from './repository.models';
import {Exception} from '../../../../../../core/models';
import {AttachmentActions} from '../../../attachment-store/src/+state/attachment.actions';
import {RepositoryActions} from './repository.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {MediaType} from '../../../attachment-store';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


@Injectable()
export class RepositoryEffects {

  loadRepositoriesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.loadRepositoriesRequest),
      switchMap(action => this.repositoryDataService.load(action.request).pipe(
        map(payload => RepositoryActions.loadRepositoriesSuccess({payload})),
        catchError(error => of(RepositoryActions.loadRepositoriesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadRepositoriesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.loadRepositoriesSuccess),
    ), {dispatch: false}
  );

  loadRepositoriesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.loadRepositoriesFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getRepositoryRowsRequest = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.getRepositoryRowsRequest),
      switchMap(action => this.repositoryDataService.getRows(action.request).pipe(
        map(payload => RepositoryActions.getRepositoryRowsSuccess({payload})),
        catchError(error => of(RepositoryActions.getRepositoryRowsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getRepositoryRowsSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.getRepositoryRowsSuccess),
    ), {dispatch: false}
  );
  getRepositoryRowsFailure = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.getRepositoryRowsFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  getRepositoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.getRepositoryRequest),
      switchMap(action => this.repositoryDataService.get(action.request.repositoryId, action.request.queryParams).pipe(
        map(payload => RepositoryActions.getRepositorySuccess({payload})),
        catchError(error => of(RepositoryActions.getRepositoryFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.getRepositorySuccess),
    ), {dispatch: false}
  );
  getRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.getRepositoryFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  createRepositoryRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(RepositoryActions.createRepositoryRequest),
      switchMap(action => this.repositoryDataService.create(action.request).pipe(
        map(payload => RepositoryActions.createRepositorySuccess({payload})),
        catchError(error => of(RepositoryActions.createRepositoryFailure({exception: new Exception(error, action)})))
      ))
    )
  );
  createRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.createRepositorySuccess),
    ), {dispatch: false}
  );
  createRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.createRepositoryFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  persistRepositoryRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(RepositoryActions.persistRepositoryRequest),
      switchMap(action => this.repositoryDataService.persist(action.repository).pipe(
        map(payload => RepositoryActions.persistRepositorySuccess({payload})),
        catchError(error => of(RepositoryActions.persistRepositoryFailure({exception: new Exception(error, action)})))
      ))
    )
  );
  persistRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.persistRepositorySuccess),
      tap(action => this.logger.trace('persistRepositorySuccess ', action)),
      map(() => this.messageService.openV2(marker('keys.local.messages.successful_persisted'))),
    ), {dispatch: false}
  );
  persistRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.persistRepositoryFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  moveRepositoryRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(RepositoryActions.moveRepositoryRequest),
      switchMap(action => this.repositoryDataService.move(action.repository, action.destination).pipe(
        map(payload => RepositoryActions.moveRepositorySuccess({payload})),
        catchError(error => of(RepositoryActions.moveRepositoryFailure({exception: new Exception(error, action)})))
      ))
    )
  );
  moveRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.moveRepositorySuccess),
    ), {dispatch: false}
  );
  moveRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.moveRepositoryFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  deleteRepositoryRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(RepositoryActions.deleteRepositoryRequest),
      switchMap(action => this.repositoryDataService.delete(action.repository).pipe(
        map(payload => RepositoryActions.deleteRepositorySuccess({payload})),
        catchError(error => of(RepositoryActions.deleteRepositoryFailure({exception: new Exception(error, action)})))
      ))
    )
  );
  deleteRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.deleteRepositorySuccess),
    ), {dispatch: false}
  );
  deleteRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.deleteRepositoryFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  shareRepositoryRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(RepositoryActions.shareRepositoryRequest),
      switchMap((action) => this.repositoryDataService.share(action.repository, action.target).pipe(
        map(update => RepositoryActions.shareRepositorySuccess({update})),
        catchError(error => of(RepositoryActions.deleteShareRepositoryFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  shareRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.shareRepositorySuccess),
    ), {dispatch: false}
  );

  shareRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.shareRepositoryFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  deleteShareRepositoryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.deleteShareRepositoryRequest),
      switchMap((action) => this.repositoryDataService.unShare(action.repository, action.target).pipe(
        map(update => RepositoryActions.deleteShareRepositorySuccess({update})),
        catchError(error => of(RepositoryActions.deleteShareRepositoryFailure({exception: new Exception(error, action)})))
      ))
    )
  );
  deleteShareRepositorySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.deleteShareRepositorySuccess),
    ), {dispatch: false}
  );
  deleteShareRepositoryFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.deleteShareRepositoryFailure),
    ), {dispatch: false}
  );
  setNotificationRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(RepositoryActions.setNotificationRequest),

      switchMap(action => this.repositoryDataService.notify(action.repository, action.notify).pipe(
        map(update => RepositoryActions.setNotificationSuccess({update})),
        catchError(error => of(RepositoryActions.setNotificationFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  setNotificationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.setNotificationSuccess),
    ), {dispatch: false}
  );

  setNotificationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.setNotificationFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  setRepositoryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.setRepositoryId),

      tap(action => this.repositoryDataService.setRepositoryId(action.repositoryId))
    ), {dispatch: false}
  );

  removeRepositoryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RepositoryActions.removeRepositoryId, AuthenticationActions.clearSession),
      tap(() => this.repositoryDataService.removeRepositoryId())
    ), {dispatch: false}
  );

  updateRepositoryImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentSuccess),
      map(action => action.payload.item),
      filter(attachment => attachment.mediaType === MediaType.REPOSITORY_IMAGE),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: attachment.id}} as Update<RepositoryEntity>)),
      map(update => RepositoryActions.updateRepository({update}))
    )
  );

  removeRepositoryImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentSuccess),
      map(action => action.payload),
      filter(attachment => attachment.mediaType === MediaType.REPOSITORY_IMAGE),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: null}} as Update<RepositoryEntity>)),
      map(update => RepositoryActions.updateRepository({update}))
    )
  );

  constructor(
    private actions$: Actions,
    private repositoryDataService: RepositoryDataService,
    private messageService: MessageService,
    private hintDialogService: HintDialogService,
    private logger: NGXLogger) {

  }
}
