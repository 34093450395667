<mat-list-item (click)="open()" class="list-item" mat-ripple>
  <hb-avatar [fallback]="['fas', 'file']" height="40" mat-list-avatar width="40"></hb-avatar>
  <span mat-line>{{viewModel.title}}</span>
  <span mat-line>{{viewModel.date | date: 'mediumDate' : timeZone : (locale$ | async)}}</span>
  <span mat-line></span>

  <button (click)="$event.stopPropagation()" *ngIf="buttons.status" mat-icon-button>
    <hb-status [status]="viewModel.status"></hb-status>
  </button>
  <button (click)="$event.stopPropagation()" *ngIf="buttons.context"
          [matMenuTriggerFor]="contextMenu"
          mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</mat-list-item>

<mat-menu #contextMenu="matMenu">
  <button (click)="edit()" [disabled]="('read' | able: 'document') || disabled" mat-menu-item>
    <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
    <span>{{'context.document.action.open' | translate}}</span>
  </button>
  <button (click)="downloadPDF()" [disabled]="!viewModel.hasPdf && ('read' | able: 'document')" mat-menu-item>
    <mat-icon fontIcon="fa-download" fontSet="fas"></mat-icon>
    <span>{{'context.document.action.download_pdf' | translate}}</span>
  </button>
  <button (click)="downloadLabel()" [disabled]="!viewModel.hasLabelPdf && ('read' | able: 'document')" mat-menu-item>
    <mat-icon fontIcon="fa-download" fontSet="fas"></mat-icon>
    <span>{{'context.document.action.download_label' | translate}}</span>
  </button>
  <button (click)="delete()" [disabled]="viewModel.readOnly && ('delete' | able: 'document') || disabled" mat-menu-item>
    <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>
    <span>{{'context.document.action.delete' | translate}}</span>
  </button>
</mat-menu>
