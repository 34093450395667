<h2 mat-dialog-title>{{'dialogs.document_sealed.title' | translate}}</h2>
<mat-dialog-content>
  <p>{{'dialogs.document_sealed.message' | translate}}</p>
  <form #ngForm="ngForm" fxLayout="column nowrap" ngForm>
    <mat-form-field *ngIf="sendViaEmail.value" appearance="outline">
      <mat-label>
        <label for="email">{{'forms.fields.email.label' | translate}}</label>
      </mat-label>
      <input #email="ngModel"
             [(ngModel)]="viewModel.email"
             [placeholder]="'forms.fields.email.placeholder' | translate"
             email
             id="email"
             matInput
             name="email" required>
      <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
      <mat-error *ngIf="email.dirty && email.touched">
        <ng-container *ngIf="email.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="email.hasError('email')">
          {{'forms.errors.invalid_email' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>
    <mat-checkbox #sendViaEmail="ngModel" [(ngModel)]="viewModel.sendViaEmail" name="sendViaEmail">
      {{'forms.fields.send_document_via_email.label' | translate}}
    </mat-checkbox>
    <mat-checkbox #addAttachmentToPDF="ngModel" [(ngModel)]="viewModel.pdfIncludeAttachments" name="addAttachmentToPDF">
      {{'forms.fields.add_attachment_to_document.label' | translate}}
    </mat-checkbox>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="(ngForm.invalid && sendViaEmail.value)" [matDialogClose]="result" cdkFocusInitial
          color="accent" mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-raised-button matDialogClose>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
