import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, Validators} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {CategoryService, DetailCategoryService, ManufacturerService, ProductService} from '../../../modules/store';

@Component({
  selector: 'hb-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.scss']
})
export class CreateProductComponent implements OnInit, OnDestroy {

  public manufacturers$ = this.manufacturerService.items$;
  public categories$ = this.categoryService.items$;
  public detailCategories$ = this.detailCategoryService.items$;

  public productForm = this.formBuilder.group({
    id: this.formBuilder.control('0'),
    title: this.formBuilder.control('', {validators: Validators.required}),
    articleNumber: this.formBuilder.control('', {validators: Validators.required}),
    ean: this.formBuilder.control('', {validators: [Validators.maxLength(13), Validators.pattern('^[0-9]*$')]}),
    strategy: this.formBuilder.control('DefaultStrategyService'),
    manufacturer: this.formBuilder.group({
      id: this.formBuilder.control(null, {validators: Validators.required}),
      caption: this.formBuilder.control('')
    }, {validators: (group) => this.selectedValidator(group, 'id', 'caption')}),
    categories: this.formBuilder.array([
      this.formBuilder.group({
        id: this.formBuilder.control(null, {validators: Validators.required}),
        title: this.formBuilder.control(''),
        type: this.formBuilder.control('ProductGroupCategory')
      }, {validators: (group) => this.selectedValidator(group, 'id', 'title')}),
      this.formBuilder.group({
        id: this.formBuilder.control(null),
        title: this.formBuilder.control(''),
        type: this.formBuilder.control('ProductGroupDetailCategory')
      }, {validators: (group) => this.selectedValidator(group, 'id', 'title')}),
    ])
  });
  private preDestroy = new Subject<boolean>();

  constructor(public productService: ProductService,
              private manufacturerService: ManufacturerService,
              private categoryService: CategoryService,
              private detailCategoryService: DetailCategoryService,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<CreateProductComponent>,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.manufacturerService.load();
    this.categoryService.load();
    this.detailCategoryService.load();
  }

  check(form: AbstractControl, controlName: string, errorFlag: string): boolean {
    let hasFlag = false;
    const errors = form.get(controlName).errors;
    if (errors != null) {
      for (const key of Object.keys(errors)) {
        if (key.includes(errorFlag)) {
          hasFlag = errors[key] != null;
        }
      }
    }
    return hasFlag;
  }

  selectedValidator(group: AbstractControl, first: string, second: string) {
    if (group.get(first).value === '0' && group.get(second).value.length === 0) {
      return {selected: true, requiredValue: `${second} is required`};
    }
    return null;
  }

  commit() {
    if (this.productForm.valid) {
      this.productService.persist(this.productForm.value).subscribe(result => {
        this.dialogRef.close(result);
      });
    }
  }

  ngOnDestroy(): void {
    this.preDestroy.next(true);
    this.preDestroy.complete();
  }

}
