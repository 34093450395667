import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {CountryListPayload, CountryListResponse} from './country.models';
import {environment} from '../../../../../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class CountryDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(): Observable<CountryListPayload> {
    return this.http.get<CountryListResponse>(`${environment.api}/countries`).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }
}
