import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {QueryParams} from '@ngrx/data';
import {filter} from 'rxjs/operators';
import {Params} from '@angular/router';
import {Update} from '@ngrx/entity';
import {
  AttachmentService,
  CountryService,
  DocumentEntity,
  DocumentService,
  DocumentViewModel,
  LanguageService,
  PathService,
  PermissionService
} from '../../../modules/store';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class DocumentViewDataSource implements OnDestroy {

  private subscription: Subscription = new Subscription();
  private _currentDate: Date;

  constructor(private documentService: DocumentService,
              private attachmentService: AttachmentService,
              private pathService: PathService,
              private languageService: LanguageService,
              private countryService: CountryService,
              private permissionService: PermissionService,
              private logger: NGXLogger) {
    this.subscription.add(this.documentService.selected$.subscribe(vm => this._viewModel = vm));
    this._currentDate = new Date(Date.now());
  }

  private _viewModel: DocumentViewModel;

  get viewModel() {
    return this._viewModel;
  }

  get isLoading$(): Observable<boolean> {
    return this.documentService.isLoading$;
  }

  get path$() {
    return this.pathService.items$;
  }

  get countries$() {
    return this.countryService.items$;
  }

  get attachments$() {
    return this.attachmentService.documentItems$;
  }

  get locale$() {
    return this.languageService.selected$;
  }

  load(request: { repositoryId: number, materialId: number, documentId: number, queryParams: QueryParams }) {
    this.pathService.load({...request, type: 'DOCUMENT'});
    this.documentService.get(request.repositoryId, request.materialId, request.documentId);
    this.attachmentService.load(request);
  }

  save() {
    return this.documentService.persist(this.viewModel.updated).pipe(
      filter(result => !!result)
    );
  }

  seal(queryParams: Params) {
    return this.documentService.persist(this.viewModel.updated, queryParams).pipe(
      filter(result => !!result),
    );
  }

  download(type) {
    this.documentService.download(this.viewModel.model, type);
  }

  update(update: Update<DocumentEntity>) {
    this.documentService.update(update);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
