<h2 mat-dialog-title>{{'dialogs.select_image.title' | translate}}</h2>
<mat-dialog-content fxLayout="column nowrap">

  <image-cropper (imageCropped)="imageCropped($event)"
                 [imageBase64]="image"
                 [imageQuality]="environment.image_uploads.compressFactor * 100"
                 [transform]=""
                 alignImage="center"
                 backgroundColor="white"
                 containWithinAspectRatio="false"
                 format="jpeg"
                 resizeToHeight="600"
                 resizeToWidth="600">
  </image-cropper>


</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [mat-dialog-close]="request" color="accent" mat-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-button mat-dialog-close>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
