import {Injectable} from '@angular/core';
import {CameraSearchDialogService} from '../../dialogs/camera-search/src/camera-search-dialog.service';
import {searchEntityFactory, SearchService} from '../../modules/store';
import {DomainCommand} from '../../modules/command-bus/command';
import {CommandHandler} from '../../modules/command-bus/command.handler';

@Injectable()
export class SearchWithCameraScanCommandHandler implements CommandHandler {

  constructor(private cameraSearchDialogService: CameraSearchDialogService,
              private searchService: SearchService) {
  }

  handle(command: DomainCommand): void {
    this.cameraSearchDialogService.open()
      .afterClosed()
      .subscribe(search =>
        this.searchService.update(command.payload.id, searchEntityFactory(command.payload.id, command.payload.timestamp, search, 0)));
  }
}
