<h2 mat-dialog-title>{{'dialogs.privacy.title' | translate}}</h2>
<mat-dialog-content fxLayout="column nowrap">
  <div [innerHTML]="'dialogs.privacy.content' | translate">

  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.close' | translate}}</button>
</mat-dialog-actions>
