import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MaterialDataSource} from '../../../modules/store/src/modules/material-store/src/+state/material.data-source';
import {
  CreateMaterialRequest,
  CreateRepositoryRequest,
  MaterialViewModel,
  PathService,
  RepositoryService,
  RepositoryViewModel
} from '../../../modules/store';
import {environment} from '../../../../environments';


@Injectable({
  providedIn: 'root'
})
export class PlacesViewDataSource {

  private _sharedPath: boolean;

  constructor(private repositoryService: RepositoryService,
              private materialDataSource: MaterialDataSource,
              private pathService: PathService,
              private logger: NGXLogger) {
  }

  get isLoading$(): Observable<boolean> {
    return combineLatest([
      this.repositoryService.isLoading$,
      this.materialDataSource.isLoading$
    ]).pipe(map(([a, b]) => a || b));
  }

  get items$() {
    return combineLatest([
      this.repositoryService.items$,
      this.materialDataSource.items$,
    ]).pipe(
      map(([a, b]) => [...a, ...b])
    );
  }

  get rows$(): Observable<number> {
    return combineLatest([
      this.repositoryService.rows$,
      this.materialDataSource.rows$
    ]).pipe(map(([a, b]) => a + b));
  }

  get path$() {
    return this.pathService.items$;
  }

  get sharedPath() {
    return this._sharedPath;
  }

  load(repositoryId: number, page: number, archive: boolean = false, search?: string) {

    this.pathService.load({repositoryId, type: 'REPOSITORY'});

    this.repositoryService.get(repositoryId).pipe(
      map(payload => payload.item.sharedPath),
    ).subscribe(sharedPath => this._sharedPath = sharedPath);

    this.repositoryService.getRows({repositoryId, archive, search}).toPromise().then(rows => {
      const pageOffset = Math.floor(rows / environment.defaultPageSize);

      if (page <= pageOffset) {
        this.repositoryService.load({repositoryId, page, archive, search}).toPromise().then(r => {
        });
      }
      if (page >= pageOffset) {
        this.materialDataSource.load({repositoryId, page: page - pageOffset, archive, search}).toPromise().then();
      }
    });
  }

  createNewMaterial(request: CreateMaterialRequest): Observable<MaterialViewModel> {
    return this.materialDataSource.create(request);
  }

  persistMaterial(material: MaterialViewModel) {
    return this.materialDataSource.persist(material);
  }

  createNewRepository(request: CreateRepositoryRequest) {
    return this.repositoryService.create(request);
  }

  async move(items: Array<RepositoryViewModel | MaterialViewModel>, destination: number) {
    for await (const item of items) {
      if (item.type === 'RepositoryViewModel') {
        await this.repositoryService.move(item, destination).toPromise();
      } else if (item.type === 'MaterialViewModel') {
        await this.materialDataSource.move(item, destination).toPromise();
      }
    }
    return destination;
  }

  async delete(items: Array<RepositoryViewModel | MaterialViewModel>) {
    for await (const item of items) {
      if (item.type === 'RepositoryViewModel') {
        await this.repositoryService.delete(item).toPromise();
      } else if (item.type === 'MaterialViewModel') {
        await this.materialDataSource.delete(item).toPromise();
      }
    }
  }
}
