import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService, RouterService, UserService, UserViewModel} from '../../../modules/store';

interface ButtonConfiguration {
  context?: boolean;
}

@Component({
  selector: 'hb-logged-in-user-item-view',
  templateUrl: './logged-in-user-item-view.component.html',
  styleUrls: ['./logged-in-user-item-view.component.css']
})
export class LoggedInUserItemViewComponent implements OnInit, OnDestroy {

  @Input()
  public viewModel: UserViewModel;

  constructor(private authenticationService: AuthenticationService,
              private routerService: RouterService,
              private userService: UserService,
              private logger: NGXLogger) {

  }

  private _buttons: ButtonConfiguration = {context: false};

  public get buttons(): ButtonConfiguration {
    return this._buttons;
  }

  @Input()
  public set buttons(buttons: ButtonConfiguration) { this._buttons = {...this._buttons, ...buttons}; }

  ngOnInit(): void {
  }

  public open($event) {
    this.routerService.navigateToUserView(this.viewModel.repositoryId, this.viewModel.id);
  }

  public logout($event) {
    this.authenticationService.logout();
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }

}
