<div *ngIf="data.prompt === 'android-install'" fxLayout="column nowrap" fxLayoutGap="15px">
  <h3>{{'dialogs.install_android.title' | translate}}</h3>
  <div>
    <span>{{'dialogs.install_android.description.line_1' | translate}}</span>
    <p>{{'dialogs.install_android.description.line_2' | translate}}</p>
  </div>
  <div fxLayout="row nowrap" fxLayoutAlign="space-between">
    <button (click)="accept()" color="primary" mat-raised-button>
      <span>{{'keys.local.global.yes' | translate}}</span>
    </button>
    <button (click)="close()" mat-stroked-button>
      <span>{{'keys.local.global.no' | translate}}</span>
    </button>
  </div>
</div>

<div *ngIf="data.prompt === 'ios-install'" fxLayout="column nowrap" fxLayoutGap="15px">
  <h3>{{'dialogs.install_ios.title' | translate}}</h3>
  <div>
    <span>{{'dialogs.install_ios.description.line_1' | translate}}</span>
    <p>{{'dialogs.install_ios.description.line_2' | translate}}</p>
  </div>
  <div fxLayout="row nowrap" fxLayoutAlign="space-between">
    <div>
      <img alt="manual" src="../../../../../../assets/images/ios/ios-manual.png"/>
    </div>
    <button (click)="close()" mat-stroked-button>
      <span>{{'keys.local.global.close' | translate}}</span>
    </button>
  </div>
</div>

<div *ngIf="data.prompt === 'web-install'" fxLayout="column nowrap" fxLayoutGap="15px">
  <h3>{{'dialogs.install_web.title' | translate}}</h3>
  <div>
    <span>{{'dialogs.install_web.description.line_1' | translate}}</span>
    <p>{{'dialogs.install_web.description.line_2' | translate}}</p>
  </div>
  <div fxLayout="row nowrap" fxLayoutAlign="space-between">
    <button (click)="accept()" color="primary" mat-raised-button>
      <span>{{'keys.local.global.yes' | translate}}</span>
    </button>
    <button (click)="close()" mat-stroked-button>
      <span>{{'keys.local.global.no' | translate}}</span>
    </button>
  </div>
</div>

<div *ngIf="data.prompt === 'update'" fxLayout="column nowrap" fxLayoutGap="15px">
  <h3>{{'dialogs.update.title' | translate}}</h3>
  <div>
    <span>{{'dialogs.update.description.line_1' | translate}}</span>
    <p>{{'dialogs.update.description.line_2' | translate}}</p>
  </div>
  <div fxLayout="row nowrap" fxLayoutAlign="space-between">
    <button (click)="accept()" color="primary" mat-raised-button>
      <span>{{'keys.local.global.update' | translate}}</span>
    </button>
    <button (click)="close()" mat-stroked-button>
      <span>{{'keys.local.global.close' | translate}}</span>
    </button>
  </div>
</div>
