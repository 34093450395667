import {createAction, props} from '@ngrx/store';
import {
  CreateUserPayload,
  CreateUserRequest,
  GetLoggedInUserPayload,
  GetLoggedInUserRequest,
  GetUserPayload,
  GetUserRequest,
  LoadAccountUserListPayload,
  LoadAccountUserListRequest,
  LoadUserRowsPayload,
  LoadUserRowsRequest,
  LoadUsersPayload,
  LoadUsersRequest,
  PersistUserPayload,
  PersistUserRequest,
  RemoveUserPayload,
  RemoveUserRequest,
  UpdateUserPayload,
  UpdateUserRequest,
  UserEntity
} from './user.models';
import {Update} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';


enum UserActionTypes {
  LOAD_USERS_REQUEST = '[User] Load Users Request',
  LOAD_USERS_SUCCESS = '[User] Load Users Success',
  LOAD_USERS_FAILURE = '[User] Load Users Failure',

  LOAD_USER_ROWS_REQUEST = '[User] Load User Rows Request',
  LOAD_USER_ROWS_SUCCESS = '[User] Load User Rows Success',
  LOAD_USER_ROWS_FAILURE = '[User] Load User Rows Failure',

  CREATE_USER_REQUEST = '[User] Create User Request',
  CREATE_USER_SUCCESS = '[User] Create User Success',
  CREATE_USER_FAILURE = '[User] Create User Failure',

  GET_USER_REQUEST = '[User] Get User Request',
  GET_USER_SUCCESS = '[User] Get User Success',
  GET_USER_FAILURE = '[User] Get User Failure',

  UPDATE_USER_REQUEST = '[User] Update User Request',
  UPDATE_USER_SUCCESS = '[User] Update User Success',
  UPDATE_USER_FAILURE = '[User] Update User Failure',

  PERSIST_USER_REQUEST = '[User] Persist User Request',
  PERSIST_USER_SUCCESS = '[User] Persist User Success',
  PERSIST_USER_FAILURE = '[User] Persist User Failure',

  DELETE_USER_REQUEST = '[User] Delete User Request',
  DELETE_USER_SUCCESS = '[User] Delete User Success',
  DELETE_USER_FAILURE = '[User] Delete User Failure',

  GET_LOGGED_IN_USER_REQUEST = '[User] Get Logged In User Request',
  GET_LOGGED_IN_USER_SUCCESS = '[User] Get Logged In User Success',
  GET_LOGGED_IN_USER_FAILURE = '[User] Get Logged In User Failure',

  LOAD_ACCOUNT_USER_LIST_REQUEST = '[User] Load Account User List Request',
  LOAD_ACCOUNT_USER_LIST_SUCCESS = '[User] Load Account User List Success',
  LOAD_ACCOUNT_USER_LIST_FAILURE = '[User] Load Account User List Failure',

  UPDATE_USER = '[User] Update User',

  SET_USER_ID = '[User] Set User Id',
  REMOVE_USER_ID = '[User] Remove User Id',

  RESET_USERS = '[User] Reset Users',
}

const loadUsersRequest = createAction(
  UserActionTypes.LOAD_USERS_REQUEST,
  props<{ request: LoadUsersRequest }>()
);

const loadUsersSuccess = createAction(
  UserActionTypes.LOAD_USERS_SUCCESS,
  props<{ payload: LoadUsersPayload }>()
);

const loadUsersFailure = createAction(
  UserActionTypes.LOAD_USERS_FAILURE,
  props<{ exception: Exception }>()
);

const loadUserRowsRequest = createAction(
  UserActionTypes.LOAD_USER_ROWS_REQUEST,
  props<{ request: LoadUserRowsRequest }>()
);

const loadUserRowsSuccess = createAction(
  UserActionTypes.LOAD_USER_ROWS_SUCCESS,
  props<{ payload: LoadUserRowsPayload }>()
);

const loadUserRowsFailure = createAction(
  UserActionTypes.LOAD_USER_ROWS_FAILURE,
  props<{ exception: Exception }>()
);

const getUserRequest = createAction(
  UserActionTypes.GET_USER_REQUEST,
  props<{ request: GetUserRequest }>()
);

const getUserSuccess = createAction(
  UserActionTypes.GET_USER_SUCCESS,
  props<{ payload: GetUserPayload }>()
);

const getUserFailure = createAction(
  UserActionTypes.GET_USER_FAILURE,
  props<{ exception: Exception }>()
);

const createUserRequest = createAction(
  UserActionTypes.CREATE_USER_REQUEST,
  props<{ request: CreateUserRequest }>()
);

const createUserSuccess = createAction(
  UserActionTypes.CREATE_USER_SUCCESS,
  props<{ payload: CreateUserPayload }>()
);

const createUserFailure = createAction(
  UserActionTypes.CREATE_USER_FAILURE,
  props<{ exception: Exception }>()
);

const updateUserRequest = createAction(
  UserActionTypes.UPDATE_USER_REQUEST,
  props<{ request: UpdateUserRequest }>()
);

const updateUserSuccess = createAction(
  UserActionTypes.UPDATE_USER_SUCCESS,
  props<{ payload: UpdateUserPayload }>()
);

const updateUserFailure = createAction(
  UserActionTypes.UPDATE_USER_FAILURE,
  props<{ exception: Exception }>()
);

const persistUserRequest = createAction(
  UserActionTypes.PERSIST_USER_REQUEST,
  props<{ request: PersistUserRequest }>()
);

const persistUserSuccess = createAction(
  UserActionTypes.PERSIST_USER_SUCCESS,
  props<{ payload: PersistUserPayload }>()
);

const persistUserFailure = createAction(
  UserActionTypes.PERSIST_USER_FAILURE,
  props<{ exception: Exception }>()
);

const deleteUserRequest = createAction(
  UserActionTypes.DELETE_USER_REQUEST,
  props<{ request: RemoveUserRequest }>()
);

const deleteUserSuccess = createAction(
  UserActionTypes.DELETE_USER_SUCCESS,
  props<{ payload: RemoveUserPayload }>()
);

const deleteUserFailure = createAction(
  UserActionTypes.DELETE_USER_FAILURE,
  props<{ exception: Exception }>()
);

const getLoggedInUserRequest = createAction(
  UserActionTypes.GET_LOGGED_IN_USER_REQUEST,
  props<{ request?: GetLoggedInUserRequest }>()
);

const getLoggedInUserSuccess = createAction(
  UserActionTypes.GET_LOGGED_IN_USER_SUCCESS,
  props<{ payload: GetLoggedInUserPayload }>()
);

const getLoggedInUserFailure = createAction(
  UserActionTypes.GET_LOGGED_IN_USER_FAILURE,
  props<{ exception: Exception }>()
);

const loadAccountUserListRequest = createAction(
  UserActionTypes.LOAD_ACCOUNT_USER_LIST_REQUEST,
  props<{ request: LoadAccountUserListRequest }>()
);

const loadAccountUserListSuccess = createAction(
  UserActionTypes.LOAD_ACCOUNT_USER_LIST_SUCCESS,
  props<{ payload: LoadAccountUserListPayload }>()
);

const loadAccountUserListFailure = createAction(
  UserActionTypes.LOAD_ACCOUNT_USER_LIST_FAILURE,
  props<{ exception: Exception }>()
);


const updateUser = createAction(
  UserActionTypes.UPDATE_USER,
  props<{ update: Update<UserEntity> }>()
);

const setUserId = createAction(
  UserActionTypes.SET_USER_ID,
  props<{ userId: number }>()
);

const removeUserId = createAction(
  UserActionTypes.REMOVE_USER_ID,
);

const resetUsers = createAction(
  UserActionTypes.RESET_USERS,
);


export const UserActions = {
  loadUsersRequest,
  loadUsersSuccess,
  loadUsersFailure,
  loadUserRowsRequest,
  loadUserRowsSuccess,
  loadUserRowsFailure,
  getUserRequest,
  getUserSuccess,
  getUserFailure,
  createUserRequest,
  createUserSuccess,
  createUserFailure,
  updateUserRequest,
  updateUserSuccess,
  updateUserFailure,
  persistUserRequest,
  persistUserSuccess,
  persistUserFailure,
  deleteUserRequest,
  deleteUserSuccess,
  deleteUserFailure,
  getLoggedInUserRequest,
  getLoggedInUserSuccess,
  getLoggedInUserFailure,
  loadAccountUserListRequest,
  loadAccountUserListSuccess,
  loadAccountUserListFailure,
  updateUser,
  setUserId,
  removeUserId,
  resetUsers,
};
