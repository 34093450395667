import {createAction, props} from '@ngrx/store';
import {LoadPermissionsRequest, PermissionListPayload} from './permission.models';
import {Exception} from '../../../../../../core/models';


enum PermissionActionTypes {
  LOAD_PERMISSIONS_REQUEST = '[Permission] Load Permissions Request',
  LOAD_PERMISSIONS_SUCCESS = '[Permission] Load Permissions Success',
  LOAD_PERMISSIONS_FAILURE = '[Permission] Load Permissions Failure',
}

const loadPermissionsRequest = createAction(
  PermissionActionTypes.LOAD_PERMISSIONS_REQUEST,
  props<{ request: LoadPermissionsRequest }>()
);

const loadPermissionsSuccess = createAction(
  PermissionActionTypes.LOAD_PERMISSIONS_SUCCESS,
  props<{ payload: PermissionListPayload }>()
);

const loadPermissionsFailure = createAction(
  PermissionActionTypes.LOAD_PERMISSIONS_FAILURE,
  props<{ exception: Exception }>()
);

export const PermissionActions = {
  loadPermissionsRequest,
  loadPermissionsSuccess,
  loadPermissionsFailure,
};
