import {PayloadListModel, ResponseListModel} from '../../../../../../core/models';
import {BaseViewModel} from '../../../../../../core/models/src/base-view-model';

export interface FormListRequest {
  repositoryId: number;
  materialId: number;
}

export interface FormListResponse extends ResponseListModel<FormEntity> {}

export interface FormListPayload extends PayloadListModel<FormEntity> {}

export interface FormEntity {
  id: number;
  name: string;
  repositoryId: number;
  materialId: number;
}

export class FormViewModel extends BaseViewModel<FormEntity> {

  constructor(model: FormEntity) {
    super(model);
  }

  public get id(): number {
    return this.model.id;
  }

  public get repositoryId(): number {
    return this.model.repositoryId;
  }

  public get materialId(): number {
    return this.model.materialId;
  }

  public get name(): string {
    return this.model.name;
  }
}

export function formViewModelFactory(entity: FormEntity) {
  return new FormViewModel(entity);
}
