import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ShareDialogService} from '../../../dialogs/share/src/share-dialog.service';
import {SelectRepositoryDialogService} from '../../../dialogs/select-repository';
import {HintDialogService} from '../../../dialogs/hint';
import {filter, flatMap, map} from 'rxjs/operators';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {RepositoryItemPayload, RepositoryService, RepositoryViewModel, RouterService} from '../../../modules/store';

interface ButtonConfiguration {
  share?: boolean;
  notify?: boolean;
  context?: boolean;
}

@Component({
  selector: 'hb-repository-item-view',
  templateUrl: './repository-item-view.component.html',
  styleUrls: ['./repository-item-view.component.css']
})
export class RepositoryItemViewComponent implements OnInit, OnDestroy {


  @Input()
  viewModel: RepositoryViewModel;

  @Input()
  public showPath = false;

  @Input()
  public search = false;

  @Input()
  public isSelected = false;

  @Output()
  public tapActionEvent = new EventEmitter<{ name: string, data?: any }>();

  @Output()
  public selection = new EventEmitter<void>();

  constructor(
    private repositoryService: RepositoryService,
    private routerService: RouterService,
    private shareDialogService: ShareDialogService,
    private selectRepositoryDialogService: SelectRepositoryDialogService,
    private hintDialogService: HintDialogService,
    private logger: NGXLogger) {

  }

  private _buttons: ButtonConfiguration = {share: false, notify: false, context: false};

  public get buttons(): ButtonConfiguration {
    return this._buttons;
  }

  @Input()
  public set buttons(buttons: ButtonConfiguration) {
    this._buttons = {...this._buttons, ...buttons};
  }

  public get globalReminder$() {
    return this.repositoryService.rootRepository$.pipe(
      filter(repository => !!repository),
      map(repository => repository.notificationEnabled)
    );
  }

  public get share() {
    return {
      action: ($event) => {
        $event.stopPropagation();
        this.shareDialogService.open(this.viewModel.id);
      },
      color: this.viewModel.hasShares ? 'primary' : 'mute',
      disabled: false,
      show: true,
    };
  }

  public get notification() {
    return {
      action: ($event) => {
        $event.stopPropagation();
        this.repositoryService.notify(this.viewModel.model, !this.viewModel.shouldNotify);
      },
      active: this.viewModel.shouldNotify,
      disabled: false,
      show: true,
    };
  }

  public get context() {
    return {
      action: ($event) => {
        $event.stopPropagation();
      },
      disabled: false,
      show: true,
    };
  }

  public get icon() {
    if (this.viewModel.hasAddress) {
      return 'map-marker-alt';
    } else if (this.viewModel.sharedFolder) {
      return 'share-alt';
    } else {
      return 'folder';
    }
  }

  ngOnInit(): void {

  }

  open() {
    this.routerService.navigateToPlaces(this.viewModel.id);
    this.tapActionEvent.emit({name: 'open'});
  }

  edit() {
    this.routerService.navigateToRepositoryView(this.viewModel.id);
    this.tapActionEvent.emit({name: 'edit'});
  }

  move(): void {
    this.selectRepositoryDialogService.open({
        repositoryId: this.routerService.repositoryId,
        filteredIds: [this.viewModel.id]
      }
    ).afterClosed().pipe(
      filter(result => !!result),
      flatMap(result => this.repositoryService.move(this.viewModel, result.repositoryId)),
      filter(result => !!result),
      map((result: RepositoryItemPayload) => result.item),
    ).subscribe(repository => {
      this.routerService.navigateToPlaces(repository.parentId);
      this.tapActionEvent.emit({name: 'move'});
    });
  }

  delete() {
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      filter(result => !!result),
    ).subscribe(() => {
      this.repositoryService.delete(this.viewModel);
      this.tapActionEvent.emit({name: 'delete'});
    });
  }

  openInRepository() {
    this.routerService.navigateToPlaces(this.viewModel.parentId);
    this.tapActionEvent.emit({name: 'open_parent'});
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }
}
