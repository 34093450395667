import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {SignatureComponent} from './signature.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class SignatureDialogService extends BaseDialogService<SignatureComponent, any> {

  protected config: MatDialogConfig = {
    minWidth: '80vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, SignatureComponent);
  }
}
