<mat-list-item class="list-item" mat-ripple>
  <hb-avatar [fallback]="['fas', 'certificate']" height="40" mat-list-avatar width="40"></hb-avatar>
  <span mat-line>{{viewModel.name}}</span>
  <span
    mat-line>{{'keys.local.global.valid_until' | translate}} {{viewModel.validUntil | date: 'mediumDate' : timeZone : (locale$ | async)}}</span>

  <button (click)="$event.stopImmediatePropagation()" *ngIf="buttons.status" [disabled]="disabled" mat-icon-button>
    <hb-status [status]="viewModel.status"></hb-status>
  </button>
  <button (click)="$event.stopImmediatePropagation()" *ngIf="buttons.context" [disabled]="disabled"
          [matMenuTriggerFor]="contextMenu" mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</mat-list-item>

<mat-menu #contextMenu="matMenu">
  <button (click)="delete($event)" mat-menu-item>
    <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>
    <span>{{'context.certificate.action.delete' | translate}}</span>
  </button>
</mat-menu>
