import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {PRODUCT_FEATURE_KEY, productAdapter, ProductPartialState, ProductState} from './product.reducer';
import {memoize} from 'lodash.memoize';

const getProductState = createFeatureSelector<ProductPartialState, ProductState>(PRODUCT_FEATURE_KEY);

const {selectAll, selectEntities} = productAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getProductState,
  (state: ProductState) => state.isLoading
);

const getException = createSelector(
  getProductState,
  (state: ProductState) => state.exception
);

const getAllProducts = createSelector(
  getProductState,
  (state: ProductState) => selectAll(state)
);

const getProductEntities = createSelector(
  getProductState,
  (state: ProductState) => selectEntities(state)
);

const getProductById = createSelector(
  getAllProducts,
  customers => memoize((id: string) => customers[id])
);

const getSelectedId = createSelector(
  getProductState,
  (state: ProductState) => state.selected
);

export const ProductSelectors = {
  isLoading,
  getException,
  getAllProducts,
  getProductEntities,
  getProductById,
  getSelectedId
};

