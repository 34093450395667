import {Component, Inject, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BreakpointService, isNotNullOrUndefined} from '../../../modules/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {filter, map} from 'rxjs/operators';
import {
  MaterialEntity,
  MaterialViewModel,
  materialViewModelFactory,
  ProductEntity,
  ProductService,
  UsageService
} from '../../../modules/store';

@Component({
  selector: 'hb-create-material',
  templateUrl: './create-material.component.html',
  styleUrls: ['./create-material.component.scss']
})
export class CreateMaterialComponent implements OnInit {

  private _product: ProductEntity;

  private _viewModel: MaterialViewModel = this.data;

  constructor(private breakpointService: BreakpointService,
              private usageService: UsageService,
              private productService: ProductService,
              @Inject(MAT_DIALOG_DATA) private data: MaterialViewModel,
              private logger: NGXLogger) {
  }

  public get viewModel() {
    return this._viewModel;
  }

  public get product() {
    return this._product;
  }

  public get mandatorySerialnumber() {
    return isNotNullOrUndefined(this._product) ? this._product.mandatorySerialnumber : true;
  }

  private _currentDate;

  get currentDate() {
    return this._currentDate;
  }

  public get usages$() {
    return this.usageService.items$;
  }

  public get matchWeb() {
    return this.breakpointService.matchWeb();
  }

  ngOnInit(): void {

    this.productService.items$.pipe(
      map(items => items.filter(item => item.id === this.data.productId)[0]),
      filter(item => !!item),
    ).subscribe(product => {
      this._product = product;
      this._viewModel = materialViewModelFactory(
        {
          id: 0,
          productId: this.data.productId,
          repositoryId: this.data.repositoryId,
          usageId: this.data.usageId,
          parentMaterialId: this.data.parentId,
          strategy: product.strategy,
          product
        } as MaterialEntity);
    });

    this.usageService.load();
    this.productService.get(this.data.productId);
    this._currentDate = new Date(Date.now());
  }

}
