import {createAction, props} from '@ngrx/store';
import {QueryParams} from '@ngrx/data';
import {Exception} from '../../../../../../core/models';
import {TileItemPayload, TileListPayload} from './tile.models';


enum TileActionsTypes {

  AUTOCOMPLETE_INITIAL = '[Tile] Autocomplete Initial',
  AUTOCOMPLETE_REQUEST = '[Tile] Autocomplete Request',
  AUTOCOMPLETE_SUCCESS = '[Tile] Autocomplete Success',
  AUTOCOMPLETE_FAILURE = '[Tile] Autocomplete Failure',

  LOAD_TILES_REQUEST = '[Tile] Load Tiles Request',
  LOAD_TILES_SUCCESS = '[Tile] Load Tiles Success',
  LOAD_TILES_FAILURE = '[Tile] Load Tiles Failure',

  FETCH_TILES_REQUEST = '[Tile] Fetch Tiles Request',
  FETCH_TILES_SUCCESS = '[Tile] Fetch Tiles Success',
  FETCH_TILES_FAILURE = '[Tile] Fetch Tiles Failure',

  GET_TILE_REQUEST = '[Tile] Get Tile Request',
  GET_TILE_SUCCESS = '[Tile] Get Tile Success',
  GET_TILE_FAILURE = '[Tile] Get Tile Failure',

  RESET_TILES = '[Tile] Reset Tiles',

}

const autocompleteInitial = createAction(
  TileActionsTypes.AUTOCOMPLETE_INITIAL,
);

const autocompleteRequest = createAction(
  TileActionsTypes.AUTOCOMPLETE_REQUEST,
  props<{ queryParams: QueryParams }>()
);

const autocompleteSuccess = createAction(
  TileActionsTypes.AUTOCOMPLETE_SUCCESS,
  props<{ payload: TileListPayload }>()
);

const autocompleteFailure = createAction(
  TileActionsTypes.AUTOCOMPLETE_FAILURE,
  props<{ exception: Exception }>()
);

const loadTilesRequest = createAction(
  TileActionsTypes.LOAD_TILES_REQUEST,
  props<{ queryParams: QueryParams }>()
);

const loadTilesSuccess = createAction(
  TileActionsTypes.LOAD_TILES_SUCCESS,
  props<{ payload: TileListPayload }>()
);

const loadTilesFailure = createAction(
  TileActionsTypes.LOAD_TILES_FAILURE,
  props<{ exception: Exception }>()
);

const fetchTilesRequest = createAction(
  TileActionsTypes.FETCH_TILES_REQUEST,
  props<{ queryParams: QueryParams }>()
);

const fetchTilesSuccess = createAction(
  TileActionsTypes.FETCH_TILES_SUCCESS,
  props<{ payload: TileListPayload }>()
);

const fetchTilesFailure = createAction(
  TileActionsTypes.FETCH_TILES_FAILURE,
  props<{ exception: Exception }>()
);

const getTileRequest = createAction(
  TileActionsTypes.GET_TILE_REQUEST,
  props<{ queryParams: QueryParams }>()
);

const getTileSuccess = createAction(
  TileActionsTypes.GET_TILE_SUCCESS,
  props<{ payload: TileItemPayload }>()
);

const getTileFailure = createAction(
  TileActionsTypes.GET_TILE_FAILURE,
  props<{ exception: Exception }>()
);

const resetTiles = createAction(
  TileActionsTypes.RESET_TILES,
);

export const TileActions = {
  autocompleteInitial,
  autocompleteRequest,
  autocompleteSuccess,
  autocompleteFailure,
  loadTilesRequest,
  loadTilesSuccess,
  loadTilesFailure,
  fetchTilesRequest,
  fetchTilesSuccess,
  fetchTilesFailure,
  getTileRequest,
  getTileSuccess,
  getTileFailure,
  resetTiles,
};
