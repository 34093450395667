import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ProductDataService} from './product-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {Exception} from '../../../../../../core/models';
import {ProductActions} from './product.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class ProductEffects {

  loadProductsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProductsRequest),
      switchMap((action) => this.productService.load(action.queryParams).pipe(
        map(payload => ProductActions.loadProductsSuccess({payload})),
        catchError(error => of(ProductActions.loadProductsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadProductsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProductsRequest),
    ), {dispatch: false}
  );
  loadProductsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadProductsFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  fetchProductsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchProductsRequest),
      switchMap(action => this.productService.load(action.queryParams).pipe(
        map(payload => ProductActions.fetchProductsSuccess({payload})),
        catchError(error => of(ProductActions.fetchProductsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  fetchProductsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchProductsSuccess),
    ), {dispatch: false}
  );
  fetchProductsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.fetchProductsFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  getProductRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductRequest),
      switchMap(action => this.productService.get(action.productId, action.queryParams).pipe(
        map(payload => ProductActions.getProductSuccess({payload})),
        catchError(error => of(ProductActions.getProductFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductSuccess),
    ), {dispatch: false}
  );
  getProductFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.getProductFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  persistProductRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.persistProductRequest),
      switchMap(action => this.productService.persist(action.product, action.queryParams).pipe(
        map(payload => ProductActions.persistProductSuccess({payload})),
        catchError(error => of(ProductActions.persistProductFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  persistProductSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.persistProductSuccess),
    ), {dispatch: false}
  );
  persistProductFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ProductActions.persistProductFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private productService: ProductDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }
}
