import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CategoryDataService} from './category-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {CategoryActions} from './category.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class CategoryEffects {

  loadCategoriesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadCategoriesRequest),

      switchMap(action => this.categoryDataService.load().pipe(
        map(payload => CategoryActions.loadCategoriesSuccess({payload})),
        catchError(error => of(CategoryActions.loadCategoriesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadCategoriesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadCategoriesSuccess),
    ), {dispatch: false}
  );

  loadCategoriesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(CategoryActions.loadCategoriesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions, private categoryDataService: CategoryDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
