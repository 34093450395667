import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as fromUser from './user.reducer';
import {select, Store} from '@ngrx/store';
import {
  CreateUserPayload,
  CreateUserRequest,
  GetLoggedInUserPayload,
  GetUserPayload,
  GetUserRequest,
  LoadAccountUserListPayload,
  LoadAccountUserListRequest,
  LoadUserRowsPayload,
  LoadUserRowsRequest,
  LoadUsersPayload,
  LoadUsersRequest,
  PersistUserPayload,
  PersistUserRequest,
  RemoveUserPayload,
  RemoveUserRequest,
  UpdateUserPayload,
  UpdateUserRequest,
  UserViewModel
} from './user.models';
import {Actions} from '@ngrx/effects';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {next} from '../../../../../../core/utils';
import {UserSelectors} from './user.selectors';
import {UserActions} from './user.actions';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userId: number;

  items$ = this.store.pipe(select(UserSelectors.getItems));
  allItems$ = this.store.pipe(select(UserSelectors.getAllItems));
  isLoading$ = this.store.pipe(select(UserSelectors.isLoading));
  isSearching$ = this.store.pipe(select(UserSelectors.isSearching));
  exception$ = this.store.pipe(select(UserSelectors.getException));
  rows$ = this.store.pipe(select(UserSelectors.getRows));
  selected$ = this.store.pipe(select(UserSelectors.getSelected), filter(item => !!item));
  userId$ = this.store.pipe(select(UserSelectors.getUserId));
  loggedInUser$ = this.store.pipe(select(UserSelectors.getLoggedIn), filter(item => !!item));
  possibleOwners$ = this.store.pipe(select(UserSelectors.getPossibleOwners));
  context$ = this.store.pipe(select(UserSelectors.getContext));

  itemsWithProps$(props: { repositoryId?: number, search?: string }): Observable<UserViewModel[]> {
    return this.store.pipe(select(UserSelectors.getItemsWithProps, props));
  }

  constructor(private store: Store<fromUser.UserPartialState>, private actions$: Actions, private logger: NGXLogger) {
    this.userId$.subscribe(userId => this._userId = userId);
  }

  public get userId() {
    return this._userId;
  }

  load(request: LoadUsersRequest): Observable<LoadUsersPayload> {
    this.store.dispatch(UserActions.loadUsersRequest({request}));
    return this.actions$.pipe(
      next(UserActions.loadUsersSuccess),
      map(action => action.payload)
    );
  }

  loadRows(request: LoadUserRowsRequest): Observable<LoadUserRowsPayload> {
    this.store.dispatch(UserActions.loadUserRowsRequest({request}));
    return this.actions$.pipe(
      next(UserActions.loadUserRowsSuccess),
      map(action => action.payload)
    );
  }

  loadAccountUsers(request: LoadAccountUserListRequest): Observable<LoadAccountUserListPayload> {
    this.store.dispatch(UserActions.loadAccountUserListRequest({request}));
    return this.actions$.pipe(
      next(UserActions.loadAccountUserListSuccess),
      map(action => action.payload)
    );
  }

  get(request: GetUserRequest): Observable<GetUserPayload> {
    this.store.dispatch(UserActions.getUserRequest({request}));
    return this.actions$.pipe(
      next(UserActions.getUserSuccess),
      map(action => action.payload)
    );
  }

  getLoggedInUser(): Observable<GetLoggedInUserPayload> {
    this.store.dispatch(UserActions.getLoggedInUserRequest({}));
    return this.actions$.pipe(
      next(UserActions.getLoggedInUserSuccess),
      map(action => action.payload)
    );
  }

  create(request: CreateUserRequest): Observable<CreateUserPayload> {
    this.store.dispatch(UserActions.createUserRequest({request}));
    return this.actions$.pipe(
      next(UserActions.createUserSuccess),
      map(action => action.payload)
    );
  }

  persist(request: PersistUserRequest): Observable<PersistUserPayload> {
    this.store.dispatch(UserActions.persistUserRequest({request}));
    return this.actions$.pipe(
      next(UserActions.persistUserSuccess),
      map(action => action.payload)
    );
  }

  update(request: UpdateUserRequest): Observable<UpdateUserPayload> {
    this.store.dispatch(UserActions.updateUserRequest({request}));
    return this.actions$.pipe(
      next(UserActions.updateUserSuccess),
      map(action => action.payload)
    );
  }

  delete(request: RemoveUserRequest): Observable<RemoveUserPayload> {
    this.store.dispatch(UserActions.deleteUserRequest({request}));
    return this.actions$.pipe(
      next(UserActions.deleteUserSuccess),
      map(action => action.payload)
    );
  }

  setUserId(userId: number) {
    this.store.dispatch(UserActions.setUserId({userId}));
  }

  removeUserId() {
    this.store.dispatch(UserActions.removeUserId());
  }

  selectWithFilter(search: string) {
    return this.store.select(UserSelectors.selectWithFilter, search);
  }
}
