import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AddAttachmentComponent} from './add-attachment.component';

@Injectable({
  providedIn: 'root'
})
export class AddAttachmentDialogService extends BaseDialogService<AddAttachmentComponent, any> {

  protected config: MatDialogConfig = {
    width: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, AddAttachmentComponent);
  }
}
