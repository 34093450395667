import {createAction, props} from '@ngrx/store';
import {LicenseListPayload} from './license.models';
import {Exception} from '../../../../../../core/models';

enum LicenseActionTypes {
  LOAD_LICENSES_REQUEST = '[License] Load Licenses Request',
  LOAD_LICENSES_SUCCESS = '[License] Load Licenses Success',
  LOAD_LICENSES_FAILURE = '[License] Load Licenses Failure',
}

const loadLicensesRequest = createAction(
  LicenseActionTypes.LOAD_LICENSES_REQUEST
);

const loadLicensesSuccess = createAction(
  LicenseActionTypes.LOAD_LICENSES_SUCCESS,
  props<{ payload: LicenseListPayload }>()
);

const loadLicensesFailure = createAction(
  LicenseActionTypes.LOAD_LICENSES_FAILURE,
  props<{ exception: Exception }>()
);

export const LicenseActions = {
  loadLicensesRequest,
  loadLicensesSuccess,
  loadLicensesFailure,
};
