import {Action, createReducer, on} from '@ngrx/store';
import {AddressEntity} from './address.models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';
import {AddressActions} from './address.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const ADDRESS_FEATURE_KEY = 'address';

export const ADDRESS_ID = 'ADDRESS_ID';

export interface AddressState extends EntityState<AddressEntity> {
  isLoading: boolean;
  exception: Exception;
  addressId: number;
}

export interface AddressPartialState {
  readonly [ADDRESS_FEATURE_KEY]: AddressState;
}

export const addressAdapter: EntityAdapter<AddressEntity> = createEntityAdapter<AddressEntity>();

export const initialState: AddressState = addressAdapter.getInitialState({
  isLoading: false,
  exception: null,
  addressId: Number(localStorage.getItem(ADDRESS_ID)) || null,
});


export const addressReducer = createReducer(
  initialState,
  on(AddressActions.loadAddressesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AddressActions.loadAddressesSuccess, (state, {payload}) =>
    addressAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(AddressActions.loadAddressesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AddressActions.getAddressRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AddressActions.getAddressSuccess, (state, {payload}) =>
    addressAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(AddressActions.getAddressFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AddressActions.persistAddressRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AddressActions.persistAddressSuccess, (state, {payload}) =>
    addressAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(AddressActions.persistAddressFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: AddressState | undefined, action: Action) {
  return addressReducer(state, action);
}
