import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {SealDocumentComponent} from './seal-document.component';
import {Params} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SealDocumentDialogService extends BaseDialogService<SealDocumentComponent, { email?: string, sendViaEmail: boolean }, Params> {

  constructor(protected matDialog: MatDialog) {
    super(matDialog, SealDocumentComponent);
  }
}
