<button color="mute" mat-dialog-close mat-icon-button style="position: absolute; top: 0.5rem; right: 0.5rem;">
  <mat-icon fontIcon="fa-times" fontSet="fas"></mat-icon>
</button>
<zxing-scanner #scanner
               (scanSuccess)="scanSuccess($event)"
               [(device)]="selectedDevice"
               [autofocusEnabled]="autoFocus"
               [formats]="formats">

</zxing-scanner>

<svg [class.hidden]="formVisible" class="centered-element" viewBox="0 0 100 100" width="33%">
  <path d="M25,2 L2,2 L2,25" fill="none" stroke="white" stroke-width="3"/>
  <path d="M2,75 L2,98 L25,98" fill="none" stroke="white" stroke-width="3"/>
  <path d="M75,98 L98,98 L98,75" fill="none" stroke="white" stroke-width="3"/>
  <path d="M98,25 L98,2 L75,2" fill="none" stroke="white" stroke-width="3"/>
</svg>

<div class="overlay">
  <div style="height: 100%; display: flex; flex-direction: column;">
    <button (click)="toggleForm();" mat-button
            style="display: flex; justify-content: center">
      <mat-icon [fontIcon]="icon" fontSet="fas" style="padding: 1rem;">

      </mat-icon>
    </button>
    <div [hidden]="!formVisible" style="flex: 1 1 auto">

      <form #ngForm=ngForm fxLayout="column nowrap" ngForm style="padding: 1rem;">
        <mat-form-field appearance="outline">
          <mat-label>
            <label for="usage">{{'forms.fields.usage.label' | translate}}</label>
          </mat-label>
          <mat-select #usage="ngModel"
                      [(ngModel)]="viewModel.usageId"
                      [placeholder]="'forms.fields.usage.placeholder' | translate"
                      id="usage"
                      name="usage"
                      required touched>
            <mat-option *ngFor="let usage of usages$ | async"
                        [value]="usage.id">{{usage.key | translate}}</mat-option>
          </mat-select>
          <mat-hint>{{'forms.fields.usage.hint' | translate}}</mat-hint>
          <mat-error *ngIf="usage.dirty && usage.touched">
            <ng-container *ngIf="usage.hasError('required')">
              {{'forms.errors.is_required' | translate}}
            </ng-container>
          </mat-error>
        </mat-form-field>

        <mat-form-field #firstUsageDateFormat="hbYearMonthDateFormat" appearance="outline" hbYearMonthDateFormat>
          <mat-label>
            <label for="firstUsage">{{'forms.fields.first_usage.label' | translate}}</label>
          </mat-label>
          <input #firstUsage="ngModel" [(ngModel)]="viewModel.firstUsage"
                 [matDatepicker]="firstUsageDatePicker"
                 [max]="currentDate | date"
                 [min]="viewModel.firstUsage | date"
                 (click)="firstUsageDatePicker.open()"
                 [placeholder]="'forms.fields.first_usage.placeholder' | translate" id="firstUsage"
                 matInput
                 name="firstUsage" readonly>
          <mat-datepicker-toggle [for]="firstUsageDatePicker"
                                 matSuffix></mat-datepicker-toggle>
          <mat-datepicker #firstUsageDatePicker
                          (monthSelected)="firstUsageDateFormat.chosenMonthHandler($event, firstUsageDatePicker)"
                          [touchUi]="!matchWeb"
                          startView="year"></mat-datepicker>
          <mat-hint>{{'forms.fields.first_usage.hint' | translate}}</mat-hint>
          <mat-error *ngIf="firstUsage.dirty && firstUsage.touched">

          </mat-error>
        </mat-form-field>


        <mat-form-field *ngIf="usage.value === 1" appearance="outline">
          <mat-label>
            <label for="soldOn">{{'forms.fields.sold_on.label' | translate}}</label>
          </mat-label>
          <input #soldOn="ngModel"
                 [(ngModel)]="viewModel.soldOn"
                 [matDatepicker]="soldOnPicker"
                 [placeholder]="'forms.fields.sold_on.placeholder' | translate"
                 (click)="soldOnPicker.open()"
                 id="soldOn"
                 matInput
                 name="soldOn">
          <mat-datepicker-toggle [for]="soldOnPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #soldOnPicker [touchUi]="!matchWeb"></mat-datepicker>
          <mat-hint>{{'forms.fields.sold_on.hint' | translate}}</mat-hint>
          <mat-error *ngIf="soldOn.dirty && soldOn.touched">

          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>
            <label
              for="customerSerialNumber">{{'forms.fields.customer_serialnumber.label' | translate}}</label>
          </mat-label>
          <input #customerSerialNumber="ngModel"
                 [(ngModel)]="viewModel.rfid"
                 [placeholder]="'forms.fields.customer_serialnumber.placeholder' | translate"
                 id="customerSerialNumber"
                 matInput
                 name="customerSerialNumber">
          <mat-hint>{{'forms.fields.customer_serialnumber.hint' | translate}}</mat-hint>
          <mat-error *ngIf="customerSerialNumber.dirty && customerSerialNumber.touched">

          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{'forms.fields.device.label' | translate}}</mat-label>
          <mat-select (selectionChange)="selectedDevice = $event.value"
                      [placeholder]="'forms.fields.device.placeholder' | translate"
                      [value]="selectedDevice">
            <mat-option [value]="null">{{'forms.fields.device.options.empty'}}</mat-option>
            <mat-option *ngFor="let device of availableDevices" [value]="device">{{device.label}}</mat-option>
          </mat-select>
          <mat-hint>{{'forms.fields.device.hint' | translate}}</mat-hint>
        </mat-form-field>
      </form>

    </div>
  </div>
</div>


