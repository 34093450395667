import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {RouterService} from '../../../modules/store';

@Component({
  selector: 'hb-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit, OnDestroy {

  constructor(public routerFacade: RouterService,
              private logger: NGXLogger) {

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

}
