import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EditEmailComponent} from './edit-email.component';
import {BaseDialogService} from '../../base/src/base-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class EditEmailDialogService extends BaseDialogService<EditEmailComponent, any> {

  protected config: MatDialogConfig = {
    width: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, EditEmailComponent);
  }


}
