<h2 *ngIf="(selected$ | async) as selected" mat-dialog-title>
  <mat-icon (click)="select(selected.parentId)" *ngIf="canNavigateUp$ | async" fontIcon="fa-arrow-left"
            fontSet="fas"></mat-icon>
  {{selected.name}}
</h2>
<mat-dialog-content fxLayout="column nowrap">
  <div fxLayout="column nowrap">
    <mat-form-field appearance="outline">
      <mat-label>
        <label for="search">{{'forms.fields.search.label' | translate}}</label>
      </mat-label>
      <input [formControl]="searchInput"
             [placeholder]="'forms.fields.search.placeholder' | translate"
             id="search"
             matInput
             type="text">
      <mat-hint>{{'forms.fields.search.hint' | translate}}</mat-hint>
      <mat-icon *ngIf="!(isLoading$ | async)" fontIcon="fa-search" fontSet="fas" inline matSuffix></mat-icon>
      <mat-spinner *ngIf="isLoading$ | async" diameter="30" matSuffix mode="indeterminate"></mat-spinner>
    </mat-form-field>
  </div>
  <hb-list #list
           (vsPageChange)="fetch(searchInput.value, $event)"
           [items]="items$ | async"
           itemSize="88"
           virtualHeight="calc(50vh)">
    <ng-template #item let-viewModel="$implicit">
      <hb-repository-item-view (click)="select(viewModel.id)"
                               [buttons]="{share: false, notify: false, context: false}"
                               showPath="true"
                               [viewModel]="viewModel">

      </hb-repository-item-view>
    </ng-template>
  </hb-list>

</mat-dialog-content>

<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [mat-dialog-close]="result" color="accent"
          mat-raised-button>{{'keys.local.global.select_this_repository' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.close' | translate}}</button>
</mat-dialog-actions>


