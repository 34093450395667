import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {HintDialogService} from '../../../dialogs/hint';
import {AuthenticationService, ResetPasswordRequest, RouterService} from '../../../modules/store';
import {environment} from '../../../../environments';


@Component({
  selector: 'hb-password-reset-view',
  templateUrl: './password-reset-view.component.html',
  styleUrls: ['./password-reset-view.component.css']
})
export class PasswordResetViewComponent implements OnInit, OnDestroy {

  public readonly password_pattern = environment.regex.password_pattern;
  private subscription = new Subscription();

  public viewModel: ResetPasswordRequest = {
    email: '',
    key: '',
    password: ''
  };

  constructor(public routerFacade: RouterService,
              private renderer: Renderer2,
              private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.viewModel.key = params.key;
      this.viewModel.email = params.email;
    });
    this.renderer.addClass(document.body, 'hb-background');
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.subscription.unsubscribe();
  }

  isLoading$() {
    return this.authenticationService.isLoading$;
  }

  submit() {
    this.authenticationService.resetPassword(this.viewModel);
  }
}
