import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromCategory from './category.reducer';
import {NGXLogger} from 'ngx-logger';
import {CategoryActions} from './category.actions';
import {CategorySelectors} from './category.selectors';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  public readonly isLoading$ = this.store.pipe(select(CategorySelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(CategorySelectors.getException));
  public readonly items$ = this.store.pipe(select(CategorySelectors.getItems));

  constructor(private store: Store<fromCategory.CategoryPartialState>, logger: NGXLogger) { }

  public load() {
    this.store.dispatch(CategoryActions.loadCategoriesRequest());
  }
}
