import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationService, LoginRequest} from '../../../modules/store';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'hb-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent implements OnInit, AfterViewInit, OnDestroy {

  public viewModel: LoginRequest = {username: '', password: ''};

  private subscription = new Subscription();

  constructor(private authenticationService: AuthenticationService,
              private logger: NGXLogger) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.subscription.unsubscribe();
  }

  isLoading(): Observable<boolean> {
    return this.authenticationService.isLoading$;
  }

  submit() {
    this.logger.trace(this.viewModel);
    this.authenticationService.login({...this.viewModel});
  }
}
