import {BehaviorSubject, Observable} from 'rxjs';

export function convert(fn) {
  return fn.call();
}

export function toSubject<T>(observable: Observable<T>, initValue: T = null): BehaviorSubject<T> {
  const subject = new BehaviorSubject(initValue);

  observable.subscribe({
    complete: () => subject.complete(),
    error: x => subject.error(x),
    next: x => subject.next(x)
  });

  return subject;
}
