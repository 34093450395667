import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ImageService} from '../../../modules/core/services/image';
import {convert} from '../../../modules/core/utils';
import {filter, flatMap, map} from 'rxjs/operators';
import {HintDialogService} from '../../../dialogs/hint';
import {SelectRepositoryDialogService} from '../../../dialogs/select-repository';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {
  LanguageService,
  MaterialItemPayload,
  MaterialService,
  MaterialViewModel,
  RepositoryService,
  RouterService
} from '../../../modules/store';
import {BreakpointService} from '../../../modules/core/services/breakpoint';

interface ButtonConfiguration {
  status?: boolean;
  case?: boolean;
  notify?: boolean;
  context?: boolean;
}

@Component({
  selector: 'hb-material-item-view',
  templateUrl: './material-item-view.component.html',
  styleUrls: ['./material-item-view.component.css']
})
export class MaterialItemViewComponent implements OnInit, OnDestroy {

  @Input()
  public sub = false;

  @Input()
  public showPath = false;

  @Input()
  viewModel: MaterialViewModel;

  @Input()
  disabled: boolean;

  @Input()
  public search = false;

  @Input()
  public isSelected = false;

  @Output()
  public deleteEvent = new EventEmitter<MaterialViewModel>();

  @Output()
  public tapActionEvent = new EventEmitter<{ name: string, data?: any }>();

  @Output()
  public selection = new EventEmitter<void>();

  constructor(private materialService: MaterialService,
              private repositoryService: RepositoryService,
              private languageService: LanguageService,
              private routerService: RouterService,
              private imageService: ImageService,
              private hintDialogService: HintDialogService,
              private selectRepositoryDialogService: SelectRepositoryDialogService,
              private breakpointService: BreakpointService,
              private logger: NGXLogger) {

  }

  private _buttons: ButtonConfiguration = {status: false, case: false, notify: false, context: false};

  public get buttons(): ButtonConfiguration {
    return this._buttons;
  }

  @Input()
  public set buttons(buttons: ButtonConfiguration) {
    this._buttons = {...this._buttons, ...buttons};
  }

  public get globalReminder$() {
    return this.repositoryService.rootRepository$.pipe(
      filter(repository => !!repository),
      map(repository => repository.notificationEnabled)
    );
  }

  get placeholder(): string {
    return this.imageService.getProductImage(this.viewModel.manufacturerImageBaseUrl, this.viewModel.articleNumber, '80', '80');
  }

  public get set() {
    return {
      action: ($event) => {
        $event.stopPropagation();
      },
      color: convert(() => {
        if (this.viewModel.isSet) {
          return 'primary';
        } else if (this.viewModel.isSetItem) {
          // @ts-ignore
          return 'mute';
        }
      }),
      disabled: false,
      show: this.viewModel.isSetItem || this.viewModel.isSet,
    };
  }

  public get notification() {
    return {
      action: ($event) => {
        $event.stopPropagation();
        this.materialService.notify(this.viewModel.model, !this.viewModel.shouldNotify);
      },
      active: this.viewModel.shouldNotify,
      disabled: false,
      show: true,
    };
  }

  public get context() {
    return {
      action: ($event) => {
        $event.stopPropagation();
      },
      disabled: false,
      show: true,
    };
  }

  public get matchHandset() {
    return this.breakpointService.matchHandset();
  }

  public get locale$() {
    return this.languageService.selected$;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit(): void {

  }

  open() {
    this.routerService.navigateToMaterialView(this.viewModel.repositoryId, this.viewModel.id);
    this.tapActionEvent.emit({name: 'open'});
  }

  edit() {
    this.routerService.navigateToMaterialView(this.viewModel.repositoryId, this.viewModel.id);
    this.tapActionEvent.emit({name: 'edit'});
  }

  move() {
    this.selectRepositoryDialogService.open({
      repositoryId: this.routerService.repositoryId,
      filteredIds: [this.viewModel.id]
    }).afterClosed().pipe(
      filter(result => !!result),
      flatMap(result => this.materialService.move(this.viewModel, result.repositoryId)),
      filter(result => !!result),
      map((result: MaterialItemPayload) => result.item),
    ).subscribe(repository => {
      this.routerService.navigateToPlaces(repository.repositoryId);
      this.tapActionEvent.emit({name: 'move', data: repository});
    });
  }

  delete() {
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      filter(result => !!result),
    ).subscribe(() => {
      this.materialService.delete(this.viewModel);
      this.deleteEvent.emit(this.viewModel);
    });
  }

  openInRepository() {
    this.tapActionEvent.emit({name: 'open_parent'});
    if (this.viewModel.isSetItem) {
      this.routerService.navigateToMaterialView(this.viewModel.repositoryId, this.viewModel.parentId);
    } else {
      this.routerService.navigateToPlaces(this.viewModel.repositoryId);
    }

  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }

}
