import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {map, mapTo} from 'rxjs/operators';
import {USER_ID} from './user.reducer';
import {HttpParamsBuilder} from '../../../../../../core/utils';
import {environment} from '@hb/environments/environment';
import {
  CreateUserPayload,
  CreateUserRequest,
  GetLoggedInUserPayload,
  GetLoggedInUserRequest,
  GetLoggedInUserResponse,
  GetUserPayload,
  GetUserRequest,
  GetUserResponse,
  LoadAccountUserListPayload,
  LoadAccountUserListRequest,
  LoadAccountUserListResponse,
  LoadUserRowsPayload,
  LoadUserRowsRequest,
  LoadUserRowsResponse,
  LoadUsersRequest,
  LoadUsersResponse,
  PersistUserPayload,
  PersistUserRequest,
  PersistUserResponse,
  RemoveUserPayload,
  RemoveUserRequest,
  RemoveUserResponse,
  SearchUserRowsPayload,
  SearchUserRowsRequest,
  SearchUserRowsResponse,
  SearchUsersResponse,
  UpdateUserPayload,
  UpdateUserRequest,
  UpdateUserResponse,
  UserItemPayload,
  UserItemResponse,
  UserListPayload
} from './user.models';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private readonly defaultPageSize = environment.defaultPageSize;

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  get(request: GetUserRequest): Observable<GetUserPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/users/${request.userId}`;
    const params = HttpParamsBuilder.get().build();

    return this.http.get<GetUserResponse>(url, {params}).pipe(
      map(res => new GetUserPayload(res))
    );
  }

  load(request: LoadUsersRequest): Observable<UserListPayload> {
    if (!!request.repositoryId) {
      return this._load(request);
    } else {
      return this._search(request);
    }
  }

  private _load(request: LoadUsersRequest): Observable<UserListPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/users`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.offset, request.page * this.defaultPageSize)
      .append(environment.queryParamsNames.length, this.defaultPageSize)
      .append(environment.queryParamsNames.filter, request.search)
      .append(environment.queryParamsNames.archive, request.archive)
      .build();

    return this.http.get<LoadUsersResponse>(url, {params}).pipe(
      map(res => new UserListPayload(res)),
    );
  }

  private _search(request: LoadUsersRequest): Observable<UserListPayload> {
    const url = `${environment.api}/users`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.offset, request.page * this.defaultPageSize)
      .append(environment.queryParamsNames.length, this.defaultPageSize)
      .append(environment.queryParamsNames.filter, request.search)
      .build();

    return this.http.get<SearchUsersResponse>(url, {params}).pipe(
      map(res => new UserListPayload(res)),
    );
  }

  loadAccountUserList(request: LoadAccountUserListRequest): Observable<LoadAccountUserListPayload> {
    const url = `${environment.api}/accounts/${request.accountId}/users`;
    const params = HttpParamsBuilder.get()
      .build();

    return this.http.get<LoadAccountUserListResponse>(url, {params}).pipe(
      map(res => new LoadAccountUserListPayload(res)),
    );
  }

  loadRows(request: LoadUserRowsRequest): Observable<LoadUserRowsPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/users`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.filter, request.filter)
      .append(environment.queryParamsNames.rowsOnly, true)
      .build();

    return this.http.get<LoadUserRowsResponse>(url, {params}).pipe(
      map(res => new LoadUserRowsPayload(res))
    );
  }

  searchRows(request: SearchUserRowsRequest): Observable<SearchUserRowsPayload> {
    const url = `${environment.api}/users`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.filter, request.filter)
      .append(environment.queryParamsNames.rowsOnly, true)
      .build();

    return this.http.get<SearchUserRowsResponse>(url, {params}).pipe(
      map(res => new SearchUserRowsPayload(res))
    );
  }

  create(request: CreateUserRequest): Observable<UserItemPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/users/0`;
    const params = HttpParamsBuilder.get()
      .build();

    return this.http.get<UserItemResponse>(url, {params}).pipe(
      map(res => new CreateUserPayload(res))
    );
  }

  update(request: UpdateUserRequest): Observable<UpdateUserPayload> {
    const url = `${environment.api}/repositories/${request.entity.repositoryId}/users/${request.entity.id}`;
    const params = HttpParamsBuilder.get()
      .build();

    return this.http.put<UpdateUserResponse>(url, request.entity, {params}).pipe(
      map(res => new UpdateUserPayload(res))
    );
  }

  persist(request: PersistUserRequest): Observable<PersistUserPayload> {
    const url = `${environment.api}/repositories/${request.entity.repositoryId}/users/${request.entity.id}`;
    const params = HttpParamsBuilder.get().build();

    return this.http.put<PersistUserResponse>(url, request.entity, {params}).pipe(
      map(res => new PersistUserPayload(res))
    );
  }

  delete(request: RemoveUserRequest): Observable<RemoveUserPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/users/${request.userId}`;
    const params = HttpParamsBuilder.get()
      .build();

    return this.http.delete<RemoveUserResponse>(url, {params}).pipe(
      mapTo(({...request}))
    );
  }

  getLoggedInUser(request: GetLoggedInUserRequest): Observable<GetLoggedInUserPayload> {
    const url = `${environment.api}/loggedinuser`;
    const params = HttpParamsBuilder.get()
      .build();


    return this.http.get<GetLoggedInUserResponse>(url, {params}).pipe(
      map(res => new GetLoggedInUserPayload(res))
    );
  }

  setUserId(userId: number) {
    window.localStorage.setItem(USER_ID, userId.toString());
  }

  removeUserId() {
    window.localStorage.removeItem(USER_ID);
  }
}
