import {NGXLogger} from 'ngx-logger';
import {ServiceInjector} from '../../core.module';

export class LoggerResolver {

  private static logger: NGXLogger;

  public static resolve() {
    if (!LoggerResolver.logger) {
      LoggerResolver.logger = ServiceInjector.get(NGXLogger);
    }

    return LoggerResolver.logger;
  }
}
