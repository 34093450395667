<button color="mute" mat-dialog-close mat-icon-button style="position: absolute; top: 0.5rem; right: 0.5rem;">
  <mat-icon fontIcon="fa-times" fontSet="fas"></mat-icon>
</button>
<zxing-scanner #scanner
               (scanSuccess)="scanSuccess($event)"
               [(device)]="selectedDevice"
               [autofocusEnabled]="autoFocus"
               [formats]="formats">

</zxing-scanner>

<svg class="centered-element" viewBox="0 0 100 100" width="33%">
  <path d="M25,2 L2,2 L2,25" fill="none" stroke="white" stroke-width="3"/>
  <path d="M2,75 L2,98 L25,98" fill="none" stroke="white" stroke-width="3"/>
  <path d="M75,98 L98,98 L98,75" fill="none" stroke="white" stroke-width="3"/>
  <path d="M98,25 L98,2 L75,2" fill="none" stroke="white" stroke-width="3"/>
</svg>


