import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {DOCUMENT_FEATURE_KEY, documentAdapter, DocumentPartialState, DocumentState} from './document.reducer';
import {DocumentEntity, DocumentViewModel, documentViewModelFactory} from './document.models';
import {Dictionary} from '@ngrx/entity';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getDocumentState = createFeatureSelector<DocumentPartialState,
  DocumentState>(DOCUMENT_FEATURE_KEY);

const {selectAll, selectEntities} = documentAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getDocumentState,
  (state: DocumentState) => state.isLoading
);

const getException = createSelector(
  getDocumentState,
  (state: DocumentState) => state.exception
);

const getAllItems: MemoizedSelector<object, DocumentViewModel[]> = createSelector(
  getDocumentState,
  (state: DocumentState) => selectAll(state).map(item => documentViewModelFactory(item))
);

const getItems: MemoizedSelector<object, DocumentViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getRepositoryIdParam,
  RouterSelectors.getMaterialIdParam,
  (items, repositoryId, materialId) =>
    items.filter(item => item.repositoryId === repositoryId && item.materialId === materialId)
);

const getRows = createSelector(
  getDocumentState,
  (state: DocumentState) => state.rows
);

const getDocumentEntities: MemoizedSelector<object, Dictionary<DocumentEntity>> = createSelector(
  getDocumentState,
  (state: DocumentState) => selectEntities(state)
);

const getSelected: MemoizedSelector<object, DocumentViewModel> = createSelector(
  getDocumentEntities,
  RouterSelectors.getDocumentIdParam,
  (entities, selected) => !!entities[selected] ? documentViewModelFactory(entities[selected]) : null
);

export const DocumentSelectors = {
  isLoading,
  getException,
  getAllItems,
  getItems,
  getRows,
  getDocumentEntities,
  getSelected,
};
