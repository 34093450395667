import {Component, Input, OnInit} from '@angular/core';
import {MaterialEntity, MaterialViewModel, materialViewModelFactory} from '../../../modules/store';

@Component({
  selector: 'hb-radio-input-material-label',
  templateUrl: './radio-input-material-label.component.html',
  styleUrls: ['./radio-input-material-label.component.scss']
})
export class RadioInputMaterialLabelComponent implements OnInit {

  private _materialVM: MaterialViewModel;

  constructor() { }

  @Input()
  set material(value: MaterialEntity) {
    this._materialVM = materialViewModelFactory(value);
  }

  get viewModel(): MaterialViewModel {
    return this._materialVM;
  }

  ngOnInit(): void {
  }

}
