import {createAction, props} from '@ngrx/store';
import {Exception} from '../../../../../../core';

enum AppActionTypes {
  INIT = '[App] INIT',
  TOGGLE_SHOW_INACTIVE = '[App] Toggle Show Inactive',
  TOGGLE_SIDENAV = '[App] Toggle SideNav',
  SET_SCROLLED = '[App] Set Scrolled',
  SHOW_SUCCESS_MESSAGE = '[App] Show Success Message',
  SHOW_FAILURE_MESSAGE = '[App] Show Failure Message',
}

const toggleShowInactive = createAction(
  AppActionTypes.TOGGLE_SHOW_INACTIVE,
);

const toggleSideNav = createAction(
  AppActionTypes.TOGGLE_SIDENAV,
  props<{ isOpen?: boolean }>()
);

const showSuccessMessage = createAction(
  AppActionTypes.SHOW_SUCCESS_MESSAGE,
  props<{ message: string }>()
);

const setScrolled = createAction(
  AppActionTypes.SET_SCROLLED,
  props<{ isScrolled: boolean }>()
);

const showFailureMessage = createAction(
  AppActionTypes.SHOW_FAILURE_MESSAGE,
  props<{ exception: Exception }>()
);

const init = createAction(
  AppActionTypes.INIT
);

export const AppActions = {
  toggleShowInactive,
  toggleSideNav,
  setScrolled,
  showSuccessMessage,
  showFailureMessage,
  init,
};


