import {Action, createReducer, on} from '@ngrx/store';
import {CountryEntity} from './country.models';
import {Exception} from '../../../../../../core/models';
import {CountryActions} from './country.actions';

export const COUNTRY_FEATURE_KEY = 'country';

export interface CountryState {
  isLoading: boolean;
  items: CountryEntity[];
  exception: Exception;
}

export interface CountryPartialState {
  readonly [COUNTRY_FEATURE_KEY]: CountryState;
}

export const initialState: CountryState = {
  isLoading: false,
  exception: null,
  items: []
};


export const countryReducer = createReducer(
  initialState,

  on(CountryActions.loadCountriesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(CountryActions.loadCountriesSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(CountryActions.loadCountriesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
);

export function reducer(state: CountryState | undefined, action: Action) {
  return countryReducer(state, action);
}
