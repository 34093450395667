import {createAction, props} from '@ngrx/store';
import {
  DocumentEntity,
  DocumentItemPayload,
  DocumentItemRequest,
  DocumentListPayload,
  DocumentListRequest,
  DocumentRemovePayload
} from './document.models';
import {Params} from '@angular/router';
import {QueryParams} from '@ngrx/data';
import {Update} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';

enum DocumentActionTypes {

  LOAD_DOCUMENTS_REQUEST = '[Document] Load Documents Request',
  LOAD_DOCUMENTS_SUCCESS = '[Document] Load Documents Success',
  LOAD_DOCUMENTS_FAILURE = '[Document] Load Documents Failure',

  GET_DOCUMENT_REQUEST = '[Document] Get Document Request',
  GET_DOCUMENT_SUCCESS = '[Document] Get Document Success',
  GET_DOCUMENT_FAILURE = '[Document] Get Document Failure',

  CREATE_DOCUMENT_REQUEST = '[Document] Create Document Request',
  CREATE_DOCUMENT_SUCCESS = '[Document] Create Document Success',
  CREATE_DOCUMENT_FAILURE = '[Document] Create Document Failure',

  PERSIST_DOCUMENT_REQUEST = '[Document] Persist Document Request',
  PERSIST_DOCUMENT_SUCCESS = '[Document] Persist Document Success',
  PERSIST_DOCUMENT_FAILURE = '[Document] Persist Document Failure',

  UPDATE_DOCUMENT = '[Document] Update Document Request',

  DELETE_DOCUMENT_REQUEST = '[Document] Delete Document Request',
  DELETE_DOCUMENT_SUCCESS = '[Document] Delete Document Success',
  DELETE_DOCUMENT_FAILURE = '[Document] Delete Document Failure',

  RESET_DOCUMENT = '[Document] Reset Document',
}

const loadDocumentsRequest = createAction(
  DocumentActionTypes.LOAD_DOCUMENTS_REQUEST,
  props<{ request: DocumentListRequest }>()
);

const loadDocumentsSuccess = createAction(
  DocumentActionTypes.LOAD_DOCUMENTS_SUCCESS,
  props<{ payload: DocumentListPayload }>()
);

const loadDocumentsFailure = createAction(
  DocumentActionTypes.LOAD_DOCUMENTS_FAILURE,
  props<{ exception: Exception }>()
);

const createDocumentRequest = createAction(
  DocumentActionTypes.CREATE_DOCUMENT_REQUEST,
  props<{ repositoryId: number, materialId: number, queryParams: QueryParams }>()
);

const createDocumentSuccess = createAction(
  DocumentActionTypes.CREATE_DOCUMENT_SUCCESS,
  props<{ payload: DocumentItemPayload }>()
);

const createDocumentFailure = createAction(
  DocumentActionTypes.CREATE_DOCUMENT_FAILURE,
  props<{ exception: Exception }>()
);

const getDocumentRequest = createAction(
  DocumentActionTypes.GET_DOCUMENT_REQUEST,
  props<{ request: DocumentItemRequest }>()
);

const getDocumentSuccess = createAction(
  DocumentActionTypes.GET_DOCUMENT_SUCCESS,
  props<{ payload: DocumentItemPayload }>()
);

const getDocumentFailure = createAction(
  DocumentActionTypes.GET_DOCUMENT_FAILURE,
  props<{ exception: Exception }>()
);

const persistDocumentRequest = createAction(
  DocumentActionTypes.PERSIST_DOCUMENT_REQUEST,
  props<{ document: DocumentEntity, queryParams?: QueryParams }>()
);

const persistDocumentSuccess = createAction(
  DocumentActionTypes.PERSIST_DOCUMENT_SUCCESS,
  props<{ payload: DocumentItemPayload }>()
);

const persistDocumentFailure = createAction(
  DocumentActionTypes.PERSIST_DOCUMENT_FAILURE,
  props<{ exception: Exception }>()
);

const deleteDocumentRequest = createAction(
  DocumentActionTypes.DELETE_DOCUMENT_REQUEST,
  props<{ params: Params, queryParams?: QueryParams }>()
);

const deleteDocumentSuccess = createAction(
  DocumentActionTypes.DELETE_DOCUMENT_SUCCESS,
  props<{ payload: DocumentRemovePayload }>()
);

const deleteDocumentFailure = createAction(
  DocumentActionTypes.DELETE_DOCUMENT_FAILURE,
  props<{ exception: Exception }>()
);

const updateDocument = createAction(
  DocumentActionTypes.UPDATE_DOCUMENT,
  props<{ update: Update<DocumentEntity> }>()
);

const resetDocument = createAction(
  DocumentActionTypes.RESET_DOCUMENT,
);

export const DocumentActions = {
  loadDocumentsRequest,
  loadDocumentsSuccess,
  loadDocumentsFailure,
  createDocumentRequest,
  createDocumentSuccess,
  createDocumentFailure,
  getDocumentRequest,
  getDocumentSuccess,
  getDocumentFailure,
  persistDocumentRequest,
  persistDocumentSuccess,
  persistDocumentFailure,
  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentFailure,
  updateDocument,
  resetDocument,
};
