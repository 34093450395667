import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, mapTo} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {LanguageListPayload, LanguageListResponse, SetLanguageRequest} from './language.models';
import {environment} from '@hb/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(): Observable<LanguageListPayload> {
    return this.http.get<LanguageListResponse>(`${environment.api}/languages`).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }

  set(request: SetLanguageRequest, hasToken: boolean): Observable<string> {

    if (hasToken) {
      return this.http.post('/api/setuserlanguage', null, {headers: {
          'Accept-Language': `${request.id}-${request.id.toUpperCase()}`
        }}).pipe(
        mapTo(request.id)
      );
    } else {
      return of(request.id);
    }
  }
}
