import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {TrainingEntity} from './training.models';
import {TrainingActions} from './training.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const TRAINING_FEATURE_KEY = 'training';

export interface TrainingState extends EntityState<TrainingEntity> {
  isLoading: boolean;
  exception: Exception;
  rows: number;
}

export interface TrainingPartialState {
  readonly [TRAINING_FEATURE_KEY]: TrainingState;
}

export const trainingAdapter: EntityAdapter<TrainingEntity> = createEntityAdapter<TrainingEntity>();

export const initialState: TrainingState = trainingAdapter.getInitialState({
  isLoading: false,
  exception: null,
  rows: 0,
});

const trainingReducer = createReducer(
  initialState,
  on(TrainingActions.loadTrainingsRequest, state => ({
    ...state,
    isLoading: true,

  })),
  on(TrainingActions.loadTrainingsSuccess, (state, {payload}) =>
    trainingAdapter.setAll(payload.items, {
      ...state,
      isLoading: false,
    })
  ),
  on(TrainingActions.loadTrainingsFailure, (state, {exception}) => ({
      ...state,
      isLoading: false,
      exception,
    })
  ),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: TrainingState | undefined, action: Action) {
  return trainingReducer(state, action);
}
