import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LanguageDataService} from './language-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {LANGUAGE_ID} from './language.reducer';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {Action} from '@ngrx/store';
import {Exception} from '../../../../../../core';
import {LanguageActions} from './language.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {AuthenticationService} from '../../../authentication-store';


@Injectable()
export class LanguageEffects implements OnInitEffects {

  loadLanguagesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguagesRequest),

      switchMap(action => this.languageDataService.load().pipe(
        map(payload => LanguageActions.loadLanguagesSuccess({payload})),
        catchError(error => of(LanguageActions.loadLanguagesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadLanguagesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.loadLanguagesSuccess),
    ), {dispatch: false}
  );

  loadLanguagesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(LanguageActions.loadLanguagesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  setLanguageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.setLanguageRequest),
      switchMap(action => this.languageDataService.set(action.request, this.authenticationService.hasToken()).pipe(
        map(payload => LanguageActions.setLanguageSuccess({payload})),
        catchError(error => of(LanguageActions.setLanguageFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  setLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LanguageActions.setLanguageSuccess),

      tap(action => localStorage.setItem(LANGUAGE_ID, action.payload)),
      tap(action => this.translationService.use(action.payload))
    ), {dispatch: false}
  );

  setLanguageFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(LanguageActions.setLanguageFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions,
              private languageDataService: LanguageDataService,
              private authenticationService: AuthenticationService,
              private translationService: TranslateService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }

  ngrxOnInitEffects(): Action {
    return LanguageActions.loadLanguagesRequest();
  }
}




