<h2 mat-dialog-title>{{'dialogs.add_certificate.title' | translate}}</h2>
<mat-dialog-content>
  <form #ngForm="ngForm" fxLayout="column nowrap">

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="training">{{'forms.fields.training.label' | translate}}</label>
      </mat-label>
      <mat-select #training="ngModel" [(ngModel)]="viewModel.trainingId"
                  [placeholder]="'forms.fields.training.placeholder' | translate"
                  id="training"
                  name="training" required>
        <mat-option *ngFor="let training of trainings$ | async"
                    [value]="training.id">{{training.name | translate}}</mat-option>
      </mat-select>
      <mat-hint>{{'forms.fields.training.hint' | translate}}</mat-hint>
      <mat-error *ngIf="training.dirty && training.touched">
        <ng-container *ngIf="training.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="valid_until">{{'forms.fields.valid_until.label' | translate}}</label>
      </mat-label>
      <input #valid_until="ngModel" [(ngModel)]="viewModel.validUntil"
             [matDatepicker]="valid_until_datepicker"
             [placeholder]="'forms.fields.valid_until.placeholder' | translate"
             (click)="valid_until_datepicker.open()"
             id="valid_until" matInput
             name="valid_until" readonly required>
      <mat-hint>{{'forms.fields.valid_until.hint' | translate}}</mat-hint>
      <mat-datepicker-toggle [for]="valid_until_datepicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #valid_until_datepicker
                      [touchUi]="!matchWeb"></mat-datepicker>

      <mat-error *ngIf="valid_until.dirty && valid_until.touched">

      </mat-error>
    </mat-form-field>


  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="viewModel" color="accent"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button [mat-dialog-close] mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
