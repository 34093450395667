import {combineLatest, forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MaterialService, RepositoryService, SearchService, UserService} from '../../../modules/store';
import {environment} from '../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class SearchDialogDataSource {

  constructor(private repositoryService: RepositoryService,
              private materialService: MaterialService,
              private userService: UserService,
              private searchService: SearchService,
              private logger: NGXLogger) {
  }

  get isLoading$(): Observable<boolean> {
    return combineLatest([
      this.repositoryService.isSearching$,
      this.materialService.isSearching$,
      this.userService.isSearching$,
    ]).pipe(map(([a, b, c]) => a || b || c));
  }

  items$(search: string) {
    // return combineLatest([
    //   this.repositoryService.itemsWithProps$,
    //   this.materialService.itemsWithProps$,
    //   this.userService.itemsWithProps$,
    // ]).pipe(
    //   filter(() => !!search && search.length >= 3),
    //   map(([a, b, c]) => [...a, ...b, ...c])
    // );
  }

  get rows$(): Observable<number> {
    return combineLatest([
      this.repositoryService.rows$,
      this.materialService.rows$,
      this.userService.rows$
    ]).pipe(map(([a, b, c]) => a + b + c));
  }

  load(search: string, page: number) {

    forkJoin([
      this.repositoryService.getRows({search, archive: false}),
      this.materialService.getRows({search})
    ]).toPromise().then(([r, m]) => {
      const repositoryPageOffset = Math.round(r / environment.defaultPageSize);
      const materialPageOffset = Math.round(m / environment.defaultPageSize);

      if (page <= repositoryPageOffset) {
        this.repositoryService.load({search, page});
      }
      if (page >= repositoryPageOffset && page <= materialPageOffset) {
        this.materialService.load({search, page});
      }
      if (page >= materialPageOffset) {
        this.userService.load({search, page});
      }
    });
  }
}
