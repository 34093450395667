import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromUsage from './usage.reducer';
import {NGXLogger} from 'ngx-logger';
import {UsageSelectors} from './usage.selectors';
import {UsageActions} from './usage.actions';

@Injectable({
  providedIn: 'root'
})
export class UsageService {

  public readonly isLoading$ = this.store.pipe(select(UsageSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(UsageSelectors.getException));
  public readonly items$ = this.store.pipe(select(UsageSelectors.getItems));
  public readonly default$ = this.store.pipe(select(UsageSelectors.getDefault));

  constructor(private store: Store<fromUsage.UsagePartialState>, logger: NGXLogger) { }

  public load() {
    this.store.dispatch(UsageActions.loadUsagesRequest());
  }
}
