import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {EditShareComponent} from './edit-share.component';

@Injectable({
  providedIn: 'root'
})
export class EditShareDialogService extends BaseDialogService<EditShareComponent, any> {

  constructor(protected matDialog: MatDialog) {
    super(matDialog, EditShareComponent);
  }
}
