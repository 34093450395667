import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {RepositoryService} from '../../modules/store';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private repositoryService: RepositoryService, private router: Router, private logger: NGXLogger) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.router.parseUrl(`/app/inspections`);
  }
}
