import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AuthenticationService, RouterService} from '../../../modules/store';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'hb-change-email-view',
  templateUrl: './change-email-view.component.html',
  styleUrls: ['./change-email-view.component.css']
})
export class ChangeEmailViewComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  constructor(public routerService: RouterService,
              private authenticationService: AuthenticationService,
              private logger: NGXLogger,
              private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.authenticationService.changeEmail({email: params.email, key: params.key});
      })
    );
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.subscription.unsubscribe();
  }
}
