import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromAttachment from './+state/attachment.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AttachmentEffects} from './+state/attachment.effects';
import {NGXLogger} from 'ngx-logger';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromAttachment.ATTACHMENT_FEATURE_KEY,
      fromAttachment.reducer
    ),
    EffectsModule.forFeature([AttachmentEffects]),
  ]
})
export class AttachmentStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}

