import {createAction, props} from '@ngrx/store';
import {CategoryListPayload} from './category.models';
import {Exception} from '../../../../../../core/models';

enum CategoryActionTypes {
  LOAD_CATEGORY_REQUEST = '[Category] Load Category Request',
  LOAD_CATEGORY_SUCCESS = '[Category] Load Category Success',
  LOAD_CATEGORY_FAILURE = '[Category] Load Category Failure',
}

const loadCategoriesRequest = createAction(
  CategoryActionTypes.LOAD_CATEGORY_REQUEST
);

const loadCategoriesSuccess = createAction(
  CategoryActionTypes.LOAD_CATEGORY_SUCCESS,
  props<{ payload: CategoryListPayload }>()
);

const loadCategoriesFailure = createAction(
  CategoryActionTypes.LOAD_CATEGORY_FAILURE,
  props<{ exception: Exception }>()
);

export const CategoryActions = {
  loadCategoriesRequest,
  loadCategoriesSuccess,
  loadCategoriesFailure,
};
