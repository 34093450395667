import {Directive, ElementRef, Input, Renderer2} from '@angular/core';

export type ExtendedThemePalette = 'primary' | 'accent' | 'warn' | 'success' | 'danger' | 'info' | 'mute' | undefined;

@Directive({
  selector: '[appTheme]'
})
export class ThemeDirective {

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @Input() set appTheme(value: ExtendedThemePalette) {
    this.renderer.addClass(this.element.nativeElement, `mat-${value}`);
  }

}
