import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {MANUFACTURER_FEATURE_KEY, ManufacturerPartialState, ManufacturerState} from './manufacturer.reducer';

import {ManufacturerEntity} from './manufacturer.models';
import {Exception} from '../../../../../../core/models';

const getManufacturerState = createFeatureSelector<ManufacturerPartialState, ManufacturerState>(MANUFACTURER_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getManufacturerState,
  (state: ManufacturerState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getManufacturerState,
  (state: ManufacturerState) => state.exception
);

const getItems: MemoizedSelector<object, ManufacturerEntity[]> = createSelector(
  getManufacturerState,
  (state: ManufacturerState) => state.items
);

export const ManufacturerSelectors = {
  isLoading,
  getException,
  getItems,
};
