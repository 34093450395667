import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateUserComponent} from './create-user.component';
import {UserEntity, UserViewModel} from '../../../modules/store/src/modules/user-store';

@Injectable({
  providedIn: 'root'
})
export class CreateUserDialogService extends BaseDialogService<CreateUserComponent, UserEntity, CreateUserDialogResult> {

  protected config: MatDialogConfig = {
    width: '80vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, CreateUserComponent);
  }
}

interface CreateUserDialogResult {
  entity: UserViewModel;
  next: boolean;
}
