import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {matchMimeType} from '../../../modules/core/utils';
import {HintDialogService} from '../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {ImageViewerDialogService} from '../../../dialogs/image-viewer/src/image-viewer-dialog.service';
import {EditAttachmentDialogService} from '../../../dialogs/edit-attachment/src/edit-attachment-dialog.service';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {AttachmentService, AttachmentViewModel} from '../../../modules/store';

@Component({
  selector: 'hb-attachment-item-view',
  templateUrl: './attachment-item-view.component.html',
  styleUrls: ['./attachment-item-view.component.css']
})
export class AttachmentItemViewComponent implements OnInit, OnDestroy {

  @Input()
  parentType: 'material' | 'document';

  @Input()
  viewModel: AttachmentViewModel;

  @Input()
  disabled: boolean;

  constructor(private attachmentService: AttachmentService,
              private imageViewerDialogService: ImageViewerDialogService,
              private editAttachmentDialogService: EditAttachmentDialogService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }

  get placeholder() {
    if (this.viewModel.mimetype.includes('image/')) {
      return this.attachmentService.url(this.viewModel.id, '80x80');
    }
  }

  get icon() {
    if (this.viewModel.mimetype.includes('image/')) {
      return 'file-image';
    } else {
      return 'file-pdf';
    }
  }

  ngOnInit(): void {
  }

  delete(): void {
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      filter(result => result),
    ).subscribe(() =>
      this.attachmentService.remove(this.viewModel));
  }

  edit(): void {
    this.editAttachmentDialogService.open(this.viewModel).afterClosed().pipe(
      filter(result => !!result),
      map(result => !result.match('([a-zA-Z0-9\\_\\\\.\\-\\(\\):])+(.jpg|.pdf|.jpeg)$')
        ? result + '.' + this.viewModel.filename.split('.')[1] : result),
    ).subscribe((filename) => {
      this.viewModel.filename = filename;
      this.attachmentService.update(this.viewModel);
    });
  }

  open(): void {
    if (matchMimeType(this.viewModel.mimetype, 'image/*')) {
      this.imageViewerDialogService.open({value: this.viewModel});
    } else {
      this.attachmentService.open(this.viewModel);
    }
  }

  download(): void {
    this.attachmentService.download(this.viewModel);
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }

}
