<h2 mat-dialog-title>{{'dialogs.select_product.title' | translate}}</h2>
<mat-dialog-content>
  <div fxLayout="column nowrap">
    <hb-autocomplete-chip-list (filterChange)="handleFilterChange($event)"
                               (selectionChange)="handleSelectionChange($event)"
                               [autocompleteInputList$]="tileService.autocomplete$">

    </hb-autocomplete-chip-list>
    <mat-divider></mat-divider>
    <div #listContainer [style.height]="availableHeight" class="border hb-overflow-hidden">
      <mat-grid-list [cols]="columns()" [style.height]="availableHeight" gutterSize="16">
        <virtual-scroller #virtualScroller (vsChange)="fetch($event)" [items]="tileService.items$ | async"
                          [style.height]="availableHeight">
          <ng-container *ngFor="let tile of virtualScroller.viewPortItems">
            <mat-grid-tile (click)="handleTileClick(tile)" class="hb-tile" mat-ripple>
              <img [class.hb-default-image]="!tile.mediaId && !tile.mediaUrl" alt="image"
                   [src]="tile.mediaId ? getImage(tile.mediaId) : tile.mediaUrl"
                   class="hb-img-fluid bg-gray-50"/>
              <mat-grid-tile-footer>
                <span mat-line>{{tile.title}}</span>
                <span mat-line>{{tile.subTitle}}</span>
              </mat-grid-tile-footer>
            </mat-grid-tile>
          </ng-container>

        </virtual-scroller>
      </mat-grid-list>
    </div>

  </div>


</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between center">
  <div style="padding-bottom: 20px">
    <mat-icon [matTooltip]="'dialogs.select_product.manufacturer_info' | translate" color="info"
              fontIcon="fa-info-circle"
              fontSet="fas" inline matTooltipTouchGestures="on"></mat-icon>
    <small> {{'dialogs.select_product.manufacturer_info' | translate}}</small>
  </div>
  <div fxLayout="row nowrap" fxLayoutAlign="end center" style="width: 100%">
    <button color="accent" mat-dialog-close="0"
            mat-raised-button>{{'keys.local.global.product_not_found' | translate}}</button>
    <button [mat-dialog-close]="null" mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
  </div>

</mat-dialog-actions>
