<h2 mat-dialog-title>{{'dialogs.edit_password.title' | translate}}</h2>
<mat-dialog-content>
  <form #ngForm="ngForm" ngForm fxLayout="column nowrap">
    <mat-form-field *ngIf="!sendViaEmail.value" appearance="outline">
      <mat-label>
        <label for="password">{{'forms.fields.password.label' | translate}}</label>
      </mat-label>
      <input #password="ngModel"
             [(ngModel)]="viewModel.password"
             [placeholder]="'forms.fields.password.placeholder' | translate"
             id="password"
             matInput
             name="password"
             type="password"
             [pattern]="password_pattern" required>
      <mat-error *ngIf="password.dirty && password.touched">
        <ng-container *ngIf="password.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="password.hasError('pattern')">
          {{'forms.errors.password_pattern' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!sendViaEmail.value" appearance="outline">
      <mat-label>
        <label for="confirm">{{'forms.fields.password_confirm.label' | translate}}</label>
      </mat-label>
      <input #confirm="ngModel"
             [placeholder]="'forms.fields.password_confirm.placeholder' | translate"
             id="confirm"
             matInput
             ngModel
             name="confirm"
             type="password"
             ngValidateEqual="password"
             required>
      <mat-hint>{{'forms.fields.password_confirm.hint' | translate}}</mat-hint>
      <mat-error *ngIf="confirm.dirty && confirm.touched">
        <ng-container *ngIf="confirm.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="confirm.hasError('notEqual')">
          {{'forms.errors.password_is_not_equal' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-checkbox #sendViaEmail="ngModel" [(ngModel)]="viewModel.sendEmail" name="sendViaEmail">
      {{'forms.fields.generate_password.label' | translate}}
    </mat-checkbox>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="viewModel" color="accent"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
