import {Component, Input, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BreakpointService, isNotNullOrUndefined} from '../../../modules/core';
import {ExtendedThemePalette} from '../../../directives/theme';
import {RepositoryService, RouterService, SegmentEntity} from '../../../modules/store';
import {Platform} from '@angular/cdk/platform';

interface BreadcrumbItem {
  id?: number;
  name: string;
  items?: BreadcrumbItem[];
  icon?: string;
  type?: string;
}


@Component({
  selector: 'hb-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input()
  public set items(items: SegmentEntity[]) {
    if (isNotNullOrUndefined(items)) {
      this._items = this.convert(items);
    }
  }

  @Input()
  public view: 'inventory' | 'users' = 'inventory';

  private _items: BreadcrumbItem[] = [];

  constructor(private repositoryService: RepositoryService,
              private routerFacade: RouterService,
              private breakpointService: BreakpointService,
              private platform: Platform,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.items = [];
  }

  get isIOS() {
    return this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  }

  getLength() {
    return this._items.length;
  }

  getItems(): BreadcrumbItem[] {
    return this._items;
  }

  getIcon(type: string, index: number) {
    if (type === 'PhysicalRepositoryGroup' && index === 0) {
      return 'fa-home';
    } else if (type === 'PhysicalRepositoryGroup' || type === 'RepositoryGroup') {
      return 'fa-folder';
    } else if (type === 'LogicalRepositoryGroup') {
      return 'fa-share-alt';
    } else if (type === 'Material') {
      return 'fa-dice-d6';
    } else if (type === 'FormDocument') {
      return 'fa-file';
    } else if (type === 'User') {
      return 'fa-user';
    }
  }

  home() {
    this.routerFacade.home();
  }

  navigate(segment: BreadcrumbItem) {
    if (segment.type === 'PhysicalRepositoryGroup'
      || segment.type === 'RepositoryGroup'
      || segment.type === 'LogicalRepositoryGroup') {
      if (this.view === 'inventory') {
        return this.routerFacade.createToPlacesUrl(segment.id);
      } else if (this.view === 'users') {
        return this.routerFacade.createToUserListUrl(segment.id);
      }
    } else if (segment.type === 'Material') {
      return this.routerFacade.createToMaterialViewUrl(this.routerFacade.repositoryId, segment.id);
    } else if (segment.type === 'FormDocument') {
      return this.routerFacade.createToDocumentViewUrl(this.routerFacade.repositoryId, this.routerFacade.materialId, segment.id);
    } else if (segment.type === 'User') {
      return this.routerFacade.createToUserViewUrl(this.routerFacade.repositoryId, segment.id);
    } else {
      return '';
    }
  }

  public getMaxLength() {
    if (this.breakpointService.matchWeb()) {
      return 6;
    } else if (this.breakpointService.matchTablet()) {
      return 5;
    } else if (this.breakpointService.matchHandset()) {
      return 4;
    }
  }

  private generate(items: BreadcrumbItem[]) {
    return {
      id: 0,
      name: '',
      items,
      icon: 'fa-ellipsis-h'
    };
  }

  public getColor(isActive): ExtendedThemePalette {
    return isActive ? 'primary' : 'mute';
  }

  private convert(items: SegmentEntity[]): BreadcrumbItem[] {

    const tmp: BreadcrumbItem[] = items.map((item, index) => ({...item, icon: this.getIcon(item.type, index)}));

    if (items.length > this.getMaxLength()) {
      return [tmp[0], this.generate(tmp.slice(1, this.items.length - (this.getMaxLength() - 2))),
        ...tmp.slice(this.items.length - (this.getMaxLength() - 2))];
    } else {
      return tmp;
    }
  }

}
