import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromLanguage from './language.reducer';
import {NGXLogger} from 'ngx-logger';
import {SetLanguageRequest} from './language.models';
import {LanguageActions} from './language.actions';
import {LanguageSelectors} from './language.selectors';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public readonly isLoading$ = this.store.pipe(select(LanguageSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(LanguageSelectors.getException));
  public readonly items$ = this.store.pipe(select(LanguageSelectors.getItems));
  public readonly selected$ = this.store.pipe(select(LanguageSelectors.getSelected));

  constructor(private store: Store<fromLanguage.LanguagePartialState>, logger: NGXLogger) {
  }

  public load() {
    this.store.dispatch(LanguageActions.loadLanguagesRequest());
  }

  public set(request: SetLanguageRequest) {
    this.store.dispatch(LanguageActions.setLanguageRequest({request}));
  }
}
