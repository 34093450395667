import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {FormDataService} from './form-data.service';
import {Exception} from '../../../../../../core/models';
import {FormActions} from './form.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class FormEffects {

  loadFormsRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(FormActions.loadFormsRequest),

      switchMap(action => this.formDataService.load(action.request.repositoryId, action.request.materialId).pipe(
        map(payload => FormActions.loadFormsSuccess({payload})),
        catchError(error => of(FormActions.loadFormsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadFormsSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(FormActions.loadFormsSuccess),
    ), {dispatch: false}
  );
  loadFormsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(FormActions.loadFormsFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private formDataService: FormDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }


}
