import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as AppSelectors from './app.selectors';
import * as fromApp from './app.reducer';
import {filter, flatMap, map, mergeMap, tap} from 'rxjs/operators';
import {Action, select, Store} from '@ngrx/store';
import {BreakpointService} from '../../../../../../core/services/breakpoint';
import {AppDataService} from './app-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {AppActions} from './app.actions';
import {LanguageActions} from '../../../language-store/src/+state/language.actions';
import {CountryActions} from '../../../country-store/src/+state/country.actions';

@Injectable()
export class AppEffects implements OnInitEffects {

  setShowInactive$ = createEffect(
    () => this.actions$.pipe(
      ofType(AppActions.toggleShowInactive),

      flatMap(() => this.store.pipe(select(AppSelectors.getShowInactive))),
      tap((showInactive) => this.appDataService.setShowInactive(showInactive))
    ), {dispatch: false}
  );

  showErrorMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.showFailureMessage),
      map(action => action.exception),
      filter(exception => !(exception.status === 401 && exception.code === null)),
      tap(exception => console.log(exception)),
      tap(exception => {
        if (!!exception.code) {
          this.hintDialogService.openErrorV2(exception.code, exception.status);
        } else {
          this.hintDialogService.openError(exception.message, exception.status);
        }
      }),
    ), {dispatch: false}
  );

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.init),
      mergeMap(() => [
        LanguageActions.loadLanguagesRequest(),
        CountryActions.loadCountriesRequest()
      ])
    )
  );

  constructor(private actions$: Actions,
              private store: Store<fromApp.AppPartialState>,
              private appDataService: AppDataService,
              private breakpointService: BreakpointService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }

  ngrxOnInitEffects(): Action {
    if (this.breakpointService.matchWeb()) {
      return AppActions.toggleSideNav({isOpen: true});
    } else {
      return AppActions.toggleSideNav({isOpen: false});
    }
  }
}
