import {Dictionary} from '@ngrx/entity';
import {
  EditableViewModel,
  ExceptionModel,
  PayloadItemModel,
  PayloadListModel,
  ResponseItemModel,
  ResponseListModel,
  TagEntity
} from '../../../../../../core/models';
import {groupBy} from 'lodash';
import {QueryParams} from '@ngrx/data';
import {AddressEntity, AddressViewModel, addressViewModelFactory} from '../../../address-store';
import {AttachmentEntity} from '../../../attachment-store';
import {MaterialEntity} from '../../../material-store';


export interface SealDocumentRequest {
  document: DocumentEntity;
  queryParams?: QueryParams;
}

export interface DocumentItemRequest {
  repositoryId: number;
  materialId: number;
  documentId: number;
}

export interface DocumentListRequest {
  repositoryId: number;
  materialId: number;
}

export interface DocumentItemResponse extends ResponseItemModel<DocumentEntity> {
}

export interface DocumentListResponse extends ResponseListModel<DocumentEntity> {
}

export interface DocumentItemPayload extends PayloadItemModel<DocumentEntity> {
}

export interface DocumentListPayload extends PayloadListModel<DocumentEntity> {
}

export interface DocumentRemovePayload {
  repositoryId: number;
  materialId: number;
  documentId: number;
}

export interface DocumentException extends ExceptionModel {
}

export interface DocumentEntity {
  id: number;
  name: string;
  form: number;
  material: number;
  caption: string;
  readOnly: boolean;
  fields: FieldEntity[];
  date: string;
  validUntil: string;
  status: TagEntity;
  pdf: string;
  pdfToken: string;
  pdfFilename: string;
  labelPdfFilename: string;
  hasPdf: boolean;
  hasLabelPdf: boolean;
  createdAt?: null;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  repositoryId: number;
  labelPdf?: null;
  zebraLabelZpl?: null;
  printerIp: string;
  materialId: number;
  attachments: AttachmentEntity[];
  signatures?: AttachmentEntity[];
}

export class DocumentViewModel extends EditableViewModel<DocumentEntity> {

  private readonly _fieldViewModels: FieldViewModel[];

  constructor(model: DocumentEntity) {
    super(model);
    this._fieldViewModels = this.model.fields.map(item => fieldViewModelFactory(item));
  }

  public get objectId(): string {
    return `doc://${this.id}`;
  }

  get id(): number {
    return this.model.id;
  }

  set id(value: number) {
    this.model.id = value;
  }

  get title(): string {
    return this.model.caption;
  }

  set title(value: string) {
    this.model.caption = value;
  }

  get createdAt(): null {
    return this.model.createdAt;
  }

  set createdAt(value: null) {
    this.model.createdAt = value;
  }

  get createdBy(): string {
    return this.model.createdBy;
  }

  set createdBy(value: string) {
    this.model.createdBy = value;
  }

  get updatedAt(): string {
    return this.model.updatedAt;
  }

  set updatedAt(value: string) {
    this.model.updatedAt = value;
  }

  get updatedBy(): string {
    return this.model.updatedBy;
  }

  set updatedBy(value: string) {
    this.model.updatedBy = value;
  }

  get date(): string {
    return this.model.date;
  }

  set date(value: string) {
    this.model.date = value;
  }

  get fields(): FieldViewModel[] {
    return this._fieldViewModels;
  }

  get groups(): Dictionary<FieldViewModel[]> {
    return groupBy(this.fields, 'group');
  }

  getGroupCaption(group: string) {
    return this.fields.filter(item => item.group === group).map(item => item.groupCaption)[0];
  }

  get hasLabelPdf(): boolean {
    return this.model.hasLabelPdf;
  }

  set hasLabelPdf(value: boolean) {
    this.model.hasLabelPdf = value;
  }

  get labelPdfFilename(): string {
    return this.model.labelPdfFilename;
  }

  set labelPdfFilename(value: string) {
    this.model.labelPdfFilename = value;
  }

  get hasPdf(): boolean {
    return this.model.hasPdf;
  }

  set hasPdf(value: boolean) {
    this.model.hasPdf = value;
  }

  get pdfFilename(): string {
    return this.model.pdfFilename;
  }

  set pdfFilename(value: string) {
    this.model.pdfFilename = value;
  }

  get readOnly(): boolean {
    return this.model.readOnly;
  }

  set readOnly(value: boolean) {
    this.model.readOnly = value;
  }

  get materialId(): number {
    return this.model.materialId;
  }

  set materialId(value: number) {
    this.model.materialId = value;
  }

  get repositoryId(): number {
    return this.model.repositoryId;
  }

  set repositoryId(value: number) {
    this.model.repositoryId = value;
  }

  get status(): TagEntity {
    return this.model.status;
  }

  set status(value: TagEntity) {
    this.model.status = value;
  }

  get updated(): DocumentEntity {
    return {...this.model, ...this.changes, fields: this._fieldViewModels.map(item => item.updated)};
  }
}

export function documentViewModelFactory(entity: DocumentEntity): DocumentViewModel {
  return new DocumentViewModel(entity);
}





export interface FieldEntity {
  id: number;
  field: string;
  caption: string;
  editor: string;
  width: number;
  readOnly: boolean;
  defaultValue: string;
  mandatory: boolean;
  validValue: string | null;
  disabled: boolean;
  value: string;
  order: number;
  group: string;
  material: MaterialEntity;
  valueRequired: boolean;
  groupCaption: string;
  address: AddressEntity;
  mediaPoolFile: AttachmentEntity;
}

export class FieldViewModel extends EditableViewModel<FieldEntity> {

  private readonly _addressViewModel: AddressViewModel;

  constructor(model: FieldEntity) {
    super(model);
    this._addressViewModel = addressViewModelFactory(model.address);
  }

  get id(): number {
    return this.model.id;
  }

  get field(): string {
    return this.model.field;
  }

  get caption(): string {
    return this.model.caption;
  }

  get editor(): string {
    return this.model.editor;
  }

  get width(): number {
    return this.model.width;
  }

  get readOnly(): boolean {
    return this.model.readOnly;
  }

  get defaultValue(): string {
    return this.model.defaultValue;
  }

  get mandatory(): boolean {
    return this.model.mandatory;
  }

  get validValue(): string | null {
    return this.model.validValue;
  }

  get value(): string {
    return this.model.value;
  }

  set value(value: string) {
    this.changes.value = value;
  }

  get order(): number {
    return this.model.order;
  }

  get group(): string {
    return this.model.group;
  }

  get material(): MaterialEntity {
    return this.model.material;
  }

  get valueRequired(): boolean {
    return this.model.valueRequired;
  }

  get groupCaption(): string {
    return this.model.groupCaption;
  }

  get address(): AddressViewModel {
    return this._addressViewModel;
  }

  get mediaPoolFile(): AttachmentEntity {
    return this.model.mediaPoolFile;
  }

  set mediaPoolFile(value: AttachmentEntity) {
    this.changes.mediaPoolFile = value;
  }

  get disabled(): boolean {
    return this.model.disabled;
  }

  set disabled(value) {
    this.changes.disabled = value;
  }

  get updated(): FieldEntity {
    return {...this.model, ...this.changes, address: this._addressViewModel.updated};
  }
}

export function fieldViewModelFactory(entity: FieldEntity): FieldViewModel {
  return new FieldViewModel(entity);
}

