import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {filter} from 'rxjs/operators';
import {HintDialogService} from '../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {RouterService, UserService, UserViewModel} from '../../../modules/store';

interface ButtonConfiguration {
  status?: boolean;
  context?: boolean;
}

@Component({
  selector: 'hb-user-item-view',
  templateUrl: './user-item-view.component.html',
  styleUrls: ['./user-item-view.component.css']
})
export class UserItemViewComponent implements OnInit, OnDestroy {

  @Input()
  viewModel: UserViewModel;

  @Input()
  public showPath = false;

  @Input()
  public search = false;

  @Input()
  public disabled = false;

  @Output()
  public tapActionEvent = new EventEmitter<{ name: string, data?: any }>();

  constructor(private userService: UserService,
              private routerService: RouterService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }

  private _buttons: ButtonConfiguration = {status: false, context: false};

  public get buttons(): ButtonConfiguration {
    return this._buttons;
  }

  @Input()
  public set buttons(buttons: ButtonConfiguration) {
    this._buttons = {...this._buttons, ...buttons};
  }

  ngOnInit(): void {

  }

  open() {
    this.routerService.navigateToUserView(this.viewModel.repositoryId, this.viewModel.id);
    this.tapActionEvent.emit({name: 'open'});
  }

  edit() {
    this.routerService.navigateToUserView(this.viewModel.repositoryId, this.viewModel.id);
    this.tapActionEvent.emit({name: 'edit'});
  }

  move() {
    this.tapActionEvent.emit({name: 'move'});
  }

  delete() {
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      filter(result => !!result),
    ).subscribe(() => {
      this.tapActionEvent.emit({name: 'delete'});
    });
  }

  openInRepository() {
    this.routerService.navigateToUserList(this.viewModel.repositoryId);
    this.tapActionEvent.emit({name: 'openIn'});
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }

}
