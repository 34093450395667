import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromAdministration from './+state/administration.reducer';
import {EffectsModule} from '@ngrx/effects';
import {AdministrationEffects} from './+state/administration.effects';
import {NGXLogger} from 'ngx-logger';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromAdministration.ADMINISTRATION_FEATURE_KEY,
      fromAdministration.reducer
    ),
    EffectsModule.forFeature([AdministrationEffects])
  ],
})
export class AdministrationStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
