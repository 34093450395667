import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BreakpointService} from '../../../modules/core';
import {MessageService} from '../../message';
import {CreateMaterialRequest, MaterialEntity, MaterialService, RouterService, UsageService} from '../../../modules/store';


@Component({
  selector: 'hb-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.scss']
})
export class ScanComponent implements OnInit, AfterViewInit {

  @ViewChild('ngForm')
  ngForm: NgForm;
  private readonly _currentDate: Date;

  constructor(
    public usageService: UsageService,
    public materialService: MaterialService,
    public routerFacade: RouterService,
    public breakpointService: BreakpointService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: CreateMaterialRequest) {
    this._currentDate = new Date(Date.now());
  }

  private _viewModel: CreateMaterialRequest = {...this.data, usageId: 1003};

  public get viewModel() {
    return this._viewModel;
  }

  public get currentDate() {
    return this._currentDate;
  }

  get usages$() {
    return this.usageService.items$;
  }

  public get matchWeb() {
    return this.breakpointService.matchWeb();
  }

  ngOnInit(): void {
    this.usageService.load();
  }

  ngAfterViewInit(): void {

  }

  handleEnter() {
    if (this.ngForm.valid) {
      this.materialService.persist(this.viewModel as MaterialEntity).subscribe(() => {
        this.messageService.open(this.translateService.instant('keys.local.messages.successful_added'));
        this._viewModel = {...this.viewModel, barcode: ''};
      });
    }
  }

}
