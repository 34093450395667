import {createAction, props} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {SegmentListPayload, SegmentListRequest} from './path.models';


enum PathActionTypes {
  LOAD_PATH_REQUEST = '[Path] Load Path Request',
  LOAD_PATH_SUCCESS = '[Path] Load Path Success',
  LOAD_PATH_FAILURE = '[Path] Load Path Failure',
}

const loadPathRequest = createAction(
  PathActionTypes.LOAD_PATH_REQUEST,
  props<{ request: SegmentListRequest }>()
);

const loadPathSuccess = createAction(
  PathActionTypes.LOAD_PATH_SUCCESS,
  props<{ payload: SegmentListPayload }>()
);

const loadPathFailure = createAction(
  PathActionTypes.LOAD_PATH_FAILURE,
  props<{ exception: Exception }>()
);

export const PathActions = {
  loadPathRequest,
  loadPathSuccess,
  loadPathFailure,
};
