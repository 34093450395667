import {Directive, Host, OnInit} from '@angular/core';
import {NgModel} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';

@Directive({
  selector: '[touched]'
})
export class MarkAsTouchedDirective implements OnInit {

  constructor(@Host() private ngModel: NgModel, private logger: NGXLogger) { }

  ngOnInit(): void {
    this.ngModel.control.markAsTouched();
  }

}
