import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CertificateDataService} from './certificate-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {TranslateService} from '@ngx-translate/core';
import {Exception} from '../../../../../../core/models';
import {CertificateActions} from './certificate.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';

@Injectable()
export class CertificateEffects {

  loadCertificatesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificateActions.loadCertificatesRequest),
      switchMap(action => this.certificateService.load(action.request).pipe(
        map(payload => CertificateActions.loadCertificatesSuccess({payload})),
        catchError(error => of(CertificateActions.loadCertificatesFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadCertificatesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificateActions.loadCertificatesSuccess),
    ), {dispatch: false}
  );
  loadCertificatesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(CertificateActions.loadCertificatesFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  persistCertificateRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(CertificateActions.persistCertificateRequest),
      switchMap(action => this.certificateService.persist(action.certificate).pipe(
        map(payload => CertificateActions.persistCertificateSuccess({payload})),
        catchError(error => of(CertificateActions.persistCertificateFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  persistCertificateSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(CertificateActions.persistCertificateSuccess),
    ), {dispatch: false}
  );
  persistCertificateFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(CertificateActions.persistCertificateFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  deleteCertificateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CertificateActions.deleteCertificateRequest),
      switchMap(action => this.certificateService.delete(action.certificate).pipe(
        map(payload => CertificateActions.deleteCertificateSuccess({payload})),
        catchError(error => of(CertificateActions.deleteCertificateFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  deleteCertificateSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(CertificateActions.deleteCertificateSuccess),
    ), {dispatch: false}
  );
  deleteCertificateFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(CertificateActions.deleteCertificateFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private certificateService: CertificateDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private translateService: TranslateService,
              private logger: NGXLogger) {

  }
}
