import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {PATH_FEATURE_KEY, PathPartialState, PathState} from './path.reducer';
import {SegmentEntity} from './path.models';
import {Exception} from '../../../../../../core/models';


const getPathState = createFeatureSelector<PathPartialState, PathState>(PATH_FEATURE_KEY);

const getIsLoading: MemoizedSelector<object, boolean> = createSelector(
  getPathState,
  (state: PathState) => state.isLoading
);

const getItems: MemoizedSelector<object, SegmentEntity[]> = createSelector(
  getPathState,
  (state: PathState) => state.segments
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getPathState,
  (state: PathState) => state.exception
);

export const PathSelectors = {
  getIsLoading,
  getItems,
  getException,
};
