import {HttpParameterCodec, HttpParams} from '@angular/common/http';

import {isNotNullOrUndefined} from './rx-extensions';
import {environment} from '@hb/environments/index';

export class HttpParamEncoder implements HttpParameterCodec {

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }

  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
}

export function appendIfExists(httpParams: HttpParams, key: string, value: any) {
  return value !== undefined && value !== null && value !== '' ? httpParams.append(key, value.toString()) : httpParams;
}

export function HttpParamsFactory() {
  return environment.encode ? new HttpParams({encoder: new HttpParamEncoder()}) : new HttpParams();
}

export class HttpParamsBuilder {

  private params: HttpParams;

  private constructor() {
    this.params = environment.encode ? new HttpParams({encoder: new HttpParamEncoder()}) : new HttpParams();
  }

  public static get(): HttpParamsBuilder {
    return new HttpParamsBuilder();
  }

  public append(key: string, value: any): HttpParamsBuilder {
    if (isNotNullOrUndefined(value) && value !== '') {
      this.params = this.params.append(key, value.toString());
    }
    return this;
  }

  public build(): HttpParams {
    return this.params;
  }
}
