import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AccountEntity, AccountItemPayload, AccountItemResponse, AccountListPayload, AccountListResponse} from './account.models';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {ACCOUNT_ID} from './account.reducer';
import {environment} from '@hb/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) { }

  load(): Observable<AccountListPayload> {
    return this.http.get<AccountListResponse>(`${environment.api}/accounts`).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }

  get(accountId: number): Observable<AccountItemPayload> {

    return this.http.get<AccountItemResponse>(`${environment.api}/accounts/${accountId}`).pipe(
      map((res) => ({item: res.data}))
    );
  }

  persist(account: AccountEntity) {
    return this.http.put<AccountItemResponse>(`${environment.api}/accounts/${account.id}`, account).pipe(
      map(res => ({item: res.data}))
    );
  }

  setAccountId(accountId: number) {
    window.localStorage.setItem(ACCOUNT_ID, accountId.toString());
  }

  removeAccountId() {
    window.localStorage.removeItem(ACCOUNT_ID);
  }
}
