import {Action, createReducer, on} from '@ngrx/store';
import {SegmentEntity} from './path.models';
import {Exception} from '../../../../../../core/models';
import {PathActions} from './path.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const PATH_FEATURE_KEY = 'path';

export interface PathState {
  isLoading: boolean;
  segments: SegmentEntity[];
  exception: Exception;
}

export interface PathPartialState {
  readonly [PATH_FEATURE_KEY]: PathState;
}

export const initialState: PathState = {
  isLoading: false,
  segments: [],
  exception: null
};


export const pathReducer = createReducer(
  initialState,
  on(PathActions.loadPathRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(PathActions.loadPathSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    segments: payload.items
  })),
  on(PathActions.loadPathFailure, (state, {exception}) => ({
      ...state,
      exception
    })
  ),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: PathState | undefined, action: Action) {
  return pathReducer(state, action);
}
