import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActionReducerMap, StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {metaReducers, reducers} from './+state/root.reducer';
import {RootFacade} from './+state/root.facade';
import {RootEffects} from './+state/root.effects';
import {FormStoreModule} from './modules/form-store/src/form-store.module';
import {CategoryStoreModule} from './modules/category-store/src/category-store.module';
import {CountryStoreModule} from './modules/country-store/src/country-store.module';
import {LanguageStoreModule} from './modules/language-store/src/language-store.module';
import {ManufacturerStoreModule} from './modules/manufacturer-store/src/manufacturer-store.module';
import {UsageStoreModule} from './modules/usage-store/src/usage-store.module';
import {AccountStoreModule} from './modules/account-store/src/account-store.module';
import {SearchStoreModule} from './modules/search-store/src/search-store.module';
import {PermissionStoreModule} from './modules/permission-store/src/permission-store.module';
import {AttachmentStoreModule} from './modules/attachment-store/src/attachment-store.module';
import {NGXLogger} from 'ngx-logger';
import {AdministrationStoreModule} from './modules/administration-store/src/administration-store.module';
import {PathStoreModule} from './modules/path-store/src/path-store.module';
import {CertificateStoreModule} from './modules/certificate-store/src/certificate-store.module';
import {ProductStoreModule} from './modules/product-store/src/product-store.module';
import {RouterStoreModule} from './modules/router-store/src/router-store.module';
import {TileStoreModule} from './modules/tile-store/src/tile-store.module';
import {TrainingStoreModule} from './modules/trainings-store/src/training-store.module';
import {AppStoreModule} from './modules/app-store/src/app-store.module';
import {UserStoreModule} from './modules/user-store/src/user-store.module';
import {AuthenticationStoreModule} from './modules/authentication-store/src/authentication-store.module';
import {DocumentStoreModule} from './modules/document-store/src/document-store.module';
import {RepositoryStoreModule} from './modules/repository-store/src/repository-store.module';
import {MaterialStoreModule} from './modules/material-store/src/material-store.module';
import {LicenseStoreModule} from './modules/license-store/src/license-store.module';
import {DetailCategoryStoreModule} from './modules/detail-category-store/src/detail-category-store.module';
import {AddressStoreModule} from './modules/address-store/src/address-store.module';
import {environment} from '../../../../environments';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(reducers as ActionReducerMap<{}>, {metaReducers}),
    EffectsModule.forRoot([RootEffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    AppStoreModule,
    RouterStoreModule,
    AuthenticationStoreModule,
    RepositoryStoreModule,
    MaterialStoreModule,
    UserStoreModule,
    DocumentStoreModule,
    FormStoreModule,
    ProductStoreModule,
    TrainingStoreModule,
    TileStoreModule,
    AdministrationStoreModule,
    PathStoreModule,
    CategoryStoreModule,
    DetailCategoryStoreModule,
    CountryStoreModule,
    LanguageStoreModule,
    ManufacturerStoreModule,
    UsageStoreModule,
    AccountStoreModule,
    CertificateStoreModule,
    SearchStoreModule,
    PermissionStoreModule,
    AttachmentStoreModule,
    LicenseStoreModule,
    AddressStoreModule
  ],
  providers: [RootFacade],
})
export class RootStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
