import {ExceptionModel, PayloadItemModel, PayloadListModel, ResponseItemModel, ResponseListModel} from '../../../../../../core';
import {remoteTranslationMap} from '../../../../../../core/maps/src/translation.maps';


export interface UsageItemResponse extends ResponseItemModel<UsageEntity> {}

export interface UsageListResponse extends ResponseListModel<UsageEntity> {}

export interface UsageItemPayload extends PayloadItemModel<UsageEntity> {}

export interface UsageListPayload extends PayloadListModel<UsageEntity> {}

export interface UsageException extends ExceptionModel {}

export class UsageViewModel {
  private readonly _model: UsageEntity;

  constructor(entity: UsageEntity) {
    this._model = entity;
  }

  get id(): number {
    return this.model.id;
  }

  get key(): string {
    return remoteTranslationMap[this.model.name];
  }

  get default(): boolean {
    return this.model.defaultValue;
  }

  get order(): number {
    return this.model.order;
  }

  public get model(): UsageEntity {
    return this._model;
  }
}

export function usageViewModelFactory(entity: UsageEntity): UsageViewModel {
  return new UsageViewModel(entity);
}

export interface UsageEntity {
  id: number;
  defaultValue: boolean;
  name: string;
  order: number;
}

