<ng-container [ngSwitch]="mode">
  <mat-form-field
    *ngSwitchCase="DISPLAY_TYPE.AUTOCOMPLETE"
    [@slideInOut]="searchVisible"
    class="hb-filter_field"
  >
    <input
      #input
      (blur)="onBlurring(input.value)"
      (focus)="onFocussing(input.value)"
      (keyup.enter)="onEnterring(input.value)"
      (ngModelChange)="updateChanges()"
      [(ngModel)]="value"
      [matAutocomplete]="matAutocomplete"
      [placeholder]="placeholder"
      autocapitalize="none"
      matInput
      type="text"
    />
  </mat-form-field>
  <mat-form-field
    *ngSwitchCase="DISPLAY_TYPE.FORM_CONTROL"
    [@slideInOut]="searchVisible"
    class="hb-filter_field"
  >
    <input
      #input
      (blur)="onBlurring(input.value)"
      (focus)="onFocussing(input.value)"
      (keyup.enter)="onEnterring(input.value)"
      [formControl]="formControl"
      [placeholder]="placeholder"
      autocapitalize="none"
      matInput
      type="text"
    />
  </mat-form-field>
  <mat-form-field
    *ngSwitchCase="DISPLAY_TYPE.FORM_CONTROL_AND_AUTOCOMPLETE"
    [@slideInOut]="searchVisible"
    class="hb-filter_field"
  >
    <input
      #input
      (blur)="onBlurring(input.value)"
      (focus)="onFocussing(input.value)"
      (keyup.enter)="onEnterring(input.value)"
      [formControl]="formControl"
      [matAutocomplete]="matAutocomplete"
      [placeholder]="placeholder"
      autocapitalize="none"
      matInput
      type="text"
    />
  </mat-form-field>
  <mat-form-field
    *ngSwitchDefault
    [@slideInOut]="searchVisible"
    class="hb-filter_field"
  >
    <input
      #input
      (blur)="onBlurring(input.value)"
      (focus)="onFocussing(input.value)"
      (keyup.enter)="onEnterring(input.value)"
      (ngModelChange)="updateChanges()"
      [(ngModel)]="value"
      [placeholder]="placeholder"
      autocapitalize="none"
      matInput
      type="text"
    />
  </mat-form-field>
</ng-container>

<button (click)="handle()" class="hb-filter_button" mat-icon-button>
  <mat-icon *ngIf="searchVisible" fontIcon="fa-times" fontSet="fas" matRipple></mat-icon>
  <mat-icon *ngIf="!searchVisible" fontIcon="fa-filter" fontSet="fas" matRipple></mat-icon>
</button>


