import {Action, createReducer, on} from '@ngrx/store';
import {DetailCategoryEntity} from './detail-category.models';
import {Exception} from '../../../../../../core/models';
import {DetailCategoryActions} from './detail-category.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const CATEGORY_FEATURE_KEY = 'category';

export interface DetailCategoryState {
  isLoading: boolean;
  items: DetailCategoryEntity[];
  exception: Exception;
}

export interface DetailCategoryPartialState {
  readonly [CATEGORY_FEATURE_KEY]: DetailCategoryState;
}

export const initialState: DetailCategoryState = {
  isLoading: false,
  exception: null,
  items: []
};


export const categoryReducer = createReducer(
  initialState,

  on(DetailCategoryActions.loadCategoriesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(DetailCategoryActions.loadCategoriesSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(DetailCategoryActions.loadCategoriesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: DetailCategoryState | undefined, action: Action) {
  return categoryReducer(state, action);
}
