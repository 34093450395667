import {createAction, props} from '@ngrx/store';
import {LanguageListPayload, SetLanguageRequest} from './language.models';
import {Exception} from '../../../../../../core/models';

enum LanguageActionTypes {
  LOAD_LANGUAGES_REQUEST = '[Language] Load Languages Request',
  LOAD_LANGUAGES_SUCCESS = '[Language] Load Languages Success',
  LOAD_LANGUAGES_FAILURE = '[Language] Load Languages Failure',

  SET_LANGUAGE_REQUEST = '[Language] Set Language Request',
  SET_LANGUAGE_SUCCESS = '[Language] Set Language Success',
  SET_LANGUAGE_FAILURE = '[Language] Set Language Failure',
}

const loadLanguagesRequest = createAction(
  LanguageActionTypes.LOAD_LANGUAGES_REQUEST
);

const loadLanguagesSuccess = createAction(
  LanguageActionTypes.LOAD_LANGUAGES_SUCCESS,
  props<{ payload: LanguageListPayload }>()
);

const loadLanguagesFailure = createAction(
  LanguageActionTypes.LOAD_LANGUAGES_FAILURE,
  props<{ exception: Exception }>()
);

const setLanguageRequest = createAction(
  LanguageActionTypes.SET_LANGUAGE_REQUEST,
  props<{ request: SetLanguageRequest }>()
);

const setLanguageSuccess = createAction(
  LanguageActionTypes.SET_LANGUAGE_SUCCESS,
  props<{ payload: string }>()
);

const setLanguageFailure = createAction(
  LanguageActionTypes.SET_LANGUAGE_FAILURE,
  props<{ exception: Exception }>()
);

export const LanguageActions = {
  loadLanguagesRequest,
  loadLanguagesSuccess,
  loadLanguagesFailure,
  setLanguageRequest,
  setLanguageSuccess,
  setLanguageFailure,
};
