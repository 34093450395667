import {Component, Input, OnInit} from '@angular/core';
import {ExtendedThemePalette} from '../../../directives/theme';

@Component({
  selector: 'hb-password-toggle-visibility',
  templateUrl: './password-toggle-visibility.component.html',
  styleUrls: ['./password-toggle-visibility.component.scss']
})
export class PasswordToggleVisibilityComponent implements OnInit {

  @Input()
  fontSet: string;

  @Input()
  color: ExtendedThemePalette;

  private hide: boolean;

  constructor() { }

  public get type(): 'password' | 'text' {
    return this.hide ? 'password' : 'text';
  }

  get icon(): 'fa-eye-slash' | 'fa-eye' {
    return this.hide ? 'fa-eye-slash' : 'fa-eye';
  }

  ngOnInit(): void {
    this.hide = true;
    this.fontSet = 'fas';
  }

  public toggle(): void {
    this.hide = !this.hide;
  }



}
