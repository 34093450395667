import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectImageComponent} from './select-image.component';

@Injectable({
  providedIn: 'root'
})
export class SelectImageDialogService extends BaseDialogService<SelectImageComponent, any> {

  constructor(protected matDialog: MatDialog) {
    super(matDialog, SelectImageComponent);
  }
}
