import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {FormEntity} from './form.models';
import {Exception} from '../../../../../../core/models';
import {FormActions} from './form.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const FORM_FEATURE_KEY = 'form';

export interface FormState extends EntityState<FormEntity> {
  isLoading: boolean;
  exception: Exception;
}

export interface FormPartialState {
  readonly [FORM_FEATURE_KEY]: FormState;
}

export const formAdapter: EntityAdapter<FormEntity> = createEntityAdapter<FormEntity>();

export const initialState: FormState = formAdapter.getInitialState({
  isLoading: false,
  exception: null
});

const formReducer = createReducer(
  initialState,
  on(FormActions.loadFormsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(FormActions.loadFormsSuccess, (state, {payload}) =>
    formAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(FormActions.loadFormsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(FormActions.resetFormStore, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: FormState | undefined, action: Action) {
  return formReducer(state, action);
}
