<h2 mat-dialog-title>{{'dialogs.add_attachment.title' | translate}}</h2>
<mat-dialog-content>
  <form fxLayout="column nowrap">
    <mat-form-field appearance="outline">
      <mat-label>{{'forms.fields.add_attachment.label' | translate}}</mat-label>
      <ngx-mat-file-input [placeholder]="'forms.fields.add_attachment.placeholder' | translate"
                          (change)="add($event)"
                          [accept]="accept"
                          multiple></ngx-mat-file-input>
      <mat-hint>{{'forms.fields.add_attachment.hint' | translate}}</mat-hint>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [mat-dialog-close] mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
