import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AccountDataService} from './account-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Update} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';
import {AccountEntity} from './account.models';
import {AccountActions} from './account.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {AttachmentActions} from '../../../attachment-store/src/+state/attachment.actions';
import {MediaType} from '../../../attachment-store';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


@Injectable()
export class AccountEffects {

  loadAccountsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadAccountsRequest),

      switchMap(action => this.accountDataService.load().pipe(
        map(payload => AccountActions.loadAccountsSuccess({payload})),
        catchError((error) => of(AccountActions.loadAccountsFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadAccountsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadAccountsSuccess),
    ), {dispatch: false}
  );

  loadAccountsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AccountActions.loadAccountsFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getAccountsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getAccountRequest),
      switchMap(action => this.accountDataService.get(action.request.accountId).pipe(
        map(payload => AccountActions.getAccountSuccess({payload})),
        catchError(error => of(AccountActions.getAccountFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  getAccountsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.getAccountSuccess),
    ), {dispatch: false}
  );

  getAccountsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AccountActions.getAccountFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  setAccountId$ = createEffect(
    () => this.actions$.pipe(
      ofType(AccountActions.setAccountId),

      tap(action => this.accountDataService.setAccountId(action.accountId))
    ), {dispatch: false}
  );

  removeAccountId$ = createEffect(
    () => this.actions$.pipe(
      ofType(AccountActions.removeAccountId, AuthenticationActions.clearSession),

      tap(action => this.accountDataService.removeAccountId())
    ), {dispatch: false}
  );

  persistAccountRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.persistAccountRequest),
      switchMap(action => this.accountDataService.persist(action.account).pipe(
        map(payload => AccountActions.persistAccountSuccess({payload})),
        catchError(error => of(AccountActions.persistAccountFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  persistAccountSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.persistAccountSuccess),
    ), {dispatch: false}
  );

  persistAccountFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AccountActions.persistAccountFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  updateAccountImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentSuccess),
      map(action => action.payload.item),
      filter(attachment => attachment.mediaType === MediaType.ACCOUNT_IMAGE),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: attachment.id}} as Update<AccountEntity>)),
      map(update => AccountActions.updateAccount({update}))
    )
  );

  removeAccountImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentSuccess),
      map(action => action.payload),
      filter(attachment => attachment.mediaType === MediaType.ACCOUNT_IMAGE),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: null}} as Update<AccountEntity>)),
      map(update => AccountActions.updateAccount({update}))
    )
  );

  constructor(private actions$: Actions, private accountDataService: AccountDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
