import {Directive} from '@angular/core';
import {AppService} from '../../../modules/store';

@Directive({
  selector: '[hbScrolledContent]',
  exportAs: 'hbScrolledContent'
})
export class ScrolledContentDirective {

  public isScrolled = false;

  constructor(private appService: AppService) {
    this.appService.setScrolled(false);
  }

  public setScrolled(e: any) {
    this.appService.setScrolled(e.target.scrollTop > 0);
  }

}
