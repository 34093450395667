import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromDetailCategory from './detail-category.reducer';
import {NGXLogger} from 'ngx-logger';
import {DetailCategorySelectors} from './detail-category.selectors';
import {DetailCategoryActions} from './detail-category.actions';

@Injectable({
  providedIn: 'root'
})
export class DetailCategoryService {

  public readonly isLoading$ = this.store.pipe(select(DetailCategorySelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(DetailCategorySelectors.getException));
  public readonly items$ = this.store.pipe(select(DetailCategorySelectors.getItems));

  constructor(private store: Store<fromDetailCategory.DetailCategoryPartialState>, logger: NGXLogger) {
  }

  public load() {
    this.store.dispatch(DetailCategoryActions.loadCategoriesRequest());
  }
}
