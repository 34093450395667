<ng-container *ngIf="(items) as items">
  <cdk-virtual-scroll-viewport (scrolledIndexChange)="scrolling($event)"
                               [itemSize]="itemSize"
                               [style.height]="virtualHeight">
    <mat-list style="padding: 0">
      <ng-container *cdkVirtualFor="let item of items; let i = index; trackBy: trackById">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item, index: i}">

        </ng-container>
      </ng-container>
    </mat-list>
    <div [ngStyle]="{'height': itemSize + 'px'}">

    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>





