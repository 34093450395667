import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {ResponseItemModel} from '../../../models';
import {environment} from '@hb/environments/index';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  public isLoading$: Observable<boolean>;

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  private _documents: number;

  get documents() {
    return this._documents;
  }

  private _materials: number;

  get materials() {
    return this._materials;
  }

  private _repositories: number;

  get repositories() {
    return this._repositories;
  }

  load() {
    return this.http.get<ResponseItemModel<any>>(`${environment.api}/dashboard`).pipe(
      map(res => res.data),
    );
  }
}
