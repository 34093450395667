import {createAction, props} from '@ngrx/store';
import {UsageListPayload} from './usage.models';
import {Exception} from '../../../../../../core/models';


enum UsageActionTypes {
  LOAD_USAGES_REQUEST = '[Usage] Load Usage Request',
  LOAD_USAGES_SUCCESS = '[Usage] Load Usage Success',
  LOAD_USAGES_FAILURE = '[Usage] Load Usage Failure',
}

const loadUsagesRequest = createAction(
  UsageActionTypes.LOAD_USAGES_REQUEST
);

const loadUsagesSuccess = createAction(
  UsageActionTypes.LOAD_USAGES_SUCCESS,
  props<{ payload: UsageListPayload }>()
);

const loadUsagesFailure = createAction(
  UsageActionTypes.LOAD_USAGES_FAILURE,
  props<{ exception: Exception }>()
);

export const UsageActions = {
  loadUsagesRequest,
  loadUsagesSuccess,
  loadUsagesFailure
};
