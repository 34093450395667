import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {combineLatest, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {map, takeUntil} from 'rxjs/operators';
import {SearchDialogService} from '../../../dialogs/search/src/search-dialog.service';
import {Router} from '@angular/router';
import {
  AccountService,
  AppService,
  AuthenticationService,
  LanguageService,
  RepositoryService,
  RouterService,
  SearchEntity,
  SearchService,
  UserService
} from '../../../modules/store';
import {MatSidenav} from '@angular/material/sidenav';
import {BreakpointService} from '../../../modules/core';

@Component({
  selector: 'hb-sidenav-view',
  templateUrl: './sidenav-view.component.html',
  styleUrls: ['./sidenav-view.component.css'],
})
export class SidenavViewComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatSidenav) matSideNav: MatSidenav;
  public readonly sideNavMode$ = this.breakpointService.streamMatchHandset().pipe(
    map(match => !!match ? 'over' : 'side')
  );
  public readonly isInitOpen$ = this.breakpointService.streamMatchHandset().pipe(
    map(match => !match)
  );
  public readonly isSideNavActive$ = this.routerService.data$.pipe(
    map(data => !!data && data.sidenav)
  );
  public readonly isActive$ = this.searchService.isActive$;
  private _userId: number;

  constructor(
    private appService: AppService,
    public routerService: RouterService,
    private userService: UserService,
    private accountService: AccountService,
    private repositoryService: RepositoryService,
    private languageService: LanguageService,
    public authenticationService: AuthenticationService,
    public translateService: TranslateService,
    private searchDialogService: SearchDialogService,
    private searchService: SearchService,
    private router: Router,
    private breakpointService: BreakpointService,
    private logger: NGXLogger) {

  }

  public get isOpen$() {
    return this.appService.showSideNav$;
  }

  toggle() {
    return this.appService.toggleSideNav();
  }

  public get loggedInUser$() {
    return this.userService.loggedInUser$;
  }

  public get accountId$() {
    return this.accountService.accountId$;
  }

  public get repositoryId$() {
    return combineLatest([
      this.routerService.repositoryId$,
      this.repositoryService.repositoryId$
    ]).pipe(
      map(([s, d]) => !!s ? s : d)
    );
  }

  public get searches$() {
    return this.searchService.items$;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get locale$() {
    return this.languageService.selected$;
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    if (this.breakpointService.matchHandset()) {
      return this.appService.toggleSideNav(false);
    }
  }

  back() {
    this.routerService.back();
    this.close();
  }

  public createSearch(search?: string) {
    if (!this.searchDialogService.hasOpenDialogs()) {
      const id = this.searchService.create(search).pipe(
        takeUntil(this.unsubscribe$),
        map(payload => payload.item)
      ).subscribe(data => this.searchDialogService.open(data));
    }
  }

  public openSearch(entity: SearchEntity) {
    this.searchDialogService.open(entity);
  }

  public removeSearch(entity: SearchEntity) {
    this.searchService.remove(entity.id);
  }
}
