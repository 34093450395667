import {ResponseItemModel, ResponseListModel, ResponseModel} from './response.models';


export class PayloadModel {
  httpCode?: number;
  message?: string;
  extendedMessage?: string;
  key?: string;
  field?: string;
  value?: any;
  code?: number;
  rows?: number;


  constructor(input: ResponseModel) {
    this.code = input.code;
    this.field = input.field;
    this.httpCode = input.htmlCode;
    this.key = input.msgKey;
    this.message = input.msg;
    this.extendedMessage = input.extMsg;
    this.value = input.value;
    this.rows = input.rows;
  }

}

export class PayloadItemModel<T> extends PayloadModel {
  id?: number;
  item?: T;

  constructor(input: ResponseItemModel<T>) {
    super(input);
    this.item = input.data;
  }
}

export class PayloadListModel<T> extends PayloadModel {
  id?: number;
  items?: T[];

  constructor(input: ResponseListModel<T>) {
    super(input);
    this.items = input.datalist;
  }
}
