import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {filter} from 'rxjs/operators';
import {HintDialogService} from '../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {DocumentService, DocumentViewModel, LanguageService, RouterService} from '../../../modules/store';


interface ButtonConfiguration {
  status?: boolean;
  context?: boolean;
}

interface DeleteEvent {
  data: DocumentViewModel;
}

@Component({
  selector: 'hb-document-item-view',
  templateUrl: './document-item-view.component.html',
  styleUrls: ['./document-item-view.component.css']
})
export class DocumentItemViewComponent implements OnInit, OnDestroy {

  @Input()
  viewModel: DocumentViewModel;
  @Output()
  public deleteEvent = new EventEmitter<DeleteEvent>();

  // @Output()
  // public open = new EventEmitter<any>();

  constructor(private documentService: DocumentService,
              private languageService: LanguageService,
              private routerService: RouterService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }

  private _buttons: ButtonConfiguration = {status: false, context: false};

  public get buttons(): ButtonConfiguration {
    return this._buttons;
  }

  @Input()
  public set buttons(buttons: ButtonConfiguration) {
    this._buttons = {...this._buttons, ...buttons};
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get locale$() {
    return this.languageService.selected$;
  }

  @Input()
  public disabled = false;


  ngOnInit(): void {

  }

  open() {
    if (!this.disabled) {
      this.routerService.navigateToDocumentView(this.viewModel.repositoryId, this.viewModel.materialId, this.viewModel.id);
    }
  }

  edit() {
    this.routerService.navigateToDocumentView(this.viewModel.repositoryId, this.viewModel.materialId, this.viewModel.id);
  }

  delete() {
    this.hintDialogService.openWarningV2(marker('keys.local.messages.really_delete')).afterClosed().pipe(
      filter(result => !!result),
    ).subscribe(() => {
      this.documentService.delete(this.viewModel.model);
      this.deleteEvent.emit({data: this.viewModel});
    });
  }

  downloadPDF() {
    this.documentService.download(this.viewModel.model, 'pdf');
  }

  downloadLabel() {
    this.documentService.download(this.viewModel.model, 'zebra');
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }
}
