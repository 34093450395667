import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromAddress from './address.reducer';
import {NGXLogger} from 'ngx-logger';
import {Actions, ofType} from '@ngrx/effects';
import {filter, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AddressViewModel} from './address.models';
import {AddressSelectors} from './address.selectors';
import {AddressActions} from './address.actions';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  public readonly isLoading$ = this.store.pipe(select(AddressSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(AddressSelectors.getException));
  public readonly items$ = this.store.pipe(select(AddressSelectors.getItems));

  constructor(private store: Store<fromAddress.AddressPartialState>, private actions$: Actions, logger: NGXLogger) {
  }

  public getById$(id: number): Observable<AddressViewModel> {
    return this.store.pipe(select(AddressSelectors.getById, id));
  }

  public load() {
    this.store.dispatch(AddressActions.loadAddressesRequest());
  }

  public get(addressId: number) {
    this.store.dispatch(AddressActions.getAddressRequest({request: {addressId}}));
    return this.next(AddressActions.getAddressSuccess).pipe(
      map(action => action.payload.item)
    );
  }

  public persist(address: AddressViewModel) {
    this.store.dispatch(AddressActions.persistAddressRequest({address: address.updated}));
    return this.next(AddressActions.persistAddressSuccess).pipe(
      map(action => action.payload)
    );
  }

  private next(type: any, actionId?: string) {
    return this.actions$.pipe(
      ofType(type),
      filter(action => !actionId || (action.actionId === actionId)),
      take(1)
    );
  }
}
