import {Injectable, OnDestroy} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as fromAuthentication from './authentication.reducer';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {
  ActivateRequest,
  ChangeEmailRequest,
  LoginRequest,
  PasswordForgottenRequest,
  RefreshPayload,
  RegisterRequest,
  ResetPasswordRequest
} from './authentication.models';
import {Actions, ofType} from '@ngrx/effects';
import {AuthenticationSelectors} from './authentication.selectors';
import {AuthenticationActions} from './authentication.actions';
import {next} from '../../../../../../core/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  public readonly isLoading$ = this.store.pipe(select(AuthenticationSelectors.isLoading));
  public access$ = this.store.pipe(select(AuthenticationSelectors.getAccess));
  public refresh$ = this.store.pipe(select(AuthenticationSelectors.getRefresh));
  public exception$ = this.store.pipe(select(AuthenticationSelectors.getException));
  private _access: string;
  private _refresh: string;

  constructor(private store: Store<fromAuthentication.AuthenticationPartialState>, private actions$: Actions, private logger: NGXLogger) {
    this.access$.subscribe(access => this._access = access);
    this.refresh$.subscribe(refresh => this._refresh = refresh);
  }

  getAccess() {
    return this._access;
  }

  getRefresh() {
    return this._refresh;
  }

  login(request: LoginRequest) {
    this.store.dispatch(AuthenticationActions.loginRequest({request}));
    return this.actions$.pipe(
      next(AuthenticationActions.loginSuccess)
    );
  }

  logout() {
    this.store.dispatch(AuthenticationActions.logoutRequest());
  }

  register(request: RegisterRequest) {
    this.store.dispatch(AuthenticationActions.registerRequest({request}));
  }

  forgottenPassword(request: PasswordForgottenRequest) {
    this.store.dispatch(AuthenticationActions.passwordForgottenRequest({request}));
  }

  resetPassword(request: ResetPasswordRequest) {
    this.store.dispatch(AuthenticationActions.resetPasswordRequest({request}));
  }

  activate(request: ActivateRequest) {
    this.store.dispatch(AuthenticationActions.activateRequest({request}));
  }

  changeEmail(request: ChangeEmailRequest) {
    this.store.dispatch(AuthenticationActions.changeEmailRequest({request}));
  }

  refresh(): Observable<RefreshPayload> {
    this.store.dispatch(AuthenticationActions.refreshRequest({request: {refresh: this._refresh}}));
    return this.actions$.pipe(
      ofType(AuthenticationActions.refreshSuccess, AuthenticationActions.refreshFailure),
      take(1),
      map((action: any) => action.payload || null)
    );
  }

  clear() {
    this.store.dispatch(AuthenticationActions.clearSession());
  }

  hasToken$(): Observable<boolean> {
    return this.access$.pipe(map(access => !!access));
  }

  hasToken(): boolean {
    return !!this._access;
  }

  isLoggedIn(): Observable<boolean> {
    this.store.dispatch(AuthenticationActions.isLoggedInRequest());
    return this.actions$.pipe(
      ofType(AuthenticationActions.isLoggedInSuccess, AuthenticationActions.isLoggedInFailure),
      take(1),
      map(action => action.type === AuthenticationActions.isLoggedInSuccess.type)
    );

  }

  ngOnDestroy(): void {

  }
}
