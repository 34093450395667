<mat-toolbar [color]="color" class="hb-header">
  <mat-toolbar-row>
    <div *ngIf="buttons.primary !== PRIMARY_BUTTON_TYPE.NONE" class="hb-left-toolbar">
      <ng-container [ngSwitch]="buttons.primary">
        <ng-container *ngSwitchCase="PRIMARY_BUTTON_TYPE.TOGGLE">
          <ng-container *ngTemplateOutlet="toggleButtonTemplate">

          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="PRIMARY_BUTTON_TYPE.BACK">
          <ng-container *ngTemplateOutlet="backButtonTemplate">

          </ng-container>
        </ng-container>
      </ng-container>
    </div>
    <h3 *ngIf="title" class="align-bottom text-truncate">{{title}}</h3>
    <div class="hb-right-toolbar">
      <hb-filter-bar [searchVisible]="!!input.value" [formControl]="input"
                     *ngIf="buttons.filter !== FILTER_BUTTON_TYPE.NONE"></hb-filter-bar>

      <button (click)="toggleGlobalReminder()" *ngIf="buttons.reminder !== REMINDER_BUTTON_TYPE.NONE"
              [disabled]="!('update' | able: 'folder') && !('update' | able: 'material')" mat-icon-button>
        <mat-icon *ngIf="!(globalReminder$ | async)" fontIcon="fa-bell-slash" fontSet="fas"></mat-icon>
        <mat-icon *ngIf="(globalReminder$ | async)" fontIcon="fa-bell" fontSet="fas"></mat-icon>
      </button>

      <button (click)="toggleShowInactive()" *ngIf="buttons.archive !== ARCHIVE_BUTTON_TYPE.NONE" mat-icon-button>
        <mat-icon *ngIf="!(showInactive$ | async)" fontIcon="fa-archive-solid-slash" fontSet="fae"></mat-icon>
        <mat-icon *ngIf="(showInactive$ | async)" fontIcon="fa-archive" fontSet="fas"></mat-icon>
      </button>

      <button [matMenuTriggerFor]="languageMenu" mat-icon-button>
        <mat-icon fontIcon="fa-globe-europe" fontSet="fas"></mat-icon>
      </button>
      <button [matMenuTriggerFor]="contextMenu" mat-icon-button>
        <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<ng-template #toggleButtonTemplate>
  <button (click)="toggle()" mat-icon-button>
    <mat-icon fontIcon="fa-bars" fontSet="fas"></mat-icon>
  </button>
</ng-template>

<ng-template #backButtonTemplate>
  <button (click)="back()" mat-icon-button>
    <mat-icon fontIcon="fa-arrow-left"  fontSet="fas"></mat-icon>
  </button>
</ng-template>


<mat-menu #contextMenu="matMenu">
  <button (click)="openScan()" *ngIf="buttons.filter !== FILTER_BUTTON_TYPE.NONE && isMobilePlatform" mat-menu-item>
    <mat-icon fontIcon="fa-camera" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.scan' | translate}}</span>
  </button>
  <button (click)="openImprint()" mat-menu-item>
    <mat-icon fontIcon="fa-id-card" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.imprint' | translate}}</span>
  </button>
  <button (click)="openPrivacy()" mat-menu-item>
    <mat-icon fontIcon="fa-shield-alt" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.privacy' | translate}}</span>
  </button>
  <button (click)="openTermsOfUse()" mat-menu-item>
    <mat-icon fontIcon="fa-file-contract" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.terms_of_use' | translate}}</span>
  </button>
  <a [href]="serviceMailTo" mat-menu-item>
    <mat-icon fontIcon="fa-envelope" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.contact_support' | translate}}</span>
  </a>
  <a [href]="servicePhoneLink" mat-menu-item>
    <mat-icon fontIcon="fa-phone" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.call_support' | translate}}</span>
  </a>
  <button (click)="installPWA()" mat-menu-item>
    <mat-icon fontIcon="fa-rocket" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.install_pwa' | translate}}</span>
  </button>
  <button (click)="checkForUpdates()" mat-menu-item>
    <mat-icon fontIcon="fa-sync" fontSet="fas"></mat-icon>
    <span>{{'context.header.action.check_for_updates' | translate}}</span>
  </button>
  <button disabled mat-menu-item>
    <mat-icon fontIcon="fa-code-branch" fontSet="fas"></mat-icon>
    <span>{{VERSION.semverString}}</span>
  </button>
</mat-menu>

<mat-menu #languageMenu="matMenu">
  <button (click)="selectLanguage(language)" *ngFor="let language of languages | async" mat-menu-item>
    <mat-icon [class.hb-mat-primary]="language.selected" fontIcon="fa-globe-europe" fontSet="fas"></mat-icon>
    <span>{{language.name}}</span>
  </button>
</mat-menu>
