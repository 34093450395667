import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {UsageDataService} from './usage-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {UsageActions} from './usage.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class UsageEffects {

  loadUsagesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsageActions.loadUsagesRequest),

      switchMap(action => this.usageDataService.load().pipe(
        map(payload => UsageActions.loadUsagesSuccess({payload})),
        catchError(error => of(UsageActions.loadUsagesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadUsagesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UsageActions.loadUsagesSuccess),
    ), {dispatch: false}
  );

  loadUsagesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(UsageActions.loadUsagesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions, private usageDataService: UsageDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
