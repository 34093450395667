<mat-sidenav-container>
  <mat-sidenav [mode]="sideNavMode$ | async" [opened]="(isSideNavActive$ | async) && (isOpen$ | async)"
               class="h-full w-4/5 md:w-72" role="navigation">
    <div class="h-28 flex flex-col flex-nowrap items-stretch justify-center p-0">
      <mat-list class="h-20">
        <hb-logged-in-user-item-view [buttons]="{context: true}" [viewModel]="loggedInUser$ | async">

        </hb-logged-in-user-item-view>
      </mat-list>
    </div>
    <mat-divider></mat-divider>
    <mat-nav-list>
      <div mat-subheader>{{'keys.local.global.navigation' | translate}}</div>
      <a (click)="close()"
         *ngIf="('open' | able: 'dashboard')"
         [routerLink]="['/app/dashboard']" mat-list-item
         routerLinkActive="selected">
        <mat-icon color="primary" fontIcon="fa-tachometer-alt" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.dashboard' | translate}}</span>
      </a>
      <a (click)="close()"
         *ngIf="('open' | able: 'account')"
         [routerLink]="['/app/accounts', accountId$ | async]"
         mat-list-item
         routerLinkActive="selected">
        <mat-icon color="primary" fontIcon="fa-home" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.account' | translate}}</span>
      </a>
      <a (click)="close()"
         *ngIf="('open' | able: 'inspection')"
         [routerLink]="['/app/inspections']" mat-list-item
         routerLinkActive="selected">
        <mat-icon color="primary" fontIcon="fa-tools" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.inspections' | translate}}</span>
      </a>
      <a (click)="close()"
         *ngIf="('open' | able: 'places')"
         [routerLink]="['/app/places', repositoryId$ | async]" mat-list-item
         routerLinkActive="selected">
        <mat-icon color="primary" fontIcon="fa-folder" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.places' | translate}}</span>
      </a>
      <a (click)="close()"
         *ngIf="('open' | able: 'user')"
         [routerLink]="['/app/repositories', repositoryId$ | async, 'users']" mat-list-item
         routerLinkActive="selected">
        <mat-icon color="primary" fontIcon="fa-user" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.users' | translate}}</span>
      </a>
      <a (click)="close()"
         *ngIf="('open' | able: 'admin')"
         [routerLink]="['/app/administration']" mat-list-item
         routerLinkActive="selected">
        <mat-icon color="primary" fontIcon="fa-cogs" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.administration' | translate}}</span>
      </a>

      <div mat-subheader>{{'keys.local.global.search' | translate}}</div>
      <mat-list-item (click)="createSearch()" (scanSuccess)="createSearch($event)" [active]="isActive$ | async"
                     hbAutoInput>
        <mat-icon color="primary" fontIcon="fa-plus" fontSet="fas" mat-list-icon></mat-icon>
        <span mat-line>{{'keys.local.global.search' | translate}}</span>
      </mat-list-item>
      <mat-list-item (click)="openSearch(search); $event.stopImmediatePropagation(); close()"
                     *ngFor="let search of searches$ | async">
        <mat-icon (click)="removeSearch(search); $event.stopImmediatePropagation()" color="mute" fontIcon="fa-times"
                  fontSet="fas" mat-list-icon></mat-icon>
        <span
          mat-line>{{'keys.local.global.search_at' | translate}} {{search.data.timestamp | date: 'mediumTime' : timeZone : (locale$ | async)}}</span>
        <small mat-line>{{'keys.local.global.search_criteria' | translate}} {{search.data.search}}</small>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content>

    </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>















