import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {LANGUAGE_FEATURE_KEY, LanguagePartialState, LanguageState} from './language.reducer';
import {LanguageEntity} from './language.models';
import {Exception} from '../../../../../../core/models';

const getLanguageState = createFeatureSelector<LanguagePartialState, LanguageState>(LANGUAGE_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getLanguageState,
  (state: LanguageState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getLanguageState,
  (state: LanguageState) => state.exception
);

const getSelected: MemoizedSelector<object, string> = createSelector(
  getLanguageState,
  (state: LanguageState) => state.languageId
);

const getItems: MemoizedSelector<object, LanguageEntity[]> = createSelector(
  getLanguageState,
  getSelected,
  (state: LanguageState, selected: string) => state.items.map(item => ({...item, selected: item.id === selected}))
);

export const LanguageSelectors = {
  isLoading,
  getException,
  getSelected,
  getItems,
};




