import {createAction, props} from '@ngrx/store';
import {QueryParams} from '@ngrx/data';
import {Update} from '@ngrx/entity';
import {
  CreateMaterialRequest,
  GetMaterialRowsRequest,
  LoadAuditableMaterialListPayload,
  LoadAuditableMaterialListRequest,
  LoadMaterialsRequest,
  MaterialEntity,
  MaterialItemPayload,
  MaterialItemRequest,
  MaterialListPayload,
  MaterialRemovePayload,
} from './material.models';
import {Exception} from '../../../../../../core';

enum MaterialActionTypes {
  LOAD_MATERIALS_REQUEST = '[Material] Load Materials Request',
  LOAD_MATERIALS_SUCCESS = '[Material] Load Materials Success',
  LOAD_MATERIALS_FAILURE = '[Material] Load Materials Failure',

  GET_MATERIAL_ROWS_REQUEST = '[Material] Get Material Rows Request',
  GET_MATERIAL_ROWS_SUCCESS = '[Material] Get Material Rows Success',
  GET_MATERIAL_ROWS_FAILURE = '[Material] Get Material Rows Failure',

  GET_MATERIAL_REQUEST = '[Material] Get Material Request',
  GET_MATERIAL_SUCCESS = '[Material] Get Material Success',
  GET_MATERIAL_FAILURE = '[Material] Get Material Failure',

  LOAD_CHILDREN_REQUEST = '[Material] Load Children Request',
  LOAD_CHILDREN_SUCCESS = '[Material] Load Children Success',
  LOAD_CHILDREN_FAILURE = '[Material] Load Children Failure',

  CREATE_MATERIAL_REQUEST = '[Material] Create Material Request',
  CREATE_MATERIAL_SUCCESS = '[Material] Create Material Success',
  CREATE_MATERIAL_FAILURE = '[Material] Create Material Failure',

  PERSIST_MATERIAL_REQUEST = '[Material] Persist Material Request',
  PERSIST_MATERIAL_SUCCESS = '[Material] Persist Material Success',
  PERSIST_MATERIAL_FAILURE = '[Material] Persist Material Failure',

  MOVE_MATERIAL_REQUEST = '[Material] Move Material Request',
  MOVE_MATERIAL_SUCCESS = '[Material] Move Material Success',
  MOVE_MATERIAL_FAILURE = '[Material] Move Material Failure',

  DELETE_MATERIAL_REQUEST = '[Material] Delete Material Request',
  DELETE_MATERIAL_SUCCESS = '[Material] Delete Material Success',
  DELETE_MATERIAL_FAILURE = '[Material] Delete Material Failure',

  SET_NOTIFICATION_REQUEST = '[Material] Set Notification Request',
  SET_NOTIFICATION_SUCCESS = '[Material] Set Notification Success',
  SET_NOTIFICATION_FAILURE = '[Material] Set Notification Failure',

  UPDATE_MATERIAL_REQUEST = '[Material] Update Material Request',
  UPDATE_MATERIAL_SUCCESS = '[Material] Update Material Success',
  UPDATE_MATERIAL_FAILURE = '[Material] Update Material Failure',

  LOAD_AUDITABLE_MATERIAL_LIST_REQUEST = '[Material] Load Auditable Material List Request',
  LOAD_AUDITABLE_MATERIAL_LIST_SUCCESS = '[Material] Load Auditable Material List Success',
  LOAD_AUDITABLE_MATERIAL_LIST_FAILURE = '[Material] Load Auditable Material List Failure',

  UPDATE_MATERIAL = '[Material] Update Material',

  RESET_MATERIALS = '[Material] Reset Materials',

}

const loadMaterialsRequest = createAction(
  MaterialActionTypes.LOAD_MATERIALS_REQUEST,
  props<{ request: LoadMaterialsRequest }>()
);

const loadMaterialsSuccess = createAction(
  MaterialActionTypes.LOAD_MATERIALS_SUCCESS,
  props<{ payload: MaterialListPayload }>()
);

const loadMaterialsFailure = createAction(
  MaterialActionTypes.LOAD_MATERIALS_FAILURE,
  props<{ exception: Exception }>()
);

const loadAuditableMaterialListRequest = createAction(
  MaterialActionTypes.LOAD_AUDITABLE_MATERIAL_LIST_REQUEST,
  props<{ request: LoadAuditableMaterialListRequest }>()
);

const loadAuditableMaterialListSuccess = createAction(
  MaterialActionTypes.LOAD_AUDITABLE_MATERIAL_LIST_SUCCESS,
  props<{ payload: LoadAuditableMaterialListPayload }>()
);

const loadAuditableMaterialListFailure = createAction(
  MaterialActionTypes.LOAD_AUDITABLE_MATERIAL_LIST_FAILURE,
  props<{ exception: Exception }>()
);

const getMaterialRowsRequest = createAction(
  MaterialActionTypes.GET_MATERIAL_ROWS_REQUEST,
  props<{ request: GetMaterialRowsRequest }>()
);

const getMaterialRowsSuccess = createAction(
  MaterialActionTypes.GET_MATERIAL_ROWS_SUCCESS,
  props<{ payload: { rows: number } }>()
);

const getMaterialRowsFailure = createAction(
  MaterialActionTypes.GET_MATERIAL_ROWS_FAILURE,
  props<{ exception: Exception }>()
);

const getMaterialRequest = createAction(
  MaterialActionTypes.GET_MATERIAL_REQUEST,
  props<{ request: MaterialItemRequest }>()
);

const getMaterialSuccess = createAction(
  MaterialActionTypes.GET_MATERIAL_SUCCESS,
  props<{ payload: MaterialItemPayload }>()
);

const getMaterialFailure = createAction(
  MaterialActionTypes.GET_MATERIAL_FAILURE,
  props<{ exception: Exception }>()
);

const loadChildrenRequest = createAction(
  MaterialActionTypes.LOAD_CHILDREN_REQUEST,
  props<{ request: MaterialItemRequest }>()
);

const loadChildrenSuccess = createAction(
  MaterialActionTypes.LOAD_CHILDREN_SUCCESS,
  props<{ payload: MaterialListPayload }>()
);

const loadChildrenFailure = createAction(
  MaterialActionTypes.LOAD_CHILDREN_FAILURE,
  props<{ exception: Exception }>()
);

const createMaterialRequest = createAction(
  MaterialActionTypes.CREATE_MATERIAL_REQUEST,
  props<{ request: CreateMaterialRequest }>()
);

const createMaterialSuccess = createAction(
  MaterialActionTypes.CREATE_MATERIAL_SUCCESS,
  props<{ payload: MaterialItemPayload }>()
);

const createMaterialFailure = createAction(
  MaterialActionTypes.CREATE_MATERIAL_FAILURE,
  props<{ exception: Exception }>()
);

const persistMaterialRequest = createAction(
  MaterialActionTypes.PERSIST_MATERIAL_REQUEST,
  props<{ material: MaterialEntity, queryParams?: QueryParams }>()
);

const persistMaterialSuccess = createAction(
  MaterialActionTypes.PERSIST_MATERIAL_SUCCESS,
  props<{ payload: MaterialItemPayload }>()
);

const persistMaterialFailure = createAction(
  MaterialActionTypes.PERSIST_MATERIAL_FAILURE,
  props<{ exception: Exception }>()
);

const moveMaterialRequest = createAction(
  MaterialActionTypes.MOVE_MATERIAL_REQUEST,
  props<{ material: MaterialEntity, destination: number, actionId?: string }>()
);

const moveMaterialSuccess = createAction(
  MaterialActionTypes.MOVE_MATERIAL_SUCCESS,
  props<{ payload: MaterialItemPayload, actionId?: string }>()
);

const moveMaterialFailure = createAction(
  MaterialActionTypes.MOVE_MATERIAL_FAILURE,
  props<{ exception: Exception, actionId?: string }>()
);

const deleteMaterialRequest = createAction(
  MaterialActionTypes.DELETE_MATERIAL_REQUEST,
  props<{ material: MaterialEntity }>()
);

const deleteMaterialSuccess = createAction(
  MaterialActionTypes.DELETE_MATERIAL_SUCCESS,
  props<{ payload: MaterialRemovePayload }>()
);

const deleteMaterialFailure = createAction(
  MaterialActionTypes.DELETE_MATERIAL_FAILURE,
  props<{ exception: Exception }>()
);

const setNotificationRequest = createAction(
  MaterialActionTypes.SET_NOTIFICATION_REQUEST,
  props<{ material: MaterialEntity, notify: boolean }>()
);

const setNotificationSuccess = createAction(
  MaterialActionTypes.SET_NOTIFICATION_SUCCESS,
  props<{ update: Update<MaterialEntity> }>()
);

const setNotificationFailure = createAction(
  MaterialActionTypes.SET_NOTIFICATION_FAILURE,
  props<{ exception: Exception }>()
);

const updateMaterialRequest = createAction(
  MaterialActionTypes.UPDATE_MATERIAL_REQUEST,
  props<{ request: MaterialItemRequest }>()
);

const updateMaterialSuccess = createAction(
  MaterialActionTypes.UPDATE_MATERIAL_SUCCESS,
  props<{ payload: MaterialItemPayload }>()
);

const updateMaterialFailure = createAction(
  MaterialActionTypes.UPDATE_MATERIAL_FAILURE,
  props<{ exception: Exception }>()
);

const updateMaterial = createAction(
  MaterialActionTypes.UPDATE_MATERIAL,
  props<{ update: Update<MaterialEntity> }>()
);

const resetMaterials = createAction(
  MaterialActionTypes.RESET_MATERIALS,
);

export const MaterialActions = {
  loadMaterialsRequest,
  loadMaterialsSuccess,
  loadMaterialsFailure,
  getMaterialRowsRequest,
  getMaterialRowsSuccess,
  getMaterialRowsFailure,
  getMaterialRequest,
  getMaterialSuccess,
  getMaterialFailure,
  loadChildrenRequest,
  loadChildrenSuccess,
  loadChildrenFailure,
  createMaterialRequest,
  createMaterialSuccess,
  createMaterialFailure,
  persistMaterialRequest,
  persistMaterialSuccess,
  persistMaterialFailure,
  moveMaterialRequest,
  moveMaterialSuccess,
  moveMaterialFailure,
  deleteMaterialRequest,
  deleteMaterialSuccess,
  deleteMaterialFailure,
  setNotificationRequest,
  setNotificationSuccess,
  setNotificationFailure,
  updateMaterialRequest,
  updateMaterialSuccess,
  updateMaterialFailure,
  updateMaterial,
  resetMaterials,
  loadAuditableMaterialListRequest,
  loadAuditableMaterialListSuccess,
  loadAuditableMaterialListFailure,
};
