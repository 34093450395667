import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AddressDataService} from './address-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {AddressActions} from './address.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class AddressEffects {

  loadAddressesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressActions.loadAddressesRequest),

      switchMap(action => this.addressDataService.load().pipe(
        map(payload => AddressActions.loadAddressesSuccess({payload})),
        catchError((error) => of(AddressActions.loadAddressesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadAddressesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressActions.loadAddressesSuccess),
    ), {dispatch: false}
  );

  loadAddressesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.loadAddressesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getAddressesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressActions.getAddressRequest),

      switchMap(action => this.addressDataService.get(action.request.addressId).pipe(
        map(payload => AddressActions.getAddressSuccess({payload})),
        catchError(error => of(AddressActions.getAddressFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  getAddressesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressActions.getAddressSuccess),
    ), {dispatch: false}
  );

  getAddressesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.getAddressFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  persistAddressRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressActions.persistAddressRequest),
      switchMap(action => this.addressDataService.persist(action.address).pipe(
        map(payload => AddressActions.persistAddressSuccess({payload})),
        catchError(error => of(AddressActions.persistAddressFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  persistAddressSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AddressActions.persistAddressSuccess),
    ), {dispatch: false}
  );

  persistAddressFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(AddressActions.persistAddressFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions, private addressDataService: AddressDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
