import {Routes} from '@angular/router';
import {LoginViewComponent} from './views/login-view';
import {RegistrationViewComponent} from './views/register-view';
import {PasswordForgottenViewComponent} from './views/password-forgotten-view';
import {MaterialViewComponent} from './views/material-view';
import {UsersViewComponent} from './views/users-view';
import {UserViewComponent} from './views/user-view';
import {DocumentViewComponent} from './views/document-view';
import {DashboardViewComponent} from './views/dashboard-view';
import {RepositoryViewComponent} from './views/repository-view';
import {PasswordResetViewComponent} from './views/password-reset-view';
import {ActivationViewComponent} from './views/activation-view';
import {ChangeEmailViewComponent} from './views/change-email-view';
import {PlacesViewComponent} from './views/places-view';
import {AdministrationViewComponent} from './views/administation-view';
import {AccountViewComponent} from './views/account-view';
import {EmptyComponent} from './components/empty/empty.component';
import {RedirectGuard} from './guards';
import {AuthenticationGuard} from './modules/store';
import {InspectionsViewComponent} from './views/inspections-view/src/inspections-view.component';


export const routes: Routes = [
  {
    path: 'login',
    component: LoginViewComponent,
    data: {
      background: 'true',
      header: {buttons: {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'}, color: 'transparent'}
    }
  },
  {
    path: 'registration',
    component: RegistrationViewComponent,
    data: {
      background: 'true',
      header: {buttons: {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'}, color: 'transparent'}
    }
  },
  {
    path: 'password',
    component: PasswordForgottenViewComponent,
    data: {
      background: 'true',
      header: {buttons: {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'}, color: 'transparent'}
    }
  },
  {
    path: 'password-reset',
    component: PasswordResetViewComponent,
    data: {
      background: 'true',
      header: {buttons: {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'}, color: 'transparent'}
    }
  },
  {
    path: 'user-activation',
    component: ActivationViewComponent,
    data: {
      background: 'true',
      header: {buttons: {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'}, color: 'transparent'}
    }
  },
  {
    path: 'user-newemail-activation',
    redirectTo: 'user-new-email-activation',
  },
  {
    path: 'user-new-email-activation',
    component: ChangeEmailViewComponent,
    data: {
      background: 'true',
      header: {buttons: {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'}, color: 'transparent'}
    }
  },
  {
    path: 'app',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'home',
        component: EmptyComponent,
        canActivate: [RedirectGuard],
      },
      {
        path: 'dashboard',
        component: DashboardViewComponent,
        data: {
          header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
          sidenav: 'true'
        }
      },
      {
        path: 'inspections',
        component: InspectionsViewComponent,
        data: {
          header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
          sidenav: 'true'
        }
      },
      {
        path: 'administration',
        component: AdministrationViewComponent,
        data: {
          header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
          sidenav: 'true'
        }
      },
      {
        path: 'accounts/:accountId',
        component: AccountViewComponent,
        data: {
          header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
          sidenav: 'true'
        }
      },
      {
        path: 'places/:repositoryId',
        component: PlacesViewComponent,
        data: {
          header: {buttons: {primary: 'toggle', filter: 'toggle', archive: 'toggle', reminder: 'toggle'}, color: 'primary'},
          sidenav: 'true'
        }
      },
      {
        path: 'repositories/:repositoryId',
        children: [
          {
            path: 'view',
            component: RepositoryViewComponent,
            data: {
              header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
              sidenav: 'true'
            }
          },
          {
            path: 'materials',
            children: [
              {
                path: ':materialId',
                component: MaterialViewComponent,
                data: {
                  header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
                  sidenav: 'true'
                }
              },
              {
                path: ':materialId',
                children: [
                  {
                    path: 'documents/:documentId',
                    component: DocumentViewComponent,
                    data: {
                      header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
                      sidenav: 'true'
                    }
                  }
                ]
              },

            ]
          },
          {
            path: 'users',
            component: UsersViewComponent,
            data: {
              header: {buttons: {primary: 'toggle', filter: 'toggle', archive: 'toggle', reminder: 'none'}, color: 'primary'},
              sidenav: 'true'
            }
          },
          {
            path: 'users',
            children: [
              {
                path: ':userId',
                component: UserViewComponent,
                data: {
                  header: {buttons: {primary: 'toggle', filter: 'none', archive: 'none', reminder: 'none'}, color: 'primary'},
                  sidenav: 'true'
                }
              },
            ]
          },
          {path: '**', redirectTo: 'materials'}
        ]
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '**', redirectTo: 'login'
  }
];



