import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Breakpoints} from '@angular/cdk/layout';
import {BreakpointService} from '../../../modules/core/services/breakpoint';
import {NGXLogger} from 'ngx-logger';
import {AdministrationService} from '../../../modules/store';

@Component({
  selector: 'hb-administration-view',
  templateUrl: './administration-view.component.html',
  styleUrls: ['./administration-view.component.css']
})
export class AdministrationViewComponent implements OnInit, OnDestroy {

  constructor(
    public administrationService: AdministrationService,
    private translateService: TranslateService,
    private breakpointService: BreakpointService,
    private logger: NGXLogger) {

  }

  ngOnInit(): void {
  }

  public columns() {
    if (this.breakpointService.matches([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait, Breakpoints.WebPortrait])) {
      return 2;
    } else if (this.breakpointService.matches([Breakpoints.HandsetLandscape, Breakpoints.TabletLandscape, Breakpoints.WebLandscape])) {
      return 4;
    }
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }
}
