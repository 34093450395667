import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {filter, flatMap, mergeMap, take, tap} from 'rxjs/operators';
import {combineLatest, Observable, of, Subject, Subscription} from 'rxjs';
import {
  AddressService,
  AddressViewModel,
  addressViewModelFactory,
  CountryService,
  LanguageService,
  MediaType,
  PathService,
  RepositoryService,
  RepositoryViewModel,
  RouterService
} from '../../../modules/store';
import {QueryParams} from '@ngrx/data';
import {isNotNullOrUndefined} from '../../../modules/core';

@Component({
  selector: 'hb-repository-view',
  templateUrl: './repository-view.component.html',
  styleUrls: ['./repository-view.component.css']
})
export class RepositoryViewComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private routerService: RouterService,
    private repositoryService: RepositoryService,
    private addressService: AddressService,
    private countryService: CountryService,
    private pathService: PathService,
    private languageService: LanguageService,
    private logger: NGXLogger) {

  }

  public readonly mediaType: typeof MediaType = MediaType;

  private _address: AddressViewModel;

  get address() {
    return this._address;
  }

  private _viewModel: RepositoryViewModel;

  get viewModel() {
    return this._viewModel;
  }

  get isLoading$(): Observable<boolean> {
    return this.repositoryService.isLoading$;
  }

  get path$() {
    return this.pathService.items$;
  }

  get countries$() {
    return this.countryService.items$;
  }

  get locale$() {
    return this.languageService.selected$;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit() {
    this.subscription = this.routerService.route$.pipe(
      filter(route => route.routeConfig.path === 'view'),
    ).subscribe((route) => {
      this.load({repositoryId: route.params.repositoryId, queryParams: route.queryParams});
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription.unsubscribe();
  }

  load(request: { repositoryId: number, queryParams: QueryParams }) {
    this.pathService.load({repositoryId: request.repositoryId, type: 'REPOSITORY'});
    this.repositoryService.get(request.repositoryId);
    this.repositoryService.selected$.pipe(
      filter(vm => isNotNullOrUndefined(vm)),
      mergeMap(vm => {
        return combineLatest([
          of(vm),
          this.addressService.get(vm.addressId || 0)
        ]);
      }),
      take(1)
    ).subscribe(([vm, address]) => {
      this._viewModel = vm;
      this._address = addressViewModelFactory(address);
      this.logger.debug('ADDRESS_WRITE', this._address);
    });
  }

  save(hasAddress: boolean) {

    if (hasAddress) {
      this.logger.info('ADDRESS', this.address);
      this.addressService.persist(this.address).pipe(
        tap(payload => this.viewModel.addressId = payload.item.id),
        flatMap(() => this.repositoryService.persist(this.viewModel))
      ).subscribe(() => this.routerService.back());
    } else {
      this.repositoryService.persist(this.viewModel)
        .subscribe(() => this.routerService.back());
    }

  }
}
