import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {map, mapTo} from 'rxjs/operators';
import {
  CreateMaterialRequest,
  GetMaterialRowsRequest,
  LoadAuditableMaterialListPayload,
  LoadAuditableMaterialListRequest,
  LoadAuditableMaterialListResponse,
  LoadMaterialsRequest,
  MaterialEntity,
  MaterialItemPayload,
  MaterialItemRequest,
  MaterialItemResponse,
  MaterialListPayload,
  MaterialListResponse,
  MaterialRemovePayload
} from './material.models';

import {Observable} from 'rxjs';
import {QueryParams} from '@ngrx/data';
import {Update} from '@ngrx/entity';
import {HttpParamsBuilder, ResponseModel} from '../../../../../../core';
import {environment} from '@hb/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MaterialDataService {

  private readonly defaultPageSize = environment.defaultPageSize;

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  getRows(request: GetMaterialRowsRequest): Observable<any> {
    const url = request.repositoryId ? `${environment.api}/repositories/${request.repositoryId}/materials` : `${environment.api}/materials`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.filter, request.search)
      .append(environment.queryParamsNames.archive, request.archive)
      .append(environment.queryParamsNames.rowsOnly, true)
      .build();
    return this.http.get<ResponseModel>(url, {params});
  }

  get(request: MaterialItemRequest): Observable<MaterialItemPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/materials/${request.materialId}`;
    const params = HttpParamsBuilder.get()
      .build();
    return this.http.get<MaterialItemResponse>(url, {params}).pipe(
      map(res => new MaterialItemPayload(res))
    );
  }

  load(request: LoadMaterialsRequest): Observable<MaterialListPayload> {
    if (!!request.repositoryId) {
      return this._load(request);
    } else {
      return this._search(request);
    }
  }

  auditable(request: LoadAuditableMaterialListRequest): Observable<LoadAuditableMaterialListPayload> {
    const url = `${environment.api}/materials`;
    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.offset, request.page * this.defaultPageSize)
      .append(environment.queryParamsNames.length, this.defaultPageSize)
      .append(environment.queryParamsNames.inspectionPending, true)
      .build();

    return this.http.get<LoadAuditableMaterialListResponse>(url, {params}).pipe(
      map(res => ({items: res.datalist, rows: res.rows}))
    );
  }

  private _load(request: LoadMaterialsRequest): Observable<MaterialListPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/materials`;

    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.offset, request.page * this.defaultPageSize)
      .append(environment.queryParamsNames.length, this.defaultPageSize)
      .append(environment.queryParamsNames.filter, request.search)
      .append(environment.queryParamsNames.archive, request.archive)
      .build();

    return this.http.get<MaterialListResponse>(url, {params}).pipe(
      map(res => ({
        items: res.datalist.map(item => ({...item, virtualParentId: Number(request.repositoryId)})),
        rows: res.rows
      }))
    );
  }

  private _search(request: LoadMaterialsRequest) {
    const url = `${environment.api}/materials`;

    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.offset, request.page * this.defaultPageSize)
      .append(environment.queryParamsNames.length, this.defaultPageSize)
      .append(environment.queryParamsNames.filter, request.search)
      .build();

    return this.http.get<MaterialListResponse>(url, {params}).pipe(
      map(res => ({
        items: res.datalist,
      })),
    );
  }

  create(request: CreateMaterialRequest) {
    this.logger.trace('CREATE', request);
    const url = `${environment.api}/repositories/${request.repositoryId}/materials/0`;

    const params = HttpParamsBuilder.get()
      .append(environment.queryParamsNames.parentMaterialId, request.parentMaterialId)
      .append(environment.queryParamsNames.productId, request.productId)
      .build();

    return this.http.get<MaterialItemResponse>(url, {params}).pipe(
      map(res => ({...res, data: {...res.data, ...request, id: 0}})),
      map(res => new MaterialItemPayload(res))
    );
  }

  children(repositoryId: number, materialId: number): Observable<MaterialListPayload> {
    const url = `${environment.api}/repositories/${repositoryId}/materials/${materialId}`;
    const params = HttpParamsBuilder.get()
      .build();
    return this.http.get<MaterialItemResponse>(url, {params}).pipe(
      map(res => ({items: [...res.data.materials]})),
    );
  }

  persist(material: MaterialEntity, queryParams?: QueryParams): Observable<MaterialItemPayload> {
    return this.http.put<MaterialItemResponse>(
      `${environment.api}/repositories/${material.repositoryId}/materials/${material.id}`, material, {params: queryParams}).pipe(
      map(res => new MaterialItemPayload(res))
    );
  }

  move(material: MaterialEntity, destination: number): Observable<MaterialItemPayload> {
    return this.http.post<MaterialItemResponse>(
      `${environment.api}/repositories/${material.repositoryId}/materials/${material.id}/move`, {destination}).pipe(
      mapTo({item: {...material, repositoryId: destination}})
    );
  }

  delete(material: MaterialEntity): Observable<MaterialRemovePayload> {
    return this.http.delete(`${environment.api}/repositories/${material.repositoryId}/materials/${material.id}`).pipe(
      mapTo({repositoryId: material.repositoryId, materialId: material.id, parentId: material.parentMaterialId})
    );
  }

  notify(material: MaterialEntity, notify: boolean): Observable<Update<MaterialEntity>> {
    return this.http.post(
      `${environment.api}/repositories/${material.repositoryId}/materials/${material.id}/notification`, {state: notify}).pipe(
      mapTo({
        id: material.id,
        changes: {
          notificationEnabled: notify
        }
      } as Update<MaterialEntity>)
    );
  }
}
