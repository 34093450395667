import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';
import {environment} from '../../../../environments';


export abstract class BaseDialogService<T, P, R = any> {
  protected config: MatDialogConfig = environment.config.dialog;

  protected constructor(protected matDialog: MatDialog, protected type: ComponentType<T>) {
  }

  public open(data?: P): MatDialogRef<T, R> {
    return this.matDialog.open(this.type, {...this.config, data});
  }

  public openDialogs(): MatDialogRef<T, R>[] {
    return this.matDialog.openDialogs.filter(dialog => dialog.componentInstance === this.type);
  }

  public hasOpenDialogs() {
    return this.matDialog.openDialogs.length > 0;
  }
}
