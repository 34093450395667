import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hb-single-container',
  templateUrl: './single-container.component.html',
  styleUrls: ['./single-container.component.scss']
})
export class SingleContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
