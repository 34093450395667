<mat-list-item (click)="open()" class="list-item" mat-ripple>
  <hb-avatar [fallback]="['fas', icon]" [placeholder]="placeholder" height="40" mat-list-avatar width="40"></hb-avatar>
  <span mat-line>{{viewModel.filename}}</span>
  <span mat-line>{{viewModel.size | byteFormat}}</span>
  <span mat-line></span>

  <button (click)="$event.stopImmediatePropagation()" [disabled]="disabled" [matMenuTriggerFor]="contextMenu"
          mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</mat-list-item>



<mat-menu #contextMenu="matMenu">
  <button (click)="open()" [disabled]="!('create' | able: parentType + '.media')" mat-menu-item>
    <mat-icon fontIcon="fa-external-link-alt" fontSet="fas"></mat-icon>
    <span>{{'context.attachment.action.open' | translate}}</span>
  </button>
  <button (click)="edit()" mat-menu-item>
    <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
    <span>{{'context.attachment.action.rename' | translate}}</span>
  </button>
  <button (click)="download()" mat-menu-item>
    <mat-icon fontIcon="fa-download" fontSet="fas"></mat-icon>
    <span>{{'context.attachment.action.download' | translate}}</span>
  </button>
  <button (click)="delete()" mat-menu-item>
    <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>
    <span>{{'context.attachment.action.delete' | translate}}</span>
  </button>
</mat-menu>
