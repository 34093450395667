import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@hb/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdministrationDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  public clearCache(): Observable<any> {
    return this.http.post(`${environment.api}/clearCache`, null, {responseType: 'text'});
  }
}
