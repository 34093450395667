import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {NGXLogger} from 'ngx-logger';


@Injectable()
export class RouterEffects {

  constructor(private actions$: Actions,
              private router: Router,
              private location: Location,
              private logger: NGXLogger) {

  }
}
