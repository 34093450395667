<h2 mat-dialog-title>{{'dialogs.search.title' | translate}}:
  <small>{{timestamp | date: 'medium' : timeZone : (locale$ | async)}}</small></h2>
<mat-dialog-content fxLayout="column nowrap">
  <div fxLayout="column nowrap">
    <mat-form-field appearance="outline">
      <mat-label>
        <label for="search">{{'forms.fields.search.label' | translate}}</label>
      </mat-label>
      <input [formControl]="search"
             autocomplete="off"
             [placeholder]="'forms.fields.search.placeholder' | translate"
             id="search"
             matInput
             type="text">
      <mat-hint>{{'forms.fields.search.hint' | translate}}</mat-hint>
      <mat-icon *ngIf="!(isLoading$ | async)" fontIcon="fa-search" fontSet="fas" inline matSuffix></mat-icon>
      <mat-spinner *ngIf="isLoading$ | async" diameter="30" matSuffix mode="indeterminate"></mat-spinner>
    </mat-form-field>
  </div>
  <hb-list #list
           (vsPageChange)="fetch(search.value, $event)"
           [items]="items$ | async"
           [rows]="rows$ | async" itemSize="88"
           virtualHeight="calc(80vh - 48px - 81px - 68px - 75px - 5px)">
    <ng-template #item let-viewModel="$implicit">
      <hb-repository-item-view *ngIf="viewModel.type === 'RepositoryViewModel'"
                               (click)="openRepository(viewModel)"
                               [buttons]="{share: true, notify: true, context: true}"
                               [viewModel]="viewModel"
                               showPath="true"
                               (tapActionEvent)="close()"
                               search="true">

      </hb-repository-item-view>
      <hb-material-item-view *ngIf="viewModel.type === 'MaterialViewModel'"
                             (click)="openMaterial(viewModel)"
                             [buttons]="{status: true, case: true, notify: true, context: true}"
                             [viewModel]="viewModel"
                             showPath="true"
                             (tapActionEvent)="close()"
                             search="true">

      </hb-material-item-view>
      <hb-user-item-view *ngIf="viewModel.type === 'UserViewModel'"
                         (click)="openUser(viewModel)"
                         [buttons]="{status: true, context: true}"
                         (tapActionEvent)="close()"
                         showPath="true"
                         [viewModel]="viewModel"
                         search="true">
      </hb-user-item-view>
    </ng-template>
  </hb-list>


</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button (click)="openScan()" *ngIf="isMobilePlatform"
          mat-raised-button>{{'keys.local.global.scan' | translate}}</button>
  <button [mat-dialog-close] mat-raised-button>{{'keys.local.global.close' | translate}}</button>
</mat-dialog-actions>
