import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Update} from '@ngrx/entity';
import {DocumentViewDataSource} from './document-view.data-source';
import {combineLatest, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {AddAttachmentDialogService} from '../../../dialogs/add-attachment/src/add-attachment-dialog.service';
import {MessageService} from '../../../dialogs/message';
import {SealDocumentDialogService} from '../../../dialogs/seal-document';
import {AttachmentUpdateEvent, DocumentEntity, FieldViewModel, LanguageService, MediaType, RouterService} from '../../../modules/store';
import {SealDocumentCommand} from '../../../commands/seal-document-command/seal-document.command';
import {CommandBus} from '../../../modules/command-bus/command.bus';

@Component({
  selector: 'hb-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent implements OnInit, OnDestroy {

  private subscription: Subscription;

  private _icons = new Map([
    ['Auftraggeber/Besitzer', 'fa-user-alt'],
    ['Monteur/Prüfer', 'fa-user-cog'],
    ['Standort', 'fa-map-marker'],
    ['Allgemeine Informationen', 'fa-file'],
    ['Prüfungsdaten', 'fa-tasks'],
    ['Bemerkung', 'fa-comment-alt'],
    ['Signaturen', 'fa-signature'],
  ]);

  public readonly mediaType: typeof MediaType = MediaType;
  public locale: string;

  constructor(
    private dataSource: DocumentViewDataSource,
    private routerService: RouterService,
    private languageService: LanguageService,
    private addAttachmentDialogService: AddAttachmentDialogService,
    private sealDocumentDialogService: SealDocumentDialogService,
    private messageService: MessageService,
    private commandBus: CommandBus,
    private logger: NGXLogger) {

  }

  public get groups() {
    return Object.keys(this.viewModel.groups);
  }

  public get viewModel() {
    return this.dataSource.viewModel;
  }

  public get path$() {
    return this.dataSource.path$;
  }

  public get countries$() {
    return this.dataSource.countries$;
  }

  public get attachments$() {
    return this.dataSource.attachments$;
  }

  public get icons() {
    return this._icons;
  }

  public get isLoading$() {
    return this.dataSource.isLoading$;
  }

  public get locale$() {
    return this.dataSource.locale$;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit() {
    this.subscription = combineLatest([this.routerService.route$, this.languageService.selected$]).pipe(
      filter(([route, language]) => route.routeConfig.path === 'documents/:documentId'),
    ).subscribe(([route, language]) => {
      this.dataSource.load({
        ...route.params,
        queryParams: route.queryParams
      });
    });
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.subscription.unsubscribe();
  }

  public download(type: string): void {
    this.dataSource.download(type);
  }

  public save() {
    this.dataSource.save().subscribe(() => this.routerService.back());
  }

  public seal() {
    const command = new SealDocumentCommand({viewModel: this.viewModel});
    this.commandBus.dispatch(command);
  }

  addAttachment() {
    this.addAttachmentDialogService.open({mediaType: MediaType.DOCUMENT_ATTACHMENT, targetId: this.viewModel.id})
      .afterClosed()
      .pipe(
        filter(result => !!result)
      ).subscribe(() => this.messageService.openV2(marker('keys.local.messages.success')));
  }

  signatureUpdateEventHandler(event: AttachmentUpdateEvent, field: FieldViewModel) {
    field.mediaPoolFile = event.data;
    const update = {
      id: this.viewModel.id,
      changes: {
        fields: this.viewModel.fields.map(oldField => oldField.id === field.id ? field.updated : oldField.updated)
      }
    } as Update<DocumentEntity>;

    this.dataSource.update(update);
  }
}
