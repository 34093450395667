import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SegmentListPayload, SegmentListResponse} from './path.models';
import {environment} from '../../../../../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class PathDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  public load(repositoryId: number, materialId: number, userId: number, documentId: number, type: string): Observable<SegmentListPayload> {
    switch (type) {
      case 'REPOSITORY':
        return this.http.get<SegmentListResponse>(
          `${environment.api}/repositories/${repositoryId}/path`).pipe(
          map(res => ({items: res.datalist}))
        );
      case 'MATERIAL':
        return this.http.get<SegmentListResponse>(
          `${environment.api}/repositories/${repositoryId}/materials/${materialId}/path`).pipe(
          map(res => ({items: res.datalist}))
        );
      case 'USER':
        return this.http.get<SegmentListResponse>(
          `${environment.api}/repositories/${repositoryId}/users/${userId}/path`).pipe(
          map(res => ({items: res.datalist}))
        );
      case 'DOCUMENT':
        return this.http.get<SegmentListResponse>(
          `${environment.api}/repositories/${repositoryId}/materials/${materialId}/documents/${documentId}/path`).pipe(
          map(res => ({items: res.datalist}))
        );
    }
  }
}
