export interface SearchModel {
  id: number;
  type: 'search';
  data: string;
}

export interface SearchEntity {
  id: number;
  type: 'search';
  data: {
    timestamp: number;
    search: string;
    rows: number;
  };
}

export class SearchEntityAdapter implements SearchModel {
  data: string;
  id: number;
  type: 'search';

  constructor(entity: SearchEntity) {
    this.id = entity.id;
    this.type = entity.type;
    this.data = JSON.stringify(entity.data);
  }
}

export class SearchModelAdapter implements SearchEntity {
  id: number;
  type: 'search';
  data: { timestamp: number; search: string; rows: number };

  constructor(model: SearchModel) {
    this.id = model.id;
    this.type = model.type;
    this.data = JSON.parse(model.data);
  }
}

// tslint:disable-next-line:no-shadowed-variable
export function searchEntityFactory(id: number, timestamp: number, search: string, rows: number): SearchEntity {
  return {id, type: 'search', data: {timestamp, search, rows}};
}
