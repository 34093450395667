import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {ACCOUNT_FEATURE_KEY, accountAdapter, AccountPartialState, AccountState} from './account.reducer';
import {AccountEntity, AccountViewModel, accountViewModelFactory} from './account.models';
import {Dictionary} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';
import {UserSelectors} from '../../../user-store/src/+state/user.selectors';
import {subject} from '@casl/ability';

const getAccountState = createFeatureSelector<AccountPartialState, AccountState>(ACCOUNT_FEATURE_KEY);

const {selectAll, selectEntities} = accountAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getAccountState,
  (state: AccountState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getAccountState,
  (state: AccountState) => state.exception
);

const getAccountId: MemoizedSelector<object, number> = createSelector(
  getAccountState,
  (state: AccountState) => state.accountId
);

const getItems: MemoizedSelector<object, AccountViewModel[]> = createSelector(
  getAccountState,
  (state: AccountState) => selectAll(state).map(item => accountViewModelFactory(item))
);

const getEntities: MemoizedSelector<object, Dictionary<AccountEntity>> = createSelector(
  getAccountState,
  (state: AccountState) => selectEntities(state)
);

const getSelected: MemoizedSelector<object, AccountViewModel> = createSelector(
  getEntities,
  RouterSelectors.getAccountIdParam,
  (entities: Dictionary<AccountEntity>, accountId: number) => {
    return !!accountId && !!entities[accountId] ? accountViewModelFactory(entities[accountId]) : undefined;
  }
);

const getContext = createSelector(
  getSelected,
  UserSelectors.getUserId,
  (account, userId) => (subject('account', {isOwner: !!account && !!account.ownerId && account.ownerId === Number(userId)}))
);

export const AccountSelectors = {
  isLoading,
  getAccountState,
  getException,
  getAccountId,
  getItems,
  getEntities,
  getSelected,
  getContext
};
