import {createAction, props} from '@ngrx/store';
import {AccountEntity, AccountItemPayload, AccountItemRequest, AccountListPayload} from './account.models';
import {Update} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';

enum AccountActionTypes {
  LOAD_ACCOUNTS_REQUEST = '[Account] Load Accounts Request',
  LOAD_ACCOUNTS_SUCCESS = '[Account] Load Accounts Success',
  LOAD_ACCOUNTS_FAILURE = '[Account] Load Accounts Failure',
  GET_ACCOUNT_REQUEST = '[Account] Get Account Request',
  GET_ACCOUNT_SUCCESS = '[Account] Get Account Success',
  GET_ACCOUNT_FAILURE = '[Account] Get Account Failure',
  SET_ACCOUNT_ID = '[Account] Set Account Id',
  REMOVE_ACCOUNT_ID = '[Account] Remove Account Id',
  PERSIST_ACCOUNT_REQUEST = '[Account] Persist Account Request',
  PERSIST_ACCOUNT_SUCCESS = '[Account] Persist Account Success',
  PERSIST_ACCOUNT_FAILURE = '[Account] Persist Account Failure',
  UPDATE_ACCOUNT = '[Account] Update Account'
}

const loadAccountsRequest = createAction(
  AccountActionTypes.LOAD_ACCOUNTS_REQUEST,
);

const loadAccountsSuccess = createAction(
  AccountActionTypes.LOAD_ACCOUNTS_SUCCESS,
  props<{ payload: AccountListPayload }>()
);

const loadAccountsFailure = createAction(
  AccountActionTypes.LOAD_ACCOUNTS_FAILURE,
  props<{ exception: Exception }>()
);

const getAccountRequest = createAction(
  AccountActionTypes.GET_ACCOUNT_REQUEST,
  props<{ request: AccountItemRequest }>()
);

const getAccountSuccess = createAction(
  AccountActionTypes.GET_ACCOUNT_SUCCESS,
  props<{ payload: AccountItemPayload }>()
);

const getAccountFailure = createAction(
  AccountActionTypes.GET_ACCOUNT_FAILURE,
  props<{ exception: Exception }>()
);

const setAccountId = createAction(
  AccountActionTypes.SET_ACCOUNT_ID,
  props<{ accountId: number }>()
);

const removeAccountId = createAction(
  AccountActionTypes.REMOVE_ACCOUNT_ID,
);

const persistAccountRequest = createAction(
  AccountActionTypes.PERSIST_ACCOUNT_REQUEST,
  props<{ account: AccountEntity }>()
);

const persistAccountSuccess = createAction(
  AccountActionTypes.PERSIST_ACCOUNT_SUCCESS,
  props<{ payload: AccountItemPayload }>()
);

const persistAccountFailure = createAction(
  AccountActionTypes.PERSIST_ACCOUNT_FAILURE,
  props<{ exception: Exception }>()
);

const updateAccount = createAction(
  AccountActionTypes.UPDATE_ACCOUNT,
  props<{ update: Update<AccountEntity> }>()
);

export const AccountActions = {
  loadAccountsRequest,
  loadAccountsSuccess,
  loadAccountsFailure,
  getAccountRequest,
  getAccountSuccess,
  getAccountFailure,
  setAccountId,
  removeAccountId,
  persistAccountRequest,
  persistAccountSuccess,
  persistAccountFailure,
  updateAccount
};
