import {createAction, props} from '@ngrx/store';
import {QueryParams} from '@ngrx/data';
import {ProductEntity, ProductItemPayload, ProductListPayload} from './product.models';
import {Exception} from '../../../../../../core/models';


enum ProductActionsTypes {

  LOAD_PRODUCTS_REQUEST = '[Product] Load Products Request',
  LOAD_PRODUCTS_SUCCESS = '[Product] Load Products Success',
  LOAD_PRODUCTS_FAILURE = '[Product] Load Products Failure',

  FETCH_PRODUCTS_REQUEST = '[Product] Fetch Products Request',
  FETCH_PRODUCTS_SUCCESS = '[Product] Fetch Products Success',
  FETCH_PRODUCTS_FAILURE = '[Product] Fetch Products Failure',

  GET_PRODUCT_REQUEST = '[Product] Get Product Request',
  GET_PRODUCT_SUCCESS = '[Product] Get Product Success',
  GET_PRODUCT_FAILURE = '[Product] Get Product Failure',

  PERSIST_PRODUCT_REQUEST = '[Product] Persist Product Request',
  PERSIST_PRODUCT_SUCCESS = '[Product] Persist Product Success',
  PERSIST_PRODUCT_FAILURE = '[Product] Persist Product Failure',

  RESET_PRODUCTS = '[Product] Reset Products',

}

const loadProductsRequest = createAction(
  ProductActionsTypes.LOAD_PRODUCTS_REQUEST,
  props<{ queryParams: QueryParams }>()
);

const loadProductsSuccess = createAction(
  ProductActionsTypes.LOAD_PRODUCTS_SUCCESS,
  props<{ payload: ProductListPayload }>()
);

const loadProductsFailure = createAction(
  ProductActionsTypes.LOAD_PRODUCTS_FAILURE,
  props<{ exception: Exception }>()
);

const fetchProductsRequest = createAction(
  ProductActionsTypes.FETCH_PRODUCTS_REQUEST,
  props<{ queryParams: QueryParams }>()
);

const fetchProductsSuccess = createAction(
  ProductActionsTypes.FETCH_PRODUCTS_SUCCESS,
  props<{ payload: ProductListPayload }>()
);

const fetchProductsFailure = createAction(
  ProductActionsTypes.FETCH_PRODUCTS_FAILURE,
  props<{ exception: Exception }>()
);

const getProductRequest = createAction(
  ProductActionsTypes.GET_PRODUCT_REQUEST,
  props<{ productId: string | number, queryParams: QueryParams }>()
);

const getProductSuccess = createAction(
  ProductActionsTypes.GET_PRODUCT_SUCCESS,
  props<{ payload: ProductItemPayload }>()
);

const getProductFailure = createAction(
  ProductActionsTypes.GET_PRODUCT_FAILURE,
  props<{ exception: Exception }>()
);

const persistProductRequest = createAction(
  ProductActionsTypes.PERSIST_PRODUCT_REQUEST,
  props<{ product: ProductEntity, queryParams: QueryParams }>()
);

const persistProductSuccess = createAction(
  ProductActionsTypes.PERSIST_PRODUCT_SUCCESS,
  props<{ payload: ProductItemPayload }>()
);

const persistProductFailure = createAction(
  ProductActionsTypes.PERSIST_PRODUCT_FAILURE,
  props<{ exception: Exception }>()
);

const resetProducts = createAction(
  ProductActionsTypes.RESET_PRODUCTS,
);

export const ProductActions = {
  loadProductsRequest,
  loadProductsSuccess,
  loadProductsFailure,
  fetchProductsRequest,
  fetchProductsSuccess,
  fetchProductsFailure,
  getProductRequest,
  getProductSuccess,
  getProductFailure,
  persistProductRequest,
  persistProductSuccess,
  persistProductFailure,
  resetProducts,
};
