import {Component, Inject, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserEntity, UserViewModel, userViewModelFactory} from '../../../modules/store';
import {environment} from '../../../../environments';


@Component({
  selector: 'hb-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {

  public readonly password_pattern = environment.regex.password_pattern;
  private _viewModel: UserViewModel = userViewModelFactory(this.data);

  public get viewModel() {
    return this._viewModel;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data: UserEntity, private logger: NGXLogger) {

  }

  ngOnInit(): void {

  }

}
