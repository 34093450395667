import {RouterService} from '../../modules/store';
import {CameraSearchDialogService} from '../../dialogs/camera-search/src/camera-search-dialog.service';
import {Injectable} from '@angular/core';
import {DomainCommand} from '../../modules/command-bus/command';
import {CommandHandler} from '../../modules/command-bus/command.handler';

@Injectable()
export class FilterWithCameraScanCommandHandler implements CommandHandler {

  constructor(private cameraSearchDialogService: CameraSearchDialogService, private routerService: RouterService) {
  }

  handle(command: DomainCommand): void {
    this.cameraSearchDialogService.open()
      .afterClosed()
      .subscribe(search => command.payload.control.patchValue(search, {emitModelToViewChange: true}));
  }
}
