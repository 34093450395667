import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {CategoryListPayload, CategoryListResponse} from './category.models';
import {environment} from '../../../../../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class CategoryDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(): Observable<CategoryListPayload> {
    return this.http.get<CategoryListResponse>(`${environment.api}/categories`).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }
}
