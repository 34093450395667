import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {ADDRESS_FEATURE_KEY, addressAdapter, AddressPartialState, AddressState} from './address.reducer';
import {AddressEntity, addressEntityFactory, AddressViewModel, addressViewModelFactory} from './address.models';
import {Dictionary} from '@ngrx/entity';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {Exception} from '../../../../../../core';

export const getAddressState = createFeatureSelector<AddressPartialState, AddressState>(ADDRESS_FEATURE_KEY);

const {selectAll, selectEntities} = addressAdapter.getSelectors();

export const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getAddressState,
  (state: AddressState) => state.isLoading
);

export const getException: MemoizedSelector<object, Exception> = createSelector(
  getAddressState,
  (state: AddressState) => state.exception
);

export const getItems: MemoizedSelector<object, AddressViewModel[]> = createSelector(
  getAddressState,
  (state: AddressState) => selectAll(state).map(item => addressViewModelFactory(item))
);

export const getEntities: MemoizedSelector<object, Dictionary<AddressEntity>> = createSelector(
  getAddressState,
  (state: AddressState) => selectEntities(state)
);

export const getById = createSelector(
  getEntities,
  (entities: Dictionary<AddressEntity>, addressId: number) => {
    console.log('ADDRESS_ID', addressId);
    console.log('ENTITY', entities[addressId]);
    console.log('IF', !isNotNullOrUndefined(addressId), addressId === 0, !entities[addressId]);
    var result = null;
    if (!isNotNullOrUndefined(addressId) || addressId === 0 || !entities[addressId]) {
      const model = addressEntityFactory();
      result = addressViewModelFactory(model);
    } else {
      result = addressViewModelFactory(entities[addressId]);
    }
    console.log('RESULT', result);
    return result;
  }
);

export const AddressSelectors = {
  getAddressState,
  isLoading,
  getException,
  getItems,
  getEntities,
  getById,
};
