<div style="flex: 1;">
  <div fxFlex fxLayout="row" fxLayoutGap="6px">
    <div fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" style="height: 40px; width: 40px">
        <hb-avatar [fallback]="['fas', 'dice-d6']" [mediaId]="viewModel.mediaId" height="40"
                   width="40">

        </hb-avatar>
      </div>
    </div>
    <div fxFlex fxFlexFill>
      <p>{{viewModel?.name}}</p>
      <p>{{viewModel?.articleNumber}}</p>
      <p>{{viewModel?.serialNumber}}</p>
    </div>
  </div>
</div>
