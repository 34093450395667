import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromManufacturer from './manufacturer.reducer';
import {NGXLogger} from 'ngx-logger';
import {ManufacturerSelectors} from './manufacturer.selectors';
import {ManufacturerActions} from './manufacturer.actions';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {

  public readonly isLoading$ = this.store.pipe(select(ManufacturerSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(ManufacturerSelectors.getException));
  public readonly items$ = this.store.pipe(select(ManufacturerSelectors.getItems));

  constructor(private store: Store<fromManufacturer.ManufacturerPartialState>, private logger: NGXLogger) {

  }

  public load() {
    this.store.dispatch(ManufacturerActions.loadManufacturersRequest());
  }
}
