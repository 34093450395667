<mat-list-item class="list-item" mat-ripple>
  <hb-avatar [fallback]="['fas', 'user']" [mediaId]="viewModel.mediaId" height="40" mat-list-avatar
             width="40"></hb-avatar>
  <span mat-line>{{viewModel.name}}</span>
  <small *ngIf="!!viewModel.path && showPath" class="hb-mat-mute" mat-line>{{'keys.local.global.in' | translate}}
    : {{viewModel.path}}</small>
  <small mat-line>{{viewModel.email}}</small>

  <button (click)="$event.stopPropagation()" *ngIf="buttons.status" [disabled]="disabled" mat-icon-button>
    <hb-status [status]="viewModel.status"></hb-status>
  </button>
  <button (click)="$event.stopPropagation()" *ngIf="buttons.context" [disabled]="disabled"
          [matMenuTriggerFor]="defaultMenu" mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</mat-list-item>

<mat-menu #defaultMenu="matMenu">
  <ng-container *ngIf="!search">
    <button (click)="edit()" [disabled]="!('read' | able: 'user')" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.user.action.open' | translate}}</span>
    </button>
    <!--    <button (click)="move()" [disabled]="!('update' | able: 'user')" mat-menu-item>-->
    <!--      <mat-icon fontIcon="fa-share" fontSet="fas"></mat-icon>-->
    <!--      <span>{{'context.user.action.move' | translate}}</span>-->
    <!--    </button>-->
    <!--    <button (click)="delete()" [disabled]="!('delete' | able: 'user')" mat-menu-item>-->
    <!--      <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>-->
    <!--      <span>{{'context.user.action.delete' | translate}}</span>-->
    <!--    </button>-->
  </ng-container>
  <ng-container *ngIf="search">
    <button (click)="edit()" [disabled]="!('read' | able: 'user')" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.user.action.open' | translate}}</span>
    </button>
    <button (click)="openInRepository()" mat-menu-item>
      <mat-icon fontIcon="fa-folder-open" fontSet="fas"></mat-icon>
      <span>{{'context.repository.action.open_parent' | translate}}</span>
    </button>
  </ng-container>
</mat-menu>
