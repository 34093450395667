import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromCertificate from './+state/certificate.reducer';
import {EffectsModule} from '@ngrx/effects';
import {CertificateEffects} from './+state/certificate.effects';
import {CertificateDataService} from './+state/certificate-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromCertificate.CERTIFICATE_FEATURE_KEY,
      fromCertificate.reducer
    ),
    EffectsModule.forFeature([CertificateEffects]),
  ],
  providers: [CertificateDataService],
  declarations: [],
  exports: []
})
export class CertificateStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
