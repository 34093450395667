import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {LicenseDataService} from './license-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {LicenseActions} from './license.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class LicenseEffects {

  loadLicensesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.loadLicensesRequest),

      switchMap(action => this.licenseDataService.load().pipe(
        map(payload => LicenseActions.loadLicensesSuccess({payload})),
        catchError(error => of(LicenseActions.loadLicensesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadLicensesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.loadLicensesSuccess),
    ), {dispatch: false}
  );

  loadLicensesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(LicenseActions.loadLicensesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions, private licenseDataService: LicenseDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
