import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {select, Store} from '@ngrx/store';
import * as fromTile from './tile.reducer';
import {QueryParams} from '@ngrx/data';
import {TileSelectors} from './tile.selectors';
import {TileActions} from './tile.actions';

@Injectable({
  providedIn: 'root'
})
export class TileService {

  items$ = this.store.pipe(select(TileSelectors.getAllTiles));
  autocomplete$ = this.store.pipe(select(TileSelectors.getAutocomplete));
  isLoading$ = this.store.pipe(select(TileSelectors.isLoading));
  exception$ = this.store.pipe(select(TileSelectors.getException));

  constructor(private store: Store<fromTile.TilePartialState>, private logger: NGXLogger) {
  }

  load(queryParams?: QueryParams) {
    this.store.dispatch(TileActions.loadTilesRequest({queryParams}));
  }

  fetch(queryParams?: QueryParams) {
    this.store.dispatch(TileActions.fetchTilesRequest({queryParams}));
  }

  get(queryParams?: QueryParams) {
    this.store.dispatch(TileActions.getTileRequest({queryParams}));
  }

  autocomplete(queryParams?: QueryParams) {
    this.store.dispatch(TileActions.autocompleteRequest({queryParams}));
  }

  resetAutocomplete() {
    this.store.dispatch(TileActions.autocompleteInitial());
  }
}
