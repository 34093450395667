import {Injectable} from '@angular/core';

import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {RepositoryEntity} from '../../../../store';
import {environment} from '@hb/environments/index';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient, private logger: NGXLogger) {

  }

  getRepositoryImage(repository: RepositoryEntity) {
    // if (repository.isUserRoot) {
    //   return '../../../../assets/images/home.png';
    // } else if (repository.isParent) {
    //   return '../../../../assets/images/level-up.png';
    // } else if (repository.sharedFolder) {
    //   return '../../../../assets/images/share.png';
    // } else {
    //   return '../../../../assets/images/folder.png';
    // }
  }

  getProductImage(baseUrl: string, article: string, width: string, height: string): string {
    return !!baseUrl ? baseUrl
      .replace('{{article}}', article)
      .replace('{{width}}', width)
      .replace('{{height}}', height) : null;
  }

  getMedia(mediaId: any): string {
    if (mediaId) {
      return `${environment.api}/media/${mediaId}`;
    } else {
      return null;
    }
  }
}
