import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {ManufacturerDataService} from './manufacturer-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {ManufacturerActions} from './manufacturer.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class ManufacturerEffects {

  loadManufacturersRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.loadManufacturersRequest),

      switchMap(action => this.manufacturerDataService.load().pipe(
        map(payload => ManufacturerActions.loadManufacturersSuccess({payload})),
        catchError(error => of(ManufacturerActions.loadManufacturersFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadManufacturersSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManufacturerActions.loadManufacturersSuccess),
    ), {dispatch: false}
  );

  loadManufacturersFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(ManufacturerActions.loadManufacturersFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions, private manufacturerDataService: ManufacturerDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
