import {createAction, props} from '@ngrx/store';
import {
  ActivatePayload,
  ActivateRequest,
  ChangeEmailPayload,
  ChangeEmailRequest,
  LoginPayload,
  LoginRequest,
  PasswordForgottenPayload,
  PasswordForgottenRequest,
  RefreshPayload,
  RefreshRequest,
  RegisterPayload,
  RegisterRequest,
  ResetPasswordPayload,
  ResetPasswordRequest
} from './authentication.models';
import {Exception} from '../../../../../../core/models';

enum AuthenticationActionTypes {
  LOGIN_REQUEST = '[Authentication] Login Request',
  LOGIN_SUCCESS = '[Authentication] Login Success',
  LOGIN_FAILURE = '[Authentication] Login Failure',
  LOGIN_COMPLETE = '[Authentication] Login Complete',
  LOGIN_FINISH = '[Authentication] Login Finish',

  LOGOUT_REQUEST = '[Authentication] Logout Request',
  LOGOUT_SUCCESS = '[Authentication] Logout Success',
  LOGOUT_FAILURE = '[Authentication] Logout Failure',

  REGISTER_REQUEST = '[Authentication] Register Request',
  REGISTER_SUCCESS = '[Authentication] Register Success',
  REGISTER_FAILURE = '[Authentication] Register Failure',

  PASSWORD_FORGOTTEN_REQUEST = '[Authentication] Password Forgotten Request',
  PASSWORD_FORGOTTEN_SUCCESS = '[Authentication] Password Forgotten Success',
  PASSWORD_FORGOTTEN_FAILURE = '[Authentication] Password Forgotten Failure',

  RESET_PASSWORD_REQUEST = '[Authentication] Reset Password Request',
  RESET_PASSWORD_SUCCESS = '[Authentication] Reset Password Success',
  RESET_PASSWORD_FAILURE = '[Authentication] Reset Password Failure',

  ACTIVATE_REQUEST = '[Authentication] Activate Request',
  ACTIVATE_SUCCESS = '[Authentication] Activate Success',
  ACTIVATE_FAILURE = '[Authentication] Activate Failure',

  CHANGE_EMAIL_REQUEST = '[Authentication] Change Email Request',
  CHANGE_EMAIL_SUCCESS = '[Authentication] Change Email Success',
  CHANGE_EMAIL_FAILURE = '[Authentication] Change Email Failure',

  REFRESH_REQUEST = '[Authentication] Refresh Request',
  REFRESH_SUCCESS = '[Authentication] Refresh Success',
  REFRESH_FAILURE = '[Authentication] Refresh Failure',
  REFRESH_COMPLETE = '[Authentication] Refresh Complete',

  GET_LOGGED_IN_USER_ID_REQUEST = '[Authentication] Get Logged In User Id Request',
  GET_LOGGED_IN_USER_ID_SUCCESS = '[Authentication] Get Logged In User Id Success',
  GET_LOGGED_IN_USER_ID_FAILURE = '[Authentication] Get Logged In User Id Failure',

  IS_LOGGED_IN_REQUEST = '[Authentication] Get Is Logged In Request',
  IS_LOGGED_IN_SUCCESS = '[Authentication] Get Is Logged In Success',
  IS_LOGGED_IN_FAILURE = '[Authentication] Get Is Logged In Failure',

  CLEAR_SESSION = '[Authentication] Clear Session',

}

const loginRequest = createAction(
  AuthenticationActionTypes.LOGIN_REQUEST,
  props<{ request: LoginRequest }>()
);

const loginSuccess = createAction(
  AuthenticationActionTypes.LOGIN_SUCCESS,
  props<{ payload: LoginPayload }>()
);

const loginFailure = createAction(
  AuthenticationActionTypes.LOGIN_FAILURE,
  props<{ exception: Exception }>()
);

const loginComplete = createAction(
  AuthenticationActionTypes.LOGIN_COMPLETE,
  props<{ payload: { repositoryId: number, accountId: number, userId: number } }>()
);

const loginFinish = createAction(
  AuthenticationActionTypes.LOGIN_FINISH,
);

const logoutRequest = createAction(
  AuthenticationActionTypes.LOGOUT_REQUEST,
);

const logoutSuccess = createAction(
  AuthenticationActionTypes.LOGOUT_SUCCESS,
);

const logoutFailure = createAction(
  AuthenticationActionTypes.LOGOUT_FAILURE,
  props<{ exception: Exception }>()
);

const registerRequest = createAction(
  AuthenticationActionTypes.REGISTER_REQUEST,
  props<{ request: RegisterRequest }>()
);

const registerSuccess = createAction(
  AuthenticationActionTypes.REGISTER_SUCCESS,
  props<{ payload: RegisterPayload }>()
);

const registerFailure = createAction(
  AuthenticationActionTypes.REGISTER_FAILURE,
  props<{ exception: Exception }>()
);


const passwordForgottenRequest = createAction(
  AuthenticationActionTypes.PASSWORD_FORGOTTEN_REQUEST,
  props<{ request: PasswordForgottenRequest }>()
);

const passwordForgottenSuccess = createAction(
  AuthenticationActionTypes.PASSWORD_FORGOTTEN_SUCCESS,
  props<{ payload: PasswordForgottenPayload }>()
);

const passwordForgottenFailure = createAction(
  AuthenticationActionTypes.PASSWORD_FORGOTTEN_FAILURE,
  props<{ exception: Exception }>()
);

const resetPasswordRequest = createAction(
  AuthenticationActionTypes.RESET_PASSWORD_REQUEST,
  props<{ request: ResetPasswordRequest }>()
);

const resetPasswordSuccess = createAction(
  AuthenticationActionTypes.RESET_PASSWORD_SUCCESS,
  props<{ payload: ResetPasswordPayload }>()
);

const resetPasswordFailure = createAction(
  AuthenticationActionTypes.RESET_PASSWORD_FAILURE,
  props<{ exception: Exception }>()
);

const activateRequest = createAction(
  AuthenticationActionTypes.ACTIVATE_REQUEST,
  props<{ request: ActivateRequest }>()
);

const activateSuccess = createAction(
  AuthenticationActionTypes.ACTIVATE_SUCCESS,
  props<{ payload: ActivatePayload }>()
);

const activateFailure = createAction(
  AuthenticationActionTypes.ACTIVATE_FAILURE,
  props<{ exception: Exception }>()
);

const changeEmailRequest = createAction(
  AuthenticationActionTypes.CHANGE_EMAIL_REQUEST,
  props<{ request: ChangeEmailRequest }>()
);

const changeEmailSuccess = createAction(
  AuthenticationActionTypes.CHANGE_EMAIL_SUCCESS,
  props<{ payload: ChangeEmailPayload }>()
);

const changeEmailFailure = createAction(
  AuthenticationActionTypes.CHANGE_EMAIL_FAILURE,
  props<{ exception: Exception }>()
);

const refreshRequest = createAction(
  AuthenticationActionTypes.REFRESH_REQUEST,
  props<{ request: RefreshRequest }>()
);

const refreshSuccess = createAction(
  AuthenticationActionTypes.REFRESH_SUCCESS,
  props<{ payload: RefreshPayload }>()
);

const refreshFailure = createAction(
  AuthenticationActionTypes.REFRESH_FAILURE,
  props<{ exception: Exception }>()
);

const getLoggedInUserIdRequest = createAction(
  AuthenticationActionTypes.GET_LOGGED_IN_USER_ID_REQUEST
);

const getLoggedInUserIdSuccess = createAction(
  AuthenticationActionTypes.GET_LOGGED_IN_USER_ID_SUCCESS,
  props<{ payload: { userId: number, homeId: number } }>()
);

const getLoggedInUserIdFailure = createAction(
  AuthenticationActionTypes.GET_LOGGED_IN_USER_ID_FAILURE,
  props<{ exception: Exception }>()
);

const clearSession = createAction(
  AuthenticationActionTypes.CLEAR_SESSION,
);

const isLoggedInRequest = createAction(
  AuthenticationActionTypes.IS_LOGGED_IN_REQUEST,
);

const isLoggedInSuccess = createAction(
  AuthenticationActionTypes.IS_LOGGED_IN_SUCCESS,
);

const isLoggedInFailure = createAction(
  AuthenticationActionTypes.IS_LOGGED_IN_FAILURE,
);

export const AuthenticationActions = {
  loginRequest,
  loginSuccess,
  loginFailure,
  loginComplete,
  loginFinish,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
  registerRequest,
  registerSuccess,
  registerFailure,
  passwordForgottenRequest,
  passwordForgottenSuccess,
  passwordForgottenFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  activateRequest,
  activateSuccess,
  activateFailure,
  changeEmailRequest,
  changeEmailSuccess,
  changeEmailFailure,
  refreshRequest,
  refreshSuccess,
  refreshFailure,
  getLoggedInUserIdRequest,
  getLoggedInUserIdSuccess,
  getLoggedInUserIdFailure,
  clearSession,
  isLoggedInRequest,
  isLoggedInSuccess,
  isLoggedInFailure,
};
