import {Action, createReducer, on} from '@ngrx/store';
import * as AttachmentActions from './attachment.actions';
import {AttachmentEntity} from './attachment.models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const ATTACHMENT_FEATURE_KEY = 'attachment';

export interface AttachmentState extends EntityState<AttachmentEntity> {
  isLoading: boolean;
  exception: Exception;
}

export interface AttachmentPartialState {
  readonly [ATTACHMENT_FEATURE_KEY]: AttachmentState;
}

export const attachmentAdapter: EntityAdapter<AttachmentEntity> = createEntityAdapter<AttachmentEntity>();

export const initialState: AttachmentState = attachmentAdapter.getInitialState({
  isLoading: false,
  exception: null,
});


export const attachmentReducer = createReducer(
  initialState,

  on(AttachmentActions.loadAttachmentsRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AttachmentActions.loadAttachmentsSuccess, (state, {payload}) =>
    attachmentAdapter.addMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(AttachmentActions.loadAttachmentsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AttachmentActions.getAttachmentRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AttachmentActions.getAttachmentSuccess, (state, {payload}) =>
    attachmentAdapter.addOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(AttachmentActions.getAttachmentFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AttachmentActions.createAttachmentSuccess, (state, {payload}) =>
    attachmentAdapter.upsertOne(payload.item, {
      ...state
    })
  ),
  on(AttachmentActions.updateAttachmentSuccess, (state, {payload}) =>
    attachmentAdapter.upsertOne(payload.item, {
      ...state
    })
  ),
  on(AttachmentActions.removeAttachmentSuccess, (state, {payload}) =>
    attachmentAdapter.removeOne(payload.id, {
      ...state
    })
  ),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: AttachmentState | undefined, action: Action) {
  return attachmentReducer(state, action);
}
