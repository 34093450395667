import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MaterialDataService} from './material-data.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Update} from '@ngrx/entity';
import {Exception} from '../../../../../../core';
import {AttachmentActions} from '../../../attachment-store/src/+state/attachment.actions';
import {MaterialActions} from './material.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {MediaType} from '../../../attachment-store';
import {MaterialEntity} from './material.models';
import {remoteTranslationMap} from '../../../../../../core/maps/src/translation.maps';


@Injectable()
export class MaterialEffects {

  loadMaterialsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.loadMaterialsRequest),

      switchMap(action => this.materialDataService.load(action.request).pipe(
        map(payload => MaterialActions.loadMaterialsSuccess({payload})),
        catchError(error => of(MaterialActions.loadMaterialsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadMaterialsSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.loadMaterialsSuccess),
    ), {dispatch: false}
  );
  loadMaterialsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.loadMaterialsFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  loadAuditableMaterialListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.loadAuditableMaterialListRequest),

      switchMap(action => this.materialDataService.auditable(action.request).pipe(
        map(payload => MaterialActions.loadAuditableMaterialListSuccess({payload})),
        catchError(error => of(MaterialActions.loadAuditableMaterialListFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadAuditableMaterialListSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.loadAuditableMaterialListSuccess),
    ), {dispatch: false}
  );
  loadAuditableMaterialListFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.loadAuditableMaterialListFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getMaterialRowsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.getMaterialRowsRequest),

      switchMap(action => this.materialDataService.getRows(action.request).pipe(
        map(payload => MaterialActions.getMaterialRowsSuccess({payload})),
        catchError(error => of(MaterialActions.getMaterialRowsFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  getMaterialRowsSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.getMaterialRowsSuccess),
    ), {dispatch: false}
  );

  getMaterialRowsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.getMaterialRowsFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  getMaterialRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.getMaterialRequest),
      switchMap(action => this.materialDataService.get(action.request).pipe(
        map(payload => MaterialActions.getMaterialSuccess({payload})),
        catchError(error => of(MaterialActions.getMaterialFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getMaterialSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.getMaterialSuccess),
    ), {dispatch: false}
  );
  getMaterialFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.getMaterialFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  updateMaterialRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.updateMaterialRequest),
      switchMap(action => this.materialDataService.get(action.request).pipe(
        map(payload => MaterialActions.updateMaterialSuccess({payload})),
        catchError(error => of(MaterialActions.updateMaterialFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  updateMaterialSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.updateMaterialSuccess),
    ), {dispatch: false}
  );

  updateMaterialFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.updateMaterialFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  createMaterialRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.createMaterialRequest),
      switchMap(action => this.materialDataService.create(action.request).pipe(
        map(payload => MaterialActions.createMaterialSuccess({payload})),
        catchError(error => of(MaterialActions.createMaterialFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  createMaterialSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.createMaterialSuccess),
    ), {dispatch: false}
  );

  createMaterialFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.createMaterialFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  loadChildrenRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.loadChildrenRequest),

      switchMap(action => this.materialDataService.children(action.request.repositoryId, action.request.materialId).pipe(
        map(payload => MaterialActions.loadChildrenSuccess({payload})),
        catchError(error => of(MaterialActions.loadMaterialsFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadChildrenSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.loadChildrenSuccess),
    ), {dispatch: false}
  );

  loadChildrenFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.loadChildrenFailure),
      map(action => action.exception),
      filter(action => !action.isAuthenticationException),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  persistMaterialRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.persistMaterialRequest),

      switchMap(action => this.materialDataService.persist(action.material, action.queryParams).pipe(
        map(payload => MaterialActions.persistMaterialSuccess({payload})),
        catchError(error => of(MaterialActions.persistMaterialFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  persistMaterialSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.persistMaterialSuccess),
      map(action => remoteTranslationMap[action.payload.key] || marker('keys.local.messages.successful_persisted')),
      map((key) => this.messageService.openV2(key)),
    ), {dispatch: false}
  );

  persistMaterialFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.persistMaterialFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  moveMaterialRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.moveMaterialRequest),

      switchMap(action => this.materialDataService.move(action.material, action.destination).pipe(
        map((payload) => MaterialActions.moveMaterialSuccess({payload, actionId: action.actionId})),
        catchError(error => of(MaterialActions.moveMaterialFailure({exception: new Exception(error, action), actionId: action.actionId})))
      )),
    )
  );

  moveMaterialSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.moveMaterialSuccess),
    ), {dispatch: false}
  );

  moveMaterialFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.moveMaterialFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  deleteMaterialRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.deleteMaterialRequest),

      switchMap(action => this.materialDataService.delete(action.material).pipe(
        map(payload => MaterialActions.deleteMaterialSuccess({payload})),
        catchError(error => of(MaterialActions.deleteMaterialFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  deleteMaterialSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.deleteMaterialSuccess),
      map(action => action.payload),
      tap(() => this.messageService.openV2(marker(`keys.local.messages.successful_deleted`))),
    ), {dispatch: false}
  );

  deleteMaterialFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.deleteMaterialFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  setNotificationRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.setNotificationRequest),

      switchMap(action => this.materialDataService.notify(action.material, action.notify).pipe(
        map(update => MaterialActions.setNotificationSuccess({update})),
        catchError(error => of(MaterialActions.setNotificationFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  setNotificationSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(MaterialActions.setNotificationSuccess),
    ), {dispatch: false}
  );

  setNotificationFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MaterialActions.setNotificationFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  updateMaterialImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.createAttachmentSuccess),
      map(action => action.payload.item),
      filter(attachment => attachment.mediaType === MediaType.MATERIAL_REAL_IMAGE),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: attachment.id}} as Update<MaterialEntity>)),
      map(update => MaterialActions.updateMaterial({update}))
    )
  );

  removeMaterialImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AttachmentActions.removeAttachmentSuccess),
      map(action => action.payload),
      filter(attachment => attachment.mediaType === MediaType.MATERIAL_REAL_IMAGE),
      map(attachment => ({id: attachment.targetId, changes: {mediaId: null}} as Update<MaterialEntity>)),
      map(update => MaterialActions.updateMaterial({update}))
    )
  );

  constructor(private actions$: Actions,
              private materialDataService: MaterialDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private translateService: TranslateService,
              private logger: NGXLogger) {

  }
}
