import {createAction, props} from '@ngrx/store';
import {ManufacturerListPayload} from './manufacturer.models';
import {Exception} from '../../../../../../core/models';


enum ManufacturerActionTypes {
  LOAD_MANUFACTURERS_REQUEST = '[Manufacturer] Load Manufacturers Request',
  LOAD_MANUFACTURERS_SUCCESS = '[Manufacturer] Load Manufacturers Success',
  LOAD_MANUFACTURERS_FAILURE = '[Manufacturer] Load Manufacturers Failure',
}

const loadManufacturersRequest = createAction(
  ManufacturerActionTypes.LOAD_MANUFACTURERS_REQUEST
);

const loadManufacturersSuccess = createAction(
  ManufacturerActionTypes.LOAD_MANUFACTURERS_SUCCESS,
  props<{ payload: ManufacturerListPayload }>()
);

const loadManufacturersFailure = createAction(
  ManufacturerActionTypes.LOAD_MANUFACTURERS_FAILURE,
  props<{ exception: Exception }>()
);

export const ManufacturerActions = {
  loadManufacturersRequest,
  loadManufacturersSuccess,
  loadManufacturersFailure,
};
