import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';

import {Observable} from 'rxjs';
import {flatMap, map, mapTo} from 'rxjs/operators';
import {QueryParams} from '@ngrx/data';
import {Update} from '@ngrx/entity';
import {REPOSITORY_ID} from './repository.reducer';
import {appendIfExists, HttpParamsFactory} from '../../../../../../core/utils';
import {
  CreateRepositoryRequest,
  GetRepositoryRowsRequest,
  LoadRepositoriesRequest,
  RepositoryEntity,
  RepositoryItemPayload,
  RepositoryItemResponse,
  RepositoryListPayload,
  RepositoryListResponse,
} from './repository.models';
import {environment} from '@hb/environments/environment';
import {ResponseModel} from '../../../../../../core';

@Injectable({
  providedIn: 'root'
})
export class RepositoryDataService {

  private readonly defaultPageSize = environment.defaultPageSize;

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(request: LoadRepositoriesRequest): Observable<RepositoryListPayload> {
    if (!!request.repositoryId) {
      return this._load(request);
    } else {
      return this._search(request);
    }
  }

  getRows(request: GetRepositoryRowsRequest): Observable<any> {
    const url = !!request.repositoryId ?
      `${environment.api}/repositories/${request.repositoryId}/childrens` :
      `${environment.api}/repositories`;
    let params = HttpParamsFactory();
    params = appendIfExists(params, environment.queryParamsNames.filter, request.search);
    params = appendIfExists(params, environment.queryParamsNames.archive, request.archive);
    params = appendIfExists(params, environment.queryParamsNames.rowsOnly, 'true');

    return this.http.get<ResponseModel>(url, {params});
  }

  private _load(request: LoadRepositoriesRequest): Observable<RepositoryListPayload> {
    const url = `${environment.api}/repositories/${request.repositoryId}/childrens`;

    let params = HttpParamsFactory();
    params = appendIfExists(params, environment.queryParamsNames.offset, request.page * this.defaultPageSize);
    params = appendIfExists(params, environment.queryParamsNames.length, this.defaultPageSize);
    params = appendIfExists(params, environment.queryParamsNames.filter, request.search);
    params = appendIfExists(params, environment.queryParamsNames.archive, request.archive);
    params = appendIfExists(params, environment.queryParamsNames.parentRepo, 'false');

    return this.http.get<RepositoryListResponse>(url, {params}).pipe(
      map(res => ({
        items: res.datalist.map(item => ({...item, virtualParentId: Number(request.repositoryId)})),
        rows: res.rows,
      })),
    );
  }

  private _search(request: LoadRepositoriesRequest) {
    const url = `${environment.api}/repositories`;

    let params = HttpParamsFactory();
    params = appendIfExists(params, environment.queryParamsNames.offset, request.page * this.defaultPageSize);
    params = appendIfExists(params, environment.queryParamsNames.length, this.defaultPageSize);
    params = appendIfExists(params, environment.queryParamsNames.filter, request.search);

    return this.http.get<RepositoryListResponse>(url, {params}).pipe(
      map(res => ({
        items: res.datalist,
      })),
    );
  }

  get(repository: string | number, queryParams?: QueryParams): Observable<RepositoryItemPayload> {
    return this.http.get<RepositoryItemResponse>(`${environment.api}/repositories/${repository}`).pipe(
      map((res) => ({
        item: {...res.data, icon: 'fa-folder'},
        selected: res.data.id,
      })),
    );
  }

  create(request: CreateRepositoryRequest): Observable<RepositoryItemPayload> {
    const url = `${environment.api}/repositories/0`;
    let params = HttpParamsFactory();
    params = appendIfExists(params, environment.queryParamsNames.parent, request.parentId);

    return this.http.get<RepositoryItemResponse>(url, {params}).pipe(
      map(res => res.data),
      map(entity => ({...entity, ...request})),
      flatMap((entity: RepositoryEntity) => this.persist(entity, params))
    );
  }


  resolve(): Observable<number> {
    return this.http.get<RepositoryItemResponse>(`${environment.api}/repositories/~`).pipe(
      map((res) => Number(res.data.id)),
    );
  }

  persist(repository: RepositoryEntity, params?: HttpParams): Observable<RepositoryItemPayload> {
    return this.http.put<RepositoryItemResponse>(`${environment.api}/repositories/${repository.id}`, repository, {params}).pipe(
      map(res => ({
        item: res.data,
      }))
    );
  }

  move(repository: RepositoryEntity, destination: number): Observable<RepositoryItemPayload> {
    return this.http.post(`${environment.api}/repositories/${repository.id}/move`, {destination}).pipe(
      mapTo({item: {...repository, parentId: destination}})
    );
  }

  delete(repository: RepositoryEntity): Observable<RepositoryItemPayload> {
    return this.http.delete(`${environment.api}/repositories/${repository.id}`).pipe(
      mapTo({item: repository})
    );
  }

  share(repository: RepositoryEntity, target: string): Observable<Update<RepositoryEntity>> {
    return this.http.post(`${environment.api}/repositories/${repository.id}/share`, target, {params: {privateShare: 'false'}}).pipe(
      mapTo({
          id: repository.id,
          changes: {
            sharedFolderTargets: [...repository.sharedFolderTargets, {targetUsrEmail: target, privateShare: false}],
          }
        } as Update<RepositoryEntity>
      )
    );
  }

  unShare(repository: RepositoryEntity, target: string): Observable<Update<RepositoryEntity>> {
    return this.http.post(`${environment.api}/repositories/${repository.id}/unshare`, target).pipe(
      mapTo({
          id: repository.id,
          changes: {
            sharedFolderTargets: repository.sharedFolderTargets.filter(shared => shared.targetUsrEmail !== target)
          }
        } as Update<RepositoryEntity>
      )
    );
  }

  notify(repository: RepositoryEntity, notify: boolean): Observable<Update<RepositoryEntity>> {
    return this.http.post(`${environment.api}/repositories/${repository.id}/notification`, {state: notify}).pipe(
      mapTo({
        id: repository.id,
        changes: {
          notificationEnabled: notify
        },
      } as Update<RepositoryEntity>)
    );
  }

  setRepositoryId(repositoryId: number) {
    window.localStorage.setItem(REPOSITORY_ID, repositoryId.toString());
  }

  removeRepositoryId() {
    window.localStorage.removeItem(REPOSITORY_ID);
  }
}
