import {ExceptionModel, PayloadListModel, ResponseListModel} from '../../../../../../core/models';
import {BaseViewModel} from '../../../../../../core/models/src/base-view-model';


export interface TrainingListRequest {}

export interface TrainingListResponse extends ResponseListModel<TrainingEntity> {

}

export interface TrainingListPayload extends PayloadListModel<TrainingEntity> {

}

export interface TrainingException extends ExceptionModel {

}

export class TrainingViewModel extends BaseViewModel<TrainingEntity> {
  constructor(model: TrainingEntity) {
    super(model);
  }

  public get id() {
    return this.model.id;
  }

  public get name() {
    return this.model.training;
  }
}

export interface TrainingEntity {
  id: number;
  training: string;
}


export function trainingViewModelFactory(model: TrainingEntity): TrainingViewModel {
  return new TrainingViewModel(model);
}



