import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {AuthenticationService, PasswordForgottenRequest, RouterService} from '../../../modules/store';

@Component({
  selector: 'hb-password-forgotten-view',
  templateUrl: './password-forgotten-view.component.html',
  styleUrls: ['./password-forgotten-view.component.css']
})
export class PasswordForgottenViewComponent implements OnInit, OnDestroy {

  public viewModel: PasswordForgottenRequest = {
    email: ''
  };

  constructor(private routerService: RouterService,
              private renderer: Renderer2,
              private authenticationFacade: AuthenticationService,
              private logger: NGXLogger) {

  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'hb-background');
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }

  submit() {
    this.authenticationFacade.forgottenPassword({...this.viewModel});
  }

  isLoading$(): Observable<boolean> {
    return this.authenticationFacade.isLoading$;
  }
}
