import {
  EditableViewModel,
  ExceptionModel,
  isNotNullOrUndefined,
  PayloadItemModel,
  PayloadListModel,
  ResponseItemModel,
  ResponseListModel,
  TagEntity
} from '../../../../../../core';
import {QueryParams} from '@ngrx/data';
import {AttachmentEntity} from '../../../attachment-store';
import {SegmentEntity} from '../../../path-store';
import {ProductEntity} from '../../../product-store';
import {formatDate} from '@angular/common';

export class MaterialItemPayload extends PayloadItemModel<MaterialEntity> {
}

export class MaterialListPayload extends PayloadListModel<MaterialEntity> {
}

export interface MaterialItemResponse extends ResponseItemModel<MaterialEntity> {
}

export interface MaterialListResponse extends ResponseListModel<MaterialEntity> {
}

export interface LoadMaterialsRequest {
  repositoryId?: number;
  page: number;
  archive?: boolean;
  search?: string;
}

export interface GetMaterialRowsRequest {
  repositoryId?: number;
  search?: string;
  archive?: boolean;
}

export interface LoadAuditableMaterialListRequest {
  page: number;
}

export interface LoadAuditableMaterialListResponse extends MaterialListResponse {
}

export interface LoadAuditableMaterialListPayload extends MaterialListPayload {
}

export interface CreateMaterialRequest extends Partial<MaterialEntity> {
  repositoryId: number;
  productId?: number;
  serialNumber?: string;
  usageId?: number;
  manufacturingDate?: string;
  parentMaterialId?: number;
  barcode?: string;
}

export interface MaterialListRequest {
  repositoryId: number;
  queryParams?: QueryParams;
  rowsOnly?: boolean;
  search?: string;
}

export interface MaterialItemRequest {
  repositoryId: number;
  materialId: number;
  queryParams?: QueryParams;
}



export class MaterialRemovePayload {
  repositoryId: number;
  materialId: number;
  parentId: number;
}

export interface MaterialException extends ExceptionModel {
}

export interface MaterialEntity {
  id: number;
  name: string;
  notificationEnabled: boolean;
  isErasable: boolean;
  mediaId: number;
  productId: number;
  repositoryId: number;
  articleNumber: string;
  manufacturerName: string;
  attachments: AttachmentEntity[];
  manufacturingDate: string;
  firstUsage?: string;
  nextCheck: string;
  serialNumber: string;
  product: ProductEntity;
  rfid?: string;
  strategy: 'DefaultStrategyService' | 'SealPacStrategyService' | 'InstallationStrategyService';
  lastUsage?: string;
  status: TagEntity;
  usageId: number;
  soldOn?: string;
  sealPacNumber?: string;
  materials?: MaterialEntity[];
  parentMaterialId: number;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  comment: string;
  geolocation: string;
  checkInterval: number;
  ean: number;
  documentIds: number[];
  orders: string[];
  paths: SegmentEntity[];
  virtualParentId?: number;
  barcode?: string;
  sharedPath: boolean;
}

export class MaterialViewModel extends EditableViewModel<MaterialEntity> {

  private defaultUsageId = 1003;

  constructor(model: MaterialEntity) {
    super(model);
    this.changes.usageId = !!this.model.usageId ? this.model.usageId : this.defaultUsageId;
  }

  public get objectId(): string {
    return `mat://${this.id}`;
  }

  public get objectBarcode(): string {
    const d = new Date(Date.parse(this.manufacturingDate));
    return `//${this.articleNumber}++${this.serialNumber}++${formatDate(d, 'MM/yyyy', 'de')}++${this.ean};//${this.articleNumber}++${this.serialNumber}++${formatDate(d, 'yyyy', 'de')}++${this.ean}`;
  }

  public get subObjectId(): string {
    return this.model.documentIds.map(id => `doc://${id}`).join('+++');
  }

  public get subObjectOrders(): string {
    return this.model.orders.filter(order => isNotNullOrUndefined(order)).join('+++');
  }

  public get id(): number {
    return this.model.id;
  }

  get name(): string {
    return this.model.product.id === 1 ? this.model.name : this.model.product.title || this.model.name || '';
  }

  set name(value: string) {
    this.changes.name = value;
  }

  get articleNumber(): string {
    return this.model.articleNumber;
  }

  set articleNumber(value: string) {
    this.changes.articleNumber = value;
  }

  get ean(): number {
    return this.model.product.ean || this.model.ean;
  }

  set ean(value: number) {
    this.changes.ean = value;
  }

  get manufacturer(): string {
    return this.model.product.id === 1 ?
      this.model.manufacturerName : this.model.product.manufacturer.caption || this.model.manufacturerName;
  }

  set manufacturer(value: string) {
    this.changes.manufacturerName = value;
  }

  get category(): string {
    return (this.model.product.categories.filter(i => i.type === 'ProductGroupCategory')[0] || {title: 'None'}).title;
  }

  get detailCategory(): string {
    return (this.model.product.categories.filter(i => i.type === 'ProductGroupDetailCategory')[0] || {title: 'None'}).title;
  }

  get manufacturingDate(): string {
    return this.model.manufacturingDate;
  }

  set manufacturingDate(value: string) {
    this.changes.manufacturingDate = value;
  }

  get serialNumber(): string {
    return this.model.serialNumber;
  }

  set serialNumber(value: string) {
    this.changes.serialNumber = value;
  }

  get rfid(): string {
    return this.model.rfid;
  }

  set rfid(value: string) {
    this.changes.rfid = value;
  }

  get strategy() {
    return this.model.strategy;
  }

  get lastUsage(): string {
    return this.model.lastUsage;
  }

  set lastUsage(value: string) {
    this.changes.lastUsage = value;
  }

  get status(): TagEntity {
    return this.model.status;
  }

  get usageId(): number {
    return this.model.usageId || this.defaultUsageId;
  }

  set usageId(value: number) {
    this.changes.usageId = value;
  }

  get soldOn(): string {
    return this.model.soldOn;
  }

  set soldOn(value: string) {
    this.changes.soldOn = value;
  }

  get sealPacNumber(): string {
    return this.model.sealPacNumber;
  }

  set sealPacNumber(value: string) {
    this.changes.sealPacNumber = value;
  }

  get comment(): string {
    return this.model.comment;
  }

  set comment(value: string) {
    this.changes.comment = value;
  }

  get geolocation(): string {
    return this.model.geolocation;
  }

  set geolocation(value: string) {
    this.changes.geolocation = value;
  }

  get checkInterval(): number {
    return this.model.checkInterval;
  }

  set checkInterval(value: number) {
    if (!!value) {
      this.changes.checkInterval = value;
    } else {
      this.changes.checkInterval = null;
    }

  }

  get repositoryId(): number {
    return this.model.repositoryId;
  }

  get parentId(): number {
    return this.model.parentMaterialId;
  }

  get attachments(): AttachmentEntity[] {
    return this.model.attachments;
  }

  get firstUsage(): string {
    return this.model.firstUsage;
  }

  set firstUsage(value: string) {
    this.changes.firstUsage = value;
  }

  get product(): ProductEntity {
    return this.model.product;
  }

  get productId(): number {
    return this.model.productId;
  }

  get createdAt(): string {
    return this.model.createdAt;
  }

  get createdBy(): string {
    return this.model.createdBy;
  }

  get updatedAt(): string {
    return this.model.updatedAt;
  }

  get updatedBy(): string {
    return this.model.updatedBy;
  }

  get mediaId(): number {
    return this.model.mediaId;
  }

  set mediaId(value: number) {
    this.changes.mediaId = value;
  }

  get shouldNotify(): boolean {
    return this.model.notificationEnabled;
  }

  get isSetItem(): boolean {
    return !!this.model.parentMaterialId;
  }

  get isSet(): boolean {
    return this.model.strategy === 'SealPacStrategyService';
  }

  get manufacturerImageBaseUrl(): string {
    return this.model.product.manufacturer.manImageUrl || this.model.product.manufacturer.manImageFallbackUrl || null;
  }

  get isSealPac(): boolean {
    return this.strategy === 'SealPacStrategyService';
  }

  get isActive(): boolean {
    return !this.model.lastUsage;
  }

  get segments(): SegmentEntity[] {
    return this.model.paths;
  }

  get nextCheck(): string {
    return this.model.nextCheck;
  }

  get needCheck(): boolean {
    return ['status.mat_overdue', 'status.mat_due', 'status.mat_open'].includes(this.model.status.key);
  }

  get path(): string {
    if (this.model.paths !== null) {
      const length = this.model.paths.length;
      return this.model.paths.map(segment => segment.name).slice(0, length).join('/');
    }
    return '';
  }

  get isErasable(): boolean {
    return this.model.isErasable;
  }

  get type(): 'MaterialViewModel' {
    return 'MaterialViewModel';
  }

  get virtualParentId(): number {
    return this.model.virtualParentId;
  }

  get sharedPath(): boolean {
    return this.model.sharedPath;
  }
}


export const materialFilterProperties =
  ['name', 'articleNumber', 'ean', 'serialNumber', 'rfid', 'objectId', 'subObjectId', 'objectBarcode', 'subObjectOrders', 'sealPacNumber'];

export function materialViewModelFactory(entity: MaterialEntity): MaterialViewModel {
  return new MaterialViewModel(entity);
}
