import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AutocompleteChipListComponent} from '../../../components/autocomplete-chip-list';
import {Subject} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {IPageInfo} from 'ngx-virtual-scroller';
import {MatDialogRef} from '@angular/material/dialog';
import {BreakpointService} from '../../../modules/core/services/breakpoint';
import {Breakpoints} from '@angular/cdk/layout';
import {AutocompleteEntity} from '../../../modules/core/models';
import {AttachmentService, TileEntity, TileService} from '../../../modules/store';
import {environment} from '../../../../environments';


@Component({
  selector: 'hb-select-product',
  templateUrl: './select-product.component.html',
  styleUrls: ['./select-product.component.scss']
})
export class SelectProductComponent implements OnInit, OnDestroy {

  @ViewChild(AutocompleteChipListComponent, {static: true})
  autocompleteChipList: AutocompleteChipListComponent;


  public input = this.fb.control('');
  public preDestroy = new Subject<boolean>();
  private fetchedPages = new Set<number>();
  private params: any;

  constructor(private fb: FormBuilder,
              public tileService: TileService,
              private attachmentService: AttachmentService,
              public breakpointService: BreakpointService,
              private dialogRef: MatDialogRef<SelectProductComponent>,
              private logger: NGXLogger) {
  }

  ngOnInit() {
  }

  public get availableHeight() {
    // return `${window.innerHeight - 298}px`;
    return '35vh';
  }

  ngOnDestroy(): void {
    this.preDestroy.next(true);
    this.preDestroy.complete();
  }

  handleSelectionChange(items: AutocompleteEntity[]) {

    const category = items.filter(item => item.type === 'ProductGroupCategory').map(item => item.key)[0];
    const detail = items.filter(item => item.type === 'ProductGroupDetailCategory').map(item => item.key)[0];
    const manufacturer = items.filter(item => item.type === 'Manufacturer').map(item => item.key)[0];
    const product = items.filter(item => item.type === 'Product').map(item => item.key)[0];
    const filter = items.filter(item => item.type === 'Filter').map(item => item.key)[0];

    let params: any = {offset: '0', length: '120'};
    if (!!category) {
      params = {...params, cat: category};
    }
    if (!!detail) {
      params = {...params, dcat: detail};
    }
    if (!!manufacturer) {
      params = {...params, manufacturer};
    }
    if (!!product) {
      this.dialogRef.close(product);
    }
    if (!!filter) {
      params = {...params, filter};
    }


    this.params = params;
    this.tileService.load(params);
  }

  public handleTileClick(tile: TileEntity) {
    if (tile.type === 'product') {
      this.dialogRef.close(tile.key);
    } else {
      this.autocompleteChipList.selectItem(tile);
    }
  }

  public handleFilterChange(event: string) {
    if (typeof event === 'string' && event.length > 2) {
      this.tileService.autocomplete({...this.params, filter: event, offset: '0', length: '25'});
    } else {
      this.tileService.resetAutocomplete();
    }
  }

  public columns() {
    if (this.breakpointService.matches([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait])) {
      return 2;
    } else if (this.breakpointService.matches([Breakpoints.HandsetLandscape, Breakpoints.WebPortrait])) {
      return 3;
    } else {
      return 6;
    }
  }

  public fetch(event: IPageInfo) {
    const page = Math.floor(event.endIndex / environment.defaultPageSize);
    if (!this.fetchedPages.has(page)) {
      this.fetchedPages.add(page);
      this.tileService.fetch({...this.params, offset: page * 120, length: '120'});
    }
  }

  public getImage(id: number) {
    return this.attachmentService.url(id, '450');
  }

}
