import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateRepositoryComponent} from './create-repository.component';
import {RepositoryEntity} from '../../../modules/store/src/modules/repository-store';

@Injectable({
  providedIn: 'root'
})
export class CreateRepositoryDialogService
  extends BaseDialogService<CreateRepositoryComponent, Partial<RepositoryEntity>, CreateRepositoryDialogResult> {

  protected config: MatDialogConfig = {
    width: '80vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, CreateRepositoryComponent);
  }
}

interface CreateRepositoryDialogResult {
  entity: RepositoryEntity;
  next: boolean;
}
