import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {CameraScanComponent} from './camera-scan.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateMaterialRequest} from '../../../modules/store';


@Injectable({
  providedIn: 'root'
})
export class CameraScanDialogService extends BaseDialogService<CameraScanComponent, CreateMaterialRequest> {

  protected config: MatDialogConfig = {
    hasBackdrop: true,
    backdropClass: 'hb-camera-backdrop',
    panelClass: 'hb-dialog-container',
    disableClose: true,
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, CameraScanComponent);
  }
}
