<div class="h-full flex flex-col justify-center items-center flex-grow">
  <form #ngForm="ngForm" (ngSubmit)="submit()" class="max-w-screen-sm w-full md:max-w-3/4 lg:max-w-1/2 xl:max-w-1/3"
        ngForm>
    <mat-card class="m-2 mat-elevation-z4">
      <div class="hb-card-image"></div>
      <mat-card-content>
        <div class="flex flex-col">
          <mat-form-field appearance="outline">
            <mat-label>
              <label for="email">{{'forms.fields.email.label' | translate}}</label>
            </mat-label>
            <input #email="ngModel" [(ngModel)]="viewModel.username"
                   [placeholder]="'forms.fields.email.placeholder' | translate" id="email"
                   matInput
                   name="email"
                   required>
            <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
            <mat-error *ngIf="email.dirty && email.touched">
              <ng-container *ngIf="email.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
              <ng-container *ngIf="email.hasError('email')">
                {{'forms.errors.invalid_email' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>
              <label for="password">{{'forms.fields.password.label' | translate}}</label>
            </mat-label>
            <hb-password-toggle-visibility #toggle color="primary" matSuffix></hb-password-toggle-visibility>
            <input #password="ngModel" [(ngModel)]="viewModel.password"
                   [placeholder]="'forms.fields.password.placeholder' | translate" [type]="toggle.type" id="password"
                   matInput
                   name="password" required>
            <mat-hint>{{'forms.fields.password.hint' | translate}}</mat-hint>
            <mat-error *ngIf="password.dirty && password.touched">
              <ng-container *ngIf="password.hasError('required')">
                {{'forms.errors.is_required' | translate}}
              </ng-container>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div class="flex flex-col flex-nowrap">
          <button [disabled]="(this.isLoading() | async) || ngForm.invalid" color="primary" mat-raised-button
                  type="submit"
          >{{'keys.local.global.login' | translate}}
          </button>
          <div class="flex flex-row flex-wrap justify-center align-items py-2">
            <a appTheme="primary" class="mx-2"
               routerLink="/registration">{{'keys.local.global.register' | translate}}</a>
            <a appTheme="primary" class="mx-2"
               routerLink="/password">{{'keys.local.global.password_forgotten' | translate}}</a><br/>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </form>
</div>





