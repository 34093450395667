import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromSearch from './+state/search.reducer';
import {EffectsModule} from '@ngrx/effects';
import {SearchEffects} from './+state/search.effects';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromSearch.SEARCH_FEATURE_KEY,
      fromSearch.reducer
    ),
    EffectsModule.forFeature([SearchEffects]),
  ],
  providers: [],
  declarations: [],
  exports: []
})
export class SearchStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
