import {
  AfterViewInit,
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ContentChild,
  Injector,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {DomPortalOutlet, PortalOutlet, TemplatePortal} from '@angular/cdk/portal';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'hb-breadcrumb-portal',
  templateUrl: './breadcrumb-portal.component.html',
  styleUrls: ['./breadcrumb-portal.component.css']
})
export class BreadcrumbPortalComponent implements OnInit, AfterViewInit, OnDestroy {

  private portalHost: PortalOutlet;
  private portal: TemplatePortal;

  @ContentChild('breadcrumb')
  private content: TemplateRef<any>;


  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector,
              private viewContainerRef: ViewContainerRef,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.portalHost = new DomPortalOutlet(
      document.querySelector('#hb-breadcrumb'),
      this.componentFactoryResolver,
      this.appRef,
      this.injector
    );

    this.portal = new TemplatePortal(
      this.content,
      this.viewContainerRef
    );

    this.portalHost.attach(this.portal);
  }

  ngOnDestroy(): void {
    this.portalHost.detach();
  }

}
