import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {NavigationExtras, Params, QueryParamsHandling, Router, UrlTree} from '@angular/router';
import {QueryParams} from '@ngrx/data';
import {Location} from '@angular/common';
import {environment} from '@hb/environments/environment';
import {RouterPartialState, RouterSelectors} from './router.selectors';
import {MaterialViewModel} from '../../../material-store';

@Injectable()
export class RouterService {

  url$ = this.store.pipe(select(RouterSelectors.getCurrentUrl));
  params$ = this.store.pipe(select(RouterSelectors.getRouteParams));
  queryParams$ = this.store.pipe(select(RouterSelectors.getQueryParams));
  route$ = this.store.pipe(select(RouterSelectors.getCurrentRoute));
  data$ = this.store.pipe(select(RouterSelectors.getRouteData));

  repositoryId$ = this.store.pipe(select(RouterSelectors.getRepositoryIdParam));
  materialId$ = this.store.pipe(select(RouterSelectors.getMaterialIdParam));
  userId$ = this.store.pipe(select(RouterSelectors.getUserIdParam));
  documentId$ = this.store.pipe(select(RouterSelectors.getDocumentIdParam));

  filter$ = this.store.pipe(select(RouterSelectors.getFilterQueryParam));

  public url: string[] = null;
  public repositoryId: number = null;
  public materialId: number = null;
  public userId: number = null;
  public documentId: number = null;
  public queryParams: QueryParams = null;
  public route: any = null;

  constructor(private store: Store<RouterPartialState>, private router: Router, private location: Location) {
    this.repositoryId$.subscribe(id => this.repositoryId = id);
    this.materialId$.subscribe(id => this.materialId = id);
    this.userId$.subscribe(id => this.userId = id);
    this.documentId$.subscribe(id => this.documentId = id);
    this.queryParams$.subscribe(q => this.queryParams = q);
    this.route$.subscribe(route => this.route = route);
  }

  public createUrlTree(commands: any[], extras?: NavigationExtras): UrlTree {
    return this.router.createUrlTree(commands, extras);
  }

  public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  public routerGoByUrl(url: string | UrlTree, extras?: NavigationExtras) {
    return this.router.navigateByUrl(url, extras);
  }

  public forward(): void {
    this.location.forward();
  }

  public back(): void {
    this.location.back();
  }

  public setQuery(queryParams: Params, queryParamsHandling: QueryParamsHandling = 'merge', skipLocationChange = false) {
    return this.navigate([], {queryParams, queryParamsHandling, skipLocationChange, replaceUrl: true});
  }

  public home(): void {
    this.navigate([environment.home]);
  }

  public login(): void {
    this.navigate(['/login']);
  }

  public navigateToPlaces(repositoryId: string | number) {
    this.navigate(['/app/places', repositoryId]);
  }

  public createToPlacesUrl(repositoryId: string | number) {
    return ['/app/places', repositoryId];
  }

  public navigateToRepositoryList(repositoryId: string | number) {
    this.navigate(['/app/repositories', repositoryId]);
  }

  public createToRepositoryList(repositoryId: string | number) {
    // this.navigate(['/app/repositories', repositoryId]);
  }

  public navigateToRepositoryView(repositoryId: number, queryParams?: Params) {
    this.navigate(['/app/repositories', repositoryId, 'view'], queryParams);
  }

  public createToRepositoryView(repositoryId: number, queryParams?: Params) {
    // this.navigate(['/app/repositories', repositoryId, 'view'], queryParams);
  }

  public navigateToMaterialList(repositoryId: number, queryParams?: Params) {
    this.navigate(['/app/repositories', repositoryId, 'materials'], queryParams);
  }

  public navigateToMaterialView(repositoryId: number, materialId: string | number, queryParams?: Params) {
    this.navigate(['/app/repositories', repositoryId, 'materials', materialId], queryParams);
  }

  public createToMaterialViewUrl(repositoryId: number, materialId: string | number, queryParams?: Params) {
    return ['/app/repositories', repositoryId, 'materials', materialId];
  }

  public navigateToMaterialViewV2(vm: MaterialViewModel) {
    this.navigate(['/app/repositories', vm.repositoryId, 'materials', vm.id]);
  }

  public navigateToUserList(repositoryId: number, queryParams?: Params) {
    this.navigate(['/app/repositories', repositoryId, 'users'], queryParams);
  }

  public createToUserListUrl(repositoryId: number, queryParams?: Params) {
    return ['/app/repositories', repositoryId, 'users'];
  }

  public navigateToUserView(repositoryId: number, userId: string | number, queryParams?: Params) {
    this.navigate(['/app/repositories', repositoryId, 'users', userId], queryParams);
  }

  public createToUserViewUrl(repositoryId: number, userId: string | number, queryParams?: Params) {
    return ['/app/repositories', repositoryId, 'users', userId];
  }

  public navigateToDocumentView(
    repositoryId: number, materialId: number, documentId: string | number, queryParams?: Params) {
    this.navigate(['/app/repositories', repositoryId, 'materials', materialId, 'documents', documentId], queryParams);
  }

  public createToDocumentViewUrl(
    repositoryId: number, materialId: number, documentId: string | number, queryParams?: Params) {
    return ['/app/repositories', repositoryId, 'materials', materialId, 'documents', documentId];
  }

  public fetchPlaces() {
    // this.store.dispatch(RouterActions.fetchPlaces(this.route));
  }

  public fetchUsers() {
    // this.store.dispatch(RouterActions.fetchUsers(this.route));
  }
}
