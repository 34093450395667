<div (click)="onClick($event)" style="padding: 1rem;">
  <div style="display: flex; flex-direction: row; flex-flow: nowrap">
    <div style="flex: 1 1 auto">
      <img *ngIf="!isEmpty()" [src]="getImage()" alt="signature"
           style="max-height: 100px; max-width: 100%"/>
    </div>
    <div style="display: flex; align-items: flex-end; justify-items: center; align-self: flex-end">
      <button (click)="onDelete($event)" *ngIf="!isEmpty() && !isDisabled()" mat-icon-button>
        <mat-icon color="danger" fontIcon="fa-trash" fontSet="fas"></mat-icon>
      </button>
      <button (click)="onAdd($event)" *ngIf="isEmpty() && !isDisabled()" [disabled]="isDisabled()" mat-icon-button>
        <mat-icon color="primary" fontIcon="fa-plus" fontSet="fas"></mat-icon>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <p>{{signatureTitle}}</p>
</div>
