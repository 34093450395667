import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as fromCertificate from './certificate.reducer';
import {select, Store} from '@ngrx/store';
import {CertificateEntity, CertificateViewModel} from './certificate.models';
import {CertificateActions} from './certificate.actions';
import {CertificateSelectors} from './certificate.selectors';


@Injectable({
  providedIn: 'root'
})
export class CertificateService {

  items$ = this.store.pipe(select(CertificateSelectors.getItems));
  isLoading$ = this.store.pipe(select(CertificateSelectors.isLoading));
  exception$ = this.store.pipe(select(CertificateSelectors.getError));
  length$ = this.store.pipe(select(CertificateSelectors.getLength));

  constructor(private store: Store<fromCertificate.CertificatePartialState>, private logger: NGXLogger) {
  }

  load(repositoryId: number, userId: number) {
    this.store.dispatch(CertificateActions.loadCertificatesRequest({request: {repositoryId, userId}}));
  }

  persist(certificate: CertificateEntity) {
    this.store.dispatch(CertificateActions.persistCertificateRequest({certificate}));
  }

  delete(certificate: CertificateViewModel) {
    this.store.dispatch(CertificateActions.deleteCertificateRequest({certificate: certificate.model}));
  }
}
