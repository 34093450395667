import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {ProductEntity} from './product.models';
import {ProductActions} from './product.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const PRODUCT_FEATURE_KEY = 'product';

export interface ProductState extends EntityState<ProductEntity> {
  isLoading: boolean;
  selected?: number;
  exception: Exception;
  rows: number;
}

export interface ProductPartialState {
  readonly [PRODUCT_FEATURE_KEY]: ProductState;
}

export const productAdapter: EntityAdapter<
  ProductEntity
  > = createEntityAdapter<ProductEntity>();

export const initialState: ProductState = productAdapter.getInitialState({
  isLoading: false,
  exception: null,
  rows: 0
});

const productReducer = createReducer(
  initialState,
  on(ProductActions.loadProductsRequest, (state) => ({
    ...state,
    selected: null,
    isLoading: true,

  })),
  on(ProductActions.loadProductsSuccess, (state, { payload }) =>
    productAdapter.setAll(payload.items, {
      ...state,
      isLoading: false,

      rows: payload.rows
    })
  ),
  on(ProductActions.loadProductsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(ProductActions.fetchProductsRequest, state => ({
    ...state,
    isLoading: true,

  })),
  on(ProductActions.fetchProductsSuccess, (state, {payload}) =>
    productAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,

    })
  ),
  on(ProductActions.fetchProductsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(ProductActions.getProductRequest, state => ({
    ...state,
    isLoading: true,

  })),
  on(ProductActions.getProductSuccess, (state, {payload}) =>
    productAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,

    })
  ),
  on(ProductActions.getProductFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(ProductActions.persistProductRequest, state => ({
    ...state,
    isLoading: true,

  })),
  on(ProductActions.persistProductSuccess, (state, {payload}) =>
    productAdapter.upsertOne(payload.item, {
      ...state,
      selected: payload.item.id,
      isLoading: false,

    })
  ),
  on(ProductActions.persistProductFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(ProductActions.resetProducts, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: ProductState | undefined, action: Action) {
  return productReducer(state, action);
}
