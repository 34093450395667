import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {UserEntity} from './user.models';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {UserActions} from './user.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const USER_FEATURE_KEY = 'user';

export const USER_ID = 'USER_ID';

export interface UserState extends EntityState<UserEntity> {
  isLoading: boolean;
  isSearching: boolean;
  exception: Exception;
  rows: number;
  userId: number;
  search: string;
}

export interface UserPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export const userAdapter: EntityAdapter<UserEntity> = createEntityAdapter<UserEntity>();

export const initialState: UserState = userAdapter.getInitialState({
  isLoading: false,
  isSearching: false,
  exception: null,
  rows: 0,
  userId: Number(localStorage.getItem(USER_ID)) || null,
  search: null,
});

const userReducer = createReducer(
  initialState,
  on(UserActions.loadUsersRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(UserActions.loadUsersSuccess, (state, {payload}) =>
    userAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })
  ),
  on(UserActions.loadUsersFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(UserActions.loadUserRowsRequest, state => ({
    ...state,
    isLoading: false,
  })),
  on(UserActions.loadUserRowsSuccess, (state, {payload}) => ({
      ...state,
      isLoading: false,
      rows: payload.rows
    })
  ),
  on(UserActions.loadUserRowsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(UserActions.persistUserRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(UserActions.persistUserSuccess, (state, {payload}) =>
    userAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(UserActions.persistUserFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(UserActions.updateUser, (state, {update}) =>
    userAdapter.updateOne(update, {
      ...state,
    })),
  on(UserActions.getUserRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(UserActions.getUserSuccess, (state, {payload}) =>
    userAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })
  ),
  on(UserActions.getUserFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(UserActions.getLoggedInUserRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(UserActions.getLoggedInUserSuccess, (state, {payload}) =>
    userAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })
  ),
  on(UserActions.getLoggedInUserFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(UserActions.loadAccountUserListRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(UserActions.loadAccountUserListSuccess, (state, {payload}) =>
    userAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })
  ),
  on(UserActions.setUserId, (state, {userId}) => ({
    ...state,
    userId
  })),
  on(UserActions.removeUserId, (state) => ({
    ...state,
    userId: null
  })),
  on(UserActions.resetUsers, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}
