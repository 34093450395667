import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectRepositoryComponent, SelectRepositoryDialogParameter, SelectRepositoryDialogResult} from './select-repository.component';

@Injectable({
  providedIn: 'root'
})
export class SelectRepositoryDialogService
  extends BaseDialogService<SelectRepositoryComponent, SelectRepositoryDialogParameter, SelectRepositoryDialogResult> {

  public config = {
    minWidth: '80vw',
    maxHeight: '-webkit-fill-available',
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, SelectRepositoryComponent);
  }
}


