<mat-form-field appearance="outline" class="w-100">
  <mat-chip-list #chipList>
    <mat-chip
      (removed)="remove(item)"
      *ngFor="let item of selectionChange | async"
      [removable]="removable"
      [selectable]="selectable">
      {{item.title}}
      <mat-icon *ngIf="removable" color="mute" fontIcon="fa-times" fontSet="fa" matChipRemove></mat-icon>
    </mat-chip>
    <input
      #native
      [formControl]="input"
      [matAutocomplete]="auto"
      [matChipInputAddOnBlur]="addOnBlur"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      placeholder="filter"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption>
    <mat-option *ngIf="native.value.length > 0 && (autocompleteInputList$ | async).length !== 1" [value]="native.value">
      <span>{{ native.value }} </span>
    </mat-option>
    <mat-option *ngFor="let item of autocompleteInputList$ | async" [value]="item" class="hb-mat-option">
      <span>{{ item.title }}
        <small>{{item.subTitle}}</small>
      </span>
      <small class="hb-text-right">{{item.type}}</small>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
