<h2 mat-dialog-title>{{'dialogs.create-user.title' | translate}}</h2>
<mat-dialog-content>
  <form #ngForm="ngForm" fxLayout="column nowrap">

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="name">{{'forms.fields.name.label' | translate}}</label>
      </mat-label>
      <input #name="ngModel" [(ngModel)]="viewModel.name" [placeholder]="'forms.fields.name.placeholder' | translate"
             id="name" matInput
             name="name"
             required
             type="text">
      <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
      <mat-error *ngIf="name.dirty && name.touched">
        <ng-container *ngIf="name.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="address_line_1">
          {{'forms.fields.address_line_1.label' | translate}}
        </label>
      </mat-label>
      <input #address_line_1="ngModel" [(ngModel)]="viewModel.addressLine1"
             [placeholder]="'forms.fields.address_line_1.placeholder' | translate" id="address_line_1" matInput
             name="address_line_1"
             required
             type="text">
      <mat-hint>{{'forms.fields.address_line_1.hint' | translate}}</mat-hint>
      <mat-error *ngIf="address_line_1.dirty && address_line_1.touched">
        <ng-container *ngIf="address_line_1.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="zip">{{'forms.fields.zip.label' | translate}}</label>
      </mat-label>
      <input #zip="ngModel" [(ngModel)]="viewModel.zip" [placeholder]="'forms.fields.zip.placeholder' | translate"
             id="zip" matInput
             name="zip"
             required
             type="text">
      <mat-hint>{{'forms.fields.zip.hint' | translate}}</mat-hint>
      <mat-error *ngIf="zip.dirty && zip.touched">
        <ng-container *ngIf="zip.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="city">{{'forms.fields.city.label' | translate}}</label>
      </mat-label>
      <input #city="ngModel" [(ngModel)]="viewModel.city" [placeholder]="'forms.fields.city.placeholder' | translate"
             id="city" matInput
             name="city"
             required
             type="text">
      <mat-hint>{{'forms.fields.city.hint' | translate}}</mat-hint>
      <mat-error *ngIf="city.dirty && city.touched">
        <ng-container *ngIf="city.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="country">{{'forms.fields.country.label' | translate}}</label>
      </mat-label>
      <mat-select #country="ngModel"
                  [(ngModel)]="viewModel.countryId"
                  [placeholder]="'forms.fields.country.placeholder' | translate"
                  id="country"
                  name="country"
                  required>
        <mat-option *ngFor="let country of countries$ | async"
                    [value]="country.id">{{country.name}}</mat-option>
      </mat-select>
      <mat-hint>{{'forms.fields.country.hint' | translate}}</mat-hint>
      <mat-error *ngIf="country.dirty && country.touched">
        <ng-container *ngIf="country.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="language">{{'forms.fields.language.label' | translate}}</label>
      </mat-label>
      <mat-select #language="ngModel"
                  [(ngModel)]="viewModel.languageId"
                  [placeholder]="'forms.fields.language.placeholder' | translate"
                  id="language"
                  name="language"
                  required>
        <mat-option *ngFor="let language of languages$ | async"
                    [value]="language.id">{{language.name}}</mat-option>
      </mat-select>
      <mat-hint>{{'forms.fields.language.hint' | translate}}</mat-hint>
      <mat-error *ngIf="language.dirty && language.touched">
        <ng-container *ngIf="language.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="role">{{'forms.fields.role.label' | translate}}</label>
      </mat-label>
      <mat-select #role="ngModel"
                  [(ngModel)]="viewModel.roleId"
                  [placeholder]="'forms.fields.role.placeholder' | translate"
                  id="role"
                  name="role"
                  required>
        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
      </mat-select>
      <mat-hint>{{'forms.fields.role.hint' | translate}}</mat-hint>
      <mat-error *ngIf="role.dirty && role.touched">
        <ng-container *ngIf="role.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="email">{{'forms.fields.email.label' | translate}}</label>
      </mat-label>
      <input #email="ngModel"
             [(ngModel)]="viewModel.email"
             [placeholder]="'forms.fields.email.placeholder' | translate" email
             matInput
             name="email"
             id="email" required>
      <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
      <mat-error *ngIf="email.dirty && email.touched">
        <ng-container *ngIf="email.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="email.hasError('email')">
          {{'forms.errors.invalid_email' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="phone">{{'forms.fields.phone.label' | translate}}</label>
      </mat-label>
      <input #phone="ngModel"
             [(ngModel)]="viewModel.phone"
             [placeholder]="'forms.fields.phone.placeholder' | translate"
             id="phone"
             matInput
             name="phone"
             type="tel">
      <mat-hint>{{'forms.fields.phone.hint' | translate}}</mat-hint>
      <mat-error *ngIf="phone.dirty && phone.touched">

      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="mobile">{{'forms.fields.mobile.label' | translate}}</label>
      </mat-label>
      <input #mobile="ngModel"
             [(ngModel)]="viewModel.cell"
             [placeholder]="'forms.fields.mobile.placeholder' | translate"
             id="mobile"
             matInput
             name="mobile">
      <mat-hint>{{'forms.fields.mobile.hint' | translate}}</mat-hint>
      <mat-error *ngIf="mobile.dirty && mobile.touched">

      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        <label for="fax">{{'forms.fields.fax.label' | translate}}</label>
      </mat-label>
      <input #fax="ngModel"
             [(ngModel)]="viewModel.fax"
             [placeholder]="'forms.fields.fax.placeholder' | translate"
             id="fax"
             matInput
             name="fax">
      <mat-hint>{{'forms.fields.fax.hint' | translate}}</mat-hint>
      <mat-error *ngIf="fax.dirty && fax.touched">

      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!generate.checked" appearance="outline">
      <mat-label>
        <label for="password">{{'forms.fields.password.label' | translate}}</label>
      </mat-label>
      <input #password="ngModel"
             [(ngModel)]="viewModel.password"
             [placeholder]="'forms.fields.password.placeholder' | translate"
             id="password"
             matInput
             name="password"
             type="password"
             [pattern]="password_pattern"
             required>
      <mat-hint>{{'forms.fields.password.hint' | translate}}</mat-hint>
      <mat-error *ngIf="password.dirty && password.touched">
        <ng-container *ngIf="password.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="password.hasError('pattern')">
          {{'forms.errors.password_pattern' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <mat-form-field *ngIf="!generate.checked" appearance="outline">
      <mat-label>
        <label for="confirm">{{'forms.fields.password_confirm.label' | translate}}</label>
      </mat-label>
      <input #confirm="ngModel"
             [placeholder]="'forms.fields.password_confirm.placeholder' | translate"
             id="confirm"
             matInput
             ngModel
             name="confirm"
             type="password"
             ngValidateEqual="password"
             required>
      <mat-error *ngIf="confirm.dirty && confirm.touched">
        <ng-container *ngIf="confirm.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="confirm.hasError('notEqual')">
          {{'forms.errors.password_is_not_equal' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>

    <div fxFlex fxLayout="row wrap" fxLayoutGap="10px">
      <div>
        <mat-checkbox #generate checked="true"
                      name="generate">{{'forms.fields.generate_password.label' | translate}}</mat-checkbox>
      </div>
      <div>
        <mat-checkbox #sendViaEmail="ngModel"
                      [(ngModel)]="viewModel.sendEmail"
                      name="sendViaEmail"
                      checked="true">{{'forms.fields.send_password_via_email.label' | translate}}</mat-checkbox>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="{entity: viewModel, next: true}" color="accent"
          mat-raised-button>{{'keys.local.global.ok_and_next' | translate}}</button>
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="{entity: viewModel, next: false}"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
