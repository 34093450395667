<mat-list-item *ngIf="('read' | able: 'folder')" [class.selected]="isSelected" class="list-item" mat-ripple>
  <hb-avatar
    (click)="selection.emit(); $event.stopImmediatePropagation()"
    [fallback]="['fas', 'dice-d6']"
    [mediaId]="viewModel.mediaId"
    [placeholder]="placeholder"
    height="40"
    mat-list-avatar
    width="40">
  </hb-avatar>


  <span mat-line>{{viewModel.name}}</span>
  <small *ngIf="!!viewModel.path && showPath" class="hb-mat-mute" mat-line>{{'keys.local.global.in' | translate}}
    : {{viewModel.path}}</small>
  <small mat-line><span
    class="hb-mat-mute">{{'keys.local.global.item_no_abbreviation' | translate}}</span> {{viewModel.articleNumber}}
  </small>
  <small mat-line><span
    class="hb-mat-mute">{{'keys.local.global.serial_no_abbreviation' | translate}}</span> {{viewModel.serialNumber}}
  </small>
  <small mat-line><span
    class="hb-mat-mute">{{'keys.local.global.next_check' | translate}}</span> {{viewModel.nextCheck | date: 'mediumDate' : timeZone : (locale$ | async)}}
  </small>


  <hb-status *ngIf="buttons.status" [extended]="!matchHandset" [status]="viewModel.status"></hb-status>
  <button (click)="set.action($event)" *ngIf="buttons.case && set.show" [disabled]="set.disabled" mat-icon-button>
    <mat-icon [color]="set.color" fontIcon="fa-suitcase" fontSet="fas"></mat-icon>
  </button>
  <button (click)="notification.action($event)"
          *ngIf="buttons.notify && ('update' | able: 'material') && !(globalReminder$ | async)"
          [disabled]="notification.disabled" mat-icon-button>
    <mat-icon *ngIf="notification.active" color="primary" fontIcon="fa-bell" fontSet="fas"></mat-icon>
    <mat-icon *ngIf="!notification.active" color="mute" fontIcon="fa-bell-slash" fontSet="fas"></mat-icon>
  </button>
  <button (click)="context.action($event)" *ngIf="buttons.context && context.show" [disabled]="context.disabled"
          [matMenuTriggerFor]="defaultMenu" mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</mat-list-item>

<mat-menu #defaultMenu="matMenu">
  <ng-container *ngIf="!sub && !search">
    <button (click)="edit()" [disabled]="!('read' | able: 'material')" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.open' | translate}}</span>
    </button>
    <button (click)="move()" [disabled]="!('update' | able: 'material') || viewModel.sharedPath" mat-menu-item>
      <mat-icon fontIcon="fa-share" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.move' | translate}}</span>
    </button>
    <button (click)="delete()"
            [disabled]="!('delete' | able: 'material') || !viewModel.isErasable || viewModel.sharedPath" mat-menu-item>
      <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.delete' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="sub && !search">
    <button (click)="edit()" [disabled]="!('read' | able: 'material')" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.open' | translate}}</span>
    </button>
    <button (click)="delete()"
            [disabled]="!('delete' | able: 'material') || !viewModel.isErasable || viewModel.sharedPath" mat-menu-item>
      <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.delete' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="!sub && search">
    <button (click)="edit()" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.open' | translate}}</span>
    </button>
    <button (click)="openInRepository()" mat-menu-item>
      <mat-icon fontIcon="fa-folder-open" fontSet="fas"></mat-icon>
      <span>{{'context.material.action.open_parent' | translate}}</span>
    </button>
  </ng-container>
</mat-menu>
