import {Component, Inject, OnInit} from '@angular/core';
import {BreakpointService} from '../../../modules/core/services/breakpoint';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {RepositoryEntity, RepositoryViewModel, repositoryViewModelFactory, UsageService} from '../../../modules/store';

@Component({
  selector: 'hb-create-repository',
  templateUrl: './create-repository.component.html',
  styleUrls: ['./create-repository.component.scss']
})
export class CreateRepositoryComponent implements OnInit {

  constructor(private breakpointService: BreakpointService,
              private usageService: UsageService,
              @Inject(MAT_DIALOG_DATA) private data: RepositoryEntity,
              private logger: NGXLogger) {
  }

  private _viewModel: RepositoryViewModel = repositoryViewModelFactory(this.data);

  public get viewModel() {
    return this._viewModel;
  }

  ngOnInit(): void {

  }
}
