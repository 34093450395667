import {
  EditableViewModel,
  PayloadItemModel,
  PayloadListModel,
  PayloadModel,
  ResponseItemModel,
  ResponseListModel,
  ResponseModel,
  TagEntity
} from '../../../../../../core/models';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {QueryParams} from '@ngrx/data';
import {SegmentEntity} from '../../../path-store';
import {TrainingEntity} from '../../../trainings-store';
import {convert} from '../../../../../../core/utils';


export interface UserListResponse extends ResponseListModel<UserEntity> {
}

export class UserListPayload extends PayloadListModel<UserEntity> {
}

export interface UserItemResponse extends ResponseItemModel<UserEntity> {
}

export class UserItemPayload extends PayloadItemModel<UserEntity> {
}


export interface LoadUsersRequest {
  repositoryId?: number;
  page: number;
  archive?: boolean;
  search?: string;
}

export interface LoadUsersResponse extends UserListResponse {
}

export class LoadUsersPayload extends UserListPayload {
}


export interface LoadUserRowsRequest {
  repositoryId: number;
  filter?: string;
}

export interface LoadUserRowsResponse extends ResponseModel {
}

export class LoadUserRowsPayload extends PayloadModel {
}


export interface CreateUserRequest {
  repositoryId: number;
}

export interface CreateUserResponse extends UserItemResponse {
}

export class CreateUserPayload extends UserItemPayload {
}


export interface GetUserRequest {
  repositoryId: number;
  userId: number;
  queryParams?: QueryParams;
}

export interface GetUserResponse extends UserItemResponse {
}

export class GetUserPayload extends UserItemPayload {
}


export interface PersistUserRequest {
  entity: UserEntity;
}

export interface PersistUserResponse extends UserItemResponse {
}

export class PersistUserPayload extends UserItemPayload {
}


export interface UpdateUserRequest {
  entity: UserEntity;
}

export interface UpdateUserResponse extends UserItemResponse {
}

export class UpdateUserPayload extends UserItemPayload {
}


export interface RemoveUserRequest {
  repositoryId: number;
  userId: number;
}

export interface RemoveUserResponse {
}

export interface RemoveUserPayload {
  repositoryId: number;
  userId: number;
}


export interface GetLoggedInUserRequest {
}

export interface GetLoggedInUserResponse extends UserItemResponse {
}

export class GetLoggedInUserPayload extends UserItemPayload {
}

export interface SearchUsersResponse extends UserListResponse {
}

export class SearchUsersPayload extends UserListPayload {
}


export interface SearchUserRowsRequest {
  filter: string;
}

export interface SearchUserRowsResponse extends ResponseModel {
}

export class SearchUserRowsPayload extends PayloadModel {
}

export interface LoadAccountUserListRequest {
  accountId: number;
}

export interface LoadAccountUserListResponse extends UserListResponse {
}

export class LoadAccountUserListPayload extends UserListPayload {
}


export interface UserEntity {
  id: number;
  name: string;
  company: string;
  email: string;
  phone?: string;
  cell: string | null;
  street: string;
  zipcode: string;
  city: string;
  fax?: string | null;
  languageId?: string;
  enabled: boolean;
  possibleAccountOwner: boolean;
  repositoryId: number | null;
  accountId: number;
  roleId: number;
  possibleRoles?: (UserRole)[] | null;
  formAccess?: ApprovalEntity[];
  userTrainings?: TrainingEntity[];
  createdAt?: string | null;
  createdBy?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;
  activated?: boolean;
  countryId?: number | null;
  mediaId?: number | null;
  lastLogin?: string | null;
  qualifiedPerson?: null;
  printerIp?: string | null;
  sendEmail?: boolean | null;
  password?: string | null;
  newPassword?: string | null;
  emailChanged?: boolean;
  paths: SegmentEntity[];
}

export interface UserRole {
  id: number;
  name?: string;
}

export class UserViewModel extends EditableViewModel<UserEntity> {

  constructor(model: UserEntity) {
    super(model);
    this._approvals = model.formAccess.map(item => approvalViewModelFactory(item));
  }

  private readonly _approvals: ApprovalViewModel[];

  public get objectId(): string {
    return `user://${this.id}`;
  }

  get id(): number {
    return this.model.id;
  }

  get name(): string {
    return this.model.name;
  }

  set name(value: string) {
    this.changes.name = value;
  }

  get company(): string {
    return this.model.company;
  }

  set company(value: string) {
    this.changes.company = value;
  }

  get email(): string {
    return this.model.email;
  }

  set email(value: string) {
    this.changes.email = value;
  }

  get addressLine1(): string {
    return this.model.street;
  }

  set addressLine1(value: string) {
    this.changes.street = value;
  }

  get zip(): string {
    return this.model.zipcode;
  }

  set zip(value: string) {
    this.changes.zipcode = value;
  }

  get city(): string {
    return this.model.city;
  }

  set city(value: string) {
    this.changes.city = value;
  }

  get countryId(): number {
    return this.model.countryId;
  }

  set countryId(value: number) {
    this.changes.countryId = value;
  }

  get cell(): string {
    return this.model.cell;
  }

  set cell(value: string) {
    this.changes.cell = value;
  }

  get phone(): string {
    return this.model.phone;
  }

  set phone(value: string) {
    this.changes.phone = value;
  }

  get fax(): string {
    return this.model.fax;
  }

  set fax(value: string) {
    this.changes.fax = value;
  }

  get languageId(): string {
    return this.model.languageId;
  }

  set languageId(value: string) {
    this.changes.languageId = value;
  }

  get enabled(): boolean {
    return this.model.enabled;
  }

  set enabled(value: boolean) {
    this.changes.enabled = value;
  }

  get roleId() {
    return this.model.roleId;
  }

  set roleId(value) {
    this.changes.roleId = value;
  }

  get password() {
    return this.model.newPassword;
  }

  set password(value: string) {
    this.changes.newPassword = value;
  }

  get status(): TagEntity {
    return {
      level: convert(() => {
        if (this.model.enabled) {
          return 'success';
        } else if (this.model.enabled && !this.model.activated) {
          return 'warn';
        } else {
          return 'danger';
        }
      }),
      key: convert(() => {
        if (this.model.enabled) {
          return marker('keys.local.status.activated');
        } else if (this.model.enabled && !this.model.activated) {
          return marker('keys.local.status.not_activated');
        } else if (!this.model.enabled && this.model.activated) {
          return marker('keys.local.status.disabled');
        } else {
          return marker('keys.local.status.disabled');
        }
      }),
      isLocal: true
    } as TagEntity;
  }

  get activated(): boolean {
    return this.model.activated;
  }

  get repositoryId(): number {
    return this.model.repositoryId;
  }

  get mediaId(): number {
    return this.model.mediaId;
  }

  set mediaId(value: number) {
    this.model.mediaId = value;
  }

  get printerIp(): string {
    return this.model.printerIp;
  }

  set printerIp(value: string) {
    this.model.printerIp = value;
  }

  get sendEmail(): boolean {
    return this.model.sendEmail;
  }

  set sendEmail(value: boolean) {
    this.changes.sendEmail = value;
  }

  get lastLogin(): string {
    return this.model.lastLogin;
  }

  get createdAt(): string {
    return this.model.createdAt;
  }

  get createdBy(): string {
    return this.model.createdBy;
  }

  get updatedAt(): string {
    return this.model.updatedAt;
  }

  get updatedBy(): string {
    return this.model.updatedBy;
  }

  get isActive(): boolean {
    return this.model.enabled;
  }

  get segments(): SegmentEntity[] {
    return this.model.paths;
  }

  get path(): string {
    if (this.model.paths !== null) {
      const length = this.model.paths.length;
      return this.model.paths.map(segment => segment.name).slice(0, length).join('/');
    }
    return '';
  }

  get possibleAccountOwner() {
    return this.model.possibleAccountOwner;
  }

  get type(): 'UserViewModel' {
    return 'UserViewModel';
  }

  get approvals() {
    return this._approvals;
  }


  get updated(): UserEntity {

    this.changes.formAccess = this.approvals.map(approval => approval.updated);

    return super.updated;
  }

}

export function userViewModelFactory(model: UserEntity): UserViewModel {
  return new UserViewModel(model);
}

export const userFilterProperties = ['name', 'email', 'objectId'];

export class ApprovalViewModel extends EditableViewModel<ApprovalEntity> {

  constructor(model: ApprovalEntity) {
    super(model);
  }

  public get id(): number {
    return this.model.form.id;
  }

  public set id(value: number) {
    this.changes.form.id = value;
  }

  public get name(): string {
    return this.model.form.name;
  }

  public set name(value: string) {
    this.changes.form.name = value;
  }

  public get validUntil(): string {
    return this.model.validUntil;
  }

  public set validUntil(value: string) {
    this.changes.validUntil = value;
  }

  public get value(): boolean {
    return this.model.value;
  }

  public set value(value: boolean) {
    this.changes.value = value;
  }

}

export interface ApprovalEntity {
  form: {
    id: number;
    name: string;
  };
  inherited: false;
  validUntil: string;
  value: boolean;
  updatedAt: string;
  updatedBy: string;
  createdAt: string;
  createdBy: string;
}

export function approvalViewModelFactory(model: ApprovalEntity): ApprovalViewModel {
  return new ApprovalViewModel(model);
}
