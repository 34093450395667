export class Identity {
  static id = 0;

  private readonly _id: number;

  constructor() {
    this._id = ++Identity.id;
  }

  public getId(): number {
    return this._id;
  }
}
