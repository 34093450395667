import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {CategoryPartialState, CategoryState, DETAIL_CATEGORY_FEATURE_KEY} from './category.reducer';
import {CategoryEntity} from './category.models';
import {Exception} from '../../../../../../core/models';

const getCategoryState = createFeatureSelector<CategoryPartialState, CategoryState>(DETAIL_CATEGORY_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getCategoryState,
  (state: CategoryState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getCategoryState,
  (state: CategoryState) => state.exception
);

const getItems: MemoizedSelector<object, CategoryEntity[]> = createSelector(
  getCategoryState,
  (state: CategoryState) => state.items
);

export const CategorySelectors = {
  isLoading,
  getException,
  getItems,
};
