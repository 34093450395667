import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {ENTER} from '@angular/cdk/keycodes';
import {debounceTime, startWith} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {AutocompleteEntity, isNullOrUndefined} from '../../../modules/core';

@Component({
  selector: 'hb-autocomplete-chip-list',
  templateUrl: './autocomplete-chip-list.component.html',
  styleUrls: ['./autocomplete-chip-list.component.scss']
})
export class AutocompleteChipListComponent implements OnInit {

  separatorKeysCodes = [ENTER];

  @Input()
  autocompleteInputList$: Observable<any[]>;

  @Input()
  visible = true;

  @Input()
  selectable = true;

  @Input()
  removable = true;

  @Input()
  addOnBlur = false;

  @Input()
  items$: Observable<any[]>;

  @Output()
  filterChange = new EventEmitter<string>();

  @Output()
  selectionChange: BehaviorSubject<AutocompleteEntity[]> = new BehaviorSubject<AutocompleteEntity[]>([]);

  input = new FormControl();

  @ViewChild('native', {static: true})
  native: ElementRef;

  constructor(private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.input.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
    ).subscribe((val: string) => this.filterChange.emit(val));
  }

  add(event: MatChipInputEvent): void {
    this.reset();
  }

  remove(item: any): void {
    this.selectionChange.next(this.selectionChange.value.filter(x => item !== x));
  }

  filter(items: AutocompleteEntity[], s: any): AutocompleteEntity[] {
    if (!isNullOrUndefined(s) && typeof s === 'string') {
      return items.filter(item => item.title.toLowerCase().includes(s.toLowerCase()));
    } else if (!isNullOrUndefined(s)) {
      return items.filter(item => item.title.toLowerCase().includes((s as AutocompleteEntity).title.toLowerCase()));
    } else {
      return items;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectItem(event.option.value);
    this.reset();
  }

  selectItem(selection: any): void {

    let value = null;

    if (typeof selection === 'string') {
      value = {
        id: null,
        title: selection,
        key: selection,
        type: 'Filter'
      } as AutocompleteEntity;
    } else {
      value = {
        id: selection.id,
        title: selection.title,
        key: selection.key,
        type: selection.type
      } as AutocompleteEntity;
    }

    this.selectionChange.next([...this.selectionChange.value, value]);
  }

  private reset(): void {
    this.input.setValue(null);
    this.native.nativeElement.value = '';
  }

}
