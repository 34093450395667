import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ScanComponent} from './scan.component';
import {CreateMaterialRequest} from '../../../modules/store';

@Injectable({
  providedIn: 'root'
})
export class ScanDialogService extends BaseDialogService<ScanComponent, CreateMaterialRequest> {

  protected config: MatDialogConfig = {
    minWidth: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, ScanComponent);
  }
}
