import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {LanguageService} from './language.service';
import {LANGUAGE_ID} from './language.reducer';


@Injectable({
  providedIn: 'root'
})
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private languageService: LanguageService, private logger: NGXLogger) {
  }

  private static addLanguage(request: HttpRequest<any>, id: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Accept-Language': `${id}-${id.toUpperCase()}`
      }
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.includes('setuserlanguage')) {
      const id = localStorage.getItem(LANGUAGE_ID);

      if (id) {
        request = LanguageInterceptor.addLanguage(request, id);
      }
    }

    return next.handle(request);
  }
}
