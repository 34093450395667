import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {DocumentDataService} from './document-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Exception} from '../../../../../../core/models';
import {DocumentActions} from './document.actions';
import {MaterialActions} from '../../../material-store/src/+state/material.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';

@Injectable()
export class DocumentEffects {

  loadDocumentsRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.loadDocumentsRequest),
      switchMap(action => this.documentService.load(action.request.repositoryId, action.request.materialId).pipe(
        map(payload => DocumentActions.loadDocumentsSuccess({payload})),
        catchError(error => of(DocumentActions.loadDocumentsFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadDocumentsSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.loadDocumentsSuccess),
    ), {dispatch: false}
  );
  loadDocumentsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.loadDocumentsFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  getDocumentRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.getDocumentRequest),
      switchMap(action => this.documentService.get(action.request.repositoryId, action.request.materialId, action.request.documentId).pipe(
        map(payload => DocumentActions.getDocumentSuccess({payload})),
        catchError(error => of(DocumentActions.getDocumentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getDocumentSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.getDocumentSuccess),
    ), {dispatch: false}
  );
  getDocumentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.getDocumentFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  createDocumentRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.createDocumentRequest),
      switchMap(action => this.documentService.create(action.repositoryId, action.materialId, action.queryParams).pipe(
        map(payload => DocumentActions.createDocumentSuccess({payload})),
        catchError(error => of(DocumentActions.createDocumentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  createDocumentSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.createDocumentSuccess),
    ), {dispatch: false}
  );
  createDocumentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.createDocumentFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  persistDocumentRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.persistDocumentRequest),
      switchMap(action => this.documentService.persist(action.document, action.queryParams).pipe(
        map(payload => DocumentActions.persistDocumentSuccess({payload})),
        catchError(error => of(DocumentActions.persistDocumentFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  persistDocumentSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.persistDocumentSuccess),

      map(action => action.payload),
      tap(() => this.messageService.openV2(marker(`keys.local.messages.successful_persisted`))),
    ), {dispatch: false}
  );
  persistDocumentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.persistDocumentFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  deleteDocumentRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.deleteDocumentRequest),

      switchMap(action => this.documentService.delete(action.params, action.queryParams).pipe(
        map(payload => DocumentActions.deleteDocumentSuccess({payload})),
        catchError(error => of(DocumentActions.deleteDocumentFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  deleteDocumentSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.deleteDocumentSuccess),
      map(action => action.payload),
      tap(() => this.messageService.openV2(marker(`keys.local.messages.successful_deleted`))),
      map((payload: any) => ({repositoryId: payload.repositoryId, materialId: payload.materialId})),
      mergeMap(request => [
        MaterialActions.updateMaterialRequest({request}),
        MaterialActions.loadChildrenRequest({request})
      ])
    ));

  deleteDocumentFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(DocumentActions.deleteDocumentFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private documentService: DocumentDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private dialog: MatDialog,
              private logger: NGXLogger) {

  }
}
