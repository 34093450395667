<mat-progress-bar *ngIf="isLoading$ | async" color="primary" mode="indeterminate"></mat-progress-bar>
<mat-divider></mat-divider>
<div class="flex flex-col flex-grow">

  <div *ngIf="!!viewModel && !(isLoading$ | async) && ('read' | able: 'user')">
    <hb-card-column-view>
      <hb-card [status]="viewModel.status" [subTitle]="viewModel.email" [title]="viewModel.name">
        <hb-card-image>
          <hb-commercial-marker style="position: absolute; z-index: 500; padding: 5px;"></hb-commercial-marker>
          <hb-image-input
            [disabled]="!('update' | able: 'user.media')"
            [mediaId]="viewModel.mediaId"
            [mediaType]="mediaType.USER_AVATAR"
            [targetId]="viewModel.id">
          </hb-image-input>
        </hb-card-image>
        <hb-card-content>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon color="primary" fontIcon="fa-clipboard-list" fontSet="fas"
                          style="margin-right: 10px"></mat-icon>
                {{'groups.meta_data.title' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <small>{{'keys.local.global.id' | translate}}: {{viewModel.objectId}}<br></small>
            <small *ngIf="viewModel.activated != null">{{'keys.local.global.activated' | translate}}
              : {{viewModel.activated ? ('keys.local.global.yes' | translate) : ('keys.local.global.no' | translate)}}
              <br></small>
            <small *ngIf="viewModel.createdAt">{{'keys.local.global.created' | translate}}
              : {{viewModel.createdAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.createdBy">, {{viewModel.createdBy}}<br></small>
            <small *ngIf="viewModel.updatedAt">{{'keys.local.global.updated' | translate}}
              : {{viewModel.updatedAt | date: 'mediumDate' : timeZone : (locale$ | async)}}</small><small
            *ngIf="viewModel.updatedBy">, {{viewModel.updatedBy}}<br></small>
            <small *ngIf="viewModel.lastLogin">{{'keys.local.global.last_login' | translate}}
              : {{viewModel.lastLogin | date: 'mediumDate' : timeZone : (locale$ | async)}}</small>
          </mat-expansion-panel>
        </hb-card-content>
      </hb-card>
      <hb-card-accordion>
        <mat-accordion>
          <form #ngForm="ngForm">
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-user" fontSet="fas" style="margin-right: 10px"></mat-icon>
                  {{'groups.user.title' | translate}}
                </mat-panel-title>
                <mat-panel-description style="justify-content: flex-end">
                  <mat-icon *ngIf="fgMain.invalid" class="hb-cropper-icon" color="warn"
                            fontIcon="fa-exclamation-triangle"
                            fontSet="fas"></mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <fieldset #fgMain="ngModelGroup" fxLayout="column nowrap" ngModelGroup="main">

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="name">{{'forms.fields.name.label' | translate}}</label>
                  </mat-label>
                  <input #name="ngModel" [(ngModel)]="viewModel.name"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.name.placeholder' | translate" id="name"
                         matInput
                         name="name"
                         required
                         type="text">
                  <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="name.dirty && name.touched">
                    <ng-container *ngIf="name.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="address_line_1">
                      {{'forms.fields.address_line_1.label' | translate}}
                    </label>
                  </mat-label>
                  <input #address_line_1="ngModel" [(ngModel)]="viewModel.addressLine1"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.address_line_1.placeholder' | translate"
                         id="address_line_1"
                         matInput
                         name="address_line_1"
                         required
                         type="text">
                  <mat-hint>{{'forms.fields.address_line_1.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="address_line_1.dirty && address_line_1.touched">
                    <ng-container *ngIf="address_line_1.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="zip">{{'forms.fields.zip.label' | translate}}</label>
                  </mat-label>
                  <input #zip="ngModel" [(ngModel)]="viewModel.zip"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.zip.placeholder' | translate" id="zip"
                         matInput
                         name="zip"
                         required
                         type="text">
                  <mat-hint>{{'forms.fields.zip.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="zip.dirty && zip.touched">
                    <ng-container *ngIf="zip.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="city">{{'forms.fields.city.label' | translate}}</label>
                  </mat-label>
                  <input #city="ngModel" [(ngModel)]="viewModel.city"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.city.placeholder' | translate" id="city"
                         matInput
                         name="city"
                         required
                         type="text">
                  <mat-hint>{{'forms.fields.city.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="city.dirty && city.touched">
                    <ng-container *ngIf="city.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="country">{{'forms.fields.country.label' | translate}}</label>
                  </mat-label>
                  <mat-select #country="ngModel" [(ngModel)]="viewModel.countryId"
                              [disabled]="!('update' | able: context)"
                              [placeholder]="'forms.fields.country.placeholder' | translate"
                              id="country"
                              name="country"
                              required touched>
                    <mat-option *ngFor="let country of countries$ | async"
                                [value]="country.id">{{country.name}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.country.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="country.dirty && country.touched">
                    <ng-container *ngIf="country.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="language">{{'forms.fields.language.label' | translate}}</label>
                  </mat-label>
                  <mat-select #language="ngModel" [(ngModel)]="viewModel.languageId"
                              [disabled]="!('update' | able: context)"
                              [placeholder]="'forms.fields.language.placeholder' | translate"
                              id="language"
                              name="language"
                              required>
                    <mat-option *ngFor="let language of languages$ | async"
                                [value]="language.id">{{language.name}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.language.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="language.dirty && language.touched">
                    <ng-container *ngIf="language.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="role">{{'forms.fields.role.label' | translate}}</label>
                  </mat-label>
                  <mat-select #role="ngModel" [(ngModel)]="viewModel.roleId"
                              [disabled]="!('update' | able: context)"
                              [placeholder]="'forms.fields.role.placeholder' | translate"
                              id="role"
                              name="role"
                              required>
                    <mat-option *ngFor="let role of roles$ | async"
                                [value]="role.id">{{getRoleName(role.name) | translate}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.role.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="role.dirty && role.touched">
                    <ng-container *ngIf="role.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="phone">{{'forms.fields.phone.label' | translate}}</label>
                  </mat-label>
                  <input #phone="ngModel" [(ngModel)]="viewModel.phone"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.phone.placeholder' | translate" id="phone"
                         matInput
                         name="phone"
                         type="tel">
                  <mat-hint>{{'forms.fields.phone.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="phone.dirty && phone.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="mobile">{{'forms.fields.mobile.label' | translate}}</label>
                  </mat-label>
                  <input #mobile="ngModel" [(ngModel)]="viewModel.cell"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.mobile.placeholder' | translate"
                         id="mobile"
                         matInput
                         name="mobile">
                  <mat-hint>{{'forms.fields.mobile.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="mobile.dirty && mobile.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="fax">{{'forms.fields.fax.label' | translate}}</label>
                  </mat-label>
                  <input #fax="ngModel" [(ngModel)]="viewModel.fax"
                         [disabled]="!('update' | able: context)" [placeholder]="'forms.fields.fax.label' | translate"
                         id="fax"
                         matInput
                         name="fax">
                  <mat-hint>{{'forms.fields.fax.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="fax.dirty && fax.touched">

                  </mat-error>
                </mat-form-field>

                <mat-form-field (click)="editEmail()" appearance="outline">
                  <mat-label>
                    <label for="email">{{'forms.fields.email.label' | translate}}</label>
                  </mat-label>
                  <input #email="ngModel" [disabled]="!('update' | able: context)"
                         [ngModel]="viewModel.email" [placeholder]="'forms.fields.email.placeholder' | translate"
                         id="email"
                         matInput
                         name="email"
                         readonly
                         required>
                  <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="email.dirty && email.touched">
                    <ng-container *ngIf="email.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                    <ng-container *ngIf="email.hasError('email')">
                      {{'forms.errors.invalid_email' | translate}}
                    </ng-container>
                  </mat-error>
                  <mat-icon fontIcon="fa-pen" fontSet="fas" matSuffix></mat-icon>
                </mat-form-field>

                <mat-form-field (click)="editPassword()" appearance="outline">
                  <mat-label>
                    <label for="password">{{'forms.fields.password.label' | translate}}</label>
                  </mat-label>
                  <input #password="ngModel" [disabled]="!('update' | able: context)"
                         [ngModel]="viewModel.password" [placeholder]="'forms.fields.password.placeholder' | translate"
                         id="password"
                         matInput
                         name="password"
                         readonly type="password">
                  <mat-hint>{{'forms.fields.password.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="password.dirty && password.touched">
                    <ng-container *ngIf="password.hasError('required')">
                      {{'forms.errors.is_required' | translate}}
                    </ng-container>
                  </mat-error>
                  <mat-icon fontIcon="fa-pen" fontSet="fas" matSuffix></mat-icon>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>
                    <label for="zebra_ip">{{'forms.fields.printer_ip.label' | translate}}</label>
                  </mat-label>
                  <input #zebra_ip="ngModel" [(ngModel)]="viewModel.printerIp"
                         [disabled]="!('update' | able: context)"
                         [placeholder]="'forms.fields.printer_ip.placeholder' | translate"
                         id="zebra_ip"
                         matInput
                         name="zebra_ip">
                  <mat-hint>{{'forms.fields.printer_ip.hint' | translate}}</mat-hint>
                  <mat-error *ngIf="zebra_ip.dirty && zebra_ip.touched">

                  </mat-error>
                </mat-form-field>

                <mat-slide-toggle [(ngModel)]="!!viewModel.enabled"
                                  [disabled]="!('update' | able: context)"
                                  color="primary"
                                  id="active"
                                  labelPosition="before"
                                  name="active">
                  {{'forms.fields.active.label' | translate}}
                </mat-slide-toggle>

              </fieldset>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-graduation-cap" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{'groups.trainings.title' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div fxLayout="column nowrap">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>
                    <mat-icon color="mute" fontIcon="fa-plus" fontSet="fas" inline matPrefix></mat-icon>
                    {{'forms.fields.add_training.label' | translate}}
                  </mat-label>
                  <mat-select [disabled]="!('update' | able: context)"
                              [placeholder]="'forms.fields.add_training.placeholder' | translate">
                    <mat-option (click)="editTraining(training)"
                                *ngFor="let training of trainings$ | async">{{training.name}}</mat-option>
                  </mat-select>
                  <mat-hint>{{'forms.fields.add_training.hint' | translate}}</mat-hint>
                </mat-form-field>
                <mat-list>
                  <hb-certificate-item-view *ngFor="let certificate of certificates$ | async"
                                            [buttons]="{status: true, context: true}"
                                            [disabled]="!('update' | able: context)"
                                            [viewModel]="certificate"
                  ></hb-certificate-item-view>
                </mat-list>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel *ngIf="('read' | able: 'user.approval')">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon color="primary" fontIcon="fa-user-lock" fontSet="fas"
                            style="margin-right: 10px"></mat-icon>
                  {{'groups.approval.title' | translate}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-list>
                <hb-approval-item-view *ngFor="let approval of viewModel.approvals"
                                       [disabled]="!('update' | able: 'user.approval')"
                                       [viewModel]="approval"></hb-approval-item-view>
              </mat-list>
            </mat-expansion-panel>
          </form>
        </mat-accordion>
      </hb-card-accordion>
    </hb-card-column-view>

    <hb-fab (fabClick)="save()" [disabled]="ngForm.invalid || !('update' | able: context)"
            [label]="'keys.local.fab.save' | translate" fontIcon="fa-save" fontSet="fas">

    </hb-fab>

  </div>

</div>


