<mat-list-item (click)="open($event)" *ngIf="!!viewModel" class="pointer">
  <hb-avatar [fallback]="['fas', 'user']" [mediaId]="viewModel.mediaId" height="40" mat-list-avatar width="40">

  </hb-avatar>
  <span mat-line>{{viewModel.name}}</span>
  <span mat-line>{{viewModel.email}}</span>
  <button (click)="$event.stopPropagation()" *ngIf="buttons.context" [matMenuTriggerFor]="contextMenu"
          mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
</mat-list-item>

<mat-menu #contextMenu="matMenu">
  <button (click)="open($event)" mat-menu-item>
    <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
    <span>{{'context.user.action.open' | translate}}</span>
  </button>
  <button (click)="logout($event)" mat-menu-item>
    <mat-icon fontIcon="fa-share" fontSet="fas"></mat-icon>
    <span>{{'context.user.action.logout' | translate}}</span>
  </button>
</mat-menu>
