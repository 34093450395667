import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SelectProductComponent} from './select-product.component';
import {Overlay} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class SelectProductDialogService extends BaseDialogService<SelectProductComponent, any> {

  protected config: MatDialogConfig = {
    width: '80vw',
    maxHeight: '-webkit-fill-available',
    scrollStrategy: this.overlay.scrollStrategies.noop()
  };

  constructor(protected matDialog: MatDialog, private overlay: Overlay) {
    super(matDialog, SelectProductComponent);
  }
}
