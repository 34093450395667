import {Component, Inject, OnInit} from '@angular/core';
import {BreakpointService} from '../../../modules/core/services/breakpoint';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {CountryService, LanguageService, UserEntity, UserViewModel, userViewModelFactory} from '../../../modules/store';
import {environment} from '../../../../environments';


@Component({
  selector: 'hb-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  public readonly password_pattern = environment.regex.password_pattern;
  public password_confirm: string;

  constructor(private breakpointService: BreakpointService,
              private countryService: CountryService,
              private languageService: LanguageService,
              @Inject(MAT_DIALOG_DATA) private data: UserEntity,
              private logger: NGXLogger) {
  }

  private _viewModel: UserViewModel = userViewModelFactory(this.data);

  public get viewModel() {
    return this._viewModel;
  }

  public get languages$() {
    return this.languageService.items$;
  }

  public get countries$() {
    return this.countryService.items$;
  }

  public get roles() {
    return this.viewModel.model.possibleRoles;
  }

  ngOnInit(): void {

  }

}
