import {AfterContentInit, Component, ElementRef, OnInit} from '@angular/core';
import SignaturePad from 'signature_pad';
import {AttachmentEntity} from '../../../modules/store';
import {NGXLogger} from 'ngx-logger';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'hb-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, AfterContentInit {

  private signaturePad: SignaturePad;

  constructor(private elementRef: ElementRef, private dialogRef: MatDialogRef<SignatureComponent>, private logger: NGXLogger) {
  }

  ngOnInit(): void {
  }


  ngAfterContentInit(): void {
    const canvas = this.elementRef.nativeElement.querySelector('canvas');
    this.signaturePad = new SignaturePad(canvas, {backgroundColor: 'white'});
    this.resizeCanvas();
  }

  resizeCanvas() {
    const canvas = this.elementRef.nativeElement.querySelector('canvas');
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.height = canvas.offsetHeight * ratio;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.clearSignature();
  }

  public acceptSignature() {
    if (this.isEmpty()) {
      return;
    } else {
      const splitBase64 = this.signaturePad.toDataURL('image/jpeg').split(',');
      const data: AttachmentEntity = {
        id: 0,
        mimetype: splitBase64[0].replace('data:', '').replace(';base64', ''),
        data: splitBase64[1],
        targetId: 0,
      };
      this.dialogRef.close(data);
    }
  }

  public clearSignature() {
    this.signaturePad.clear();
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }
}
