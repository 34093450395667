import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {AttachmentService, MediaType} from '../../../modules/store';
import {flatMap} from 'rxjs/operators';


@Component({
  selector: 'hb-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnInit, OnDestroy {

  public readonly accept = '.jpg, .jpeg, .pdf';
  private readonly mediaType: MediaType;
  private readonly targetId: number;

  constructor(private attachmentService: AttachmentService,
              private logger: NGXLogger,
              private dialogRef: MatDialogRef<AddAttachmentComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.mediaType = data.mediaType;
    this.targetId = data.targetId;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  add($event) {
    this.addItems($event).finally(() => this.dialogRef.close(true));
  }

  async addItems($event) {
    for await (const file of Array.from($event.target.files as FileList)) {
      await this.attachmentService.convertFileToRequest(file, this.mediaType, this.targetId).pipe(
        flatMap(request => this.attachmentService.create(request))
      ).toPromise();
    }
  }
}
