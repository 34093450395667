import {
  EditableViewModel,
  ExceptionModel,
  PayloadItemModel,
  PayloadListModel,
  ResponseItemModel,
  ResponseListModel,
  TagEntity
} from '../../../../../../core/models';
import {TrainingViewModel} from '../../../trainings-store';


export interface CertificateListRequest {
  repositoryId: number;
  userId: number;
}

export interface CertificateListResponse extends ResponseListModel<CertificateEntity> {

}

export interface CertificateListPayload extends PayloadListModel<CertificateEntity> {

}

export interface CertificateItemRequest {
  repositoryId: number;
  userId: number;
}

export interface CertificateItemResponse extends ResponseItemModel<CertificateEntity> {

}

export interface CertificateItemPayload extends PayloadItemModel<CertificateEntity> {

}

export interface CertificateException extends ExceptionModel {

}

export class CertificateViewModel extends EditableViewModel<CertificateEntity> {
  constructor(model: CertificateEntity) {
    super(model);
  }

  public get id() {
    return this.model.id;
  }

  public get name() {
    return this.model.training;
  }

  public get userId() {
    return this.model.userId;
  }

  public get repositoryId() {
    return this.model.repositoryId;
  }

  public get validUntil() {
    return this.model.validUntil;
  }

  public set validUntil(value) {
    this.changes.validUntil = value;
  }

  public get trainingId() {
    return this.model.trainingId;
  }

  public set trainingId(id: number) {
    this.changes.trainingId = id;
  }

  get status(): TagEntity {
    return {...this.model.status, isLocal: false};
  }
}

export interface CertificateEntity {
  id: number;
  training: string;
  validUntil: string;
  valid: boolean;
  status: TagEntity;
  userId: number;
  repositoryId: number;
  trainingId: number;
}

export function certificateFactory(model: TrainingViewModel) {
  return {id: 0, training: model.name, trainingId: model.id, validUntil: null} as CertificateEntity;
}

export function certificateViewModelFactory(model: CertificateEntity): CertificateViewModel {
  return new CertificateViewModel(model);
}



