import {
  EditableViewModel,
  ExceptionModel,
  PayloadItemModel,
  PayloadListModel,
  ResponseItemModel,
  ResponseListModel
} from '../../../../../../core/models';
import {QueryParams} from '@ngrx/data';
import {SegmentEntity} from '../../../path-store';


export interface GetRepositoryRowsRequest {
  repositoryId?: number;
  archive?: boolean;
  search?: string;
}

export interface LoadRepositoriesRequest {
  repositoryId?: number;
  page: number;
  archive?: boolean;
  filter?: string;
  search?: string;
}

export interface CreateRepositoryRequest extends Partial<RepositoryEntity> {
  parentId: number;
  name: string;
}

export interface RepositoryListRequest {
  repositoryId: number;
  queryParams: QueryParams;
  rowsOnly?: boolean;
}

export interface RepositoryItemRequest {
  repositoryId: number;
  queryParams?: QueryParams;
}

export interface RepositoryPathRequest {
  repositoryId: number;
}

export interface RepositoryItemResponse extends ResponseItemModel<RepositoryEntity> {}

export interface RepositoryItemPayload extends PayloadItemModel<RepositoryEntity> {}

export interface RepositoryListResponse extends ResponseListModel<RepositoryEntity> {}

export interface RepositoryListPayload extends PayloadListModel<RepositoryEntity> {}

export interface RepositoryException extends ExceptionModel {}


export interface RepositoryEntity {
  id: number;
  name: string;
  sharedFolder: boolean;
  sharedPath: boolean;
  notificationEnabled: boolean;
  isErasable: boolean;
  checkInterval: number;
  mediaId: number;
  addressId: number;
  sharedFolderTargets: SharedTarget[];
  path: SegmentEntity[];
  parentId: number;
  comment: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  virtualParentId?: number;
}

export interface SharedTarget {
  privateShare: boolean;
  targetUsrEmail: string;
}

export class RepositoryViewModel extends EditableViewModel<RepositoryEntity> {

  constructor(model: RepositoryEntity) {
    super(model);
  }

  public get objectId(): string {
    return `repo://${this.id}`;
  }

  get id(): number {
    return this.model.id;
  }

  get name(): string {
    return this.model.name;
  }

  set name(value: string) {
    this.changes.name = value;
  }

  get comment(): string {
    return this.model.comment;
  }

  set comment(value: string) {
    this.changes.comment = value;
  }

  get parentId(): number {
    return this.model.parentId;
  }

  get createdAt(): string {
    return this.model.createdAt;
  }

  get createdBy(): string {
    return this.model.createdBy;
  }

  get updatedAt(): string {
    return this.model.updatedAt;
  }

  get updatedBy(): string {
    return this.model.updatedBy;
  }

  get shouldNotify(): boolean {
    return this.model.notificationEnabled;
  }

  set shouldNotify(value: boolean) {
    this.changes.notificationEnabled = value;
  }

  get hasShares(): boolean {
    return !!this.model.sharedFolderTargets && this.model.sharedFolderTargets.length > 0;
  }

  get sharedTargets(): SharedTarget[] {
    return this.model.sharedFolderTargets;
  }

  set sharedTargets(value: SharedTarget[]) {
    this.changes.sharedFolderTargets = value;
  }

  get isErasable(): boolean {
    return this.model.isErasable;
  }

  get checkInterval(): number {
    return this.model.checkInterval;
  }

  set checkInterval(value: number) {
    this.changes.checkInterval = value === 0 ? null : value;
  }

  get mediaId(): number {
    return this.model.mediaId;
  }

  set mediaId(value: number) {
    this.changes.mediaId = value;
  }

  get hasAddress(): boolean {
    return !!this.model.addressId;
  }

  get addressId() {
    return this.model.addressId;
  }

  set addressId(id: number) {
    this.changes.addressId = id;
  }

  get segments(): SegmentEntity[] {
    return this.model.path;
  }

  get path(): string {
    if (this.model.path !== null) {
      const length = this.model.path.length - 1;
      return this.model.path.map(segment => segment.name).slice(0, length).join('/');
    }
    return '';
  }

  get type(): 'RepositoryViewModel' {
    return 'RepositoryViewModel';
  }

  get sharedFolder(): boolean {
    return this.model.sharedFolder;
  }

  get sharedPath(): boolean {
    return this.model.sharedPath;
  }

  get virtualParentId(): number {
    return this.model.virtualParentId;
  }

  get updated(): RepositoryEntity {
    return {...this.model, ...this.changes};
  }

  public isInPath(ids: number[]) {
    return this._isInPath(ids) || this._isSelf(ids);
  }

  private _isInPath(ids: number[]) {
    if (!!this.model.path) {
      return this.model.path.some(segment => segment.type === 'PhysicalRepositoryGroup' && ids.includes(segment.id));
    }
    return false;
  }

  private _isSelf(ids: number[]) {
    return ids.includes(this.model.id);
  }
}

export function repositoryViewModelFactory(model: RepositoryEntity): RepositoryViewModel {
  return new RepositoryViewModel(model);
}

export const repositoryFilterProperties = ['name', 'comment', 'objectId'];
