import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UsersViewDataSource} from './users-view.data-source';
import {filter, flatMap, map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {CreateUserDialogService} from '../../../dialogs/create-user/src/create-user-dialog.service';
import {AppService, RouterService, UserViewModel} from '../../../modules/store';

@Component({
  selector: 'hb-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.css'],
})
export class UsersViewComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public archive = new BehaviorSubject<boolean>(true);

  constructor(private datasource: UsersViewDataSource,
              private appService: AppService,
              private createUserDialogService: CreateUserDialogService,
              private routerService: RouterService,
              private logger: NGXLogger) {

  }

  public get path$() {
    return this.datasource.path$;
  }

  public get items$() {
    return this.datasource.items$;
  }

  public get rows$() {
    return this.datasource.rows$;
  }

  public get isLoading$() {
    return this.datasource.isLoading$;
  }

  public get innerHeight() {
    return `${window.innerHeight - 117}px`;
  }

  public get sharedPath() {
    return this.datasource.sharedPath;
  }

  ngOnInit() {
    this.subscription = this.routerService.route$.pipe(
      filter(route => route.routeConfig.path === 'users'),
    ).subscribe((route) => {
      this.datasource.load(route.params.repositoryId, 0, this.appService.showInactive, route.queryParams.filter);
    });
  }

  fetch($event) {
    this.datasource.load(this.routerService.repositoryId, $event.page, this.appService.showInactive);
  }

  add() {
    this.datasource.create(this.routerService.repositoryId).pipe(
      flatMap(result => this.createUserDialogService.open(result.item).afterClosed()),
      filter(result => !!result),
      flatMap(result => this.datasource.persist(result.entity).pipe(
        filter(payload => !!payload),
        map(payload => ({item: payload.item, next: result.next}))
      )),
      filter(result => result.next),
    ).subscribe(result => this.routerService.navigateToUserView(result.item.repositoryId, result.item.id));
  }

  open(viewModel: UserViewModel) {
    this.routerService.navigateToUserView(viewModel.repositoryId, viewModel.id);
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.subscription.unsubscribe();
  }
}
