import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {QueryParams} from '@ngrx/data';
import {filter, map} from 'rxjs/operators';
import {
  CertificateService,
  CountryService,
  LanguageService,
  PathService,
  TrainingService,
  UserService,
  UserViewModel
} from '../../../modules/store';

@Injectable({
  providedIn: 'root'
})
export class UserViewDataSource implements OnDestroy {

  private subscription: Subscription = new Subscription();
  private readonly _currentDate: Date;

  constructor(private userService: UserService,
              private pathService: PathService,
              private countryService: CountryService,
              private languageService: LanguageService,
              private trainingService: TrainingService,
              private certificateService: CertificateService,
              private logger: NGXLogger) {
    this.subscription.add(this.userService.selected$.subscribe(vm => this._viewModel = vm));
    this._currentDate = new Date(Date.now());
  }

  private _viewModel: UserViewModel;

  get viewModel() {
    return this._viewModel;
  }

  get isLoading$(): Observable<boolean> {
    return this.userService.isLoading$;
  }

  get path$() {
    return this.pathService.items$;
  }

  get countries$() {
    return this.countryService.items$;
  }

  get trainings$() {
    return this.trainingService.items$;
  }

  get languages$() {
    return this.languageService.items$;
  }

  get certificates$() {
    return this.certificateService.items$;
  }

  get roles$() {
    return this.userService.selected$.pipe(map(selected => selected.model.possibleRoles));
  }

  get locale$() {
    return this.languageService.selected$;
  }

  load(request: { repositoryId: number, userId: number, queryParams: QueryParams }) {
    this.pathService.load({repositoryId: request.repositoryId, userId: request.userId, type: 'USER'});
    this.userService.get(request);
    this.trainingService.load();
    this.certificateService.load(request.repositoryId, request.userId);
  }

  save() {
    return this.userService.persist({entity: this.viewModel.updated}).pipe(
      filter(result => !!result),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
