import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {USAGE_FEATURE_KEY, UsagePartialState, UsageState} from './usage.reducer';

import {UsageViewModel, usageViewModelFactory} from './usage.models';
import {Exception} from '../../../../../../core/models';

const getUsageState = createFeatureSelector<UsagePartialState, UsageState>(USAGE_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getUsageState,
  (state: UsageState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getUsageState,
  (state: UsageState) => state.exception
);

const getItems: MemoizedSelector<object, UsageViewModel[]> = createSelector(
  getUsageState,
  (state: UsageState) => state.items.map(e => usageViewModelFactory(e))
);

const getDefault: MemoizedSelector<object, number> = createSelector(
  getItems,
  (items) => items.filter(usage => usage.default).map(usage => usage.id).last()
);

export const UsageSelectors = {
  isLoading,
  getException,
  getItems,
  getDefault
};
