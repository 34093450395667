import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NGXLogger} from 'ngx-logger';
import {ImageCroppedEvent} from 'ngx-image-cropper';
import {CreateAttachmentRequest} from '../../../modules/store';
import {environment} from '../../../../environments';

@Component({
  selector: 'hb-select-image',
  templateUrl: './select-image.component.html',
  styleUrls: ['./select-image.component.scss']
})
export class SelectImageComponent implements OnInit {

  public image: any = null;
  public request: CreateAttachmentRequest = {} as CreateAttachmentRequest;
  public readonly environment = environment;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { file: File }, private logger: NGXLogger) {
    this.request.filename = data.file.name;
    this.request.mimetype = data.file.type;

    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {
      this.image = reader.result;
    };
  }

  imageCropped(event: ImageCroppedEvent) {
    this.request.data = event.base64.split(',')[1];
  }

  ngOnInit(): void {
  }

}
