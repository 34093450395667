<mat-list-item *ngIf="('read' | able: 'folder')" [class.selected]="isSelected" class="list-item" mat-ripple>
  <hb-avatar (click)="selection.emit(); $event.stopImmediatePropagation()"
             [fallback]="['fas', icon]"
             [mediaId]="viewModel.mediaId"
             height="40" mat-list-avatar
             width="40"></hb-avatar>
  <span mat-line>{{viewModel.name}}</span>
  <small *ngIf="!!viewModel.path && showPath" class="hb-mat-mute" mat-line>{{'keys.local.global.in' | translate}}
    : {{viewModel.path}}</small>
  <small mat-line>{{viewModel.comment}}</small>

  <button (click)="share.action($event)"
          *ngIf="buttons.share && ('execute' | able: 'folder.share') && !viewModel.sharedFolder && !viewModel.sharedPath"
          mat-icon-button>
    <mat-icon [color]="share.color" fontIcon="fa-share-alt" fontSet="fas"></mat-icon>
  </button>
  <button (click)="notification.action($event)"
          *ngIf="buttons.notify && ('update' | able: 'folder') && !(globalReminder$ | async)"
          [disabled]="notification.disabled" mat-icon-button>
    <mat-icon *ngIf="notification.active" color="primary" fontIcon="fa-bell" fontSet="fas"></mat-icon>
    <mat-icon *ngIf="!notification.active" color="mute" fontIcon="fa-bell-slash" fontSet="fas"></mat-icon>
  </button>
  <button (click)="context.action($event);" *ngIf="buttons.context"
          [matMenuTriggerFor]="defaultMenu" mat-icon-button>
    <mat-icon fontIcon="fa-ellipsis-v" fontSet="fas"></mat-icon>
  </button>
  <mat-divider></mat-divider>
</mat-list-item>

<mat-menu #defaultMenu="matMenu">
  <ng-container *ngIf="!search">
    <button (click)="edit()" [disabled]="!('read' | able: 'folder')" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.repository.action.edit' | translate}}</span>
    </button>
    <button (click)="move()" [disabled]="!('update' | able: 'folder') || viewModel.sharedFolder || viewModel.sharedPath"
            mat-menu-item>
      <mat-icon fontIcon="fa-share" fontSet="fas"></mat-icon>
      <span>{{'context.repository.action.move' | translate}}</span>
    </button>
    <button (click)="delete()"
            [disabled]="!('delete' | able: 'folder') || viewModel.sharedFolder || viewModel.sharedPath" mat-menu-item>
      <mat-icon fontIcon="fa-trash" fontSet="fas"></mat-icon>
      <span>{{'context.repository.action.delete' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="search">
    <button (click)="edit()" [disabled]="!('read' | able: 'folder')" mat-menu-item>
      <mat-icon fontIcon="fa-pen" fontSet="fas"></mat-icon>
      <span>{{'context.repository.action.edit' | translate}}</span>
    </button>
    <button (click)="openInRepository()" mat-menu-item>
      <mat-icon fontIcon="fa-folder-open" fontSet="fas"></mat-icon>
      <span>{{'context.repository.action.open_parent' | translate}}</span>
    </button>
  </ng-container>
</mat-menu>
