import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromTraining from './+state/training.reducer';
import {EffectsModule} from '@ngrx/effects';
import {TrainingEffects} from './+state/training.effects';
import {TrainingDataService} from './+state/training-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromTraining.TRAINING_FEATURE_KEY,
      fromTraining.reducer
    ),
    EffectsModule.forFeature([TrainingEffects]),
  ],
  providers: [TrainingDataService],
  declarations: [],
  exports: []
})
export class TrainingStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
