import {Action, createReducer, on} from '@ngrx/store';
import {AppActions} from './app.actions';

export const APP_FEATURE_KEY = 'app';

export const SHOW_INACTIVE = 'SHOW_INACTIVE';
export const SHOW_SIDENAV = 'SHOW_SIDENAV';


export interface AppState {
  showInactive: boolean;
  showSideNav: boolean;
  isScrolled: boolean;
}

export interface AppPartialState {
  readonly [APP_FEATURE_KEY]: AppState;
}

export const initialState: AppState = {
  showInactive: Boolean(localStorage.getItem(SHOW_INACTIVE)) || true,
  showSideNav: null,
  isScrolled: false
};


export const appReducer = createReducer(
  initialState,
  on(AppActions.toggleShowInactive, (state) => ({
    ...state,
    showInactive: !state.showInactive
  })),
  on(AppActions.toggleSideNav, (state, {isOpen}) => ({
    ...state,
    showSideNav: isOpen === undefined ? !state.showSideNav : isOpen
  })),
  on(AppActions.setScrolled, (state, {isScrolled}) => ({
    ...state,
    isScrolled
  }))
);

export function reducer(state: AppState | undefined, action: Action) {
  return appReducer(state, action);
}
