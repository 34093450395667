import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TileDataService} from './tile-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {Exception} from '../../../../../../core/models';
import {TileActions} from './tile.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class TileEffects {

  autocompleteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.autocompleteRequest),

      switchMap((action) => this.tileDataService.load(action.queryParams).pipe(
        map(payload => TileActions.autocompleteSuccess({payload})),
        catchError(error => of(TileActions.autocompleteFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  autocompleteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.autocompleteSuccess),
    ), {dispatch: false}
  );
  autocompleteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.autocompleteFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  loadTilesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.loadTilesRequest),

      switchMap((action) => this.tileDataService.load(action.queryParams).pipe(
        map(payload => TileActions.loadTilesSuccess({payload})),
        catchError(error => of(TileActions.loadTilesFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  loadTilesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.loadTilesSuccess),
    ), {dispatch: false}
  );
  loadTilesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.loadTilesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  fetchTilesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.fetchTilesRequest),

      switchMap(action => this.tileDataService.load(action.queryParams).pipe(
        map(payload => TileActions.fetchTilesSuccess({payload})),
        catchError(error => of(TileActions.fetchTilesFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  fetchTilesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.fetchTilesSuccess),
    ), {dispatch: false}
  );
  fetchTilesFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.fetchTilesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );
  getTileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.getTileRequest),

      switchMap(action => this.tileDataService.get(action.queryParams).pipe(
        map(payload => TileActions.getTileSuccess({payload})),
        catchError(error => of(TileActions.getTileFailure({exception: new Exception(error, action)})))
      )),
    )
  );
  getTileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.getTileSuccess),
    ), {dispatch: false}
  );
  getTileFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TileActions.getTileFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private tileDataService: TileDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private logger: NGXLogger) {

  }
}
