import {Injectable} from '@angular/core';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  public Breakpoints = Breakpoints;
  private breakpoint: BreakpointState;

  constructor(private breakpointObserver: BreakpointObserver, private logger: NGXLogger) {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape,
      Breakpoints.Tablet,
      Breakpoints.TabletPortrait,
      Breakpoints.TabletLandscape,
      Breakpoints.Web,
      Breakpoints.WebPortrait,
      Breakpoints.WebLandscape,
      Breakpoints.XLarge,
      Breakpoints.Large,
      Breakpoints.Medium,
      Breakpoints.Small,
      Breakpoints.XSmall
    ]).subscribe(result => {
      this.breakpoint = result;
    });
  }

  matches(breakpoints: string[]): boolean {
    return this.breakpoint ? breakpoints.some(b => this.breakpoint.breakpoints[b]) : false;
  }

  matchHandset(): boolean {
    return this.matches([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]);
  }

  matchTablet(): boolean {
    return this.matches([Breakpoints.Tablet, Breakpoints.TabletLandscape, Breakpoints.TabletPortrait]);
  }

  matchWeb(): boolean {
    return this.matches([Breakpoints.Web, Breakpoints.WebLandscape, Breakpoints.WebPortrait]);
  }

  streamMatchHandset(): Observable<boolean> {
    return this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).pipe(
      map(res => res.matches)
    );
  }

  streamMatchWeb(): Observable<boolean> {
    return this.breakpointObserver.observe([Breakpoints.Web, Breakpoints.WebPortrait, Breakpoints.WebLandscape]).pipe(
      map(res => res.matches)
    );
  }
}
