import {Injectable} from '@angular/core';
import * as fromPath from './path.reducer';
import {select, Store} from '@ngrx/store';
import {Actions} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {SegmentListRequest} from './path.models';
import {PathActions} from './path.actions';
import {PathSelectors} from './path.selectors';

@Injectable({
  providedIn: 'root'
})
export class PathService {

  items$ = this.store.pipe(select(PathSelectors.getItems));
  isLoading$ = this.store.pipe(select(PathSelectors.getIsLoading));
  exception$ = this.store.pipe(select(PathSelectors.getException));

  constructor(private store: Store<fromPath.PathPartialState>, private actions$: Actions, private logger: NGXLogger) { }

  public load(request: SegmentListRequest): void {
    this.store.dispatch(PathActions.loadPathRequest({request}));
  }
}
