import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {SearchEntity} from './models/entity/search.models';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {SearchActions} from './search.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const SEARCH_FEATURE_KEY = 'search';

export interface SearchState extends EntityState<SearchEntity> {
  isLoading: boolean;
  exception: Exception;
  rows: number;
}

export interface SearchPartialState {
  readonly [SEARCH_FEATURE_KEY]: SearchState;
}

export const searchAdapter: EntityAdapter<SearchEntity> = createEntityAdapter<SearchEntity>();

export const initialState: SearchState = searchAdapter.getInitialState({
  isLoading: false,
  exception: null,
  rows: 0,
});

const searchReducer = createReducer(
  initialState,
  on(SearchActions.loadSearchSuccess, (state, {payload}) =>
    searchAdapter.upsertMany(payload.items, {
      ...state
    })
  ),
  on(SearchActions.getSearchSuccess, (state, {payload}) =>
    searchAdapter.upsertOne(payload.item, {
      ...state
    })
  ),
  on(SearchActions.createSearchSuccess, (state, {payload}) =>
    searchAdapter.addOne(payload.item, {
      ...state,
    })
  ),
  on(SearchActions.updateSearchSuccess, (state, {payload}) =>
    searchAdapter.upsertOne(payload.item, {
      ...state,
    })
  ),
  on(SearchActions.removeSearchSuccess, (state, {payload}) =>
    searchAdapter.removeOne(payload.item.id, {
      ...state,
    })
  ),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: SearchState | undefined, action: Action) {
  return searchReducer(state, action);
}
