import {Action, createReducer, on} from '@ngrx/store';
import {PermissionEntity} from './permission.models';
import {Exception} from '../../../../../../core/models';
import {PermissionActions} from './permission.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const PERMISSION_FEATURE_KEY = 'permission';

export interface PermissionState {
  isLoading: boolean;
  items: PermissionEntity[];
  exception: Exception;
}

export interface PermissionPartialState {
  readonly [PERMISSION_FEATURE_KEY]: PermissionState;
}

export const initialState: PermissionState = {
  isLoading: false,
  exception: null,
  items: []
};


export const permissionReducer = createReducer(
  initialState,

  on(PermissionActions.loadPermissionsRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(PermissionActions.loadPermissionsSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(PermissionActions.loadPermissionsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: PermissionState | undefined, action: Action) {
  return permissionReducer(state, action);
}
