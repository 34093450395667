import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType, OnInitEffects} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CountryDataService} from './country-data.service';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Action} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {CountryActions} from './country.actions';


@Injectable()
export class CountryEffects implements OnInitEffects {

  loadCountriesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.loadCountriesRequest),

      switchMap(action => this.countryDataService.load().pipe(
        map(payload => CountryActions.loadCountriesSuccess({payload})),
        catchError(error => of(CountryActions.loadCountriesFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadCountriesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.loadCountriesSuccess),
    ), {dispatch: false}
  );

  loadCountriesFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(CountryActions.loadCountriesFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions, private countryDataService: CountryDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

  ngrxOnInitEffects(): Action {
    return CountryActions.loadCountriesRequest();
  }

}
