import {createAction, props} from '@ngrx/store';
import {CertificateEntity, CertificateItemPayload, CertificateListPayload, CertificateListRequest} from './certificate.models';
import {Exception} from '../../../../../../core/models';

enum CertificateActionTypes {
  LOAD_CERTIFICATES_REQUEST = '[Certificate] Load Certificates Request',
  LOAD_CERTIFICATES_SUCCESS = '[Certificate] Load Certificates Success',
  LOAD_CERTIFICATES_FAILURE = '[Certificate] Load Certificates Failure',

  PERSIST_CERTIFICATE_REQUEST = '[Certificate] Persist Certificate Request',
  PERSIST_CERTIFICATE_SUCCESS = '[Certificate] Persist Certificate Success',
  PERSIST_CERTIFICATE_FAILURE = '[Certificate] Persist Certificate Failure',

  DELETE_CERTIFICATE_REQUEST = '[Certificate] Delete Certificate Request',
  DELETE_CERTIFICATE_SUCCESS = '[Certificate] Delete Certificate Success',
  DELETE_CERTIFICATE_FAILURE = '[Certificate] Delete Certificate Failure',
}

const loadCertificatesRequest = createAction(
  CertificateActionTypes.LOAD_CERTIFICATES_REQUEST,
  props<{ request?: CertificateListRequest }>()
);

const loadCertificatesSuccess = createAction(
  CertificateActionTypes.LOAD_CERTIFICATES_SUCCESS,
  props<{ payload: CertificateListPayload }>()
);

const loadCertificatesFailure = createAction(
  CertificateActionTypes.LOAD_CERTIFICATES_FAILURE,
  props<{ exception: Exception }>()
);

const persistCertificateRequest = createAction(
  CertificateActionTypes.PERSIST_CERTIFICATE_REQUEST,
  props<{ certificate: CertificateEntity }>()
);

const persistCertificateSuccess = createAction(
  CertificateActionTypes.PERSIST_CERTIFICATE_SUCCESS,
  props<{ payload: CertificateItemPayload }>()
);

const persistCertificateFailure = createAction(
  CertificateActionTypes.PERSIST_CERTIFICATE_FAILURE,
  props<{ exception: Exception }>()
);

const deleteCertificateRequest = createAction(
  CertificateActionTypes.DELETE_CERTIFICATE_REQUEST,
  props<{ certificate: CertificateEntity }>()
);

const deleteCertificateSuccess = createAction(
  CertificateActionTypes.DELETE_CERTIFICATE_SUCCESS,
  props<{ payload: CertificateItemPayload }>()
);

const deleteCertificateFailure = createAction(
  CertificateActionTypes.DELETE_CERTIFICATE_FAILURE,
  props<{ exception: Exception }>()
);

export const CertificateActions = {
  loadCertificatesRequest,
  loadCertificatesSuccess,
  loadCertificatesFailure,
  persistCertificateRequest,
  persistCertificateSuccess,
  persistCertificateFailure,
  deleteCertificateRequest,
  deleteCertificateSuccess,
  deleteCertificateFailure,
};
