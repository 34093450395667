import {Component, OnInit, ViewChild} from '@angular/core';
import {AppService, RouterService} from '../../../modules/store';
import {MatSidenav} from '@angular/material/sidenav';
import {map} from 'rxjs/operators';
import {BreakpointService} from '../../../modules/core';


@Component({
  selector: 'hb-sidenav-container',
  templateUrl: './sidenav-container.component.html',
  styleUrls: ['./sidenav-container.component.scss']
})
export class SidenavContainerComponent implements OnInit {

  @ViewChild(MatSidenav) matSideNav: MatSidenav;

  public readonly sideNavMode = this.breakpointService.streamMatchHandset().pipe(
    map(match => !!match ? 'over' : 'side')
  );

  public readonly isInitOpen = this.breakpointService.streamMatchHandset().pipe(
    map(match => !match)
  );


  constructor(private appService: AppService, private routerService: RouterService, private breakpointService: BreakpointService) {

  }

  public get isOpen$() {
    return this.isInitOpen;
  }

  toggle() {
    return this.appService.toggleSideNav();
  }

  close() {
    return this.appService.toggleSideNav(false);
  }

  ngOnInit() {

  }
}
