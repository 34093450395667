import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {CATEGORY_FEATURE_KEY, DetailCategoryPartialState, DetailCategoryState} from './detail-category.reducer';
import {DetailCategoryEntity} from './detail-category.models';
import {Exception} from '../../../../../../core/models';

const getDetailCategoryState = createFeatureSelector<DetailCategoryPartialState, DetailCategoryState>(CATEGORY_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getDetailCategoryState,
  (state: DetailCategoryState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getDetailCategoryState,
  (state: DetailCategoryState) => state.exception
);

const getItems: MemoizedSelector<object, DetailCategoryEntity[]> = createSelector(
  getDetailCategoryState,
  (state: DetailCategoryState) => state.items
);

export const DetailCategorySelectors = {
  isLoading,
  getException,
  getItems,
};
