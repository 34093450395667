import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AddressViewModel, CountryEntity} from '../../../modules/store';

@Component({
  selector: 'hb-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.css']
})
export class AddressViewComponent implements OnInit {

  @Input()
  public id: number;

  public viewModel: AddressViewModel;

  public countries$: Observable<CountryEntity[]>;

  constructor() {
  }

  ngOnInit(): void {
  }

}
