import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Directive({
  selector: '[hbAutoInput]'
})
export class AutoInputDirective {

  @Output()
  public scanSuccess: EventEmitter<string> = new EventEmitter<string>();
  private UPC = '';

  @Input()
  private active = false;

  private possibleInputCodes = ['Digit', 'Key', 'Numpad'];
  private possibleInputKeys = ['/'];
  private reading = false;
  private barcode = '';

  constructor(private logger: NGXLogger) {
  }

  @HostListener('body:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (!this.reading) {
      this.reading = true;
      this.barcode = '';
      setTimeout(() => {
        this.barcode = '';
        this.reading = false;
      }, 2000);
    }

    if (event.key === 'Enter') {
      if (this.barcode.match(/(sn:\/\/.*|doc:\/\/\d*|repo:\/\/\d*|mat:\/\/\d*|\/\/.*)/)) {
        this.scanSuccess.emit(this.barcode);
      }
      this.barcode = '';
    } else if (event.key !== 'Shift') {
      this.barcode += event.key;
    }
  }
}


// this.logger.debug('LISTENER', event);
// if (!this.active &&
//   this.possibleInputCodes.some(i => event.code.includes(i)) || this.possibleInputKeys.some(i => event.key.includes(i))) {
//   this.active = true;
//   this.keyUp.emit(event);
// }
