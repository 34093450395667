<h2 mat-dialog-title>{{'dialogs.create_share.title' | translate}}</h2>
<mat-dialog-content>
  <div class="container">
    <div class="grid-item-1" fxLayout="column nowrap" fxLayoutAlign="center center" fxLayoutGap="5px">
      <form #ngForm="ngForm" fxLayout="column nowrap" ngForm>
        <mat-form-field appearance="outline">
          <mat-label>
            <label for="email">{{'forms.fields.email.label' | translate}}</label>
          </mat-label>
          <input #email="ngModel"
                 [(ngModel)]="value"
                 [placeholder]="'forms.fields.email.placeholder' | translate" email
                 id="email"
                 matInput
                 name="email" required>
          <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
          <mat-error *ngIf="email.dirty && email.touched">
            <ng-container *ngIf="email.hasError('required')">
              {{'forms.errors.is_required' | translate}}
            </ng-container>
            <ng-container *ngIf="email.hasError('email')">
              {{'forms.errors.invalid_email' | translate}}
            </ng-container>
          </mat-error>
        </mat-form-field>
      </form>
    </div>
    <div class="grid-item-2 border">
      <mat-list class="list">
        <mat-list-item *ngIf="!viewModel.hasShares">
          <span mat-line>{{'dialogs.create_share.no_items_here' | translate}}</span>
          <mat-divider></mat-divider>
        </mat-list-item>
        <mat-list-item *ngFor="let share of viewModel.sharedTargets">
          <span mat-line>{{share.targetUsrEmail}}</span>
          <button (click)="delete(share)" mat-icon-button
                  matTooltip="{{'keys.local.tooltips.remove_item' | translate}}">
            <mat-icon color="danger" fontIcon="fa-times-circle" fontSet="fas"></mat-icon>
          </button>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button (click)="share()" [disabled]="ngForm.invalid" cdkfocusinitial color="accent"
          mat-raised-button>{{'keys.local.global.share' | translate}}</button>
  <button [mat-dialog-close] mat-raised-button>{{'keys.local.global.close' | translate}}</button>
</mat-dialog-actions>
