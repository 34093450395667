export class ResponseModel {
  extMsg: string | null;
  field: string | null;
  htmlCode: number | null;
  msg: string | null;
  msgKey: string | null;
  value: any | null;
  code: number | null;
  rows?: number;
}

export class ResponseItemModel<T> extends ResponseModel {
  data?: T;
}

export class ResponseListModel<T> extends ResponseModel {
  datalist?: T[];
}
