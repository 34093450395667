import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {FORM_FEATURE_KEY, formAdapter, FormPartialState, FormState} from './form.reducer';
import {FormViewModel, formViewModelFactory} from './form.models';
import {Exception} from '../../../../../../core/models';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getFormState = createFeatureSelector<FormPartialState,
  FormState>(FORM_FEATURE_KEY);

const {selectAll, selectEntities} = formAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getFormState,
  (state: FormState) => state.isLoading
);

const getAllItems: MemoizedSelector<object, FormViewModel[]> = createSelector(
  getFormState,
  (state: FormState) => selectAll(state).map(item => formViewModelFactory(item))
);

const getItems: MemoizedSelector<object, FormViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getRepositoryIdParam,
  RouterSelectors.getMaterialIdParam,
  (items, repositoryId, materialId) =>
    items.filter(item => item.repositoryId === repositoryId && item.materialId === materialId)
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getFormState,
  (state: FormState) => state.exception
);

export const FormSelectors = {
  isLoading,
  getAllItems,
  getItems,
  getException,
};
