import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {BarcodeFormat} from '@zxing/library';
import {BreakpointService, SoundService} from '../../../modules/core';
import {MessageService} from '../../message';
import {CreateMaterialRequest, MaterialEntity, MaterialService, RouterService, UsageService} from '../../../modules/store';

@Component({
  selector: 'hb-camera-scan',
  templateUrl: './camera-scan.component.html',
  styleUrls: ['./camera-scan.component.scss']
})
export class CameraScanComponent implements OnInit, AfterViewInit {

  @ViewChild('scanner')
  scanner: ZXingScannerComponent;

  @ViewChild('ngForm')
  ngForm: NgForm;

  formats: BarcodeFormat[] = [BarcodeFormat.DATA_MATRIX, BarcodeFormat.EAN_13, BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128];
  hasCameras = false;
  hasPermission: boolean;

  availableDevices: MediaDeviceInfo[];
  selectedDevice: MediaDeviceInfo = null;
  private readonly _currentDate: Date;
  private last = '';
  private loading = false;

  constructor(
    public usageService: UsageService,
    public materialService: MaterialService,
    public routerFacade: RouterService,
    public breakpointService: BreakpointService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private soundService: SoundService,
    private logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: CreateMaterialRequest) {
    this._currentDate = new Date(Date.now());
  }

  private _viewModel: MaterialEntity = {...this.data, usageId: 1003} as MaterialEntity;

  public get viewModel() {
    return this._viewModel;
  }

  private _formVisible = false;

  public get formVisible(): boolean {
    return this._formVisible;
  }

  private _autoFocus = false;

  public get autoFocus() {
    return this._autoFocus;
  }

  public get currentDate() {
    return this._currentDate;
  }

  public get icon(): string {
    return this._formVisible ? 'fa-chevron-down' : 'fa-chevron-up';
  }

  ngOnInit(): void {
    this.usageService.load();
  }

  ngAfterViewInit(): void {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      this.hasCameras = true;
      this.availableDevices = devices;
      this.selectedDevice = devices.find(device => device.label.includes('0, facing back'));
    });

    this.scanner.camerasNotFound.subscribe((devices: MediaDeviceInfo[]) => {
      console.error('An error has occurred when trying to enumerate your video-stream-enabled devices.');
    });

    this.scanner.permissionResponse.subscribe((answer: boolean) => {
      this.hasPermission = answer;
    });
  }

  get usages$() {
    return this.usageService.items$;
  }

  public get matchWeb() {
    return this.breakpointService.matchWeb();
  }

  scanSuccess(e: any) {
    if (this.last !== e && !this.loading) {
      this.loading = true;
      if (this.ngForm.valid) {
        this.materialService.persist({...this.viewModel, barcode: e}).subscribe(() => {
          this.soundService.playScan();
          this.messageService.open(this.translateService.instant('keys.local.messages.successful_added'));
          this.loading = false;
          this.last = e;
          this._viewModel = {...this.viewModel, barcode: ''};
        });
      } else {
        this.loading = false;
      }
    }
  }

  toggleForm() {
    this._formVisible = !this._formVisible;
  }
}
