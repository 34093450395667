import {Injectable, OnDestroy} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromPermission from './permission.reducer';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {Ability} from '@casl/ability';
import {LoadPermissionsRequest} from './permission.models';
import {PermissionSelectors} from './permission.selectors';
import {PermissionActions} from './permission.actions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService implements OnDestroy {

  private subscription = new Subscription();
  public readonly isLoading$ = this.store.pipe(select(PermissionSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(PermissionSelectors.getException));
  public readonly items$ = this.store.pipe(select(PermissionSelectors.getItems));

  constructor(private store: Store<fromPermission.PermissionPartialState>, private ability: Ability, logger: NGXLogger) {
    this.subscription.add(this.items$.subscribe(rules => this.ability.update(rules)));
  }

  public load(request: LoadPermissionsRequest) {
    this.store.dispatch(PermissionActions.loadPermissionsRequest({request}));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
