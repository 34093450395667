import {Component, HostBinding, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../../environments';

@Component({
  selector: 'hb-commercial-marker',
  templateUrl: './commercial-marker.component.html',
  styleUrls: ['./commercial-marker.component.scss']
})
export class CommercialMarkerComponent implements OnInit {

  @HostBinding('class.hide') hide: boolean;

  constructor(private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.hide = !environment.hasPaymentFeatures;
  }

}
