import {Component, Inject, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Params} from '@angular/router';
import {UserService} from '../../../modules/store';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'hb-document-sealed',
  templateUrl: './seal-document.component.html',
  styleUrls: ['./seal-document.component.scss']
})
export class SealDocumentComponent implements OnInit {

  private _viewModel = {
    createPDF: 'true',
    email: this.data.email,
    sendViaEmail: this.data.sendViaEmail,
    pdfIncludeAttachments: false,
  };

  constructor(private userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: { email?: string, sendViaEmail: boolean },
              private logger: NGXLogger) {
  }

  get viewModel() {
    return this._viewModel;
  }

  get result(): Params {
    return {...this.viewModel, email: this.viewModel.sendViaEmail ? this.viewModel.email : null};
  }

  ngOnInit() {

  }
}
