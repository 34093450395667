import {Component, ElementRef, HostBinding, Input, OnDestroy, Optional, Self} from '@angular/core';
import {MatFormFieldControl} from '@angular/material/form-field';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {FocusMonitor} from '@angular/cdk/a11y';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {NGXLogger} from 'ngx-logger';

interface RadioButtonConfiguration {
  value: string;
  caption: string;
}

@Component({
  selector: 'hb-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: RadioInputComponent
    }
  ]
})
export class RadioInputComponent implements MatFormFieldControl<string>, ControlValueAccessor, OnDestroy {


  static nextId = 0;

  private _autofilled: boolean;
  readonly controlType = 'radio-input';
  readonly stateChanges = new Subject<void>();

  private _value: string | null;
  private _placeholder: string;
  private _focused: boolean;
  private _required: boolean;
  private _disabled = false;
  private _touched = false;



  @HostBinding()
  id = `radio-input-${RadioInputComponent.nextId++}`;

  @HostBinding()
  class = this.controlType;

  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get empty(): boolean {
    return !this.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this._focused || !this.empty;
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.ngControl.errors !== null && !!this.ngControl.touched;
  }

  get focused(): boolean {
    return this._focused;
  }

  get autofilled(): boolean {
    return this._autofilled;
  }

  @Input()
  buttons: RadioButtonConfiguration[];

  @Input()
  validValue: string;

  constructor(@Optional() @Self() public ngControl: NgControl, private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>, private logger: NGXLogger) {
    this.ngControl.valueAccessor = this;
    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this._focused = !!origin;
      this.stateChanges.next();
    });

  }

  onChange = (value: any) => {};
  onTouched = () => this._touched = true;

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  isChecked(value: string) {
    return value === this.value;
  }
}
