import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AdministrationDataService} from './administration-data.service';
import {of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {Exception} from '../../../../../../core/models';
import {AdministrationActions} from './administration.actions';

@Injectable()
export class AdministrationEffects {

  constructor(
    private actions$: Actions,
    private administrationService: AdministrationDataService,
    private messageService: MessageService,
    private hintDialogService: HintDialogService,
    private translateService: TranslateService,
    private logger: NGXLogger) {

  }

  clearCacheRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdministrationActions.clearCacheRequest),

      switchMap(action => this.administrationService.clearCache().pipe(
        map(payload => AdministrationActions.clearCacheSuccess()),
        catchError(error => of(AdministrationActions.clearCacheFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  clearCacheSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdministrationActions.clearCacheSuccess),

      tap(() => this.messageService.openV2(marker('keys.local.messages.successful_cache_cleared'))),
      map(() => AdministrationActions.clearCacheComplete({success: true}))
    )
  );

  clearCacheFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdministrationActions.clearCacheFailure),

      map(action => action.exception),
      tap(exception => this.hintDialogService.openError(exception.message)),
      map(() => AdministrationActions.clearCacheComplete({success: false}))
    )
  );
}
