import {Action, createReducer, on} from '@ngrx/store';
import {LanguageEntity} from './language.models';
import {Exception} from '../../../../../../core/models';
import {LanguageActions} from './language.actions';

export const LANGUAGE_FEATURE_KEY = 'language';

export const LANGUAGE_ID = 'LANGUAGE_ID';

export interface LanguageState {
  isLoading: boolean;
  items: LanguageEntity[];
  exception: Exception;
  languageId: string;
}

export interface LanguagePartialState {
  readonly [LANGUAGE_FEATURE_KEY]: LanguageState;
}

export const initialState: LanguageState = {
  isLoading: false,
  exception: null,
  items: [],
  languageId: localStorage.getItem(LANGUAGE_ID) || null,
};


export const languageReducer = createReducer(
  initialState,

  on(LanguageActions.loadLanguagesRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(LanguageActions.loadLanguagesSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    items: payload.items
  })),
  on(LanguageActions.loadLanguagesFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(LanguageActions.setLanguageRequest, (state) => ({
    ...state,
    isLoading: true
  })),
  on(LanguageActions.setLanguageSuccess, (state, {payload}) => ({
    ...state,
    isLoading: false,
    languageId: payload,
  })),
  on(LanguageActions.setLanguageFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
);

export function reducer(state: LanguageState | undefined, action: Action) {
  return languageReducer(state, action);
}
