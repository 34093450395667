<mat-sidenav-container>
  <mat-sidenav [mode]="sideNavMode | async" [opened]="isOpen$ | async" class="h-full w-4/5 sm:w-1/6" role="navigation">
    <hb-sidenav-view>

    </hb-sidenav-view>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content>

    </ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
