import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {CERTIFICATE_FEATURE_KEY, certificateAdapter, CertificatePartialState, CertificateState} from './certificate.reducer';
import {CertificateViewModel, certificateViewModelFactory} from './certificate.models';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getCertificateState = createFeatureSelector<CertificatePartialState,
  CertificateState>(CERTIFICATE_FEATURE_KEY);

const {selectAll, selectEntities} = certificateAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getCertificateState,
  (state: CertificateState) => state.isLoading
);

const getError = createSelector(
  getCertificateState,
  (state: CertificateState) => state.exception
);

const getAllItems: MemoizedSelector<object, CertificateViewModel[]> = createSelector(
  getCertificateState,
  (state: CertificateState) => selectAll(state).map(item => certificateViewModelFactory(item))
);

const getItems: MemoizedSelector<object, CertificateViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getUserIdParam,
  (items, userId) => items.filter(item => item.userId === userId)
);

const getLength = createSelector(
  getCertificateState,
  (state: CertificateState) => state.rows
);

const getCertificateEntities = createSelector(
  getCertificateState,
  (state: CertificateState) => selectEntities(state)
);

export const CertificateSelectors = {
  isLoading,
  getError,
  getAllItems,
  getItems,
  getLength,
  getCertificateEntities,
};
