export interface BaseRequest {
}

export interface RequestWithParams<P> {
  params: P;
}

export interface RequestWithQuery<Q> {
  queryParams: Q;
}

export interface RequestWithBody<B> {
  body: B;
}

export interface RequestWithParamsAndQuery<P, Q> extends RequestWithParams<P>, RequestWithQuery<Q> {
}

export interface RequestWithParamsAndBody<P, B> extends RequestWithParams<P>, RequestWithBody<B> {
}

export interface RequestWithQueryAndBody<Q, B> extends RequestWithQuery<Q>, RequestWithBody<B> {
}

export interface RequestWithParamsAndQueryAndBody<P, Q, B> extends RequestWithParams<P>, RequestWithQuery<Q>, RequestWithBody<B> {
}


// export abstract class RequestDTO<T> {
//
//   private readonly request: any;
//   public readonly data: T;
//   private errorKey: string;
//   private errorValue: any;
//
//   constructor(data: T) {
//     this.data = data;
//     const url = this.buildUrl();
//     const params = this.buildParams();
//     const body = this.buildBody();
//     this.request = {url, params, body};
//     if (!this.validate()) {
//       throw new Error(`${this.constructor.name} is Invalid: ${this.errorKey}: ${this.errorValue}`);
//     }
//   }
//
//   asRequest(): Request {
//     return this.request;
//   }
//
//   protected abstract buildUrl();
//
//   protected abstract validate(): boolean;
//
//   protected abstract buildParams(): HttpParams;
//
//   protected abstract buildBody(): any;
//
//   protected setErrorKey(key: string, value: any) {
//     this.errorKey = this.errorKey === undefined ? key : this.errorKey;
//     this.errorValue = this.errorValue === undefined ? value : this.errorValue;
//   }
// }
