<hb-single-container class="hb-user-activation wrapper">
  <hb-header [buttons]="{primary: 'none', filter: 'none'}" color="transparent"></hb-header>
  <hb-content class="container">

    <mat-card class="email-change-card mat-elevation-z4">
      <div class="hb-card-image"></div>
      <mat-card-actions fxLayout="column nowrap">
        <div fxFlexOffset="15px" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="10px"
             style="padding-bottom: 15px">
          <a href="" routerLink="/login">{{'keys.local.global.back_to_login' | translate}}</a><br/>
        </div>
      </mat-card-actions>
    </mat-card>

  </hb-content>
</hb-single-container>
