import {createFeatureSelector, createSelector} from '@ngrx/store';
import {APP_FEATURE_KEY, AppPartialState, AppState} from './app.reducer';

export const getAppState = createFeatureSelector<AppPartialState, AppState>(APP_FEATURE_KEY);

export const getShowInactive = createSelector(
  getAppState,
  (state) => state.showInactive
);

export const getShowSideNav = createSelector(
  getAppState,
  (state) => state.showSideNav
);

export const getIsScrolled = createSelector(
  getAppState,
  (state) => state.isScrolled
);

export const AppSelectors = {
  getShowInactive,
  getShowSideNav,
  getIsScrolled
};
