import {Auditable} from './auditable.models';


export abstract class BaseViewModel<T> {
  private readonly _model: T;

  protected constructor(model: T) {
    this._model = model;

  }

  public get model(): T {
    return this._model;
  }
}

export abstract class BaseAuditViewModel<T extends Auditable> extends BaseViewModel<T> {

  public get createdAt(): string {
    return this.model.createdAt;
  }

  public get createdBy(): string {
    return this.model.createdBy;
  }

  public get updatedAt(): string {
    return this.model.updatedAt;
  }

  public get updatedBy(): string {
    return this.model.updatedBy;
  }
}

export abstract class EditableViewModel<T> extends BaseViewModel<T> {
  protected _changes: Partial<T> = {};

  protected constructor(model: T) {
    super(model);
  }

  public get changes() {
    return this._changes;
  }

  public get updated(): T {
    if (!!this.model) {
      return {...this.model, ...this.changes};
    } else {
      return null;
    }
  }
}

export abstract class EditableAuditViewModel<T extends Auditable> extends BaseAuditViewModel<T> {
  protected _changes: Partial<T> = {};

  protected constructor(model: T) {
    super(model);
  }

  public get changes() {
    return this._changes;
  }

  public get updated(): T {
    if (!!this.model) {
      return {...this.model, ...this.changes};
    } else {
      return null;
    }
  }
}
