import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, flatMap, map, takeUntil, withLatestFrom} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {forkJoin, Observable, Subject} from 'rxjs';
import {QueryParams} from '@ngrx/data';
import {
  AccountService,
  AccountViewModel,
  AddressEntity,
  AddressService,
  AddressViewModel,
  addressViewModelFactory,
  CountryService,
  LanguageService,
  LicenseService,
  MediaType,
  PathService,
  RepositoryService,
  RouterService,
  UserService
} from '../../../modules/store';
import {Ability} from '@casl/ability';


@Component({
  selector: 'hb-account-view',
  templateUrl: './account-view.component.html',
  styleUrls: ['./account-view.component.css']
})
export class AccountViewComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  private readonly _currentDate: Date;
  private _context;

  public readonly mediaType: typeof MediaType = MediaType;

  constructor(private accountService: AccountService,
              private pathService: PathService,
              private repositoryService: RepositoryService,
              private countryService: CountryService,
              private licenseService: LicenseService,
              private languageService: LanguageService,
              private userService: UserService,
              private addressService: AddressService,
              private routerService: RouterService,
              private ability: Ability,
              private logger: NGXLogger) {

    this._currentDate = new Date(Date.now());
  }

  private _address: AddressViewModel = addressViewModelFactory({} as AddressEntity);

  get address() {
    return this._address;
  }

  private _viewModel: AccountViewModel;

  get viewModel() {
    return this._viewModel;
  }

  get isLoading$(): Observable<boolean> {
    return this.accountService.isLoading$;
  }

  get path$() {
    return this.pathService.items$;
  }

  get licenses$() {
    return this.licenseService.items$;
  }

  get countries$() {
    return this.countryService.items$;
  }

  get users$() {
    return this.userService.possibleOwners$;
  }

  get currentDate() {
    return this._currentDate;
  }

  get locale$() {
    return this.languageService.selected$;
  }

  get context() {
    return this._context;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  ngOnInit(): void {
    this.logger.trace('INIT: ', this);
    this.routerService.route$.pipe(
      takeUntil(this.unsubscribe$),
      filter(route => !!route && route.routeConfig.path === 'accounts/:accountId'),
    ).subscribe((route) => {
      this.load({
        accountId: Number(route.params.accountId),
        queryParams: route.queryParams
      });
    });

    this.accountService.context$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(context => this._context = context);
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save() {
    forkJoin([
      this.addressService.persist(this.address),
      this.accountService.persist(this.viewModel)
    ]).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.routerService.back());
  }

  load(request: { accountId: number, queryParams: QueryParams }) {

    this.accountService.selected$.pipe(
      takeUntil(this.unsubscribe$),
      filter(vm => !!vm),
      flatMap((vm: AccountViewModel) => this.addressService.getById$(vm.addressId).pipe(
        map(address => ({vm, address}))
      ))
    ).subscribe(({vm, address}) => {
      this._viewModel = vm;
      this._address = address;
    });

    this.accountService.get(request.accountId).pipe(
      takeUntil(this.unsubscribe$),
      filter(vm => !!vm),
      withLatestFrom(this.repositoryService.repositoryId$),
    ).subscribe(([account, repositoryId]) => {

      this.pathService.load({repositoryId, type: 'REPOSITORY'});
      this.addressService.get(account.addressId);

      if (account.rootRepositoryGroupId === repositoryId) {
        this.userService.get({repositoryId: account.rootRepositoryGroupId, userId: account.ownerId});
        this.userService.loadAccountUsers({accountId: account.id});
      }
    });

    this.licenseService.load();
  }
}
