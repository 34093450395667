import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as fromDocument from './document.reducer';
import {select, Store} from '@ngrx/store';
import {QueryParams} from '@ngrx/data';
import {HttpClient} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {Actions, ofType} from '@ngrx/effects';
import {filter, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Update} from '@ngrx/entity';
import {isNotNullOrUndefined} from '../../../../../../core/utils/src/rx-extensions';
import {DocumentEntity} from './document.models';
import {environment} from '@hb/environments/environment';
import {DocumentActions} from './document.actions';
import {DocumentSelectors} from './document.selectors';


@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  items$ = this.store.pipe(select(DocumentSelectors.getItems));
  selected$ = this.store.pipe(
    select(DocumentSelectors.getSelected),
    filter(item => isNotNullOrUndefined(item))
  );
  isLoading$ = this.store.pipe(select(DocumentSelectors.isLoading));
  exception$ = this.store.pipe(select(DocumentSelectors.getException));


  constructor(private http: HttpClient,
              private store: Store<fromDocument.DocumentPartialState>,
              private actions$: Actions,
              private logger: NGXLogger) {
  }

  create(repositoryId: number, materialId: number, queryParams?: QueryParams) {
    this.store.dispatch(DocumentActions.createDocumentRequest({repositoryId, materialId, queryParams}));
    return this.next(DocumentActions.createDocumentSuccess).pipe(
      map(action => action.payload)
    );
  }

  load(repositoryId: number, materialId: number) {
    this.store.dispatch(DocumentActions.loadDocumentsRequest({request: {repositoryId, materialId}}));
  }

  get(repositoryId: number, materialId: number, documentId: number, queryParams?: QueryParams) {
    this.store.dispatch(DocumentActions.getDocumentRequest({request: {repositoryId, materialId, documentId}}));
  }

  persist(document: DocumentEntity, queryParams?: QueryParams): Observable<any> {
    this.store.dispatch(DocumentActions.persistDocumentRequest({document, queryParams}));
    return this.next(DocumentActions.persistDocumentSuccess);
  }

  update(update: Update<DocumentEntity>) {
    this.store.dispatch(DocumentActions.updateDocument({update}));
  }

  delete(documentEntity: DocumentEntity) {
    const params = {repository: documentEntity.repositoryId, material: documentEntity.materialId, document: documentEntity.id};
    this.store.dispatch(DocumentActions.deleteDocumentRequest({params}));
  }

  reset() {
    this.store.dispatch(DocumentActions.resetDocument());
  }

  download(document: DocumentEntity, type: 'pdf' | 'zebra') {

    if (type === 'pdf') {
      this.http.get(
        `${environment.api}/repositories/${document.repositoryId}/materials/${document.materialId}/documents/${document.id}/pdf`,
        {responseType: 'blob'}).subscribe(blob => {
        saveAs(blob, document.pdfFilename);
      });
    } else if (type === 'zebra') {
      this.http.get(
        `${environment.api}/repositories/${document.repositoryId}/materials/${document.materialId}/documents/${document.id}/labelhtml`,
        {responseType: 'blob'}).subscribe(blob => {
        saveAs(blob, document.pdfFilename.replace('.pdf', '.html'));
      });
    }
  }

  private next(type: any) {
    return this.actions$.pipe(
      ofType(type),
      take(1)
    );
  }
}
