import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromLicense from './+state/license.reducer';
import {EffectsModule} from '@ngrx/effects';
import {LicenseEffects} from './+state/license.effects';
import {NGXLogger} from 'ngx-logger';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromLicense.LICENSE_FEATURE_KEY,
      fromLicense.reducer
    ),
    EffectsModule.forFeature([LicenseEffects]),
  ]
})
export class LicenseStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
