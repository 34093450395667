import {Component, ContentChildren, Directive, Input, OnInit, QueryList, TemplateRef} from '@angular/core';
import {fabGroupAnimations} from './fab-group.animations';


@Directive({selector: '[hbFabItem]'})
export class FabGroupItemDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'hb-fab-group',
  templateUrl: './fab-group.component.html',
  styleUrls: ['./fab-group.component.scss'],
  animations: [fabGroupAnimations]
})
export class FabGroupComponent implements OnInit {

  @Input()
  public label: string = null;

  @Input()
  public disabled: false;

  @ContentChildren(FabGroupItemDirective) fabItems: QueryList<FabGroupItemDirective>;

  fabToggleState = false;

  constructor() {
  }

  get length(): number {
    return this.fabItems && this.fabToggleState ? this.fabItems.length : 0;
  }

  get items() {
    return this.fabItems && this.fabToggleState ? this.fabItems : this.fabItems.filter(item => false);
  }

  ngOnInit(): void {
  }

  onToggleFab() {
    this.fabToggleState = !this.fabToggleState;
  }

  close() {
    this.fabToggleState = false;
  }

}
