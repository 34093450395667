<div (click)="onToggleFab()" *ngIf="fabToggleState" id="fab-dismiss">
</div>
<div class="fab-container">
  <button (click)="onToggleFab()" [disabled]="disabled" class="fab-toggle"
          color="accent"
          [matTooltip]="label" mat-fab matTooltipHideDelay="200" matTooltipPosition="left" matTooltipTouchGestures="on">
    <mat-icon [@fabToggle]="{value: fabToggleState}" fontIcon="fa-plus" fontSet="fas"
              inline="true"></mat-icon>
  </button>
  <div [@speedDialStagger]="length">
    <div (click)="onToggleFab();" *ngFor="let item of items" class="hb-mini-fab">
      <ng-template [ngTemplateOutlet]="item.template">

      </ng-template>
    </div>
  </div>
</div>
