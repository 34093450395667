import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromManufacturer from './+state/manufacturer.reducer';
import {EffectsModule} from '@ngrx/effects';
import {ManufacturerEffects} from './+state/manufacturer.effects';
import {NGXLogger} from 'ngx-logger';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromManufacturer.MANUFACTURER_FEATURE_KEY,
      fromManufacturer.reducer
    ),
    EffectsModule.forFeature([ManufacturerEffects]),
  ]
})
export class ManufacturerStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
