import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromRepository from './repository.reducer';
import {NGXLogger} from 'ngx-logger';
import {QueryParams} from '@ngrx/data';
import {Actions} from '@ngrx/effects';
import {filter, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Update} from '@ngrx/entity';
import {
  CreateRepositoryRequest,
  GetRepositoryRowsRequest,
  LoadRepositoriesRequest,
  RepositoryEntity,
  RepositoryItemPayload,
  RepositoryViewModel,
} from './repository.models';
import {RepositorySelectors} from './repository.selectors';
import {RepositoryActions} from './repository.actions';
import {next} from '../../../../../../core/utils';


@Injectable({
  providedIn: 'root'
})
export class RepositoryService {

  items$ = this.store.pipe(select(RepositorySelectors.getItems));

  isLoading$ = this.store.pipe(select(RepositorySelectors.getIsLoading));
  isSearching$ = this.store.pipe(select(RepositorySelectors.getIsSearching));
  rows$ = this.store.pipe(select(RepositorySelectors.getRows));
  exception$ = this.store.pipe(select(RepositorySelectors.getException));
  selected$ = this.store.pipe(
    select(RepositorySelectors.getSelected),
    filter(selected => !!selected)
  );
  repositoryId$ = this.store.pipe(select(RepositorySelectors.getRepositoryId));
  rootRepository$ = this.store.pipe(select(RepositorySelectors.getRootRepository));

  itemsWithProps$(props: { repositoryId?: number, search?: string }): Observable<RepositoryViewModel[]> {
    return this.store.pipe(select(RepositorySelectors.getItemsWithProps, props));
  }

  constructor(private store: Store<fromRepository.RepositoryPartialState>,
              private actions$: Actions,
              private logger: NGXLogger) {
  }

  load(request: LoadRepositoriesRequest) {
    this.store.dispatch(RepositoryActions.loadRepositoriesRequest({request}));
    return this.actions$.pipe(
      next(RepositoryActions.loadRepositoriesSuccess),
      map(action => action.payload)
    );
  }

  getRows(request: GetRepositoryRowsRequest) {
    this.store.dispatch(RepositoryActions.getRepositoryRowsRequest({request}));
    return this.actions$.pipe(
      next(RepositoryActions.getRepositoryRowsSuccess),
      map(action => action.payload.rows)
    );
  }

  get(repositoryId: number, queryParams?: QueryParams) {
    this.store.dispatch(RepositoryActions.getRepositoryRequest({request: {repositoryId, queryParams}}));
    return this.actions$.pipe(
      next(RepositoryActions.getRepositorySuccess),
      map(action => action.payload)
    );
  }

  create(request: CreateRepositoryRequest): Observable<RepositoryEntity> {
    this.store.dispatch(RepositoryActions.createRepositoryRequest({request}));
    return this.actions$.pipe(
      next(RepositoryActions.createRepositorySuccess),
      map(action => action.payload.item)
    );
  }

  persist(vm: RepositoryViewModel): Observable<RepositoryItemPayload> {
    this.store.dispatch(RepositoryActions.persistRepositoryRequest({repository: vm.updated}));
    return this.actions$.pipe(
      next(RepositoryActions.persistRepositorySuccess),
      map(action => action.payload)
    );
  }

  move(vm: RepositoryViewModel, destination: number) {
    this.store.dispatch(RepositoryActions.moveRepositoryRequest({repository: vm.model, destination}));
    return this.actions$.pipe(
      next(RepositoryActions.moveRepositorySuccess),
      map(action => action.payload)
    );
  }

  delete(vm: RepositoryViewModel) {
    this.store.dispatch(RepositoryActions.deleteRepositoryRequest({repository: vm.model}));
    return this.actions$.pipe(
      next(RepositoryActions.deleteRepositorySuccess),
      map(action => action.payload)
    );
  }

  update(update: Update<RepositoryEntity>) {
    this.store.dispatch(RepositoryActions.updateRepository({update}));
  }

  share(repository: RepositoryEntity, target: string) {
    this.store.dispatch(RepositoryActions.shareRepositoryRequest({repository, target}));
    return this.actions$.pipe(
      next(RepositoryActions.shareRepositorySuccess)
    );
  }

  unShare(repository: RepositoryEntity, target: string) {
    this.store.dispatch(RepositoryActions.deleteShareRepositoryRequest({repository, target}));
  }

  notify(repository: RepositoryEntity, notify: boolean) {
    this.store.dispatch(RepositoryActions.setNotificationRequest({repository, notify}));
  }

  getById(id: any): Observable<RepositoryViewModel> {
    return this.store.select(RepositorySelectors.getItemById, id);
  }

  selectWithFilter(search: string) {
    return this.store.select(RepositorySelectors.selectWithFilter, search);
  }

  selectById(id: number) {
    return this.store.select(RepositorySelectors.getItemsById, id);
  }

  setRepositoryId(repositoryId: number) {
    this.store.dispatch(RepositoryActions.setRepositoryId({repositoryId}));
  }

  removeRepositoryId() {
    this.store.dispatch(RepositoryActions.removeRepositoryId());
  }

  reset() {
    this.store.dispatch(RepositoryActions.resetRepositories());
  }
}
