<h2 mat-dialog-title>
  <mat-icon *ngIf="type == 'warn'" color="warn" fontIcon="fa-exclamation-triangle" fontSet="fas"></mat-icon>
  <mat-icon *ngIf="type == 'error'" color="danger" fontIcon="fa-exclamation-triangle" fontSet="fas"></mat-icon>
  <mat-icon *ngIf="type == 'info'" color="info" fontIcon="fa-info-circle" fontSet="fas"></mat-icon>
  <mat-icon *ngIf="type == 'success'" color="success" fontIcon="fa-check-circle" fontSet="fas"></mat-icon>
  <span>{{title}}</span></h2>
<mat-dialog-content>
  <p>{{description}}</p>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [mat-dialog-close]="true" color="accent" mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button *ngIf="cancel" mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
