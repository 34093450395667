import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hb-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.scss']
})
export class CardImageComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
