import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {debounceTime, filter, map, take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NGXLogger} from 'ngx-logger';
import {ExtendedThemePalette} from '../../../directives/theme';
import {TermsOfUseDialogService} from '../../../dialogs/terms-of-use';
import {PrivacyDialogService} from '../../../dialogs/privacy';
import {ImprintDialogService} from '../../../dialogs/imprint';
import {AppService, LanguageEntity, LanguageService, RepositoryService, RouterService} from '../../../modules/store';
import {environment, VERSION} from '../../../../environments';
import {PwaService} from '../../../modules/core';
import {FilterWithCameraScanCommand} from '../../../commands/filter-with-camera-scan-command/filter-with-camera-scan.command';
import {OpenPrivacyCommand} from '../../../commands/open-privacy-command/open-privacy.command';
import {OpenImprintCommand} from '../../../commands/open-imprint-command/open-imprint.command';
import {Platform} from '@angular/cdk/platform';
import {CommandBus} from '../../../modules/command-bus/command.bus';

export enum PRIMARY_BUTTON_TYPE {
  TOGGLE = 'toggle',
  BACK = 'back',
  NONE = 'none'
}

export enum FILTER_BUTTON_TYPE {
  TOGGLE = 'toggle',
  NONE = 'none'
}

export enum ARCHIVE_BUTTON_TYPE {
  TOGGLE = 'toggle',
  NONE = 'none'
}

export enum REMINDER_BUTTON_TYPE {
  TOGGLE = 'toggle',
  NONE = 'none'
}

export interface ButtonOptions {
  primary?: PRIMARY_BUTTON_TYPE | 'none' | 'toggle' | 'back';
  filter?: FILTER_BUTTON_TYPE | 'none' | 'toggle';
  archive?: ARCHIVE_BUTTON_TYPE | 'none' | 'toggle';
  reminder?: REMINDER_BUTTON_TYPE | 'none' | 'toggle';
}

@Component({
  selector: 'hb-header',
  templateUrl: './header-view.component.html',
  styleUrls: ['./header-view.component.css']
})
export class HeaderViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  title: string;

  @Input()
  color: ExtendedThemePalette = 'primary';

  private _buttons: ButtonOptions = {primary: 'none', filter: 'none', archive: 'none', reminder: 'none'};

  public get buttons() {
    return this._buttons;
  }

  @Input()
  public set buttons(value: ButtonOptions) {
    this._buttons = {...this._buttons, ...value};
  }

  constructor(private languageService: LanguageService,
              private routerService: RouterService,
              private appService: AppService,
              private repositoryService: RepositoryService,
              private formBuilder: FormBuilder,
              private termsOfUseDialogService: TermsOfUseDialogService,
              private privacyDialogService: PrivacyDialogService,
              private imprintDialogService: ImprintDialogService,
              private pwaService: PwaService,
              private commandBus: CommandBus,
              private platform: Platform,
              private logger: NGXLogger) {
  }

  private _primaryClick = new EventEmitter();

  @Output()
  public get primaryClick(): EventEmitter<any> {
    return this._primaryClick;
  }

  public inputMode: 'none' | 'filter' | 'search' = 'none';

  public input = this.formBuilder.control('');

  get languages(): Observable<any> {
    return this.languageService.items$;
  }

  get VERSION() {
    return VERSION;
  }

  get showInactive$() {
    return this.appService.showInactive$;
  }

  get globalReminder$() {
    return this.repositoryService.rootRepository$.pipe(
      filter(repository => !!repository),
      map(repository => repository.notificationEnabled)
    );
  }

  get PRIMARY_BUTTON_TYPE() {
    return PRIMARY_BUTTON_TYPE;
  }

  get FILTER_BUTTON_TYPE() {
    return FILTER_BUTTON_TYPE;
  }

  get ARCHIVE_BUTTON_TYPE() {
    return ARCHIVE_BUTTON_TYPE;
  }

  get REMINDER_BUTTON_TYPE() {
    return REMINDER_BUTTON_TYPE;
  }

  ngOnInit() {

    this.routerService.filter$.pipe(
      take(1)
    ).subscribe(value => this.input.patchValue(value, {emitModelToViewChange: true}));

    this.input.valueChanges.pipe(
      debounceTime(500),
      map((value: string) => !!value ? value.trim() : null),
      filter((value: string) => value === null || value.length > 2),
      take(10)
    ).subscribe(value => this.routerService.setQuery({filter: value}));

    this.routerService.data$.pipe(
      filter(data => !!data && !!data.header),
      map(data => data.header.buttons)
    ).subscribe(buttons => this.buttons = buttons);

    this.routerService.data$.pipe(
      filter(data => !!data && !!data.header),
      map(data => data.header.color)
    ).subscribe(color => this.color = color);
  }



  public openImprint() {
    this.commandBus.dispatch(new OpenImprintCommand());
  }

  public openPrivacy() {
    this.commandBus.dispatch(new OpenPrivacyCommand());
  }

  public openTermsOfUse() {
    this.termsOfUseDialogService.open();
  }

  public selectLanguage(language: LanguageEntity) {
    this.languageService.set({...language});
  }

  public toggleShowInactive() {
    this.appService.toggleShowInactive();
  }

  public toggleGlobalReminder() {
    this.repositoryService.rootRepository$.pipe(
      filter(repository => !!repository),
      take(1)
    ).subscribe((repository) => this.repositoryService.notify(repository, !repository.notificationEnabled));
  }

  public installPWA() {
    this.pwaService.promptPWA();
  }

  public checkForUpdates() {
    this.pwaService.checkForUpdates();
  }

  public get isMobilePlatform() {
    return this.platform.ANDROID || this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  }

  public back() {
    this.routerService.back();
  }

  public toggle() {
    this.appService.toggleSideNav();
  }

  public get servicePhoneLink() {
    return `tel:${environment.serviceNumber}`;
  }

  public get serviceMailTo() {
    return `mailto:${environment.contactEmail}`;
  }

  public openScan() {
    const command = new FilterWithCameraScanCommand(this.input);
    this.commandBus.dispatch(command);
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
  }
}
