import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ImageViewerComponent} from './image-viewer.component';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerDialogService extends BaseDialogService<ImageViewerComponent, any> {

  protected config: MatDialogConfig = {
    width: '50vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, ImageViewerComponent);
  }
}
