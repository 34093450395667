import {environment} from '@hb/environments/environment';
import {PrivacyDialogService} from '../../dialogs/privacy';
import {Injectable} from '@angular/core';
import {CommandHandler} from '../../modules/command-bus/command.handler';
import {DomainCommand} from '../../modules/command-bus/command';

@Injectable()
export class OpenPrivacyCommandHandler implements CommandHandler {

  constructor(private privacyDialogService: PrivacyDialogService) {
  }

  handle(command: DomainCommand): void {
    if (environment.privacyLink) {
      window.open(environment.privacyLink, '_blank');
    } else {
      this.privacyDialogService.open();
    }
  }

}
