import {HttpErrorResponse} from '@angular/common/http';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';

export class Exception {

  private readonly _status: number = null;
  private readonly _context: any;
  private readonly _errorResponse: HttpErrorResponse;

  constructor(errorResponse: HttpErrorResponse, context: any, status?: number) {
    this._errorResponse = errorResponse;
    this._context = context;
    this._status = status;
  }

  public get context() {
    return this._context;
  }

  public get errorResponse() {
    return this._errorResponse;
  }

  public get message() {
    return this.errorResponse?.error?.msg || this.errorResponse.message;
  }

  public get status() {
    return this._status ? this._status : this.errorResponse.status;
  }

  public get code() {
    return this.errorResponse?.error?.code || null;
  }

  public get isAuthenticationException() {
    return this.status === 401;
  }
}


export class ExceptionModel {
  code: number;
  extMsg: string;
  field: string;
  htmlCode: number;
  msg: string;
  value: any;
}

export const errorCodeMap = {
  ex1000: {
    title: marker('keys.local.errors.ex1000.title'),
    message: marker('keys.local.errors.ex1000.message')
  },
  ex2000: {
    title: marker('keys.local.errors.ex2000.title'),
    message: marker('keys.local.errors.ex2000.message')
  },
  ex2001: {
    title: marker('keys.local.errors.ex2001.title'),
    message: marker('keys.local.errors.ex2001.message')
  },
  ex2010: {
    title: marker('keys.local.errors.ex2010.title'),
    message: marker('keys.local.errors.ex2010.message'),
  },
  ex2020: {
    title: marker('keys.local.errors.ex2020.title'),
    message: marker('keys.local.errors.ex2020.message'),
  },
  ex2030: {
    title: marker('keys.local.errors.ex2030.title'),
    message: marker('keys.local.errors.ex2030.message'),
  },
  ex2040: {
    title: marker('keys.local.errors.ex2040.title'),
    message: marker('keys.local.errors.ex2040.message'),
  },
  ex2050: {
    title: marker('keys.local.errors.ex2050.title'),
    message: marker('keys.local.errors.ex2050.message'),
  },
  ex2060: {
    title: marker('keys.local.errors.ex2060.title'),
    message: marker('keys.local.errors.ex2060.message'),
  },
  ex2070: {
    title: marker('keys.local.errors.ex2070.title'),
    message: marker('keys.local.errors.ex2070.message'),
  },
  ex2080: {
    title: marker('keys.local.errors.ex2080.title'),
    message: marker('keys.local.errors.ex2080.message'),
  },
  ex2090: {
    title: marker('keys.local.errors.ex2090.title'),
    message: marker('keys.local.errors.ex2090.message'),
  },
  ex2100: {
    title: marker('keys.local.errors.ex2100.title'),
    message: marker('keys.local.errors.ex2100.message'),
  },
  ex2110: {
    title: marker('keys.local.errors.ex2110.title'),
    message: marker('keys.local.errors.ex2110.message'),
  },
  ex2120: {
    title: marker('keys.local.errors.ex2120.title'),
    message: marker('keys.local.errors.ex2120.message'),
  },
  ex2130: {
    title: marker('keys.local.errors.ex2130.title'),
    message: marker('keys.local.errors.ex2130.message'),
  },
  ex2140: {
    title: marker('keys.local.errors.ex2140.title'),
    message: marker('keys.local.errors.ex2140.message'),
  },
  ex2150: {
    title: marker('keys.local.errors.ex2150.title'),
    message: marker('keys.local.errors.ex2150.message'),
  },
  ex2160: {
    title: marker('keys.local.errors.ex2160.title'),
    message: marker('keys.local.errors.ex2160.message'),
  },
  ex2170: {
    title: marker('keys.local.errors.ex2170.title'),
    message: marker('keys.local.errors.ex2170.message'),
  },
  ex2180: {
    title: marker('keys.local.errors.ex2180.title'),
    message: marker('keys.local.errors.ex2180.message'),
  },
  ex2190: {
    title: marker('keys.local.errors.ex2190.title'),
    message: marker('keys.local.errors.ex2190.message'),
  },
  ex2200: {
    title: marker('keys.local.errors.ex2200.title'),
    message: marker('keys.local.errors.ex2200.message'),
  },
  ex2210: {
    title: marker('keys.local.errors.ex2210.title'),
    message: marker('keys.local.errors.ex2210.message'),
  },
  ex2220: {
    title: marker('keys.local.errors.ex2220.title'),
    message: marker('keys.local.errors.ex2220.message'),
  },
  ex2230: {
    title: marker('keys.local.errors.ex2230.title'),
    message: marker('keys.local.errors.ex2230.message'),
  },
  ex2240: {
    title: marker('keys.local.errors.ex2240.title'),
    message: marker('keys.local.errors.ex2240.message'),
  },
  ex2250: {
    title: marker('keys.local.errors.ex2250.title'),
    message: marker('keys.local.errors.ex2250.message'),
  },
  ex2260: {
    title: marker('keys.local.errors.ex2260.title'),
    message: marker('keys.local.errors.ex2260.message'),
  },
  ex2270: {
    title: marker('keys.local.errors.ex2270.title'),
    message: marker('keys.local.errors.ex2270.message'),
  },
  ex2280: {
    title: marker('keys.local.errors.ex2280.title'),
    message: marker('keys.local.errors.ex2280.message'),
  },
  ex2290: {
    title: marker('keys.local.errors.ex2290.title'),
    message: marker('keys.local.errors.ex2290.message'),
  },
  ex2300: {
    title: marker('keys.local.errors.ex2300.title'),
    message: marker('keys.local.errors.ex2300.message'),
  },
  ex2310: {
    title: marker('keys.local.errors.ex2310.title'),
    message: marker('keys.local.errors.ex2310.message'),
  },
  ex2320: {
    title: marker('keys.local.errors.ex2320.title'),
    message: marker('keys.local.errors.ex2320.message'),
  },
  ex2330: {
    title: marker('keys.local.errors.ex2330.title'),
    message: marker('keys.local.errors.ex2330.message'),
  }
};

export function toException(errorResponse: HttpErrorResponse) {
  return {...errorResponse.error, msg: errorResponse.message};
}
