import {Component, HostBinding, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DateAdapter} from '@angular/material/core';
import {Title} from '@angular/platform-browser';
import {NGXLogger} from 'ngx-logger';
import {DOCUMENT} from '@angular/common';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {BreakpointService, PwaService} from './modules/core';
import {
  AppService,
  AuthenticationService,
  CountryService,
  LanguageService,
  PathService,
  PermissionService,
  RepositoryService,
  RouterService,
  SearchService,
  UserService
} from './modules/store';
import {environment, VERSION} from '../environments';


@Component({
  selector: 'hb-app-shell',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  @HostBinding('class')
  private get theme(): string {
    return `theme-${environment.style.theme}`;
  }

  constructor(
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private authenticationService: AuthenticationService,
    private repositoryService: RepositoryService,
    private userService: UserService,
    private searchService: SearchService,
    private permissionService: PermissionService,
    private countryService: CountryService,
    private languageService: LanguageService,
    private titleService: Title,
    private pwa: PwaService,
    private logger: NGXLogger,
    private appService: AppService,
    private routerService: RouterService,
    private breakpointService: BreakpointService,
    private pathService: PathService,
    @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.onResize();
  }

  get isScrolled$() {
    return this.appService.isScrolled$;
  }

  public get background(): Observable<boolean> {
    return this.routerService.data$.pipe(
      map(data => !!data && data.background)
    );
  }

  public get path$() {
    return this.pathService.items$;
  }

  ngOnInit(): void {

    this.translate.stream('views.app.title').subscribe(title =>
      this.titleService.setTitle(`${title} ${VERSION.semver.version}`));

    this.subscription.add(combineLatest([
      this.repositoryService.repositoryId$,
      this.userService.userId$,
      this.authenticationService.hasToken$()
    ]).pipe(
      filter(([repositoryId, userId, isLoggedIn]) => !!isLoggedIn && !!repositoryId && !!userId),
    ).subscribe(([repositoryId, userId]) => {
      this.userService.getLoggedInUser();
      this.repositoryService.get(repositoryId);
      this.permissionService.load({repositoryId, userId});
      this.searchService.load('search');
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
  }

  public onScroll(event) {
    this.appService.setScrolled(event.target.scrollTop > 0);
  }
}
