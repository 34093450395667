import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {EditEmailDialogService} from '../../../dialogs/edit-email';
import {EditPasswordDialogService} from '../../../dialogs/edit-password/src/edit-password-dialog.service';
import {filter, flatMap, map, mapTo, takeUntil, tap} from 'rxjs/operators';
import {HintDialogService} from '../../../dialogs/hint';
import {AddCertificateDialogService} from '../../../dialogs/add-certificate/src/add-certificate-dialog.service';
import {UserViewDataSource} from './user-view.data-source';
import {Subject, Subscription} from 'rxjs';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {
  AuthenticationService,
  CertificateService,
  CountryService,
  LanguageService,
  MediaType,
  PathService,
  RouterService,
  TrainingService,
  TrainingViewModel,
  UserService
} from '../../../modules/store';
import {Ability} from '@casl/ability';
import {remoteTranslationMap} from '../../../modules/core/maps/src/translation.maps';

@Component({
  selector: 'hb-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})
export class UserViewComponent implements OnInit, OnDestroy {

  private subscription: Subscription;
  private unsubscribe$ = new Subject<void>();
  private _context;

  constructor(
    private userService: UserService,
    private pathService: PathService,
    private languageService: LanguageService,
    private countryService: CountryService,
    private trainingService: TrainingService,
    private certificateService: CertificateService,
    private dataSource: UserViewDataSource,
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
    private editEmailDialogService: EditEmailDialogService,
    private editPasswordDialogService: EditPasswordDialogService,
    private certificateDialogService: AddCertificateDialogService,
    private hintDialogService: HintDialogService,
    private ability: Ability,
    private logger: NGXLogger) {

  }

  public get roles$() {
    return this.dataSource.roles$;
  }

  public readonly mediaType: typeof MediaType = MediaType;

  public get isLoading$() {
    return this.dataSource.isLoading$;
  }

  public get viewModel() {
    return this.dataSource.viewModel;
  }

  public get path$() {
    return this.dataSource.path$;
  }

  public get countries$() {
    return this.dataSource.countries$;
  }

  public get languages$() {
    return this.dataSource.languages$;
  }

  public get trainings$() {
    return this.dataSource.trainings$;
  }

  public get certificates$() {
    return this.dataSource.certificates$;
  }

  public get locale$() {
    return this.dataSource.locale$;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get context() {
    return this._context;
  }

  public getRoleName(key: string) {
    return remoteTranslationMap[key];
  }

  ngOnInit() {
    this.subscription = this.routerService.route$.pipe(
      filter(route => route.routeConfig.path === ':userId'),
    ).subscribe((route) => {
      this.dataSource.load({repositoryId: route.params.repositoryId, userId: route.params.userId, queryParams: route.queryParams});
    });

    this.userService.context$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(context => this._context = context);
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editEmail() {
    if (this.ability.can('update', this.context)) {
      this.editEmailDialogService.open(this.viewModel.model).afterClosed().pipe(
        filter(result => !!result),
        flatMap(vm => this.hintDialogService.openWarningV2(marker('keys.local.messages.email_changed_confirm')).afterClosed().pipe(
          filter(r => !!r),
          mapTo(vm)
        )),
        flatMap(vm => this.userService.persist({entity: vm.updated})),
        tap(() => this.authenticationService.logout())
      ).subscribe(() => this.hintDialogService.openInfoV2(marker('keys.local.messages.email_changed_check')));
    }
  }

  editPassword() {
    if (this.ability.can('update', this.context)) {
      this.editPasswordDialogService.open(this.viewModel.model).afterClosed().pipe(
        filter(result => !!result),
        flatMap(vm => this.userService.persist({entity: vm.updated}))
      ).subscribe(() => this.hintDialogService.openInfoV2(marker('keys.local.messages.password_changed')));
    }
  }

  editTraining(training: TrainingViewModel) {
    this.certificateDialogService.open(training).afterClosed().pipe(
      filter(result => !!result),
      map(result => ({...result, repositoryId: this.routerService.repositoryId, userId: this.routerService.userId})),
    ).subscribe(result => this.certificateService.persist(result));
  }

  save() {
    this.dataSource.save().subscribe(() => this.routerService.back());
  }
}
