import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import {Exception} from '../../../../../../core/models';
import {MaterialEntity} from './material.models';
import {MaterialActions} from './material.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';


export const MATERIAL_FEATURE_KEY = 'material';

export interface MaterialState extends EntityState<MaterialEntity> {
  isLoading: boolean;
  isSearching: boolean;
  exception: Exception;
  rows: number;
  search: string;
}

export interface MaterialPartialState {
  readonly [MATERIAL_FEATURE_KEY]: MaterialState;
}

export const materialAdapter: EntityAdapter<MaterialEntity> = createEntityAdapter<MaterialEntity>();

export const initialState: MaterialState = materialAdapter.getInitialState({
  isLoading: false,
  isSearching: false,
  rows: 0,
  exception: null,
  search: null,
});

const materialReducer = createReducer(
  initialState,
  on(MaterialActions.loadMaterialsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(MaterialActions.loadMaterialsSuccess, (state, {payload}) =>
    materialAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(MaterialActions.loadMaterialsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(MaterialActions.loadAuditableMaterialListRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(MaterialActions.loadAuditableMaterialListSuccess, (state, {payload}) =>
    materialAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(MaterialActions.loadAuditableMaterialListFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(MaterialActions.getMaterialRowsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(MaterialActions.getMaterialRowsSuccess, (state, {payload}) => ({
      ...state,
      isLoading: false,
      rows: payload.rows,
    })
  ),
  on(MaterialActions.getMaterialRowsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(MaterialActions.getMaterialRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(MaterialActions.getMaterialSuccess, (state, {payload}) =>
    materialAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })
  ),
  on(MaterialActions.getMaterialFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(MaterialActions.createMaterialRequest, (state) => ({
    ...state,
  })),
  on(MaterialActions.createMaterialSuccess, (state, {payload}) => ({
    ...state,
  })),
  on(MaterialActions.createMaterialFailure, (state) => ({
    ...state,
  })),
  on(MaterialActions.loadChildrenRequest, (state) => ({
    ...state,
  })),
  on(MaterialActions.loadChildrenSuccess, (state, {payload}) =>
    materialAdapter.upsertMany(payload.items, {
      ...state,
    })),
  on(MaterialActions.loadChildrenFailure, (state, {exception}) => ({
    ...state,
    exception
  })),
  on(MaterialActions.setNotificationSuccess, (state, {update}) =>
    materialAdapter.updateOne(update, {
      ...state
    })),
  on(MaterialActions.deleteMaterialRequest, (state) => ({
    ...state,
  })),
  on(MaterialActions.deleteMaterialSuccess, (state, {payload}) =>
    materialAdapter.removeOne(payload.materialId, {
      ...state,
    })
  ),
  on(MaterialActions.deleteMaterialFailure, (state) => ({
    ...state,
  })),
  on(MaterialActions.persistMaterialSuccess, (state, {payload}) =>
    materialAdapter.upsertOne(payload.item, {
      ...state
    })
  ),
  on(MaterialActions.updateMaterial, (state, {update}) =>
    materialAdapter.updateOne(update, {
      ...state
    })),
  on(MaterialActions.moveMaterialRequest),
  on(MaterialActions.moveMaterialSuccess, (state, {payload}) =>
    materialAdapter.upsertOne(payload.item, {
      ...state
    })
  ),
  on(MaterialActions.moveMaterialFailure),
  on(MaterialActions.updateMaterialRequest),
  on(MaterialActions.updateMaterialSuccess, (state, {payload}) =>
    materialAdapter.upsertOne(payload.item, {
      ...state
    })
  ),
  on(MaterialActions.updateMaterialFailure),
  on(MaterialActions.resetMaterials, () => initialState),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: MaterialState | undefined, action: Action) {
  return materialReducer(state, action);
}
