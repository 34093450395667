import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {COUNTRY_FEATURE_KEY, CountryPartialState, CountryState} from './country.reducer';
import {CountryEntity} from './country.models';
import {Exception} from '../../../../../../core/models';

const getCountryState = createFeatureSelector<CountryPartialState, CountryState>(COUNTRY_FEATURE_KEY);

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getCountryState,
  (state: CountryState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getCountryState,
  (state: CountryState) => state.exception
);

const getItems: MemoizedSelector<object, CountryEntity[]> = createSelector(
  getCountryState,
  (state: CountryState) => state.items
);

export const CountrySelectors = {
  isLoading,
  getException,
  getItems,
};
