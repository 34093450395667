import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromLicense from './license.reducer';
import {NGXLogger} from 'ngx-logger';
import {LicenseActions} from './license.actions';
import {LicenseSelectors} from './license.selectors';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {

  public readonly isLoading$ = this.store.pipe(select(LicenseSelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(LicenseSelectors.getException));
  public readonly items$ = this.store.pipe(select(LicenseSelectors.getItems));

  constructor(private store: Store<fromLicense.LicensePartialState>, logger: NGXLogger) {
  }

  public load() {
    this.store.dispatch(LicenseActions.loadLicensesRequest());
  }
}
