<h2 mat-dialog-title>{{'dialogs.imprint.title' | translate}}</h2>
<mat-dialog-content fxLayout="column nowrap">
  <div [innerHTML]="'dialogs.imprint.content' | translate">

  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.close' | translate}}</button>
</mat-dialog-actions>


<!--<br>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_name' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_name' | translate" [value]="'imprint.form_name_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_line1' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_line1' | translate" [value]="'imprint.form_line1_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_city' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_city' | translate" [value]="'imprint.form_city_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_country' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_country' | translate" [value]="'imprint.form_country_value' | translate"-->
<!--         matInput readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_phone' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_phone' | translate" [value]="'imprint.form_phone_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_fax' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_fax' | translate" [value]="'imprint.form_fax_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_mail' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_mail' | translate" [value]="'imprint.form_mail_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_web' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_web' | translate" [value]="'imprint.form_web_value' | translate" matInput-->
<!--         readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_managing_director' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_managing_director' | translate"-->
<!--         [value]="'imprint.form_managing_director_value' | translate" matInput readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_commercial_register' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_commercial_register' | translate"-->
<!--         [value]="'imprint.form_commercial_register_value' | translate" matInput readonly>-->
<!--</mat-form-field>-->
<!--<mat-form-field appearance="outline">-->
<!--  <mat-label>{{'imprint.form_st_id_nr' | translate}}</mat-label>-->
<!--  <input [placeholder]="'imprint.form_st_id_nr' | translate" [value]="'imprint.form_st_id_nr_value' | translate"-->
<!--         matInput readonly>-->
<!--</mat-form-field>-->
