import {createFeatureSelector, createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {REPOSITORY_FEATURE_KEY, repositoryAdapter, RepositoryPartialState, RepositoryState} from './repository.reducer';
import {RepositoryEntity, repositoryFilterProperties, RepositoryViewModel, repositoryViewModelFactory} from './repository.models';
import {objectFilter} from '../../../../../../core/utils/src/filter';
import {Dictionary} from '@ngrx/entity';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';
import {isNullOrUndefined} from '../../../../../../core/utils';

const getRepositoryState = createFeatureSelector<RepositoryPartialState,
  RepositoryState>(REPOSITORY_FEATURE_KEY);

const {selectAll, selectEntities} = repositoryAdapter.getSelectors();

const getIsLoading: MemoizedSelector<object, boolean> = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.isLoading
);

const getIsSearching: MemoizedSelector<object, boolean> = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.isSearching
);

const getSearch = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.search
);

const getFilteredId = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.filteredId
);

const getException = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.exception
);

const getRepositoryId = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.repositoryId
);

const getAllItems: MemoizedSelector<object, RepositoryViewModel[]> = createSelector(
  getRepositoryState,
  (state: RepositoryState) => selectAll(state).map(item => repositoryViewModelFactory(item))
);

const getItemsById: MemoizedSelectorWithProps<object, number, RepositoryViewModel[]> = createSelector(
  getAllItems,
  (items, id) => items
    .filter(item => item.parentId === id || item.virtualParentId === id)
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getItems: MemoizedSelector<object, RepositoryViewModel[]> = createSelector(
  getAllItems,
  RouterSelectors.getRepositoryIdParam,
  RouterSelectors.getFilterQueryParam,
  (items, repositoryId, search) => items
    .filter(item => item.parentId === repositoryId || item.virtualParentId === repositoryId)
    .filter(item => !search || (search && (objectFilter(item, repositoryFilterProperties, search))))
);

const getSearchItems = createSelector(
  getAllItems,
  getSearch,
  getFilteredId,
  (items, search, filteredId) => {
    return items
      .filter(item => !filteredId || item.id !== filteredId)
      .filter(item => !search || (search && (objectFilter(item, repositoryFilterProperties, search))))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
);

const getItemsWithProps = createSelector(
  getAllItems,
  (items, props: { search?: string, repositoryId?: number }) => items
    .filter(item => isNullOrUndefined(props.search) || (props.search && (objectFilter(item, repositoryFilterProperties, props.search))))
    .filter(item => isNullOrUndefined(props.repositoryId) || (props.repositoryId && item.parentId === props.repositoryId))
    .sort((a, b) => a.name.localeCompare(b.name))
);

const getRows: MemoizedSelector<object, number> = createSelector(
  getRepositoryState,
  (state: RepositoryState) => state.rows
);

const getRepositoryEntities: MemoizedSelector<object, Dictionary<RepositoryEntity>> = createSelector(
  getRepositoryState,
  (state: RepositoryState) => selectEntities(state)
);

const getSelected = createSelector(
  getRepositoryEntities,
  RouterSelectors.getRepositoryIdParam,
  (items, selected) => items[selected] ? repositoryViewModelFactory(items[selected]) : undefined
);

const getItemById = createSelector(
  getRepositoryEntities,
  (items, props) => !!items[props] ? repositoryViewModelFactory(items[props]) : undefined
);

const selectWithFilter = createSelector(
  getItems,
  (items, search) => items.filter(item => !search || (search && (objectFilter(item, repositoryFilterProperties, search))))
);

const getRootRepository = createSelector(
  getRepositoryEntities,
  getRepositoryId,
  (entities, id) => entities[id]
);

export const RepositorySelectors = {
  getIsLoading,
  getIsSearching,
  getSearch,
  getFilteredId,
  getException,
  getRepositoryId,
  getAllItems,
  getItemsById,
  getItems,
  getSearchItems,
  getRows,
  getRepositoryEntities,
  getSelected,
  getItemById,
  selectWithFilter,
  getRootRepository,
  getItemsWithProps
};
