import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CreateProductComponent} from './create-product.component';

@Injectable({
  providedIn: 'root'
})
export class CreateProductDialogService extends BaseDialogService<CreateProductComponent, any> {

  protected config: MatDialogConfig = {
    width: '80vw'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, CreateProductComponent);
  }
}
