import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PermissionDataService} from './permission-data.service';

import {HintDialogService} from '../../../../../../../dialogs/hint';
import {NGXLogger} from 'ngx-logger';
import {Exception} from '../../../../../../core/models';
import {PermissionActions} from './permission.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class PermissionEffects {

  loadPermissionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.loadPermissionsRequest),

      switchMap(action => this.permissionDataService.load(action.request).pipe(
        map(payload => PermissionActions.loadPermissionsSuccess({payload})),
        catchError(error => of(PermissionActions.loadPermissionsFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  loadPermissionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PermissionActions.loadPermissionsSuccess),
    ), {dispatch: false}
  );

  loadPermissionsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(PermissionActions.loadPermissionsFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );


  constructor(private actions$: Actions, private permissionDataService: PermissionDataService,
              private hintDialogService: HintDialogService, private logger: NGXLogger) {

  }

}
