import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, combineLatest, forkJoin, Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SearchDialogDataSource} from './search-dialog.data-source';
import {debounceTime, distinctUntilChanged, filter, flatMap, map, takeUntil} from 'rxjs/operators';
import {
  LanguageService,
  LoadMaterialsRequest,
  LoadRepositoriesRequest,
  LoadUsersRequest,
  MaterialService,
  MaterialViewModel,
  RepositoryService,
  RepositoryViewModel,
  RouterService,
  searchEntityFactory,
  SearchService,
  UserService,
  UserViewModel
} from '../../../modules/store';
import {environment} from '@hb/environments/environment';
import {SearchWithCameraScanCommand} from '../../../commands/search-with-camera-scan-command/search-with-camera-scan.command';
import {Platform} from '@angular/cdk/platform';
import {CommandBus} from '../../../modules/command-bus/command.bus';

@Component({
  selector: 'hb-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>();
  private readonly _search = this.formBuilder.control('');
  private readonly _items$: BehaviorSubject<LoadRepositoriesRequest | LoadMaterialsRequest | LoadUsersRequest>;
  private _userId: number;

  private searchValue: string;


  constructor(private searchService: SearchService,
              private repositoryService: RepositoryService,
              private materialService: MaterialService,
              private userService: UserService,
              private dataSource: SearchDialogDataSource,
              private languageService: LanguageService,
              private formBuilder: FormBuilder,
              private routerService: RouterService,
              private commandBus: CommandBus,
              private platform: Platform,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private dialogRef: MatDialogRef<SearchComponent>,
              private logger: NGXLogger) {
    this._items$ = new BehaviorSubject<LoadRepositoriesRequest | LoadMaterialsRequest | LoadUsersRequest>({search: null, page: 0});
  }

  public get userId(): number {
    return this._userId;
  }

  public get search(): FormControl {
    return this._search;
  }

  public get timestamp() {
    return this.data.data.timestamp;
  }

  public get timeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get locale$() {
    return this.languageService.selected$;
  }

  public get isMobilePlatform() {
    return this.platform.ANDROID || this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
  }

  public get items$() {
    return this._items$.pipe(
      filter(request => !!request && !!request.search && request.search.length > 2),
      flatMap(request => combineLatest([
        this.repositoryService.itemsWithProps$(request),
        this.materialService.itemsWithProps$(request),
        this.userService.itemsWithProps$(request),
      ])),
      map(([a, b, c]) => [...a, ...b, ...c])
    );
  }


  public get isLoading$() {
    return combineLatest([
      this.repositoryService.isLoading$,
      this.materialService.isLoading$,
      this.userService.isLoading$,
    ]).pipe(map(([a, b, c]) => a || b || c));
  }


  public get rows$() {
    return this.dataSource.rows$;
  }

  public get availableHeight() {
    return `${window.innerHeight - 298}px`;
  }

  ngOnInit(): void {

    this.userService.getLoggedInUser().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(payload => this._userId = payload.item.id);


    this._items$.pipe(
      takeUntil(this.unsubscribe$),
      filter(request => !!request && !!request.search && request.search.length > 2),
    ).subscribe(request => {
      forkJoin([
        this.repositoryService.getRows(request),
        this.materialService.getRows(request)
      ]).toPromise().then(([r, m]) => {
        const repositoryPageOffset = Math.round(r / environment.defaultPageSize);
        const materialPageOffset = Math.round(m / environment.defaultPageSize);

        if (request.page <= repositoryPageOffset) {
          this.repositoryService.load(request);
        }
        if (request.page >= repositoryPageOffset && request.page <= materialPageOffset) {
          this.materialService.load(request);
        }
        if (request.page >= materialPageOffset) {
          this.userService.load(request);
        }
      });
    });

    this.search.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(1000),
      distinctUntilChanged((prev, curr) => prev === curr),
      filter(search => !!search && search.length > 2),
    ).subscribe((search) => {
      this._items$.next({search, page: 0});
      this.searchService.update(this.data.id, searchEntityFactory(this.data.id, this.timestamp, search, 0));
      this.searchValue = search;
    });


    this.searchService.getById(this.data.id).pipe(
      takeUntil(this.unsubscribe$),
      filter(e => !!e),
      distinctUntilChanged((prev, curr) => prev.data.search === curr.data.search),
    ).subscribe(e => this.search.patchValue(e.data.search, {emitEvent: true}));
  }

  fetch(search, $event) {
    this._items$.next({...this._items$.getValue(), page: $event.page});
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.dialogRef.close();
  }

  openRepository(viewModel: RepositoryViewModel) {
    this.routerService.navigateToPlaces(viewModel.id);
    this.close();
  }

  openMaterial(viewModel: MaterialViewModel) {
    this.routerService.navigateToMaterialView(viewModel.repositoryId, viewModel.id);
    this.close();
  }

  openUser(viewModel: UserViewModel) {
    this.routerService.navigateToUserView(viewModel.repositoryId, viewModel.id);
    this.close();
  }

  openScan() {
    const command = new SearchWithCameraScanCommand(this.data.id, this.timestamp);
    this.commandBus.dispatch(command);
  }
}
