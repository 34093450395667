import {MonoTypeOperatorFunction, Observable, OperatorFunction, pipe} from 'rxjs';
import {filter, map, take, tap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRouteSnapshot} from '@angular/router';
import {LoggerResolver} from './logger-resolver';
import {ActionCreator} from '@ngrx/store';
import {ofType} from '@ngrx/effects';
import {Identity} from './identity';

export function isNullOrUndefined<T>(value: T): boolean {
  return value === null || value === undefined;
}

export function isNotNullOrUndefined<T>(value: T) {
  return value !== null && value !== undefined;
}

export function getDeepestNode(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  if (!!route.firstChild) {
    return getDeepestNode(route.firstChild);
  }
  return route;
}

export function arrayGroupBy<T>(array: T[], key: string) {
  array.reduce((r, a) => {
    r[a[key]] = [...r[a[key]] || [], a];
    return r;
  }, {});
}

export function doWhile<R>(secondStream: Observable<boolean>): MonoTypeOperatorFunction<R> {
  return input$ => input$.pipe(
    withLatestFrom(secondStream),
    filter(([first, second]) => second),
    map(([first, second]) => first)
  );
}

export function mapWithLatestFrom<R>(secondStream: Observable<R>) {
  return input$ => input$.pipe(
    withLatestFrom(secondStream),
    map(([first, second]) => second)
  );
}

declare global {
  interface Array<T> {
    last(): T;

    preLast(): T;

    distinct(): Array<T>;

  }
}

Array.prototype.last = function() {
  return this[this.length - 1];
};

Array.prototype.preLast = function() {
  return this[this.length - 2];
};

Array.prototype.distinct = function() {
  return this.filter((elem, index, self) => index === self.indexOf(elem));
};

export function next<T extends ActionCreator>(action: T) {
  return pipe(
    ofType(action),
    take(1)
  );
}

export function nextV2<T extends ActionCreator>(action: T, id: Identity) {
  return pipe(
    ofType(action),
    filter(a => (a as any).id.getId() === id.getId()),
    take(1)
  );
}

export function log<T>(message?: string): OperatorFunction<T, T> {
  return tap(e => LoggerResolver.resolve().log(message, e));
}

export function debug<T>(message?: string): OperatorFunction<T, T> {
  return tap(e => LoggerResolver.resolve().debug(message, e));
}

export function trace<T>(message?: string): OperatorFunction<T, T> {
  return tap(e => LoggerResolver.resolve().trace(message, e));
}

export function info<T>(message?: string): OperatorFunction<T, T> {
  return tap(e => LoggerResolver.resolve().info(message, e));
}
