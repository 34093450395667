import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromDocument from './+state/document.reducer';
import {EffectsModule} from '@ngrx/effects';
import {DocumentEffects} from './+state/document.effects';
import {DocumentDataService} from './+state/document-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromDocument.DOCUMENT_FEATURE_KEY,
      fromDocument.reducer
    ),
    EffectsModule.forFeature([DocumentEffects]),
  ],
  providers: [DocumentDataService],
  declarations: [],
  exports: []
})
export class DocumentStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
