import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {State} from './root.state';
import {LoggerResolver} from '../../../core/utils';
import {environment} from '../../../../../environments';

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    LoggerResolver.resolve().trace(action.type, action, state);

    return reducer(state, action);
  };
}

export const reducers: ActionReducerMap<State> = {};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
