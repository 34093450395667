import {createAction, props} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {
  CreateRepositoryRequest,
  GetRepositoryRowsRequest,
  LoadRepositoriesRequest,
  RepositoryEntity,
  RepositoryItemPayload,
  RepositoryItemRequest,
  RepositoryListPayload,
} from './repository.models';
import {Exception} from '../../../../../../core/models';


enum RepositoryActionTypes {

  LOAD_REPOSITORIES_REQUEST = '[Repository] Load Repositories Request',
  LOAD_REPOSITORIES_SUCCESS = '[Repository] Load Repositories Success',
  LOAD_REPOSITORIES_FAILURE = '[Repository] Load Repositories Failure',

  GET_REPOSITORY_ROWS_REQUEST = '[Repository] Get Repository Rows Request',
  GET_REPOSITORY_ROWS_SUCCESS = '[Repository] Get Repository Rows Success',
  GET_REPOSITORY_ROWS_FAILURE = '[Repository] Get Repository Rows Failure',

  GET_REPOSITORY_REQUEST = '[Repository] Get Repository Request',
  GET_REPOSITORY_SUCCESS = '[Repository] Get Repository Success',
  GET_REPOSITORY_FAILURE = '[Repository] Get Repository Failure',

  CREATE_REPOSITORY_REQUEST = '[Repository] Create Repository Request',
  CREATE_REPOSITORY_SUCCESS = '[Repository] Create Repository Success',
  CREATE_REPOSITORY_FAILURE = '[Repository] Create Repository Failure',

  PERSIST_REPOSITORY_REQUEST = '[Repository] Persist Repository Request',
  PERSIST_REPOSITORY_SUCCESS = '[Repository] Persist Repository Success',
  PERSIST_REPOSITORY_FAILURE = '[Repository] Persist Repository Failure',

  MOVE_REPOSITORY_REQUEST = '[Repository] Move Repository Request',
  MOVE_REPOSITORY_SUCCESS = '[Repository] Move Repository Success',
  MOVE_REPOSITORY_FAILURE = '[Repository] Move Repository Failure',

  DELETE_REPOSITORY_REQUEST = '[Repository] Delete Repository Request',
  DELETE_REPOSITORY_SUCCESS = '[Repository] Delete Repository Success',
  DELETE_REPOSITORY_FAILURE = '[Repository] Delete Repository Failure',

  SHARE_REPOSITORY_REQUEST = '[Repository] Share Repository Request',
  SHARE_REPOSITORY_SUCCESS = '[Repository] Share Repository Success',
  SHARE_REPOSITORY_FAILURE = '[Repository] Share Repository Failure',

  DELETE_SHARE_REPOSITORY_REQUEST = '[Repository] Delete Share Repository Request',
  DELETE_SHARE_REPOSITORY_SUCCESS = '[Repository] Delete Share Repository Success',
  DELETE_SHARE_REPOSITORY_FAILURE = '[Repository] Delete Share Repository Failure',

  SET_NOTIFICATION_REQUEST = '[Repository] Set Notification Request',
  SET_NOTIFICATION_SUCCESS = '[Repository] Set Notification Success',
  SET_NOTIFICATION_FAILURE = '[Repository] Set Notification Failure',

  UPDATE_REPOSITORY = '[Repository] Update Repository',

  SET_REPOSITORY_ID = '[Repository] Set Repository Id',
  REMOVE_REPOSITORY_ID = '[Repository] Remove Repository Id',

  SET_REPOSITORY_SEARCH = '[Repository] Set Repository Search',
  REMOVE_REPOSITORY_SEARCH = '[Repository] Remove Repository Search',

  SET_REPOSITORY_SEARCH_ID = '[Repository] Set Repository Search Id',
  REMOVE_REPOSITORY_SEARCH_ID = '[Repository] Remove Repository Search Id',

  RESET_REPOSITORIES = '[Repository] Reset Repositories'
}

const loadRepositoriesRequest = createAction(
  RepositoryActionTypes.LOAD_REPOSITORIES_REQUEST,
  props<{ request: LoadRepositoriesRequest }>()
);

const loadRepositoriesSuccess = createAction(
  RepositoryActionTypes.LOAD_REPOSITORIES_SUCCESS,
  props<{ payload: RepositoryListPayload }>()
);

const loadRepositoriesFailure = createAction(
  RepositoryActionTypes.LOAD_REPOSITORIES_FAILURE,
  props<{ exception: Exception }>()
);

const getRepositoryRowsRequest = createAction(
  RepositoryActionTypes.GET_REPOSITORY_ROWS_REQUEST,
  props<{ request: GetRepositoryRowsRequest }>()
);

const getRepositoryRowsSuccess = createAction(
  RepositoryActionTypes.GET_REPOSITORY_ROWS_SUCCESS,
  props<{ payload: { rows: number } }>()
);

const getRepositoryRowsFailure = createAction(
  RepositoryActionTypes.GET_REPOSITORY_ROWS_FAILURE,
  props<{ exception: Exception }>()
);

const getRepositoryRequest = createAction(
  RepositoryActionTypes.GET_REPOSITORY_REQUEST,
  props<{ request: RepositoryItemRequest }>()
);

const getRepositorySuccess = createAction(
  RepositoryActionTypes.GET_REPOSITORY_SUCCESS,
  props<{ payload: RepositoryItemPayload }>()
);

const getRepositoryFailure = createAction(
  RepositoryActionTypes.GET_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);

const createRepositoryRequest = createAction(
  RepositoryActionTypes.CREATE_REPOSITORY_REQUEST,
  props<{ request: CreateRepositoryRequest }>()
);

const createRepositorySuccess = createAction(
  RepositoryActionTypes.CREATE_REPOSITORY_SUCCESS,
  props<{ payload: RepositoryItemPayload }>()
);

const createRepositoryFailure = createAction(
  RepositoryActionTypes.CREATE_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);


const persistRepositoryRequest = createAction(
  RepositoryActionTypes.PERSIST_REPOSITORY_REQUEST,
  props<{ repository: RepositoryEntity }>()
);

const persistRepositorySuccess = createAction(
  RepositoryActionTypes.PERSIST_REPOSITORY_SUCCESS,
  props<{ payload: RepositoryItemPayload }>()
);

const persistRepositoryFailure = createAction(
  RepositoryActionTypes.PERSIST_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);

const moveRepositoryRequest = createAction(
  RepositoryActionTypes.MOVE_REPOSITORY_REQUEST,
  props<{ repository: RepositoryEntity, destination: number }>()
);

const moveRepositorySuccess = createAction(
  RepositoryActionTypes.MOVE_REPOSITORY_SUCCESS,
  props<{ payload: RepositoryItemPayload }>()
);

const moveRepositoryFailure = createAction(
  RepositoryActionTypes.MOVE_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);

const deleteRepositoryRequest = createAction(
  RepositoryActionTypes.DELETE_REPOSITORY_REQUEST,
  props<{ repository: RepositoryEntity }>()
);

const deleteRepositorySuccess = createAction(
  RepositoryActionTypes.DELETE_REPOSITORY_SUCCESS,
  props<{ payload: RepositoryItemPayload }>()
);

const deleteRepositoryFailure = createAction(
  RepositoryActionTypes.DELETE_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);

const shareRepositoryRequest = createAction(
  RepositoryActionTypes.SHARE_REPOSITORY_REQUEST,
  props<{ repository: RepositoryEntity, target: string }>()
);

const shareRepositorySuccess = createAction(
  RepositoryActionTypes.SHARE_REPOSITORY_SUCCESS,
  props<{ update: Update<RepositoryEntity> }>()
);

const shareRepositoryFailure = createAction(
  RepositoryActionTypes.SHARE_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);

const deleteShareRepositoryRequest = createAction(
  RepositoryActionTypes.DELETE_SHARE_REPOSITORY_REQUEST,
  props<{ repository: RepositoryEntity, target: string }>()
);

const deleteShareRepositorySuccess = createAction(
  RepositoryActionTypes.DELETE_SHARE_REPOSITORY_SUCCESS,
  props<{ update: Update<RepositoryEntity> }>()
);

const deleteShareRepositoryFailure = createAction(
  RepositoryActionTypes.DELETE_SHARE_REPOSITORY_FAILURE,
  props<{ exception: Exception }>()
);

const setNotificationRequest = createAction(
  RepositoryActionTypes.SET_NOTIFICATION_REQUEST,
  props<{ repository: RepositoryEntity, notify: boolean }>()
);

const setNotificationSuccess = createAction(
  RepositoryActionTypes.SET_NOTIFICATION_SUCCESS,
  props<{ update: Update<RepositoryEntity> }>()
);

const setNotificationFailure = createAction(
  RepositoryActionTypes.SET_NOTIFICATION_FAILURE,
  props<{ exception: Exception }>()
);

const updateRepository = createAction(
  RepositoryActionTypes.UPDATE_REPOSITORY,
  props<{ update: Update<RepositoryEntity> }>()
);

const setRepositoryId = createAction(
  RepositoryActionTypes.SET_REPOSITORY_ID,
  props<{ repositoryId: number }>()
);

const removeRepositoryId = createAction(
  RepositoryActionTypes.REMOVE_REPOSITORY_ID,
);

const setRepositorySearch = createAction(
  RepositoryActionTypes.SET_REPOSITORY_SEARCH,
  props<{ search: string }>()
);

const removeRepositorySearch = createAction(
  RepositoryActionTypes.REMOVE_REPOSITORY_SEARCH,
);

const setRepositorySearchId = createAction(
  RepositoryActionTypes.SET_REPOSITORY_SEARCH_ID,
  props<{ id: number }>()
);

const removeRepositorySearchId = createAction(
  RepositoryActionTypes.REMOVE_REPOSITORY_SEARCH_ID,
);

const resetRepositories = createAction(
  RepositoryActionTypes.RESET_REPOSITORIES
);

export const RepositoryActions = {
  loadRepositoriesRequest,
  loadRepositoriesSuccess,
  loadRepositoriesFailure,
  getRepositoryRowsRequest,
  getRepositoryRowsSuccess,
  getRepositoryRowsFailure,
  getRepositoryRequest,
  getRepositorySuccess,
  getRepositoryFailure,
  createRepositoryRequest,
  createRepositorySuccess,
  createRepositoryFailure,
  persistRepositoryRequest,
  persistRepositorySuccess,
  persistRepositoryFailure,
  moveRepositoryRequest,
  moveRepositorySuccess,
  moveRepositoryFailure,
  deleteRepositoryRequest,
  deleteRepositorySuccess,
  deleteRepositoryFailure,
  shareRepositoryRequest,
  shareRepositorySuccess,
  shareRepositoryFailure,
  deleteShareRepositoryRequest,
  deleteShareRepositorySuccess,
  deleteShareRepositoryFailure,
  setNotificationRequest,
  setNotificationSuccess,
  setNotificationFailure,
  updateRepository,
  setRepositoryId,
  removeRepositoryId,
  setRepositorySearch,
  removeRepositorySearch,
  setRepositorySearchId,
  removeRepositorySearchId,
  resetRepositories,
};
