import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  AttachmentEntity,
  AttachmentItemPayload,
  AttachmentItemResponse,
  AttachmentListPayload,
  CreateAttachmentRequest,
  GetAttachmentRequest,
  LoadAttachmentsRequest,
  RemoveRequest
} from './attachment.models';
import {map, mapTo} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../../../../../../environments';

@Injectable({
  providedIn: 'root'
})
export class AttachmentDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) { }

  load(request: LoadAttachmentsRequest): Observable<AttachmentListPayload> {

    let targetId: number;
    let url: string;

    if (request.documentId) {
      url = `${environment.api}/repositories/${request.repositoryId}/materials/${request.materialId}/documents/${request.documentId}`;
      targetId = request.documentId;
    } else {
      url = `${environment.api}/repositories/${request.repositoryId}/materials/${request.materialId}`;
      targetId = request.materialId;
    }

    return this.http.get<any>(url).pipe(
      map(res => res.data.attachments.map(attachment => ({...attachment, targetId: Number(targetId)}))),
      map((items) => ({items}))
    );
  }

  get(request: GetAttachmentRequest): Observable<AttachmentItemPayload> {
    return this.http.get<AttachmentItemResponse>(`${environment.api}/media/${request.id}`).pipe(
      map(res => ({item: res.data}))
    );
  }

  create(request: CreateAttachmentRequest): Observable<AttachmentItemPayload> {
    return this.http.put<AttachmentItemResponse>(`${environment.api}/media`, request).pipe(
      map(res => ({item: res.data}))
    );
  }

  update(attachment: AttachmentEntity): Observable<AttachmentItemPayload> {
    return this.http.post<AttachmentItemResponse>(`${environment.api}/media/${attachment.id}`, attachment).pipe(
      map(res => ({item: res.data}))
    );
  }

  remove(request: RemoveRequest): Observable<RemoveRequest> {
    return this.http.delete(`${environment.api}/media/${request.id}`).pipe(
      mapTo(request)
    );
  }

  private convertToBase64(file: any): Observable<any> {
    return new Observable<any>(observer => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (file.type.includes('image')) {
          this.compress(String(reader.result), environment.image_uploads.compressFactor)
            .then(result => observer.next(result.split(',')[1]));
        } else {
          observer.next(String((reader.result as string).split(',')[1]));
        }
      };
    });
  }

  private compress(src: string, compressFactor: number): Promise<any> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        const newWidth = img.width;
        const newHeight = img.height;
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL('image/jpeg', compressFactor);
        res(data);
      };
      img.onerror = error => rej(error);
    });
  }
}
