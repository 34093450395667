import {createAction, props} from '@ngrx/store';
import {TrainingListPayload} from './training.models';
import {Exception} from '../../../../../../core/models';


enum TrainingActionTypes {
  LOAD_TRAININGS_REQUEST = '[Training] Load Trainings Request',
  LOAD_TRAININGS_SUCCESS = '[Training] Load Trainings Success',
  LOAD_TRAININGS_FAILURE = '[Training] Load Trainings Failure',
}

const loadTrainingsRequest = createAction(
  TrainingActionTypes.LOAD_TRAININGS_REQUEST,
);

const loadTrainingsSuccess = createAction(
  TrainingActionTypes.LOAD_TRAININGS_SUCCESS,
  props<{ payload: TrainingListPayload }>()
);

const loadTrainingsFailure = createAction(
  TrainingActionTypes.LOAD_TRAININGS_FAILURE,
  props<{ exception: Exception }>()
);

export const TrainingActions = {
  loadTrainingsRequest,
  loadTrainingsSuccess,
  loadTrainingsFailure,
};
