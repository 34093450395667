<h2 mat-dialog-title>{{'dialogs.signature.title' | translate}}</h2>
<mat-dialog-content>
  <div #container (window:resize)="resizeCanvas()" class="hb-signature" style="width: 100%; height: 20vw">
    <canvas #canvasElement></canvas>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="acceptSignature()" color="primary" mat-button>{{'keys.local.global.ok' | translate}}</button>
  <button (click)="clearSignature()" mat-button>{{'keys.local.global.clear' | translate}}</button>
  <button mat-button mat-dialog-close>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
