import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import * as fromCountry from './country.reducer';
import {NGXLogger} from 'ngx-logger';
import {CountrySelectors} from './country.selectors';
import {CountryActions} from './country.actions';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  public readonly isLoading$ = this.store.pipe(select(CountrySelectors.isLoading));
  public readonly exception$ = this.store.pipe(select(CountrySelectors.getException));
  public readonly items$ = this.store.pipe(select(CountrySelectors.getItems));

  constructor(private store: Store<fromCountry.CountryPartialState>, logger: NGXLogger) { }

  public load() {
    this.store.dispatch(CountryActions.loadCountriesRequest());
  }
}
