import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {select, Store} from '@ngrx/store';
import {SearchEntity, searchEntityFactory} from './models/entity/search.models';
import {Actions, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import * as fromSearch from './search.reducer';
import {SearchSelectors} from './search.selectors';
import {SearchActions} from './search.actions';
import {CreatePayload} from './models/create/create-payload.models';
import {Identity} from '../../../../../../core';
import {filter, map, take} from 'rxjs/operators';
import {UpdatePayload} from './models/update/update-payload.models';
import {DeletePayload} from './models/delete/delete-payload.models';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  items$ = this.store.pipe(select(SearchSelectors.getItems));
  isLoading$ = this.store.pipe(select(SearchSelectors.isLoading));
  exception$ = this.store.pipe(select(SearchSelectors.getException));
  rows$ = this.store.pipe(select(SearchSelectors.getLength));
  isActive$ = this.store.pipe(select(SearchSelectors.isActive));

  constructor(private store: Store<fromSearch.SearchPartialState>, private actions$: Actions, private logger: NGXLogger) {
  }

  public getById(id: number): Observable<SearchEntity> {
    return this.store.pipe(select(SearchSelectors.getById, id));
  }

  public load(type?: string): Observable<CreatePayload> {
    const identity = new Identity();
    const queryParams = {type};
    this.store.dispatch(SearchActions.loadSearchRequest({request: {queryParams}, identity}));
    return this.actions$.pipe(
      ofType(SearchActions.createSearchSuccess),
      filter(action => action.identity === identity),
      take(1),
      map(action => action.payload)
    );
  }

  public get(id: number): Observable<CreatePayload> {
    const identity = new Identity();
    const params = {id};
    this.store.dispatch(SearchActions.getSearchRequest({request: {params}, identity}));
    return this.actions$.pipe(
      ofType(SearchActions.createSearchSuccess),
      filter(action => action.identity === identity),
      take(1),
      map(action => action.payload)
    );
  }

  public create(search?: string): Observable<CreatePayload> {
    const identity = new Identity();
    const body = searchEntityFactory(0, Date.now(), search, 0);
    this.store.dispatch(SearchActions.createSearchRequest({request: {body}, identity}));
    return this.actions$.pipe(
      ofType(SearchActions.createSearchSuccess),
      filter(action => action.identity === identity),
      take(1),
      map(action => action.payload)
    );
  }

  public update(id: number, entity: SearchEntity): Observable<UpdatePayload> {
    const identity = new Identity();
    const body = entity;
    const params = {id};
    this.store.dispatch(SearchActions.updateSearchRequest({request: {body, params}, identity}));
    return this.actions$.pipe(
      ofType(SearchActions.updateSearchSuccess),
      filter(action => action.identity === identity),
      take(1),
      map(action => action.payload)
    );
  }

  public remove(id: number): Observable<DeletePayload> {
    const identity = new Identity();
    const params = {id};
    this.store.dispatch(SearchActions.removeSearchRequest({request: {params}, identity}));
    return this.actions$.pipe(
      ofType(SearchActions.removeSearchSuccess),
      filter(action => action.identity === identity),
      take(1),
      map(action => action.payload)
    );
  }
}
