<h2 mat-dialog-title>{{'dialogs.create_product.title' | translate}}</h2>
<mat-dialog-content>
  <form [formGroup]="productForm" fxLayout="column nowrap">
    <mat-accordion class="border">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon color="primary" fontIcon="fa-box" fontSet="fas"></mat-icon>
            {{'groups.product.title' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon *ngIf="!this.productForm.valid && this.productForm.touched" class="hb-cropper-icon" color="warn"
                      fontIcon="fa-exclamation-triangle"
                      fontSet="fas"></mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column nowrap">
          <mat-form-field appearance="outline">
            <mat-label>{{'forms.fields.name.label' | translate}}</mat-label>
            <input [placeholder]="'forms.fields.name.placeholder' | translate" formControlName="title" matInput required>
            <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
            <mat-error *ngIf="check(productForm, 'title', 'required')">
              {{'forms.errors.is_required' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'forms.fields.articleNumber.label' | translate}}</mat-label>
            <input [placeholder]="'forms.fields.articleNumber.placeholder' | translate" formControlName="articleNumber"
                   matInput
                   required>
            <mat-hint>{{'forms.fields.articleNumber.hint' | translate}}</mat-hint>
            <mat-error *ngIf="check(productForm, 'articleNumber', 'required')">
              {{'forms.errors.is_required' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{'forms.fields.ean.label' | translate}}</mat-label>
            <input [placeholder]="'forms.fields.ean.placeholder' | translate" formControlName="ean" matInput>
            <mat-hint>{{'forms.fields.ean.hint' | translate}}</mat-hint>
            <mat-error *ngIf="check(productForm, 'ean', 'pattern')">
              {{'forms.errors.only_digits' | translate}}
            </mat-error>
            <mat-error *ngIf="check(productForm, 'ean', 'maxlength')">
              {{'forms.errors.max_length' | translate}}
            </mat-error>
          </mat-form-field>
          <ng-container formGroupName="manufacturer">
            <mat-form-field appearance="outline">
              <mat-label>{{'forms.fields.manufacturer.label' | translate}}</mat-label>
              <mat-select #select_manufacturer [placeholder]="'forms.fields.manufacturer.placeholder' | translate"
                          [typeaheadDebounceInterval]="200"
                          [value]="null" formControlName="id" required>
                <mat-option value="0">{{'forms.fields.manufacturer.options.add' | translate}}</mat-option>
                <mat-option *ngFor="let manufacturer of manufacturers$ | async"
                            [value]="manufacturer.id">{{manufacturer.caption}}</mat-option>
              </mat-select>
              <mat-hint>{{'forms.fields.manufacturer.hint' | translate}}</mat-hint>
            </mat-form-field>
          </ng-container>

          <ng-container formArrayName="categories">
            <ng-container formGroupName="0">
              <mat-form-field appearance="outline">
                <mat-label>{{'forms.fields.category.label' | translate}}</mat-label>
                <mat-select #select_category [placeholder]="'forms.fields.category.placeholder' | translate"
                            [typeaheadDebounceInterval]="200"
                            [value]="null" formControlName="id" required>
                  <mat-option value="0">{{'forms.fields.category.options.add' | translate}}</mat-option>
                  <mat-option *ngFor="let category of categories$ | async"
                              [value]="category.id">{{category.title}}</mat-option>
                </mat-select>
                <mat-hint>{{'forms.fields.category.hint' | translate}}</mat-hint>
              </mat-form-field>
            </ng-container>

            <ng-container formGroupName="1">
              <mat-form-field appearance="outline">
                <mat-label>{{'forms.fields.detail_category.label' | translate}}</mat-label>
                <mat-select #select_detailCategory
                            [placeholder]="'forms.fields.detail_category.placeholder' | translate"
                            [typeaheadDebounceInterval]="200"
                            [value]="null" formControlName="id">
                  <mat-option [value]="null">{{'forms.fields.detail_category.options.empty' | translate}}</mat-option>
                  <mat-option value="0">{{'forms.fields.detail_category.options.add' | translate}}</mat-option>
                  <mat-option *ngFor="let detailCategory of detailCategories$ | async"
                              [value]="detailCategory.id">{{detailCategory.title}}</mat-option>
                </mat-select>
                <mat-hint>{{'forms.fields.detail_category.hint' | translate}}</mat-hint>
              </mat-form-field>
            </ng-container>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="select_manufacturer.value === '0'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon color="primary" fontIcon="fa-industry" fontSet="fas"></mat-icon>
            {{'groups.manufacturer.title' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon
              *ngIf="!this.productForm.get('manufacturer').valid && this.productForm.get('manufacturer').touched"
              class="hb-cropper-icon" color="warn"
              fontIcon="fa-exclamation-triangle"
              fontSet="fas"></mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div fxLayout="column nowrap">
          <ng-container formGroupName="manufacturer">

            <mat-form-field appearance="outline">
              <mat-label>{{'forms.fields.name.label' | translate}}</mat-label>
              <input [placeholder]="'forms.fields.name.placeholder' | translate" formControlName="caption" matInput>
              <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
              <mat-error *ngIf="check(productForm.get('manufacturer'), 'caption', 'required')">
                {{'forms.errors.is_required' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="select_category.value === '0'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon color="primary" fontIcon="fa-tag" fontSet="fas"></mat-icon>
            {{'groups.category.title' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon
              *ngIf="!productForm.get(['categories', 0]).valid && productForm.get(['categories', 0]).touched"
              class="hb-cropper-icon" color="warn"
              fontIcon="fa-exclamation-triangle"
              fontSet="fas"></mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column nowrap">
          <ng-container [formGroup]="productForm.get(['categories', 0])">
            <mat-form-field appearance="outline">
              <mat-label>{{'forms.fields.name.label' | translate}}</mat-label>
              <input [placeholder]="'forms.fields.name.placeholder' | translate" formControlName="title" matInput>
              <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
              <mat-error *ngIf="check(productForm.get(['categories', 0]), 'title', 'required')">
                {{'forms.errors.is_required' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="select_detailCategory.value === '0'">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon color="primary" fontIcon="fa-tag" fontSet="fas"></mat-icon>
            {{'groups.detail_category.title' | translate}}
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon
              *ngIf="!productForm.get(['categories', 1]).valid && productForm.get(['categories', 1]).touched"
              class="hb-cropper-icon" color="warn"
              fontIcon="fa-exclamation-triangle"
              fontSet="fas"></mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div fxLayout="column nowrap">
          <ng-container [formGroup]="productForm.get(['categories', 1])">
            <mat-form-field appearance="outline">
              <mat-label>{{'forms.fields.name.label' | translate}}</mat-label>
              <input [placeholder]="'forms.fields.name.placeholder' | translate" formControlName="title" matInput>
              <mat-hint>{{'forms.fields.name.hint' | translate}}</mat-hint>
              <mat-error *ngIf="check(productForm.get(['categories', 1]), 'title', 'required')">
                {{'forms.errors.is_required' | translate}}
              </mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button (click)="commit()" [disabled]="!productForm.valid" cdkfocusinitial color="accent"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
