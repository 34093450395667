import {createAction, props} from '@ngrx/store';
import {DetailCategoryListPayload} from './detail-category.models';
import {Exception} from '../../../../../../core/models';

enum DetailCategoryActionTypes {
  LOAD_CATEGORY_REQUEST = '[DetailCategory] Load DetailCategory Request',
  LOAD_CATEGORY_SUCCESS = '[DetailCategory] Load DetailCategory Success',
  LOAD_CATEGORY_FAILURE = '[DetailCategory] Load DetailCategory Failure',
}

const loadCategoriesRequest = createAction(
  DetailCategoryActionTypes.LOAD_CATEGORY_REQUEST
);

const loadCategoriesSuccess = createAction(
  DetailCategoryActionTypes.LOAD_CATEGORY_SUCCESS,
  props<{ payload: DetailCategoryListPayload }>()
);

const loadCategoriesFailure = createAction(
  DetailCategoryActionTypes.LOAD_CATEGORY_FAILURE,
  props<{ exception: Exception }>()
);

export const DetailCategoryActions = {
  loadCategoriesRequest,
  loadCategoriesSuccess,
  loadCategoriesFailure,
};
