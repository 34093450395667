import {Component, OnInit} from '@angular/core';
import {SearchDialogService} from '../../../dialogs/search/src/search-dialog.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'hb-search-dialog-entry',
  template: '',
})
export class SearchDialogEntryComponent implements OnInit {

  constructor(private searchDialogService: SearchDialogService, private router: Router, private route: ActivatedRoute) {
    this.searchDialogService.open().afterClosed().subscribe(() => {
      this.router.navigate(['../'], {relativeTo: this.route});
    });
  }

  ngOnInit(): void {
  }

}
