import {Injectable} from '@angular/core';
import {SHOW_INACTIVE} from './app.reducer';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  constructor() {
  }

  setShowInactive(showInactive: boolean) {
    localStorage.setItem(SHOW_INACTIVE, String(showInactive));
  }
}
