import {createAction, props} from '@ngrx/store';
import {
  AttachmentEntity,
  AttachmentItemPayload,
  AttachmentListPayload,
  CreateAttachmentRequest,
  GetAttachmentRequest,
  LoadAttachmentsRequest,
  RemovePayload,
  RemoveRequest
} from './attachment.models';
import {Exception} from '../../../../../../core/models';


export enum AttachmentActionTypes {
  LOAD_ATTACHMENTS_REQUEST = '[Attachment] Load Attachments Request',
  LOAD_ATTACHMENTS_SUCCESS = '[Attachment] Load Attachments Success',
  LOAD_ATTACHMENTS_FAILURE = '[Attachment] Load Attachments Failure',

  GET_ATTACHMENT_REQUEST = '[Attachment] Get Attachment Request',
  GET_ATTACHMENT_SUCCESS = '[Attachment] Get Attachment Success',
  GET_ATTACHMENT_FAILURE = '[Attachment] Get Attachment Failure',

  CREATE_ATTACHMENT_REQUEST = '[Attachment] Create Attachment Request',
  CREATE_ATTACHMENT_SUCCESS = '[Attachment] Create Attachment Success',
  CREATE_ATTACHMENT_FAILURE = '[Attachment] Create Attachment Failure',

  ADD_ATTACHMENT_REQUEST = '[Attachment] Add Attachment Request',
  ADD_ATTACHMENT_SUCCESS = '[Attachment] Add Attachment Success',
  ADD_ATTACHMENT_FAILURE = '[Attachment] Add Attachment Failure',

  UPDATE_ATTACHMENT_REQUEST = '[Attachment] Update Attachment Request',
  UPDATE_ATTACHMENT_SUCCESS = '[Attachment] Update Attachment Success',
  UPDATE_ATTACHMENT_FAILURE = '[Attachment] Update Attachment Failure',

  REMOVE_ATTACHMENT_REQUEST = '[Attachment] Remove Attachment Request',
  REMOVE_ATTACHMENT_SUCCESS = '[Attachment] Remove Attachment Success',
  REMOVE_ATTACHMENT_FAILURE = '[Attachment] Remove Attachment Failure',
}

export const loadAttachmentsRequest = createAction(
  AttachmentActionTypes.LOAD_ATTACHMENTS_REQUEST,
  props<{ request: LoadAttachmentsRequest }>()
);

export const loadAttachmentsSuccess = createAction(
  AttachmentActionTypes.LOAD_ATTACHMENTS_SUCCESS,
  props<{ payload: AttachmentListPayload }>()
);

export const loadAttachmentsFailure = createAction(
  AttachmentActionTypes.LOAD_ATTACHMENTS_FAILURE,
  props<{ exception: Exception }>()
);

export const getAttachmentRequest = createAction(
  AttachmentActionTypes.GET_ATTACHMENT_REQUEST,
  props<{ request: GetAttachmentRequest }>()
);

export const getAttachmentSuccess = createAction(
  AttachmentActionTypes.GET_ATTACHMENT_SUCCESS,
  props<{ payload: AttachmentItemPayload }>()
);

export const getAttachmentFailure = createAction(
  AttachmentActionTypes.GET_ATTACHMENT_FAILURE,
  props<{ exception: Exception }>()
);

export const createAttachmentRequest = createAction(
  AttachmentActionTypes.CREATE_ATTACHMENT_REQUEST,
  props<{ request: CreateAttachmentRequest }>()
);

export const createAttachmentSuccess = createAction(
  AttachmentActionTypes.CREATE_ATTACHMENT_SUCCESS,
  props<{ payload: AttachmentItemPayload }>()
);

export const createAttachmentFailure = createAction(
  AttachmentActionTypes.CREATE_ATTACHMENT_FAILURE,
  props<{ exception: Exception }>()
);

export const updateAttachmentRequest = createAction(
  AttachmentActionTypes.UPDATE_ATTACHMENT_REQUEST,
  props<{ entity: AttachmentEntity }>()
);

export const updateAttachmentSuccess = createAction(
  AttachmentActionTypes.UPDATE_ATTACHMENT_SUCCESS,
  props<{ payload: AttachmentItemPayload }>()
);

export const updateAttachmentFailure = createAction(
  AttachmentActionTypes.UPDATE_ATTACHMENT_FAILURE,
  props<{ exception: Exception }>()
);

export const removeAttachmentRequest = createAction(
  AttachmentActionTypes.REMOVE_ATTACHMENT_REQUEST,
  props<{ request: RemoveRequest }>()
);

export const removeAttachmentSuccess = createAction(
  AttachmentActionTypes.REMOVE_ATTACHMENT_SUCCESS,
  props<{ payload: RemovePayload }>()
);

export const removeAttachmentFailure = createAction(
  AttachmentActionTypes.REMOVE_ATTACHMENT_FAILURE,
  props<{ exception: Exception }>()
);

export const AttachmentActions = {
  loadAttachmentsRequest,
  loadAttachmentsSuccess,
  loadAttachmentsFailure,
  getAttachmentRequest,
  getAttachmentSuccess,
  getAttachmentFailure,
  createAttachmentRequest,
  createAttachmentSuccess,
  createAttachmentFailure,
  updateAttachmentRequest,
  updateAttachmentSuccess,
  updateAttachmentFailure,
  removeAttachmentRequest,
  removeAttachmentSuccess,
  removeAttachmentFailure,
};
