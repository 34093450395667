import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'hb-card-accordion',
  templateUrl: './card-accordion.component.html',
  styleUrls: ['./card-accordion.component.scss']
})
export class CardAccordionComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
