import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {TermsOfUseComponent} from './terms-of-use.component';

@Injectable({
  providedIn: 'root'
})
export class TermsOfUseDialogService extends BaseDialogService<TermsOfUseComponent, any> {

  constructor(protected matDialog: MatDialog) {
    super(matDialog, TermsOfUseComponent);
  }
}
