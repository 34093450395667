import {Action, createReducer, on} from '@ngrx/store';
import {AccountEntity} from './account.models';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {Exception} from '../../../../../../core/models';
import {AccountActions} from './account.actions';
import {AuthenticationActions} from '../../../authentication-store/src/+state/authentication.actions';

export const ACCOUNT_FEATURE_KEY = 'account';

export const ACCOUNT_ID = 'ACCOUNT_ID';

export interface AccountState extends EntityState<AccountEntity> {
  isLoading: boolean;
  exception: Exception;
  accountId: number;
}

export interface AccountPartialState {
  readonly [ACCOUNT_FEATURE_KEY]: AccountState;
}

export const accountAdapter: EntityAdapter<AccountEntity> = createEntityAdapter<AccountEntity>();

export const initialState: AccountState = accountAdapter.getInitialState({
  isLoading: false,
  exception: null,
  accountId: Number(localStorage.getItem(ACCOUNT_ID)) || null,
});


export const accountReducer = createReducer(
  initialState,
  on(AccountActions.loadAccountsRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AccountActions.loadAccountsSuccess, (state, {payload}) =>
    accountAdapter.upsertMany(payload.items, {
      ...state,
      isLoading: false,
    })),
  on(AccountActions.loadAccountsFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AccountActions.getAccountRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AccountActions.getAccountSuccess, (state, {payload}) =>
    accountAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(AccountActions.getAccountFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AccountActions.setAccountId, (state, {accountId}) => ({
    ...state,
    accountId
  })),
  on(AccountActions.persistAccountRequest, state => ({
    ...state,
    isLoading: true,
  })),
  on(AccountActions.persistAccountSuccess, (state, {payload}) =>
    accountAdapter.upsertOne(payload.item, {
      ...state,
      isLoading: false,
    })),
  on(AccountActions.persistAccountFailure, (state, {exception}) => ({
    ...state,
    isLoading: false,
    exception
  })),
  on(AccountActions.updateAccount, (state, {update}) =>
    accountAdapter.updateOne(update, {
      ...state
    })),
  on(AuthenticationActions.clearSession, () => initialState),
);

export function reducer(state: AccountState | undefined, action: Action) {
  return accountReducer(state, action);
}
