import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AttachmentService, AttachmentViewModel} from '../../../modules/store';


@Component({
  selector: 'hb-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  public readonly value: AttachmentViewModel;

  constructor(private attachmentService: AttachmentService, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.value = data.value;
  }

  ngOnInit(): void {
  }

  getImage() {
    return this.attachmentService.url(this.value.id);
  }

}
