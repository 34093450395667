import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {SearchComponent} from './search.component';

@Injectable({
  providedIn: 'root'
})
export class SearchDialogService extends BaseDialogService<SearchComponent, any> {

  protected config: MatDialogConfig = {
    width: '80vw',
    maxHeight: '-webkit-fill-available'
  };

  constructor(protected matDialog: MatDialog) {
    super(matDialog, SearchComponent);
  }
}
