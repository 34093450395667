export function objectFilter(obj: any, properties: string[], filter: string) {
  return properties.some(property => {
    if (!obj[property]) {
      return false;
    } else {
      return obj[property].toString().toLowerCase().includes(filter.toLowerCase());
    }
  });
}

export function filterTreeById(items: any[], idNameProperty: string, parentIdProperty: string, startId: number) {
  const ids = new Array<number>();
  let lastLength = ids.length;

  ids.push(startId);

  while (ids.length > lastLength) {
    items.filter(item => ids.includes(item[parentIdProperty]))
      .map(item => item[idNameProperty])
      .forEach(item => ids.push(item));
    lastLength = ids.length;
  }

  return ids;
}
