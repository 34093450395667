import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TrainingDataService} from './training-data.service';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {TranslateService} from '@ngx-translate/core';
import {Exception} from '../../../../../../core/models';
import {TrainingActions} from './training.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';


@Injectable()
export class TrainingEffects {

  private loadTrainingsRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(TrainingActions.loadTrainingsRequest),

      switchMap(action => this.trainingService.load().pipe(
        map(payload => TrainingActions.loadTrainingsSuccess({payload})),
        catchError(error => of(TrainingActions.loadTrainingsFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  private loadTrainingsSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(TrainingActions.loadTrainingsSuccess),
    ), {dispatch: false}
  );

  private loadTrainingsFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(TrainingActions.loadTrainingsFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  constructor(private actions$: Actions,
              private trainingService: TrainingDataService,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private translateService: TranslateService,
              private logger: NGXLogger) {

  }
}
