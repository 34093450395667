<h2 mat-dialog-title>{{'dialogs.edit_email.title' | translate}}</h2>
<mat-dialog-content>
  <form #ngForm="ngForm" ngForm fxLayout="column nowrap">
    <mat-form-field appearance="outline">
      <mat-label>
        <label for="email">{{'forms.fields.email.label' | translate}}</label>
      </mat-label>
      <input #email="ngModel"
             [(ngModel)]="viewModel.email"
             [placeholder]="'forms.fields.email.placeholder' | translate"
             id="email"
             matInput
             name="email"
             required>
      <mat-hint>{{'forms.fields.email.hint' | translate}}</mat-hint>
      <mat-error *ngIf="email.dirty && email.touched">
        <ng-container *ngIf="email.hasError('required')">
          {{'forms.errors.is_required' | translate}}
        </ng-container>
        <ng-container *ngIf="email.hasError('email')">
          {{'forms.errors.invalid_email' | translate}}
        </ng-container>
      </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions fxLayout="row nowrap" fxLayoutAlign="end center">
  <button [disabled]="ngForm.invalid" [mat-dialog-close]="viewModel" color="accent"
          mat-raised-button>{{'keys.local.global.ok' | translate}}</button>
  <button mat-dialog-close mat-raised-button>{{'keys.local.global.cancel' | translate}}</button>
</mat-dialog-actions>
