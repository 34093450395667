export const core = {
  production: false,
  staging: false,
  updateInterval: 60000,
  installSleepDays: 30,
  configuration: 'nothing',
  api: '/api',
  i18n: '/assets/i18n',
  mock: '/mock',
  home: '/app/home',
  serviceNumber: '',
  contactEmail: '',
  privacyLink: null,
  imprintLink: null,
  placeholders: {
    product: '/assets/images/homebase_placeholder_material_image.png'
  },
  style: {
    theme: 'homebase'
  },
  config: {
    dialog: {
      minWidth: '20vw',
      maxWidth: '80vw'
    },
    message: {}
  },
  image_uploads: {
    compressFactor: 0.8
  },
  maintenance: false,
  hasPaymentFeatures: false,
  defaultPageSize: 25,
  encode: true,
  queryParamsNames: {
    formId: 'formid',
    parentMaterialId: 'parentmaterialid',
    productId: 'productId',
    parent: 'parent',
    offset: 'offset',
    length: 'length',
    filter: 'filter',
    archive: 'showinactive',
    rowsOnly: 'rowsOnly',
    parentRepo: 'parentRepo',
    email: 'email',
    createPDF: 'createpdf',
    pdfIncludeAttachments: 'pdfincludeattachments',
    inspectionPending: 'inspectionpending',
    role: 'role',
    type: 'type',
  },
  regex: {
    password_pattern: new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()–[{}\]:;',?\/*~$^+=<>]).{8,128}$/)
  }
};
