import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {filter, map} from 'rxjs/operators';
import {PathService, RepositoryService, UserService, UserViewModel} from '../../../modules/store';

@Injectable({
  providedIn: 'root'
})
export class UsersViewDataSource {

  constructor(private userService: UserService,
              private repositoryService: RepositoryService,
              private pathService: PathService) {
  }

  private _sharedPath: boolean;

  get isLoading$() {
    return this.userService.isLoading$;
  }

  get items$() {
    return this.userService.items$.pipe(
      filter(items => !!items)
    );
  }

  get rows$(): Observable<number> {
    return this.userService.rows$;
  }

  get path$() {
    return this.pathService.items$;
  }

  get sharedPath() {
    return this._sharedPath;
  }

  load(repositoryId: number, page: number, archive: boolean = false, search?: string) {
    this.pathService.load({repositoryId, type: 'REPOSITORY'});
    this.userService.load({repositoryId, page, archive, search});

    this.repositoryService.get(repositoryId).pipe(
      map(payload => payload.item.sharedPath),
    ).subscribe(sharedPath => this._sharedPath = sharedPath);

  }

  create(repositoryId: number) {
    return this.userService.create({repositoryId});
  }

  persist(vm: UserViewModel) {
    return this.userService.persist({entity: vm.updated});
  }
}
