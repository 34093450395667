import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {ApprovalViewModel, RouterService} from '../../../modules/store';

@Component({
  selector: 'hb-approval-item-view',
  templateUrl: './approval-item-view.component.html',
  styleUrls: ['./approval-item-view.component.css']
})
export class ApprovalItemViewComponent implements OnInit, OnDestroy {

  constructor(private routerService: RouterService,
              private logger: NGXLogger) {

  }

  private _viewModel: ApprovalViewModel;

  @Input()
  get viewModel(): ApprovalViewModel {
    return this._viewModel;
  }

  set viewModel(value: ApprovalViewModel) {
    this._viewModel = value;
  }

  @Input()
  public disabled = false;

  ngOnInit(): void {

  }

  delete($event) {
    $event.stopImmediatePropagation();
  }

  ngOnDestroy(): void {
    this.logger.trace('DESTROY: ', this);
  }
}
