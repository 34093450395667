import {Component, ContentChild, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {items, list, listAnimation} from './list.animation';
import {NGXLogger} from 'ngx-logger';
import {environment} from '../../../../environments';
import {AppService} from '../../../modules/store';

interface PageInfo {
  start: number;
  end: number;
  page: number;
  index: number;
  endIndex: number;
  length: number;
}


@Component({
  selector: 'hb-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [listAnimation, list, items]
})
export class ListComponent implements OnInit {

  @HostBinding('class.empty-background')
  get isEmpty() {
    return this.items?.length === 0;
  }

  public pageInfo = {
    start: 0,
    end: 0,
    page: 0,
    index: 0,
    length: 0
  } as PageInfo;

  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  @ContentChild('item')
  itemTemplate: TemplateRef<any>;

  @Input()
  public items: any[];

  @Input()
  public rows: number;

  @Input()
  public itemSize = 0;

  @Input()
  public virtualHeight = '0px';

  @Input()
  public hasParent = false;

  doReload = new BehaviorSubject(false);

  @Output()
  lengthChange = new EventEmitter();

  @Output()
  vsEnd = new EventEmitter<PageInfo>();

  @Output()
  vsStart = new EventEmitter<PageInfo>();

  @Output()
  vsPageChange = new EventEmitter<PageInfo>();

  public isScrolled = new BehaviorSubject<boolean>(false);

  constructor(private appService: AppService, private logger: NGXLogger) {
    this.appService.setScrolled(false);
  }

  public scrolling(index): void {

    const endIndex = Math.ceil(this.viewport.getViewportSize() / this.itemSize) + 1 + index;
    const pageInfo = {
      start: this.viewport.getRenderedRange().start,
      end: this.viewport.getRenderedRange().end,
      length: this.viewport.getDataLength(),
      index,
      endIndex,
      page: Math.floor(endIndex / environment.defaultPageSize)
    };

    this.appService.setScrolled(index > 0);

    if (pageInfo.end === pageInfo.length && pageInfo.length === pageInfo.endIndex) {
      this.vsEnd.emit(this.pageInfo);
    } else if (pageInfo.start === pageInfo.index) {
      this.vsStart.emit(this.pageInfo);
    }

    if (this.pageInfo.page !== pageInfo.page) {
      this.vsPageChange.next(pageInfo);
    }

    this.pageInfo = pageInfo;
  }

  trackById(i) {
    return i;
  }

  ngOnInit(): void {

  }
}
