import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private config: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: 3000,
  };

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService) {
  }

  public open(message: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    action = action ? action : this.translateService.instant('keys.local.global.close');
    return this.snackBar.open(message, action, this.config);
  }

  public openV2(key: string, action?: string): MatSnackBarRef<SimpleSnackBar> {
    return this.open(this.translateService.instant(key), action);
  }

}
