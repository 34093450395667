import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {filter, first, flatMap, take, takeWhile, tap} from 'rxjs/operators';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {concat, interval, timer} from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {PromptComponent} from '../../../component/prompt/src/prompt.component';
import {NGXLogger} from 'ngx-logger';
import {MessageService} from '../../../../../dialogs/message';
import {environment} from '@hb/environments/index';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {isNullOrUndefined} from '../../../utils';


@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private standalone = 'standalone';

  private promptEvent: any;
  private isPromptActive: boolean;
  private isInStandaloneMode: boolean;

  constructor(
    private messageService: MessageService,
    private appRef: ApplicationRef,
    private swUpdate: SwUpdate,
    private bottomSheet: MatBottomSheet,
    private platform: Platform,
    private logger: NGXLogger
  ) {
    this.initPwaPrompt();

    if (swUpdate.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const everySixHours$ = interval(1000 * 20); // interval(6 * 60 * 60 * 1000);
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);
      everySixHoursOnceAppIsStable$.pipe(
      ).subscribe(() => swUpdate.checkForUpdate());

      swUpdate.available.subscribe(event => {
        this.logger.info('current version is', event.current);
        this.logger.info('available version is', event.available);
      });
      swUpdate.activated.subscribe(event => {
        this.logger.info('old version was', event.previous);
        this.logger.info('new version is', event.current);
      });

      swUpdate.available.pipe(
        flatMap(event => this.openPromptComponent('update')),
        filter(result => !!result),
      ).subscribe(event => swUpdate.activateUpdate()
        .then(() => window.location.reload())
        .finally(() => this.messageService.openV2(marker('keys.local.messages.app_successful_updated'))));
    }
  }

  public initPwaPrompt() {

    const timeOut = localStorage.getItem('INSTALL_TIMEOUT');

    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        if (isNullOrUndefined(timeOut) || (!!timeOut && Date.parse(timeOut) < Date.now())) {
          this.openPromptComponent('android-install').subscribe(result => {
            if (result) {
              this.promptEvent.prompt();
            } else {
              this.setTimeOut();
            }
          });
        }
      });
    }
    if (this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      this.isInStandaloneMode = (this.standalone in window.navigator) && (window.navigator[this.standalone]);
      if (!this.isInStandaloneMode) {
        if (isNullOrUndefined(timeOut) || (!!timeOut && Date.parse(timeOut) < Date.now())) {
          this.openPromptComponent('ios-install');
        } else {
          this.setTimeOut();
        }
      }
    }
    if (this.platform.BLINK) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.promptEvent = event;
        if (isNullOrUndefined(timeOut) || (!!timeOut && Date.parse(timeOut) < Date.now())) {
          this.openPromptComponent('web-install').subscribe(result => {
            if (result) {
              this.promptEvent.prompt();
            } else {
              this.setTimeOut();
            }
          });
        }
      });
    }
  }

  public checkForUpdates() {
    this.swUpdate.checkForUpdate().then(result => this.logger.debug('CHECK FOR UPDATES', result));
  }

  public promptPWA() {
    if (this.platform.ANDROID) {
      this.openPromptComponent('android-install').subscribe(result => {
        if (result) {
          this.promptEvent.prompt();
        } else {
          this.setTimeOut();
        }
      });
    }
    if (this.platform.IOS || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) {
      if (!this.isInStandaloneMode) {
        this.openPromptComponent('ios-install').subscribe(result => {
          if (!result) {
            this.setTimeOut();
          }
        });
      }
    }
    if (this.platform.BLINK) {
      this.openPromptComponent('web-install').subscribe(result => {
        if (result) {
          this.promptEvent.prompt();
        } else {
          this.setTimeOut();
        }
      });
    }
  }

  private openPromptComponent(prompt: 'ios-install' | 'android-install' | 'web-install' | 'update') {
    return timer(3000).pipe(
      takeWhile(() => !this.isPromptActive),
      take(1),
      tap(() => this.isPromptActive = true),
      flatMap(() => this.bottomSheet.open(PromptComponent, {data: {prompt, promptEvent: this.promptEvent}}).afterDismissed()),
      tap(() => this.isPromptActive = false),
    );
  }

  private setTimeOut(): void {
    const timeOut = new Date(Date.now() + environment.installSleepDays * 24 * 60 * 60 * 1000).toDateString();
    localStorage.setItem('INSTALL_TIMEOUT', timeOut);
  }
}
