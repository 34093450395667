import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RepositoryService, RepositoryViewModel, SharedTarget} from '../../../modules/store';
import {NGXLogger} from 'ngx-logger';

@Component({
  selector: 'hb-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

  private vm: RepositoryViewModel;

  public value = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private dialogRef: MatDialogRef<ShareComponent>,
    private formBuilder: FormBuilder,
    private repositoryService: RepositoryService,
    private logger: NGXLogger) {
    this.repositoryService.getById(data).subscribe(vm => this.vm = vm);
  }

  public get viewModel(): RepositoryViewModel {
    return this.vm;
  }

  ngOnInit() {
  }

  share() {
    this.repositoryService.share(this.viewModel.model, this.value);
  }

  delete(target: SharedTarget) {
    this.repositoryService.unShare(this.viewModel.model, target.targetUsrEmail);
  }
}
