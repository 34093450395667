import {createFeatureSelector, createSelector, MemoizedSelector, MemoizedSelectorWithProps} from '@ngrx/store';
import {ATTACHMENT_FEATURE_KEY, attachmentAdapter, AttachmentPartialState, AttachmentState} from './attachment.reducer';
import {AttachmentViewModel, attachmentViewModelFactory, MediaType} from './attachment.models';
import {Exception} from '../../../../../../core/models';
import {RouterSelectors} from '../../../router-store/src/+state/router.selectors';

const getAttachmentState = createFeatureSelector<AttachmentPartialState, AttachmentState>(ATTACHMENT_FEATURE_KEY);

const {selectAll} = attachmentAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getAttachmentState,
  (state: AttachmentState) => state.isLoading
);

const getException: MemoizedSelector<object, Exception> = createSelector(
  getAttachmentState,
  (state: AttachmentState) => state.exception
);

const getAllItems: MemoizedSelector<object, AttachmentViewModel[]> = createSelector(
  getAttachmentState,
  (state: AttachmentState) => selectAll(state).map(item => attachmentViewModelFactory(item))
);

const getItems: MemoizedSelectorWithProps<object, { targetId: number, mediaType: MediaType }, AttachmentViewModel[]> = createSelector(
  getAllItems,
  (items, props) => items.filter(item => item.targetId === props.targetId && item.mediaType === props.mediaType)
);

const getItem: MemoizedSelectorWithProps<object, { id: number }, AttachmentViewModel> = createSelector(
  getAllItems,
  (items, props) => items.filter(item => item.id === props.id)[0]
);


const getMaterialItems = createSelector(
  getAllItems,
  RouterSelectors.getMaterialIdParam,
  (items, id) => items.filter(item => item.targetId === id && item.mediaType === MediaType.MATERIAL_ATTACHMENT)
);

const getDocumentItems = createSelector(
  getAllItems,
  RouterSelectors.getDocumentIdParam,
  (items, id) => items.filter(item => item.targetId === id && item.mediaType === MediaType.DOCUMENT_ATTACHMENT)
);

export const AttachmentSelectors = {
  isLoading,
  getException,
  getAllItems,
  getItems,
  getItem,
  getMaterialItems,
  getDocumentItems,
};
