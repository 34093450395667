import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import * as fromForm from './+state/form.reducer';
import {EffectsModule} from '@ngrx/effects';
import {FormEffects} from './+state/form.effects';
import {FormDataService} from './+state/form-data.service';
import {NGXLogger} from 'ngx-logger';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forFeature(
      fromForm.FORM_FEATURE_KEY,
      fromForm.reducer
    ),
    EffectsModule.forFeature([FormEffects]),
  ],
  providers: [FormDataService],
  declarations: [],
  exports: []
})
export class FormStoreModule {

  constructor(private logger: NGXLogger) {
    logger.trace('*---------------------------------------------------------------------*');
    logger.trace('*', this.constructor.name, 'Loaded');
    logger.trace('*---------------------------------------------------------------------*');
  }

}
