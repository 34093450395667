import {core} from './environment.core';


export const base = {
  ...core,
  serviceNumber: '+4926319680778',
  contactEmail: 'support@myhomebase.de',
  style: {
    theme: 'homebase'
  },
  hasPaymentFeatures: false,
};
