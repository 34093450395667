import {createFeatureSelector, createSelector, MemoizedSelector} from '@ngrx/store';
import {TILE_FEATURE_KEY, tileAdapter, TilePartialState, TileState} from './tile.reducer';

const getTileState = createFeatureSelector<TilePartialState, TileState>(TILE_FEATURE_KEY);

const {selectAll, selectEntities} = tileAdapter.getSelectors();

const isLoading: MemoizedSelector<object, boolean> = createSelector(
  getTileState,
  (state: TileState) => state.isLoading
);

const getException = createSelector(
  getTileState,
  (state: TileState) => state.exception
);

const getAllTiles = createSelector(
  getTileState,
  (state: TileState) => selectAll(state)
);

const getAutocomplete = createSelector(
  getTileState,
  (state: TileState) => state.autocomplete
);

const getTileEntities = createSelector(
  getTileState,
  (state: TileState) => selectEntities(state)
);

export const TileSelectors = {
  isLoading,
  getException,
  getAllTiles,
  getAutocomplete,
  getTileEntities,
};
