import {SearchWithCameraScanCommandHandler} from '../../commands/search-with-camera-scan-command/search-with-camera-scan.command-handler';
import {Type} from '@angular/core';
import {FilterWithCameraScanCommandHandler} from '../../commands/filter-with-camera-scan-command/filter-with-camera-scan.command-handler';
import {OpenPrivacyCommandHandler} from '../../commands/open-privacy-command/open-privacy.command-handler';
import {SealDocumentCommandHandler} from '../../commands/seal-document-command/seal-document.command-handler';
import {OpenImprintCommandHandler} from '../../commands/open-imprint-command/open-imprint.command-handler';
import {CommandHandler} from '../command-bus/command.handler';

export const handlers: Type<CommandHandler>[] = [
  SearchWithCameraScanCommandHandler,
  FilterWithCameraScanCommandHandler,
  SealDocumentCommandHandler,
  OpenPrivacyCommandHandler,
  OpenImprintCommandHandler,
];
