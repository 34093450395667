<div fxLayout="column nowrap" fxLayoutAlign="space-between stretch">
  <div style="padding: 10px">
    <ng-content>

    </ng-content>
  </div>
  <mat-divider></mat-divider>
  <mat-radio-group fxLayout="row wrap" fxLayoutAlign="flex-start end" style="padding: 10px 10px 0 10px"
                   [value]="value" fxLayoutGap="1rem">
    <mat-radio-button *ngFor="let button of buttons" style="padding-bottom: 10px;"
                      [disabled]="disabled"
                      [appTheme]="validValue.toLowerCase().includes(button.value.toLowerCase()) ? 'success' : 'danger'"
                      (change)="writeValue(button.value)"
                      [value]="button.value"
                      labelPosition="before">
      {{button.caption}}
    </mat-radio-button>
  </mat-radio-group>
</div>




