<div class="h-full flex flex-col justify-center items-center flex-grow">
  <div class="max-w-screen-sm w-full md:max-w-3/4 lg:max-w-1/2 xl:max-w-1/3">
    <mat-card class="m-2 mat-elevation-z4">
      <div class="hb-card-image"></div>
      <mat-card-actions>
        <div class="flex flex-col flex-nowrap">
          <div class="flex flex-row flex-wrap justify-center align-items py-2">
            <a appTheme="primary" routerLink="/login">{{'keys.local.global.back_to_login' | translate}}</a>
          </div>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

