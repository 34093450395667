import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {AuthenticationDataService} from './authentication-data.service';
import {catchError, flatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from '../../../../../../../dialogs/message';
import {HintDialogService} from '../../../../../../../dialogs/hint';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {MatDialog} from '@angular/material/dialog';
import {Exception} from '../../../../../../core/models';
import {AccountActions} from '../../../account-store/src/+state/account.actions';
import {RepositoryActions} from '../../../repository-store/src/+state/repository.actions';
import {AuthenticationActions} from './authentication.actions';
import {UserActions} from '../../../user-store/src/+state/user.actions';
import {AppActions} from '../../../app-store/src/+state/app.actions';
import {RouterService} from '../../../router-store';


@Injectable()
export class AuthenticationEffects {

  refreshSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.refreshSuccess),
      map(action => action.payload),
      tap((payload) => this.authenticationDataService.setTokens(payload)),
    ), {dispatch: false}
  );

  loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.loginRequest),

      switchMap(action => this.authenticationDataService.login(action.request).pipe(
        map(payload => AuthenticationActions.loginSuccess({payload})),
        catchError(error => of(AuthenticationActions.loginFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.loginSuccess),

      tap((action) => this.authenticationDataService.setTokens(action.payload)),
      switchMap(action => this.authenticationDataService.getLoggedInData().pipe(
        map(payload => AuthenticationActions.loginComplete({payload})),
        catchError(error => of(AuthenticationActions.loginFailure({exception: new Exception(error, action)})))
      ))
    )
  );

  loginFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.loginFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  loginComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.loginComplete),

      mergeMap(({payload: {accountId, userId, repositoryId}}) => ([
        UserActions.setUserId({userId}),
        RepositoryActions.setRepositoryId({repositoryId}),
        AccountActions.setAccountId({accountId}),
        AuthenticationActions.loginFinish()
      ]))
    )
  );

  loginFinish$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.loginFinish),

      tap(() => this.routerService.home())
    ), {dispatch: false}
  );

  logoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logoutRequest),
      switchMap(action => this.authenticationDataService.logout().pipe(
        map(() => AuthenticationActions.logoutSuccess()),
        catchError(error => of(AuthenticationActions.logoutFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logoutSuccess),
      map(() => AuthenticationActions.clearSession()),
    )
  );

  logoutFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.logoutFailure),
      map(action => action.exception),
      tap(exception => AppActions.showFailureMessage({exception})),
      map(() => AuthenticationActions.clearSession())
    )
  );

  registerRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.registerRequest),

      switchMap((action) => this.authenticationDataService.register(action.request).pipe(
        map((payload) => AuthenticationActions.registerSuccess({payload})),
        catchError(error => of(AuthenticationActions.registerFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  registerSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.registerSuccess),

      map(action => action.payload),
      flatMap(() => this.hintDialogService.openSuccessV2(marker('keys.local.messages.registration_created')).afterClosed()),
      tap(() => this.routerService.login())
    ), {dispatch: false}
  );

  registerFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.registerFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  passwordForgottenRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.passwordForgottenRequest),

      switchMap((action) => this.authenticationDataService.passwordForgotten(action.request).pipe(
        map((payload) => AuthenticationActions.passwordForgottenSuccess({payload})),
        catchError(error => of(AuthenticationActions.passwordForgottenFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  passwordForgottenSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.passwordForgottenSuccess),

      map(action => action.payload),
      flatMap(() => this.hintDialogService.openSuccessV2(marker('keys.local.messages.password_reset_send_email_successful')).afterClosed()),
      tap(() => this.routerService.login())
    ), {dispatch: false}
  );

  passwordForgottenFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.passwordForgottenFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  resetPasswordRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.resetPasswordRequest),

      switchMap((action) => this.authenticationDataService.resetPassword(action.request).pipe(
        map((payload) => AuthenticationActions.resetPasswordSuccess({payload})),
        catchError(error => of(AuthenticationActions.resetPasswordFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  resetPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.resetPasswordSuccess),

      map(action => action.payload),
      flatMap(() => this.hintDialogService.openSuccessV2(marker('keys.local.messages.password_reset_successful')).afterClosed()),
      tap(() => this.routerService.login())
    ), {dispatch: false}
  );

  resetPasswordFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.resetPasswordFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  activateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.activateRequest),

      switchMap((action) => this.authenticationDataService.activate(action.request).pipe(
        map((payload) => AuthenticationActions.activateSuccess({payload})),
        catchError(error => of(AuthenticationActions.activateFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  activateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.activateSuccess),
      map(action => action.payload),
      flatMap(() => this.hintDialogService.openSuccessV2(marker('keys.local.messages.account_activated')).afterClosed()),
      tap(() => this.routerService.login())
    ), {dispatch: false}
  );

  activateFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.activateFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  changeEmailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.changeEmailRequest),

      switchMap((action) => this.authenticationDataService.changeEmail(action.request).pipe(
        map((payload) => AuthenticationActions.changeEmailSuccess({payload})),
        catchError(error => of(AuthenticationActions.changeEmailFailure({exception: new Exception(error, action)})))
      )),
    )
  );

  changeEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.changeEmailSuccess),
      map(action => action.payload),
      flatMap(() => this.hintDialogService.openSuccessV2(marker('keys.local.messages.email_changed')).afterClosed()),
      tap(() => this.routerService.login()),
      map(() => AuthenticationActions.clearSession())
    )
  );

  changeEmailFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.changeEmailFailure),

      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  refreshRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.refreshRequest),

      switchMap((action) => this.authenticationDataService.refreshToken(action.request).pipe(
        map((payload) => AuthenticationActions.refreshSuccess({payload})),
        catchError(error => of(AuthenticationActions.refreshFailure({exception: new Exception(error, action, 408)})))
      )),
    )
  );
  refreshFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.refreshFailure),
      map(action => action.exception),
      map(exception => AppActions.showFailureMessage({exception})),
    )
  );

  clearSession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.clearSession),
      tap((payload) => this.authenticationDataService.removeTokens()),
      tap(() => this.matDialog.closeAll()),
      tap(() => this.routerService.login()),
    ), {dispatch: false}
  );

  isLoggedInRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthenticationActions.isLoggedInRequest),
      mergeMap(() => this.authenticationDataService.isLoggedIn().pipe(
        map(() => AuthenticationActions.isLoggedInSuccess()),
        catchError(() => of(AuthenticationActions.isLoggedInFailure()))
      )),
    )
  );

  constructor(private actions$: Actions,
              private authenticationDataService: AuthenticationDataService,
              private routerService: RouterService,
              private translateService: TranslateService,
              private logger: NGXLogger,
              private messageService: MessageService,
              private hintDialogService: HintDialogService,
              private matDialog: MatDialog) {

  }
}
