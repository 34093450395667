import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Params} from '@angular/router';
import {QueryParams} from '@ngrx/data';
import {ProductEntity, ProductItemPayload, ProductItemResponse, ProductListPayload, ProductListResponse} from './product.models';
import {environment} from '../../../../../../../../environments';


@Injectable({
  providedIn: 'root'
})
export class ProductDataService {

  constructor(private http: HttpClient, private logger: NGXLogger) {
  }

  load(queryParams: Params): Observable<ProductListPayload> {
    return this.http.get<ProductListResponse>(`${environment.api}/products`, {params: queryParams}).pipe(
      map((res) => ({items: res.datalist, rows: res.rows}))
    );
  }

  get(productId: string | number, queryParams: Params): Observable<ProductItemPayload> {
    return this.http.get<ProductItemResponse>(
      `${environment.api}/products/${productId}`, {params: queryParams}).pipe(
      map(res => ({item: res.data}))
    );
  }

  persist(product: ProductEntity, queryParams: QueryParams) {
    return this.http.put<ProductItemResponse>(`${environment.api}/products/${product.id}`, product, {params: queryParams}).pipe(
      map(res => ({item: res.data}))
    );
  }

}
