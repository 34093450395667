import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'hb-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit {

  title: string;
  description: string;
  type: string;
  cancel = false;
  httpCode: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, description: string, type: string, cancel: boolean, httpCode: number },
    public translate: TranslateService) {
    this.title = data.title;
    this.description = data.description;
    this.type = data.type;
    this.cancel = data.cancel;
    this.httpCode = data.httpCode;
  }

  ngOnInit() {
  }

}
