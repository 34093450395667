import {animate, keyframes, style, transition, trigger} from '@angular/animations';

// const topLevelTransitions = [
//   query(':enter, :leave',
//     style({opacity: 0, 'z-index': '1000', width: '100vw'}),
//     {optional: true}),
//   group([
//     query(':enter', [
//       style({opacity: 0}),
//       animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
//         style({opacity: 1}))
//     ], {optional: true}),
//     query(':leave', [
//       style({opacity: 1}),
//       animate('250ms cubic-bezier(0.4, 0.0, 0.2, 1)',
//         style({opacity: 0}))
//     ], {optional: true}),
//   ]),
// ];

// const topLevelListTransitions = [
//   query(
//     ':enter',
//     [
//       style({opacity: 0, transform: 'translateY(-15px)'}),
//       stagger(
//         '50ms',
//         animate(
//           '550ms ease-out',
//           style({opacity: 1, transform: 'translateY(0px)'})
//         )
//       )
//     ],
//     {optional: true}
//   ),
//   query(':leave', animate('50ms', style({opacity: 0})), {
//     optional: true
//   })
// ];

// const listSlideInTransitions = [
//   query(
//     ':enter',
//     [
//       style({opacity: 0, transform: 'translateX(-45px)'}),
//       stagger(
//         '50ms',
//         animate(
//           '550ms ease-out',
//           style({opacity: 1, transform: 'translateX(0px)'})
//         )
//       )
//     ],
//     {optional: true}
//   ),
//   query(':leave', animate('50ms', style({opacity: 0})), {
//     optional: true
//   })
// ];

const bellTransitions = [
  animate('1s', keyframes([
    style({transform: 'rotateZ(0)'}),
    style({transform: 'rotateZ(30deg)'}),
    style({transform: 'rotateZ(-28deg)'}),
    style({transform: 'rotateZ(10deg)'}),
    style({transform: 'rotateZ(-8deg)'}),
    style({transform: 'rotateZ(0)'}),
  ]))
];

// export const topLevelAnimation =
//   trigger('topLevelAnimation', [
//     transition('* => *', topLevelTransitions),
//   ]);
//
// export const topLevelListAnimation =
//   trigger('topLevelListAnimation', [
//     transition('* <=> *', topLevelListTransitions)
//   ]);
//
// export const listSlideInAnimation =
//   trigger('listSlideInAnimation', [
//     transition('* <=> *', listSlideInTransitions)
//   ]);

export const bellAnimation =
  trigger('bellAnimation', [
    transition('* <=> *', bellTransitions)
  ]);


