import {ModuleWithProviders, NgModule, Type} from '@angular/core';
import {CommandBus} from './command.bus';
import {TokenBasedCommandHandlerRegistry} from './token-based-command-handler-registry';
import {CommandHandler} from './command.handler';
import {COMMAND_HANDLER_TOKEN} from './command.handler.token';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class CommandBusModule {

  static forRoot(handlers: Type<CommandHandler>[]): ModuleWithProviders<CommandBusModule> {
    const providers = handlers.map(handler => provideHandler(handler));
    return { ngModule: CommandBusModule, providers: [
        CommandBus,
        TokenBasedCommandHandlerRegistry,
        ...providers
      ]
    };
  }

  static forChild(handlers: Type<CommandHandler>[]): ModuleWithProviders<CommandBusModule> {
    const providers = handlers.map(handler => provideHandler(handler));
    return {
      ngModule: CommandBusModule,
      providers: [...providers],
    };
  }
}

function provideHandler(handler: Type<CommandHandler>): any {
  return {provide: COMMAND_HANDLER_TOKEN, multi: true, useClass: handler};
}
