import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import * as fromMaterial from './material.reducer';
import {select, Store} from '@ngrx/store';
import {QueryParams} from '@ngrx/data';
import {Observable} from 'rxjs';
import {Actions} from '@ngrx/effects';
import {filter, map} from 'rxjs/operators';
import {isNotNullOrUndefined, next} from '../../../../../../core';
import {Update} from '@ngrx/entity';
import {Params} from '@angular/router';
import {
  CreateMaterialRequest,
  GetMaterialRowsRequest,
  LoadAuditableMaterialListRequest,
  LoadMaterialsRequest,
  MaterialEntity,
  MaterialItemPayload,
  MaterialItemRequest,
  MaterialRemovePayload,
  MaterialViewModel,
  materialViewModelFactory,
} from './material.models';
import {MaterialSelectors} from './material.selectors';
import {MaterialActions} from './material.actions';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  items$ = this.store.pipe(select(MaterialSelectors.getItems));
  selected$ = this.store.pipe(
    select(MaterialSelectors.getSelected),
    filter(item => isNotNullOrUndefined(item))
  );
  parent$ = this.store.pipe(select(MaterialSelectors.getSelectedParent));
  children$ = this.store.pipe(select(MaterialSelectors.getSelectedChildren));
  isLoading$ = this.store.pipe(select(MaterialSelectors.getIsLoading));
  isSearching$ = this.store.pipe(select(MaterialSelectors.getIsSearching));
  exception$ = this.store.pipe(select(MaterialSelectors.getException));
  rows$ = this.store.pipe(select(MaterialSelectors.getRows));
  auditable$ = this.store.pipe(select(MaterialSelectors.getAuditable));

  itemsWithProps$(props: { repositoryId?: number, search?: string }): Observable<MaterialViewModel[]> {
    return this.store.pipe(select(MaterialSelectors.getItemsWithProps, props));
  }

  constructor(
    private store: Store<fromMaterial.MaterialPartialState>,
    private actions$: Actions,
    private logger: NGXLogger) {
  }

  load(request: LoadMaterialsRequest) {
    this.store.dispatch(MaterialActions.loadMaterialsRequest({request}));
    return this.actions$.pipe(
      next(MaterialActions.loadMaterialsSuccess)
    );
  }

  getRows(request: GetMaterialRowsRequest) {
    this.store.dispatch(MaterialActions.getMaterialRowsRequest({request}));
    return this.actions$.pipe(
      next(MaterialActions.getMaterialRowsSuccess),
      map(action => action.payload.rows)
    );
  }

  get(repositoryId: number, materialId: number, queryParams?: QueryParams) {
    this.store.dispatch(MaterialActions.getMaterialRequest({request: {repositoryId, materialId, queryParams}}));
  }

  children(repositoryId: number, materialId: number) {
    this.store.dispatch(MaterialActions.loadChildrenRequest({request: {repositoryId, materialId}}));
  }

  persist(material: MaterialEntity, queryParams?: any): Observable<MaterialEntity> {
    this.store.dispatch(MaterialActions.persistMaterialRequest({material, queryParams}));
    return this.actions$.pipe(
      next(MaterialActions.persistMaterialSuccess),
      map(action => action.payload.item)
    );
  }

  persistV2(vm: MaterialViewModel, queryParams?: Params): Observable<MaterialEntity> {
    this.store.dispatch(MaterialActions.persistMaterialRequest({material: vm.updated, queryParams}));
    return this.actions$.pipe(
      next(MaterialActions.persistMaterialSuccess),
      map(action => action.payload.item)
    );
  }

  create(request: CreateMaterialRequest): Observable<MaterialViewModel> {
    this.store.dispatch(MaterialActions.createMaterialRequest({request}));
    return this.actions$.pipe(
      next(MaterialActions.createMaterialSuccess),
      map(action => action.payload),
      map(payload => materialViewModelFactory(payload.item))
    );
  }

  delete(vm: MaterialViewModel): Observable<MaterialRemovePayload> {
    this.store.dispatch(MaterialActions.deleteMaterialRequest({material: vm.model}));
    return this.actions$.pipe(
      next(MaterialActions.deleteMaterialSuccess),
      map(action => action.payload)
    );
  }

  notify(material: MaterialEntity, notify: boolean) {
    this.store.dispatch(MaterialActions.setNotificationRequest({material, notify}));
  }

  update(request: MaterialItemRequest) {
    this.store.dispatch(MaterialActions.updateMaterialRequest({request}));
  }

  change(update: Update<MaterialEntity>) {
    this.store.dispatch(MaterialActions.updateMaterial({update}));
  }

  move(viewModel: MaterialViewModel, destination: number): Observable<MaterialItemPayload> {
    this.store.dispatch(MaterialActions.moveMaterialRequest({material: viewModel.model, destination}));
    return this.actions$.pipe(
      next(MaterialActions.moveMaterialSuccess),
      map(action => action.payload)
    );
  }

  selectWithFilter(search: string) {
    return this.store.select(MaterialSelectors.selectWithFilter, search);
  }


  auditable(request: LoadAuditableMaterialListRequest) {
    this.store.dispatch(MaterialActions.loadAuditableMaterialListRequest({request}));
    return this.actions$.pipe(
      next(MaterialActions.loadAuditableMaterialListSuccess)
    );
  }
}
