import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@Component({
  selector: 'hb-prompt',
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { prompt: 'ios-install' | 'android-install' | 'web-install' | 'update', promptEvent?: any },
    private bottomSheetRef: MatBottomSheetRef<PromptComponent>
  ) {
  }

  public accept(): void {
    this.bottomSheetRef.dismiss(true);
  }

  public close() {
    this.bottomSheetRef.dismiss(false);
  }

}
