import {Actions} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export class RootEffects {

  constructor(private actions$: Actions,
              private logger: NGXLogger) {

  }
}
