import {
  Auditable,
  ExceptionModel,
  PayloadItemModel,
  PayloadListModel,
  ResponseItemModel,
  ResponseListModel
} from '../../../../../../core/models';
import {EditableAuditViewModel} from '../../../../../../core/models/src/base-view-model';


export interface AccountItemRequest {
  accountId: number;
}

export interface AccountItemResponse extends ResponseItemModel<AccountEntity> {}

export interface AccountItemPayload extends PayloadItemModel<AccountEntity> {}

export interface AccountListResponse extends ResponseListModel<AccountEntity> {}

export interface AccountListPayload extends PayloadListModel<AccountEntity> {}

export interface AccountException extends ExceptionModel {}

export class AccountViewModel extends EditableAuditViewModel<AccountEntity> {

  constructor(model: AccountEntity) {
    super(model);
  }


  public get id(): number {
    return this.model.id;
  }

  public get name(): string {
    return this.model.name;
  }

  public set name(name: string) {
    this.changes.name = name;
  }

  public get licenseId(): number {
    return this.model.licenseId;
  }

  public get rootRepositoryGroupId(): number {
    return this.model.rootRepositoryGroupId;
  }

  public get ownerId(): number {
    return this.model.ownerId;
  }

  public set ownerId(ownerId: number) {
    this.changes.ownerId = ownerId;
  }

  public get customerId(): number {
    return this.model.customerId;
  }

  public get erpMapId(): number {
    return this.model.erpMapId;
  }

  public get addressId(): number {
    return this.model.addressId;
  }

  public get checkInterval() {
    return this.model.checkInterval;
  }

  public set checkInterval(value: number) {
    this.changes.checkInterval = value;
  }

  public get mediaId() {
    return this.model.mediaId;
  }

  public set mediaId(mediaId) {
    this.changes.mediaId = mediaId;
  }

  public get objectId(): string {
    return `acc://${this.model.id}`;
  }
}

export interface AccountEntity extends Auditable {
  id: number;
  name: string;
  licenseId: number;
  rootRepositoryGroupId: number;
  ownerId: number;
  customerId: number;
  erpMapId: number;
  addressId: number;
  checkInterval: number;
  mediaId: number;
}

export function accountViewModelFactory(model: AccountEntity): AccountViewModel {
  return new AccountViewModel(model);
}
