import {Injectable} from '@angular/core';
import {BaseDialogService} from '../../base/src/base-dialog.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {HintComponent} from './hint.component';
import {TranslateService} from '@ngx-translate/core';
import {remoteTranslationMap} from '../../../modules/core/maps/src/translation.maps';

@Injectable({
  providedIn: 'root'
})
export class HintDialogService extends BaseDialogService<HintComponent, any> {

  protected config: MatDialogConfig = {
    disableClose: false,
    hasBackdrop: false,
  };

  constructor(protected matDialog: MatDialog, private translateService: TranslateService) {
    super(matDialog, HintComponent);
  }

  public openError(message: string, httpCode?: number) {
    return this.open({
      type: 'error',
      title: this.translateService.instant('dialogs.error_dialog.title'),
      description: message,
      cancel: false,
      httpCode
    });
  }

  public openErrorV2(code: string, httpCode?: number) {
    return this.open({
      type: 'error',
      title: this.translateService.instant(remoteTranslationMap[`${code}_title`]),
      description: this.translateService.instant(remoteTranslationMap[`${code}_message`]),
      cancel: false,
      httpCode
    });
  }

  public openWarning(message: string) {
    return this.open({
      type: 'warn',
      title: this.translateService.instant('dialogs.warning_dialog.title'),
      description: message,
      cancel: true
    });
  }

  public openWarningV2(key: string) {
    return this.open({
      type: 'warn',
      title: this.translateService.instant('dialogs.warning_dialog.title'),
      description: this.translateService.instant(key),
      cancel: true
    });
  }

  public openSuccess(message: string) {
    return this.open({
      type: 'success',
      title: this.translateService.instant('dialogs.success_dialog.title'),
      description: message,
      cancel: false
    });
  }

  public openSuccessV2(key: string) {
    return this.open({
      type: 'success',
      title: this.translateService.instant('dialogs.success_dialog.title'),
      description: this.translateService.instant(key),
      cancel: false
    });
  }

  public openInfo(message: string) {
    return this.open({
      type: 'info',
      title: this.translateService.instant('dialogs.info_dialog.title'),
      description: message,
      cancel: false
    });
  }

  public openInfoV2(key: string, cancel: boolean = false) {
    return this.open({
      type: 'info',
      title: this.translateService.instant('dialogs.info_dialog.title'),
      description: this.translateService.instant(key),
      cancel
    });
  }

  public hasOpenErrorDialogs(httpCode?: number): boolean {
    return super.openDialogs().some(dialog => httpCode ? dialog.componentInstance.httpCode === httpCode : true);
  }
}
